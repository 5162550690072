import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    background: theme?.palette?.darkBackground?.main,
  },
  title: {
    boxSizing: 'border-box',
    background: theme?.palette?.header?.main,
    overflow: 'hidden',
    height: '86px',
    width: '100%',
    '@media only screen and (min-width:769px)': {
      height: '46px',
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    height: 0,
    flex: 1,
    padding: '0px 20px 14px 20px',
    '@media only screen and (min-width:426px)': {
      padding: '0px 40px 14px 40px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '0px 10% 14px 10%',
    },
  },
  reviewAndSelectContainer: {
    padding: '0px 10%',
    width: '100%',
  },
  reviewAndSelectWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 0,
    '@media only screen and (min-width:769px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '30px 0',
      borderBottom: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
    },
  },
  quizInstructionsWrapper: {
    width: '100%',
    height: '100%',
  },
  quizInstructionsMsg: {
    textAlign: 'center',
    padding: '0 20px',
  },
  reviewAndSubmitTestTextWrapper: {
    padding: '21px 20px',
    '@media only screen and (min-width:426px)': {
      padding: '21px 40px',
    },
  },
  reviewAndSubmitTestText: {
    fontSize: '20px',
    fontWeight: 600,
  },

  answeredWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  answeredText: {
    color: theme?.palette?.lightTextColor?.main,
  },
  questionFilterSelectContainer: {
    background: theme?.palette?.lightBackground?.main,
    height: '40px',
    width: '100%',
    padding: '5px',
    borderBottom: `solid 1px ${theme?.palette?.borders?.main}`,
  },
  questionFilterSelectWrapper: {
    height: '100%',
    width: '100%',
    border: 'solid 1px lightgray',
    borderRadius: '4px',
    '@media only screen and (min-width:769px)': {
      height: '30px',
      width: '200px',
    },
  },

  buttonWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: '46px',
    padding: '5px',
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
    borderTop: `solid 1px ${theme?.palette?.borders?.main}`,
  },
  cancelButton: {
    background: theme?.palette?.tertiaryButton?.main,
    color: theme?.palette?.tertiaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.tertiaryButton?.main}`,
    padding: '0 1rem',
    height: '100%',
    fontWeight: theme?.palette?.tertiaryButton?.fontWeight,
    marginRight: '0.3rem',
  },
  submitTestButton: {
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
    padding: '0 1rem',
    height: '100%',
    fontWeight: theme?.palette?.primaryButton?.fontWeight,
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
  },
}))
export { useStyles }
