import React from 'react'
import Button from '@material-ui/core/Button'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import LoopIcon from '@material-ui/icons/Loop'
import Grid from '@material-ui/core/Grid'
import ShowResultsFullScreenDialog from '../ShowResultsFullScreenDialog/ShowResultsFullScreenDialog'
import SubmissionAcknowledgement from './SubmissionAcknowledgement'
import Loader from '../Loader/Loader'
import { useStyles } from './SubmissionProgressContentStyles'

function SubmissionProgressContent({
  isError,
  isLoading,
  isEvaluationloading,
  statusIcon,
  message,
  helperMessage,
  buttonHandler,
  popUp,
  setPopUp,
  result,
  title,
}) {
  const classes = useStyles()
  return (
    <>
      <SubmissionAcknowledgement
        statusIcon={statusIcon}
        isLoading={isLoading}
        message={message}
        helperMessage={helperMessage}
      />
      {isEvaluationloading && (
        <SubmissionAcknowledgement
          statusIcon={
            <Grid item className={classes.submissionLoader}>
              <Loader size="3rem" />
            </Grid>
          }
          isLoading={isLoading}
          helperMessage="Evaluation is in progress"
        />
      )}
      {result && (
        <>
          <Button
            onClick={buttonHandler}
            endIcon={<KeyboardArrowDownIcon style={{ color: '#000000' }} />}
            data-testid="submitsuccesspage__showresultsbutton--test"
            className={classes.showResultsButton}
          >
            Show Results
          </Button>
          <ShowResultsFullScreenDialog
            popUp={popUp}
            setPopUp={setPopUp}
            result={result}
            title={title}
          />
        </>
      )}
      {isError && (
        <Button startIcon={<LoopIcon />} onClick={buttonHandler}>
          Try again
        </Button>
      )}
    </>
  )
}
export default SubmissionProgressContent
