import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
  },
  content: {
    boxSizing: 'border-box',
    width: '100%',
  },
  consoleBodyForMobiles: { height: '100%', width: '100%', overflowY: 'auto' },
  consoleBodyWrapper: { width: '100%', height: 'max-content' },
  consoleBody: {
    marginRight: (props) => (props?.editorWrapperWidth < 425 ? '12px' : '26px'),
  },
  pendingEvaluationMessage: {
    marginRight: (props) => (props?.editorWrapperWidth < 425 ? '10px' : '15px'),
  },
  compileMsgWrapper: {
    width: '100%',
  },
  compileMsgContainer: {
    marginRight: (props) => (props?.editorWrapperWidth < 425 ? '0px' : '10px'),
  },
  compileResultMsg: {
    fontSize: (props) => props?.fontSize,
  },
  compilationPassMsg: { fontSize: (props) => props?.fontSize, fontWeight: 600 },
  gapBetweenMsgAndTestCase: {
    height: (props) => (props?.editorWrapperWidth < 425 ? '15px' : '30px'),
  },
  compileMsgAndTestCaseWrapper: {
    width: '100%',
    paddingTop: (props) => (props?.editorWrapperWidth < 425 ? '12px' : '26px'),
    paddingBottom: (props) =>
      props?.editorWrapperWidth < 425 ? '15px' : '30px',
  },
  compileSuccessMsgWrapper: {
    marginRight: (props) => (props?.editorWrapperWidth < 425 ? '0px' : '10px'),
  },
  compileSuccessMsg: {
    fontSize: (props) => props?.fontSize,
    fontWeight: 600,
  },
  codeRunOutPut: {
    width: '100%',
    height: 'max-content',
  },
  testCasesContainer: {
    flex: 1,
    overflowY: 'auto',
  },
}))
export { useStyles }
