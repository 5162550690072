import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme?.palette?.darkBackground?.main,
  },
  header: {
    height: '46px',
    width: '100%',
    background: theme?.palette?.header?.main,
    paddingLeft: '16px',
  },
  body: {
    height: 'calc(100% - 46px)',
    width: '100%',
    overflowY: 'auto',
  },
  illustrationAndTextWrapper: {
    width: '100%',
    paddingBottom: '46px',
    '@media only screen and (min-width:426px)': {
      paddingBottom: '65px',
    },
    '@media only screen and (min-width:769px)': {
      paddingBottom: '85px',
    },
    '@media only screen and (min-width:1025px)': {
      paddingBottom: '100px',
    },
    '@media only screen and (min-width:1800px)': {
      paddingBottom: '125px',
    },
  },
  illustrationWrapper: {
    width: '75%',
    paddingBottom: '20px',
    '@media only screen and (min-width:426px)': {
      width: '55%',
      paddingBottom: '30px',
    },
    '@media only screen and (min-width:769px)': {
      width: '43%',
      paddingBottom: '50px',
    },
    '@media only screen and (min-width:1025px)': {
      width: '33%',
      paddingBottom: '60px',
    },
  },
  textWrapper: {
    width: '75%',
    '@media only screen and (min-width:426px)': {
      width: '55%',
    },
    '@media only screen and (min-width:769px)': {
      width: '45%',
    },
    '@media only screen and (min-width:1025px)': {
      width: '35%',
    },
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
    '@media only screen and (min-width:426px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:1800px)': {
      fontSize: '28px',
    },
  },
}))
export { useStyles }
