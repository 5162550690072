import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  showResultsButton: {
    background: theme?.palette?.secondaryButton?.main,
    color: theme?.palette?.secondaryButton?.contrastText,
    borderRadius: '20px',
    fontWeight: 'bold',
    padding: '0.5rem 1.2rem',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  submissionLoader: {
    marginBottom: '1rem',
  },
}))
export { useStyles }
