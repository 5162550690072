import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  select: {
    width: '8rem',
    height: '100%',
    border: 'solid 1px lightgray',
    borderRadius: '5px',
  },
  editorSettingsHeading: {
    fontWeight: 600,
  },
  editorSettingsWrapper: {
    background: 'whitesmoke',
    padding: '0.7rem 0.5rem 0.5rem 1rem',
    marginBottom: '1rem',
  },
  settingsWrapper: {
    marginBottom: '2rem',
  },
}))
export { useStyles }
