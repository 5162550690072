import React from 'react'
import Typography from '@material-ui/core/Typography'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import IndiaFlagIcon from '../../Icons/IndiaFlagIcon'
import UKFlagIcon from '../../Icons/UKFlagIcon'
import Logo from '../Logo/Logo'
import { useStyles } from './HomePageFooterStyles'

function HomePageFooter() {
  const classes = useStyles()
  return (
    <div className={classes.root} data-testid="render-home-page-footer">
      <div className={classes.footerWrapper}>
        <div className={classes.footerContentWrapper}>
          <div className={classes.footerColumnWrapper}>
            <Typography className={classes.footerColumnHeading}>
              Company
            </Typography>
            <Typography className={classes.footerColumnCellText}>
              <a
                href="https://zopsmart.com/about"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                About us
              </a>
            </Typography>
            <Typography
              className={classes.footerColumnCellText}
              style={{ paddingBottom: 0 }}
            >
              <a
                href="https://zopsmart.com/about/#team"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                Our Team
              </a>
            </Typography>
          </div>

          <div className={classes.footerColumnWrapper}>
            <Typography className={classes.footerColumnHeading}>
              Contact us
            </Typography>
            <Typography className={classes.footerColumnCellText}>
              <a href="tel:+91 80411 26521" className={classes.link}>
                <IndiaFlagIcon />
                &nbsp; +91 80411 26521
              </a>
            </Typography>
            <Typography className={classes.footerColumnCellText}>
              <a href="tel:+44 23820 29871" className={classes.link}>
                <UKFlagIcon />
                &nbsp; +44 23820 29871
              </a>
            </Typography>
            <Typography
              className={classes.footerColumnCellText}
              style={{ paddingBottom: 0 }}
            >
              <a href="mailto:info@zopsmart.com" className={classes.link}>
                <MailOutlineIcon className={classes.instituteMail} />
                &nbsp; info@zopsmart.com
              </a>
            </Typography>
          </div>

          <div
            className={classes.footerColumnWrapper}
            style={{ paddingBottom: 0 }}
          >
            <Typography className={classes.footerColumnHeading}>
              Stay in touch
            </Typography>
            <div className={classes.iconWrapper}>
              <a
                href="https://twitter.com/zopsmart"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                aria-label="Link to Zopsmart Twitter page"
              >
                <TwitterIcon className={classes.twitterIcon} />
              </a>
              <a
                href="https://www.facebook.com/ZopSmart"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                aria-label="Link to Zopsmart Facebook page"
              >
                <FacebookIcon className={classes.faceBookIcon} />
              </a>

              <a
                href="https://www.linkedin.com/company/2325998/"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                aria-label="Link to Zopsmart LinkedIn page"
              >
                <LinkedInIcon className={classes.linkedInIcon} />
              </a>
            </div>
          </div>
        </div>

        <div className={classes.footerContainer}>
          <div className={classes.footerDivider} />
        </div>

        <div className={classes.footerEndWrapper}>
          <div className={classes.logoWrapper}>
            <Logo size="big" />
          </div>

          <Typography className={classes.institueName}>
            © Raramuri Technology Private Limited, 2023. All rights reserved
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default HomePageFooter
