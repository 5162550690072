import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    outline: 'none',
    background: (props) =>
      props?.isAnswered ? theme?.palette?.primaryButton?.main : props.bgColor,
    border: (props) => {
      if (props?.isAnswered) {
        return `solid 1px ${theme?.palette?.primaryButton?.main}`
      }

      if (props?.isSelected) {
        return `solid 1px ${theme?.palette?.primarySelection?.main}`
      }

      return `solid 1px ${theme?.palette?.borders?.main}`
    },

    minWidth: '30px',

    borderRadius: '4px',

    height: '30px',
    '&:hover': {
      background: (props) =>
        props?.isAnswered
          ? theme?.palette?.primaryButton?.hover
          : props.bgColor,
    },
  },
  buttonText: {
    fontSize: '12px',
    color: (props) => {
      if (props?.isAnswered) {
        return theme?.palette?.primaryButton?.contrastText
      }

      if (props?.isSelected) {
        return theme?.palette?.primarySelection?.main
      }

      return theme?.palette?.darkTextColor?.main
    },
    fontWeight: (props) => (props?.isSelected ? 600 : 400),
  },
}))
export { useStyles }
