import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Logo from '../Logo/Logo'
import ResultPage from '../ResultPage/ResultPage'
import useWindowSize from '../../hooks/useWindowSize'
import { useStyles } from './ShowResultsFullScreenDialogStyles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function ShowResultsFullScreenDialog({ popUp, setPopUp, result, title }) {
  const classes = useStyles()
  const windowSize = useWindowSize()

  return (
    <Dialog
      fullScreen
      open={popUp}
      TransitionComponent={Transition}
      data-testid="submitsuccesspage__dialog--test"
      className={classes.resultPageDialog}
      style={{ height: `${windowSize?.height}px` }}
    >
      <Grid container direction="column" className={classes.root}>
        <Grid container alignItems="center" className={classes.header}>
          <Grid item>
            <Logo />
          </Grid>
          <Grid item className={classes.closeIconWrapper}>
            <IconButton
              onClick={() => setPopUp(() => false)}
              className={classes.closeIcon}
            >
              <CloseIcon className={classes.closeIconStyle} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.marksWrapper}
        >
          <Typography className={classes.evaluatedMarks}>
            Marks: {result?.evaluatedMarks}/{result?.maximumMarks}
          </Typography>
        </Grid>

        <Grid item className={classes.resultPageWrapper}>
          <ResultPage result={result} title={title} />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ShowResultsFullScreenDialog
