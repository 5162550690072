import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import useWindowSize from '../../hooks/useWindowSize'
import HomePageHeader from '../HomePageHeader/HomePageHeader'
import HomePageFooter from '../HomePageFooter/HomePageFooter'
import HomePagePricingCard from '../HomePagePricingCard/HomePagePricingCard'
import { pricingTableData } from '../../utils'
import { useStyles } from './HomePagePricingStyles'

function HomePagePricing() {
  const [allPlans, setAllPlans] = useState([])
  const windowSize = useWindowSize()
  const classes = useStyles({})

  useEffect(() => {
    setAllPlans(() => pricingTableData?.data)
  }, [])

  const renderClassname = (index, length) => {
    if (index === 0) {
      return classes.leftPaddingPricingCardWrapper
    }
    if (index === length - 1) {
      return classes.rightPaddingPricingCardWrapper
    }
    return classes.paddingPricingCardWrapper
  }

  return (
    <div
      className={classes.root}
      style={{
        height: `${windowSize?.height}px`,
      }}
      data-testid="pricing-card"
    >
      <div className={classes.homePageHeaderContainer}>
        <HomePageHeader />
      </div>

      <div className={classes.pricingTableAndFooterWrapper}>
        <div
          style={{
            minHeight: `calc(${windowSize?.height}px - 65px)`,
          }}
          className={classes.pricingTableAndFooterWrapperChild}
        >
          <div className={classes.pricingPlanContainer}>
            <div className={classes.pricingPlanHeadingWrapper}>
              <Typography className={classes.pricingPlanHeading}>
                Explore Our Pricing Options
              </Typography>
            </div>
            <div className={classes.pricingPlanCaptionWrapper}>
              <Typography className={classes.pricingPlanCaption}>
                1c = 0.05 USD
              </Typography>
            </div>
            <div className={classes.pricingCardsContainer}>
              <div className={classes.pricingCardsWrapper}>
                {allPlans?.map((planItem, index) => {
                  return (
                    <div
                      key={index?.toString(2)}
                      className={renderClassname(index, allPlans?.length)}
                    >
                      <div className={classes.pricingCardWrapper}>
                        <HomePagePricingCard pricingPlan={planItem} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={classes.homePageFooterContainer}>
            <HomePageFooter />
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomePagePricing
