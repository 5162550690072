import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    background: theme?.palette?.lightBackground?.main,
  },
  quizInfoWrapper: {
    width: '100%',
    height: '0px',
    flex: 1,
    overflowY: 'auto',
  },
  topContainer: {
    boxSizing: 'border-box',
    background: theme?.palette?.header?.main,
    height: 'max-content',
    width: '100%',
    padding: '20px',
  },
  logo: {
    display: 'flex',
    width: '100%',
    paddingBottom: '50px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  testVideoWrapper: {
    width: 'max-content',
  },
  cameraOnIcon: {
    height: '24px',
    width: '24px',
  },
  testVideo: {
    maxWidth: '130px',
    color: theme?.palette?.secondaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.secondaryButton?.border}`,
    padding: '5px 12px',
    fontWeight: 600,
  },
  rightContainer: {
    width: '65%',
    height: '100%',
    background: theme?.palette?.darkBackground?.main,
  },

  titleAndDetailsWrapper: {
    boxSizing: 'border-box',
    padding: '10px',
    overflowY: 'auto',
    width: '100%',
    height: 'max-content',
    '@media only screen and (min-width:426px)': {
      padding: '15px',
    },
    '@media only screen and (min-width:650px)': {
      padding: '20px',
    },
  },
  questionAndDurationDetails: {
    width: '100%',
    height: 'max-content',
  },
  title: {
    fontSize: '18px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '125%',
    paddingBottom: '25px',
    '@media only screen and (min-width:426px)': {
      fontSize: '20px',
    },
  },

  durationIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
    '@media only screen and (min-width:426px)': {
      width: '22px',
      height: '22px',
    },
  },
  durationText: {
    fontSize: '14px',
    color: theme?.palette?.greenTextColor?.main,
    flex: 1,
    borderBottom: `solid 1px ${theme?.palette?.onboardingPageDividerLines?.main}`,
    paddingBottom: '15px',
    '@media only screen and (min-width:426px)': {
      fontSize: '16px',
    },
  },
  questionDetails: {
    paddingTop: '15px',
  },
  questionsIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
    '@media only screen and (min-width:426px)': {
      width: '22px',
      height: '22px',
    },
  },
  questionCountDetails: {
    flex: 1,
  },
  questionsText: {
    fontSize: '14px',
    color: theme?.palette?.greenTextColor?.main,
    paddingBottom: '10px',
    '@media only screen and (min-width:426px)': {
      fontSize: '16px',
    },
  },
  multipleChoiceQuestionsText: {
    fontSize: '11px',
    color: theme?.palette?.onboardingPageGrayTextColor?.main,
    '@media only screen and (min-width:426px)': {
      fontSize: '12px',
    },
    paddingBottom: (props) => (props?.codingQuestions ? '5px' : '0px'),
  },
  questionsCount: {
    fontWeight: 700,
  },
  codingQuestionsText: {
    fontSize: '11px',
    color: theme?.palette?.onboardingPageGrayTextColor?.main,
    '@media only screen and (min-width:426px)': {
      fontSize: '12px',
    },
  },
  divider: {
    borderTop: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    width: '100%',
    height: 0,
    paddingBottom: '20px',
  },

  bottomContainer: {
    height: 'max-content',
    width: '100%',
    padding: '30px',
    '@media only screen and (min-width:426px)': {
      padding: '35px',
    },
    '@media only screen and (min-width:650px)': {
      padding: '40px',
    },
  },

  descriptionHeading: {
    fontSize: '16px',
    fontWeight: 700,
    wordBreak: 'break-word',
    paddingBottom: '10px',
    color: '#202020',
  },

  descriptionContainer: {
    paddingBottom: '20px',
  },
  descriptionWrapper: {
    paddingBottom: '5px',
  },
  descriptionBeforeViewMore: {
    fontSize: '12px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    color: '#202020',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'elipsis',
  },

  description: {
    fontSize: '12px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    color: '#202020',
  },

  instructionsHeading: {
    fontSize: '16px',
    fontWeight: 700,
    wordBreak: 'break-word',
    paddingBottom: '15px',
    color: '#202020',
  },

  instructionsContainer: {
    paddingBottom: '20px',
  },

  instructionsWrapper: {
    paddingBottom: '5px',
  },

  instructionsBeforeViewMore: {
    fontSize: '12px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    color: '#202020',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'elipsis',
  },

  instructions: {
    fontSize: '12px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    color: '#202020',
  },

  viewMore: {
    color: theme?.palette?.primarySelection?.main,
    justifyContent: 'flex-start',
    fontSize: '12px',
    padding: 0,
    width: 'max-content',
    '&:hover': {
      background: 'none',
    },
  },

  ctaWrapper: {
    width: '100%',
    minHeight: (props) =>
      !props?.isQuizStarted || props?.isQuizEnded ? '50px' : '90px',
    height: 'max-content',
    padding: (props) =>
      !props?.isQuizStarted || props?.isQuizEnded ? '0px 30px' : '20px 30px',
    '@media only screen and (min-width:426px)': {
      padding: (props) =>
        !props?.isQuizStarted || props?.isQuizEnded ? '0px 30px' : '20px 35px',
    },
    '@media only screen and (min-width:650px)': {
      padding: (props) =>
        !props?.isQuizStarted || props?.isQuizEnded ? '0px 30px' : '20px 40px',
    },
  },
}))
export { useStyles }
