import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: '100%',
  },
  editorConfigsContainer: {
    height: '100%',
  },
  langToolTip: {
    marginLeft: '0.5rem',
    fontSize: '1rem',
  },
  tabChangeSnackBar: { background: 'goldenrod', color: 'black' },
  submitFailedSnackBar: { background: 'crimson', color: 'whitesmoke' },
  codeRetrieveLoader: { color: 'gray' },
  previousIconWrapper: {
    height: '24px',
    width: '24px',
  },
  editorConfigs: {
    width: '100%',
    height: '40px',
    background: theme?.palette?.lightBackground?.main,
    borderTop: `solid 1px ${theme?.palette?.borders?.main}`,
    '@media only screen and (min-width:769px)': {
      borderTop: 'none',
    },
  },
  runCodeButtonWrapper: {
    width: 'max-content',
    height: '100%',
  },
  questionLoader: {
    color: 'whitesmoke',
  },
  retrieveCodeSubmissionContainer: {
    padding: (props) => (props?.editorWrapperWidth > 376 ? '0 11px' : '0 7px'),
    borderRight: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    borderLeft: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    height: '100%',
    width: 'max-content',
  },

  restoreCodeStubContainer: {
    padding: (props) => (props?.editorWrapperWidth > 376 ? '0 11px' : '0 7px'),
    borderRight: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    height: '100%',
    width: 'max-content',
  },

  editorSettingsContainer: {
    padding: (props) => (props?.editorWrapperWidth > 376 ? '0 11px' : '0 7px'),
    borderRight: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    height: '100%',
    width: 'max-content',
  },

  themeContainer: {
    padding: (props) => (props?.editorWrapperWidth > 376 ? '0 11px' : '0 7px'),
    height: '100%',
    width: 'max-content',
  },

  editorAndConsoleWrapper: {
    width: '100%',
    backgroundColor: (props) =>
      props?.isDarkMode
        ? theme?.palette?.codeEditorBackground?.darkMode
        : theme?.palette?.codeEditorBackground?.lightMode,
    height: 0,
    flex: 1,
  },

  editorWrapper: {
    width: '100%',
    height: (props) => {
      if (props.consoleOpened) {
        return 0
      }
      return '100%'
    },
    flex: (props) => {
      if (props.consoleOpened) {
        return 1
      }
      return 'none'
    },
    '@media only screen and (min-width:769px)': {
      height: (props) => {
        if (props.consoleOpened) {
          return 0
        }
        return '100%'
      },
      flex: (props) => {
        if (props.consoleOpened) {
          return 1
        }
        return 'none'
      },
    },
  },
  customInputTextField: {
    background: 'whitesmoke',
    minHeight: '100%',
  },

  blurredWrapper: {
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)',
    position: 'absolute',
    zIndex: 1000,
    '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)': {
      backdropFilter: 'blur(3px)',
      WebkitBackdropFilter: 'blur(3px)',
    },
    '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))':
      {
        background: 'rgba(255, 255, 255, .7)',
      },
  },
  lockIconWrapper: {
    height: '30%',
    width: '30%',
  },
  consoleHeaderWrapper: {
    boxSizing: 'border-box',
    height: 'max-content',
    width: '100%',
    borderBottom: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    borderTop: `solid 1px ${theme?.palette?.dividerLines?.main}`,
  },

  consoleBodyWrapper: {
    width: '100%',
    height: '40%',
    padding: '0px 12px',
    background: theme?.palette?.lightBackground?.main,
    '@media only screen and (min-width:426px)': {
      padding: '0px 24px',
    },
  },

  languageSelect: {
    height: '100%',
    width: (props) => (props?.editorWrapperWidth > 425 ? '170px' : '100%'),

    border: 'solid 1px lightgray',
    borderRadius: '5px',
  },

  buttonWrapper: {
    boxSizing: 'border-box',
    height: '46px',
    padding: '5px 5px 5px 10px',
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
    borderTop: `solid 1px ${theme?.palette?.borders?.main}`,
    '@media only screen and (min-width:426px)': {
      padding: '5px 5px 5px 20px',
    },
  },

  runCodeButton: {
    background: theme?.palette?.tertiaryButton?.main,
    color: theme?.palette?.tertiaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.tertiaryButton?.main}`,
    width: (props) => {
      if (props?.editorWrapperWidth > 376) {
        return '104px'
      }
      if (props?.editorWrapperWidth > 321) {
        return '55px'
      }
      return '25px'
    },
    height: '100%',
    fontWeight: theme?.palette?.tertiaryButton?.fontWeight,
    marginRight: '0.3rem',
    '&:hover': {
      background: theme?.palette?.tertiaryButton?.main,
    },
  },
  continueButton: {
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
    width: (props) => {
      if (props?.editorWrapperWidth > 319) {
        return '148px'
      }

      return '108px'
    },
    height: '100%',
    fontWeight: theme?.palette?.primaryButton?.fontWeight,
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
  },
}))
export { useStyles }
