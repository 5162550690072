import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  warningDialogButtons: {
    margin: '0 0.5rem 0.5rem 0',
  },
  cancelButton: {
    fontWeight: 600,
  },
  confirmButton: {
    fontWeight: 600,
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
  },
}))
export { useStyles }
