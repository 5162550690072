import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {
  MOCK_BASE_URL,
  CANDIDATE_ENDPOINT,
  QUIZ_ENDPOINT,
} from '../../constants'
import { createFetcher } from '../../Fetcher/Fetcher'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * this function is to make url according to request parameters
 * @function urlBuilder
 ** @param {url, reqParam} default parameter
 */

export const urlBuilder = () => {}
/**
 * Creates a new Quiz.
 * @Applications
 */
export const unixTimeStamp = (t) => {
  const dateString = new Date(t * 1000)
    .toISOString()
    .slice(0, 16)
    .replace('T', ' ')
  const timeZoneFormattedTime = dayjs(dateString).utc(true).local().format()
  return timeZoneFormattedTime
}

export class Quiz {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Quiz(
        MOCK_BASE_URL,
        CANDIDATE_ENDPOINT,
        QUIZ_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(baseUrl, candidateEndpoint, endpoint, fetcherObj) {
    this.base_url = baseUrl
    this.endpoint = endpoint
    this.candidateEndpoint = candidateEndpoint
    this.fetcher = fetcherObj
  }

  /**
   * this function is to get all the details related to quiz
   * @function getQuizById
   ** @param {reqParam} default parameter
   */

  async getQuizById(reqId) {
    const url = `${
      this.base_url + this.candidateEndpoint + this.endpoint
    }/${reqId}`
    const response = await this.fetcher.getData(url)
    const quiz = response?.data?.data
    if (quiz?.time?.start !== null) {
      quiz.time.start = unixTimeStamp(quiz?.time?.start)
    }
    if (quiz?.time?.end !== null) {
      quiz.time.end = unixTimeStamp(quiz?.time?.end)
    }
    // if (quiz?.createdAt !== null) {
    //   quiz.createdAt = unixTimeStamp(quiz?.createdAt)
    // }
    return response?.data?.data
  }

  /**
   * this function is to get all applications
   * @function getUserResponseByQuizId
   ** @param {reqParam} default parameter
   */

  async getUserResponseByQuizId(reqId, reqUserIdentifier) {
    const url = `${
      this.base_url + this.candidateEndpoint + this.endpoint
    }/${reqId}/response?userID=${reqUserIdentifier}`
    const response = await this.fetcher.getData(url)

    const userResponse = response?.data?.data?.data

    if (userResponse) {
      localStorage.setItem('userResponse', JSON.stringify(userResponse?.[0]))
      return userResponse?.[0]
    }

    return userResponse
  }
  /**
   * this function is to get all applications
   * @function getUserResponseByQuizId
   ** @param {reqParam} default parameter
   */

  async getQueryEvaluatedResponseById(reqId) {
    const url = `${`${
      this.base_url + this.candidateEndpoint
    }/quiz-response`}/${reqId}`
    const response = await this.fetcher.getData(url)
    const userResponse = response?.data?.data
    return userResponse
  }

  /**
   * this function is to get all applications
   * @function getQuizById
   ** @param {reqParam} default parameter
   */

  /**
   * this function is to get all applications
   * @function putQuizResponseById
   ** @param {reqParam} default parameter
   */

  async putQuizResponseById(reqId) {
    const url = `${
      this.base_url + this.candidateEndpoint + this.endpoint
    }/${reqId}`
    const response = await this.fetcher.putData(url, null)
    return response?.data?.data
  }

  /**
   * this function is to get all applications
   * @function postQuizById
   ** @param {reqParam} default parameter
   */

  async postQuizById(reqId) {
    const url = `${this.base_url}${this.candidateEndpoint}${this.endpoint}/${reqId}`
    const response = await this.fetcher.postData(url, null)
    return response?.data?.data
  }

  /**
   * this function is to get all applications
   * @function putMCQById
   ** @param {reqParam} default parameter
   */

  async postMCQById(reqQuestionId, reqData) {
    let quesitonTypeFieldName
    const url = `${
      this.base_url + this.candidateEndpoint
    }/quiz-question-response/${reqData?.questionId}`
    quesitonTypeFieldName =
      reqData?.type === 'mcq' ? 'selectedChoice' : 'multiSelectedChoices'
    if (reqData?.type === 'essay') {
      if (reqData?.type !== '') {
        quesitonTypeFieldName = 'essayAnswer'
        const response = await this.fetcher.postData(url, {
          [quesitonTypeFieldName]: reqData?.essayAnswer,
        })
        return response?.data?.data
      }
    }
    if (reqData?.type === 'fill-in-blanks') {
      if (reqData?.type !== '') {
        quesitonTypeFieldName = 'answer'
        const response = await this.fetcher.postData(url, {
          [quesitonTypeFieldName]: reqData?.answer,
        })
        return response?.data?.data
      }
    }
    const response = await this.fetcher.postData(url, {
      [quesitonTypeFieldName]: reqData?.selectedChoice,
    })
    return response?.data?.data
  }

  /**
   * this function is to get all applications
   * @function putCodingQuestionById
   ** @param {reqParam} default parameter
   */
  async postCodingQuestionById(reqQuizId, reqData) {
    const url = `${
      this.base_url + this.candidateEndpoint
    }/quiz-question-response/${reqData?.questionId}`

    const response = await this.fetcher.postData(url, {
      language: reqData?.language,
      code: reqData?.code,
    })
    return response?.data?.data
  }

  /**
   * this function is to get all applications
   * @function getQuestionSubmisisonById
   ** @param {reqParam} default parameter
   */

  async getQuestionSubmisisonById(reqId, reqQuestionId, reqUserIdentifier) {
    const url = `${
      this.base_url + this.candidateEndpoint + this.endpoint
    }/${reqId}/coding/${reqQuestionId}/user/${reqUserIdentifier}`
    const response = await this.fetcher.getData(url)

    return response?.data?.data
  }

  /**
   * this function is to get all applications
   * @function patchTabChange
   ** @param {reqParam} default parameter
   */
  async patchTabChange() {
    const url = `${this.base_url + this.candidateEndpoint}/tab-change`
    const response = await this.fetcher.patchData(url, null)
    return response?.data?.data
  }
}

export const quizzes = () => Quiz.getInstance()
