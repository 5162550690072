import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme?.palette?.darkBackground?.main,
    overflow: 'hidden',
  },

  header: {
    height: 'max-content',
    width: '100%',
    background: theme?.palette?.header?.main,
    paddingBottom: '43px',
    '@media only screen and (min-width:426px)': {
      paddingBottom: '53px',
    },
    '@media only screen and (min-width:769px)': {
      paddingBottom: '50px',
    },
    '@media only screen and (min-width:1025px)': {
      paddingBottom: '60px',
    },
    '@media only screen and (min-width:1100px)': {
      paddingBottom: '70px',
    },
  },

  body: {
    position: 'relative',
    height: 0,
    width: '100%',
    flex: 1,
    padding: '0 20px',
    bottom: '43px',
    '@media only screen and (min-width:426px)': {
      padding: '0 40px',
      bottom: '53px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '0 145px',
      bottom: '50px',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '0 220px',
      bottom: '60px',
    },
    '@media only screen and (min-width:1100px)': {
      padding: '0 25%',
      bottom: '70px',
    },
  },

  cookiesDialogContent: {
    padding: '0 15px 25px 15px',
    '@media only screen and (min-width:426px)': {
      padding: '0 20px 25px 20px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '0 25px 25px 35px',
    },
    '@media only screen and (min-width:1100px)': {
      padding: '0 30px 25px 30px',
    },
  },
  cookiesMsgWrapper: {
    paddingBottom: '15px',
    fontWeight: 600,
  },
  cookiesMsg: {
    paddingBottom: '5px',
  },
}))
export { useStyles }
