import React from 'react'

function GreenTickCircleOutlinedIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Green-icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="utility/messaging/success-copy" fillRule="nonzero">
          <path
            d="M9.57129,15.2036113 C9.37683957,15.2040094 9.19286725,15.1155259 9.07179,14.96337 L7.46631,12.941895 C7.24866783,12.6659406 7.29516769,12.2659438 7.57030998,12.0472759 C7.84545227,11.828608 8.24561942,11.8736186 8.46531,12.147945 L9.6738,13.67139 L15.637185,8.93409 C15.8155528,8.79124653 16.0566933,8.7547003 16.2693765,8.83827771 C16.4820598,8.92185512 16.6338066,9.11279293 16.6672071,9.3388543 C16.7006077,9.56491568 16.6105613,9.79157841 16.431135,9.93309 L9.968265,15.065925 C9.85516774,15.155118 9.71532604,15.2036113 9.57129,15.2036113 Z M12,21.1875 C6.92588386,21.1875 2.8125,17.0741161 2.8125,12 C2.8125,6.92588386 6.92588386,2.8125 12,2.8125 C17.0741161,2.8125 21.1875,6.92588386 21.1875,12 C21.181359,17.0715703 17.0715703,21.181359 12,21.1875 Z M12,3.9375 C7.5472042,3.9375 3.9375,7.5472042 3.9375,12 C3.9375,16.4527958 7.5472042,20.0625 12,20.0625 C16.4527958,20.0625 20.0625,16.4527958 20.0625,12 C20.05754,7.54926031 16.4507397,3.94245997 12,3.9375 Z"
            id="Color"
            fill="#00B7A4"
          />
          <rect
            id="success"
            fillOpacity="0"
            fill="#FFFFFF"
            x="0"
            y="0"
            width="24"
            height="24"
          />
        </g>
      </g>
    </svg>
  )
}

export default GreenTickCircleOutlinedIcon
