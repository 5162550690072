import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  header: {
    height: '46px',
    width: '100%',
    background: theme?.palette?.header?.main,
    paddingLeft: '16px',
  },
  body: {
    height: 'calc(100% - 46px)',
    width: '100%',
    overflowY: 'auto',
  },
  videoCamWrapper: {
    height: '100px',
    width: '100px',
    padding: '20px 0px',
    backgroundColor: '#f3f7f7',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videoCamIcon: {
    fontSize: '45px',
  },
  allowCameraWrapper: {
    fontSize: '20px',
    fontWeight: '500',
    marginTop: '16px',
  },
  requestPermissionMessage: {
    fontSize: '11px',
    '@media only screen and (min-width:375px)': {
      fontSize: '14px',
    },
  },
  allowBtn: {
    backgroundColor: `${theme?.palette?.primaryButton?.main}`,
    color: `${theme?.palette?.primaryButton?.contrastText}`,
    margin: '20px 0px',
    '&:hover': {
      backgroundColor: `${theme?.palette?.primaryButton?.hover}`,
    },
  },
}))
export { useStyles }
