import React, { useRef, useEffect } from 'react'
import AceEditor from 'react-ace'
import 'ace-builds'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/mode-golang'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/keybinding-vscode'
import 'ace-builds/src-noconflict/keybinding-vim'
import 'ace-builds/src-noconflict/keybinding-emacs'
import 'ace-builds/src-noconflict/keybinding-sublime'
import { aceModesForLanguages } from '../../utils'

function CodeEditor({
  language,
  isDarkMode,
  code,
  onCodeEditorChange,
  editorSettings,
  readOnly,
  width,
  height,
}) {
  const editorRef = useRef(null)

  const stopEvent = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }
  useEffect(() => {
    const editorElement = document.querySelector('#ACE_EDITOR')
    if (editorElement) {
      editorElement.addEventListener('paste', stopEvent, true)
      editorElement.addEventListener('dragstart', stopEvent, true)
      editorElement.addEventListener('drop', stopEvent, true)
    }

    return () => {
      if (editorElement) {
        editorElement.removeEventListener('paste', stopEvent)
        editorElement.removeEventListener('dragstart', stopEvent)
        editorElement.removeEventListener('drop', stopEvent)
      }
    }
  }, [])
  return (
    <AceEditor
      className="ACE_EDITOR"
      ref={editorRef}
      name="ACE_EDITOR"
      setOptions={{ useWorker: false }}
      mode={aceModesForLanguages(language)}
      theme={isDarkMode ? 'dracula' : 'chrome'}
      value={code || ''}
      onChange={onCodeEditorChange}
      fontSize={editorSettings?.fontSize}
      tabSize={editorSettings?.tabSize}
      keyboardHandler={editorSettings?.keyBinding}
      showPrintMargin
      highlightActiveLine
      enableBasicAutocompletion
      readOnly={readOnly}
      width={width}
      height={height}
      commands={[
        {
          name: 'breakTheEditor',
          bindKey:
            'ctrl-c|ctrl-v|ctrl-x|ctrl-shift-v|shift-del|cmd-c|cmd-v|cmd-x',
          exec() {},
        },
      ]}
    />
  )
}

export default CodeEditor
