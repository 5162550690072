import React, { useEffect, useState, useContext, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { Redirect, useParams } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Grid, IconButton, Snackbar, useMediaQuery } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Cookies from 'js-cookie'
import QuestionWrapper from '../QuestionWrapper/QuestionWrapper'
import PageNotFound from '../PageNotFound/PageNotFound'
import AccessDenied from '../AccessDenied/AccessDenied'
import QuizAlreadySubmitted from '../QuizAlreadySubmitted/QuizAlreadySubmitted'
import Loader from '../Loader/Loader'
import QuizSubmissionContext from '../../Contexts/QuizSubmissionContext'
import {
  useQuizGetQueryById,
  useQuizPostQueryById,
  useMCQPostQueryById,
  useTabChangePatchQuery,
} from '../../common/Queries/quizQueries'
import QuizPageHeader from '../QuizPageHeader/QuizPageHeader'
import QuizPageHeaderMobile from '../QuizPageHeaderMobile/QuizPageHeaderMobile'
import MirrorCameraDialog from '../MirrorCameraDialog/MirrorCameraDialog'
import { getQuestionById, returnErrors, areCookiesEnabled } from '../../utils'
import useWindowSize from '../../hooks/useWindowSize'
import IsProctoredContext from '../../Contexts/IsProctoredContext'
import CandidateStream from '../Proctoring/CandidateStream'
import InternalServerError from '../InternalServerError/InternalServerError'
import { useStyles } from './QuizPageStyles'
import QuizNotFound from '../QuizNotFound/QuizNotFound'

export function QuizTimeLeft(executableFunction, timeInterval) {
  this.timeInterval = timeInterval
  this.timerStarted = false

  this.start = () => {
    this.timerStarted = true
    this.expected = Date.now() + this.timeInterval
    this.timeout = setTimeout(this.round, this.timeInterval)
  }
  this.stop = () => {
    if (this.timerStarted) {
      this.timerStarted = false
      clearTimeout(this.timeout)
    }
  }
  this.round = () => {
    const drift = Date.now() - this.expected
    let reduceSeconds = 1
    let updatedTimeInterval = this.timeInterval - drift
    if (drift > 999) {
      reduceSeconds = Math.floor(drift / 1000)
      updatedTimeInterval = this.timeInterval - (drift % 1000)
    }
    executableFunction(reduceSeconds)
    this.expected += reduceSeconds * 1000
    this.timeout = setTimeout(this.round, updatedTimeInterval)
  }
}

function QuizPage() {
  const { setQuizSubmission } = useContext(QuizSubmissionContext)
  const [quiz, setQuiz] = useState()
  const [quizTitle, setQuizTitle] = useState('')
  const [currentQuestion, setCurrentQuestion] = useState({
    index: 0,
    id: quiz?.questions?.[0]?.id,
  })
  const [errorValue, setErrorValue] = useState('')
  const [currentQuestionFilter, setCurrentQuestionFilter] = useState('all')
  const [seconds, setSeconds] = useState(0)
  const [errors, setErrors] = useState({
    runError: false,
    customInputError: false,
    submitError: false,
    networkOfflineError: false,
  })
  const [canRedirect, setCanRedirect] = useState(false)
  const [tabFocus, setTabFocus] = useState(true)
  const [tabSnackBar, setTabSnackBar] = useState(false)
  const [tabChanged, setTabChanged] = useState(0)
  const [successMsgs, setSuccessMsgs] = useState({
    submitSuccess: false,
    networkOnlineSuccess: false,
  })
  const { quizId } = useParams()
  const [audioPermission, updateAudioPermission] = useState(false)
  const [videoPermission, updateVideoPermission] = useState(false)
  const [streamOpen, setStream] = useState(false)
  const proctoringConnectionRefs = useRef({
    webRTCPeerConn: null,
    webSocketConn: null,
    streams: null,
  })

  const userResponseDataRef = useRef()
  const [isNonExistingTokenError, setIsNonExistingTokenError] = useState(false)
  const [userResponseErrorCode, setUserResponseErrorCode] = useState('')
  const [userResponseErrorReason, setUserResponseErrorReason] = useState('')
  const [quizErrorCode, setQuizErrorCode] = useState('')
  const [quizErrorReason, setQuizErrorReason] = useState('')
  const [responseId] = useState('')
  const [openMirrorCamera, setOpenMirrorCamera] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [submissionTime, setSubmissionTime] = useState(null)
  const [quizResponseByInfo, setQuizResponseByInfo] = useState(null)
  const { isProctored, setIsProctored } = useContext(IsProctoredContext)
  const windowSize = useWindowSize()
  const [, setAuthError] = useState(false)
  const changeQuizHeaderStyle = useMediaQuery(
    'only screen and (min-width:1000px)'
  )
  const classes = useStyles()
  const timeLeftRef = React.useRef(
    new QuizTimeLeft((reduceSeconds) => {
      setSeconds((sec) => {
        return sec - reduceSeconds
      })
    }, 1000)
  )

  const handleGetUserAndQuizResponse = (userResponse) => {
    const getQuestionResponse = (questionId, setting) => {
      let returnResponse = {}
      if (setting.toUpperCase() === 'MCQ') {
        returnResponse = userResponse?.response?.mcq?.filter((item) => {
          return item?.id === questionId
        })?.[0]

        returnResponse = returnResponse?.selectedChoice
          ? { ...returnResponse, saved: true }
          : { selectedChoice: '', saved: false }
      }

      if (setting === 'fill-in-blanks') {
        returnResponse = userResponse?.response?.fillInTheBlanks?.filter(
          (item) => {
            return item?.id === questionId
          }
        )?.[0]

        returnResponse = returnResponse?.answer
          ? { ...returnResponse, saved: true }
          : { answer: '', saved: false }
      }

      if (setting === 'multi-choice') {
        returnResponse = userResponse?.response?.mcq?.filter((item) => {
          return item?.id === questionId
        })?.[0]

        returnResponse = returnResponse?.multiSelectedChoices
          ? { ...returnResponse, saved: true }
          : { multiSelectedChoices: '', saved: false }
      }

      if (setting === 'coding') {
        returnResponse = userResponse?.response?.coding?.filter((item) => {
          return item?.id === questionId
        })?.[0]

        returnResponse =
          returnResponse?.code && returnResponse?.language
            ? { ...returnResponse, saved: true }
            : { code: '', language: '', saved: false }
      }

      return returnResponse
    }
    let quizResponse = quizResponseByInfo

    let questions = []

    if (userResponse?.questions?.mcq) {
      const modifiedMCQ = userResponse?.questions?.mcq?.map((question) => {
        const questionResponse = userResponse
          ? getQuestionResponse(question?.id, question?.type)
          : { selectedChoice: '', saved: false }

        return {
          ...question,
          type: question?.type,
          selectedChoice:
            question?.type === 'multi-choice'
              ? questionResponse?.multiSelectedChoices
              : questionResponse?.selectedChoice,
          saved: questionResponse?.saved,
          atleastOneSubmissionOccured: questionResponse?.saved,
        }
      })
      questions = questions.concat(modifiedMCQ)
    }
    if (userResponse?.questions?.essay) {
      const modifiedMCQ = userResponse?.questions?.essay?.map((question) => {
        const questionResponse = userResponse
          ? getQuestionResponse(question?.id, question?.type)
          : { essayAnswer: '', saved: false }

        return {
          ...question,
          type: question?.type,
          essayAnswer: questionResponse?.essayAnswer,
          saved: questionResponse?.saved,
          atleastOneSubmissionOccured: questionResponse?.saved,
        }
      })
      questions = questions.concat(modifiedMCQ)
    }

    if (userResponse?.questions?.['fill-in-blanks']) {
      // const type = 'fill-in-blanks'
      const modifiedMCQ = userResponse?.questions?.['fill-in-blanks']?.map(
        (question) => {
          const questionResponse = userResponse
            ? getQuestionResponse(question?.id, question?.type)
            : { answer: '', saved: false }

          return {
            ...question,
            type: question?.type,
            answer: questionResponse?.answer,
            saved: questionResponse?.saved,
            atleastOneSubmissionOccured: questionResponse?.saved,
          }
        }
      )
      questions = questions.concat(modifiedMCQ)
    }

    const fillLanguages = (question) => {
      return question?.stubs?.map((codeStub) => {
        const copyCodeStub = JSON.parse(JSON.stringify(codeStub))
        copyCodeStub.value = codeStub?.language
        if (codeStub?.language === 'golang') copyCodeStub.label = 'Go'
        else if (codeStub?.language === 'javascript')
          copyCodeStub.label = 'JavaScript'
        else if (codeStub?.language === 'java') copyCodeStub.label = 'Java'
        else if (codeStub?.language === 'c') copyCodeStub.label = 'C'
        else if (codeStub?.language === 'cpp') copyCodeStub.label = 'C++'
        else if (codeStub?.language === 'python') copyCodeStub.label = 'Python'
        else copyCodeStub.label = codeStub?.language
        delete copyCodeStub?.body
        delete copyCodeStub?.language
        return copyCodeStub
      })
    }

    const fillCodeLanguageStorage = (question) => {
      return question?.stubs?.map((codeStub) => {
        const copyCodeStub = JSON.parse(JSON.stringify(codeStub))
        const currentLanguage = codeStub?.language
        if (question?.code?.language === currentLanguage) {
          copyCodeStub.code = question?.code?.code
        } else {
          copyCodeStub.code = codeStub?.functionSignature
        }
        delete copyCodeStub?.body
        return copyCodeStub
      })
    }

    const fillDefaultCodeForLanguage = (
      // questionResponse,
      question,
      currentLanguage
    ) => {
      let returnobj
      if (
        question?.code?.code?.language &&
        currentLanguage &&
        question?.code?.code?.language === currentLanguage
      ) {
        returnobj = question?.code?.code
      } else {
        if (question?.code) {
          return question?.code?.functionSignature
        }
        returnobj = question?.stubs?.filter((codeStub) => {
          return codeStub?.language === currentLanguage
        })?.[0]?.functionSignature
      }

      return returnobj
    }

    if (userResponse?.questions?.coding) {
      const modifiedCodingQuestions = userResponse?.questions?.coding?.map(
        (question) => {
          const questionResponse = userResponse
            ? getQuestionResponse(question?.id, 'coding')
            : { code: '', language: '', saved: false }
          return {
            ...question,
            type: 'coding',
            languages: fillLanguages(question),
            codeLanguageStorage: fillCodeLanguageStorage(question),
            code: fillDefaultCodeForLanguage(
              question,
              question?.selectedChoice
                ? question?.language
                : question?.codeStubs?.[0]?.language
            ),
            language: questionResponse?.language
              ? questionResponse?.language
              : question?.codeStubs?.[0]?.language,
            saved: questionResponse?.saved,
            atleastOneSubmissionOccured: questionResponse?.saved,
          }
        }
      )
      questions = questions.concat(modifiedCodingQuestions)
    }

    const questionResult = userResponse?.questions?.map((question) => {
      // need to add other type here as well
      if (question?.type === 'multi-choice' || question?.type === 'mcq') {
        const selected =
          question?.type === 'multi-choice'
            ? question?.multiSelectedChoices
            : question?.selectedChoice
        return {
          ...question,
          type: question?.type,
          selectedChoice: selected,
          saved: !!selected,
          atleastOneSubmissionOccured: !!selected,
        }
      }

      if (question?.type === 'fill-in-blanks') {
        if (
          question?.answer !== 'undefined' &&
          question?.answer !== undefined
        ) {
          return {
            ...question,
            type: question?.type,
            answer: question?.answer,
            saved: true,
            atleastOneSubmissionOccured: true,
          }
        }
        return {
          ...question,
          type: question?.type,
          answer: '',
          saved: false,
          atleastOneSubmissionOccured: false,
        }
      }

      if (question?.type === 'essay') {
        if (
          question?.essayAnswer !== 'undefined' &&
          question?.essayAnswer !== undefined
        ) {
          return {
            ...question,
            type: question?.type,
            essayAnswer: question?.essayAnswer,
            saved: true,
            atleastOneSubmissionOccured: true,
          }
        }
        return {
          ...question,
          type: question?.type,
          essayAnswer: '',
          saved: false,
          atleastOneSubmissionOccured: false,
        }
      }

      if (question?.type === 'coding') {
        const language = question?.code?.language
          ? question?.code?.language
          : question?.stubs?.[0]?.language
        const questionSave = Object?.keys(question?.code)?.length !== 0
        return {
          ...question,
          type: 'coding',
          languages: fillLanguages(question),
          codeLanguageStorage: fillCodeLanguageStorage(question),
          code: fillDefaultCodeForLanguage(question, language),
          language,
          saved: questionSave,
          atleastOneSubmissionOccured: questionSave,
        }
      }
      return null
    })
    questions = questionResult
    quizResponse = { ...quizResponse, questions }
    return quizResponse
  }

  useEffect(() => {
    sessionStorage.setItem('quizCopy', JSON.stringify(quiz))
  }, [quiz])
  useEffect(() => {
    if (seconds > 0 && !timeLeftRef.current.timerStarted) {
      timeLeftRef.current.start()
    }
  }, [seconds])

  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [])

  const setOnline = () => {
    setSuccessMsgs((successMsgsPrev) => {
      return { ...successMsgsPrev, networkOnlineSuccess: true }
    })
  }
  const setOffline = () => {
    setErrors((errorsPrev) => {
      return { ...errorsPrev, networkOfflineError: true }
    })
  }

  // Register the event listeners
  useEffect(() => {
    window.addEventListener('offline', setOffline)
    window.addEventListener('online', setOnline)

    // cleanup if we unmount
    return () => {
      window.removeEventListener('offline', setOffline)
      window.removeEventListener('online', setOnline)
    }
  }, [])

  const tabChangeMutation = useTabChangePatchQuery()

  const mutation = useQuizPostQueryById(quizId, {
    onError: (err) => {
      window.removeEventListener('beforeunload', alertUser)
      if (err?.statusCode === 401) {
        setIsNonExistingTokenError(true)
        setAuthError(true)
      }
      if (err?.statusCode !== 401) {
        setQuizErrorCode(err?.errorCode)
        setQuizErrorReason(err?.errorReason)
      }
      if (
        err?.errorCode === 'CONFLICT' ||
        err?.errorCode === 'SUBMISSION_EXISTS'
      ) {
        setUserResponseErrorCode(err?.errorCode)
        setUserResponseErrorReason(err?.errorReason)
      }
    },
  })
  useEffect(() => {
    mutation.mutate()
  }, [])

  useEffect(() => {
    if (mutation?.data && mutation?.isSuccess) {
      const successResponse = mutation?.data
      if (successResponse) {
        localStorage.setItem('userResponse', JSON.stringify(successResponse))
      }
      const result = handleGetUserAndQuizResponse(successResponse)
      setQuiz(result)
      setCurrentQuestion(() => ({
        index: 0,
        id: result.questions?.[0]?.id,
      }))
      setSeconds(successResponse?.info?.remainingDuration)
      if (!userResponseDataRef.current) {
        const copyQuiz = {
          userID: sessionStorage.getItem('userID'),
          quiz,
          remainingDuration: seconds,
        }
        mutation.mutate(copyQuiz)
      }
      userResponseDataRef.current = successResponse
      setSubmissionTime(successResponse?.info?.submissionTime)
      if (successResponse?.info?.submissionTime) {
        setUserResponseErrorCode('SUBMISSION_EXISTS')
        setUserResponseErrorReason('You have already submitted the quiz.')
      }
      setAuthError(false)
    }
  }, [mutation?.data, mutation?.isSuccess])
  const {
    isLoading: isQuizInfoLoading,
    isError: isQuizInfoError,
    refetch: quizInfoRefetch,
  } = useQuizGetQueryById(quizId, {
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (successResponse) => {
      setQuizResponseByInfo(successResponse)
      setQuizTitle(successResponse?.info?.title)
      setShowResult(successResponse?.quizConfig?.showResult)
      setIsProctored(successResponse?.quizConfig?.isProctored)
      if (!successResponse?.quizConfig?.isProctored) {
        // userResponseRefetch()
      }
    },
    onError: (error) => {
      setQuizErrorCode(() => error?.errorCode)
      setQuizErrorReason(() => error?.errorReason)
    },
  })

  useEffect(() => {
    const handleVisibilityChange = () => {
      const token = Cookies.get(`${sessionStorage.getItem('userID')}_token`)
      const userResponseId = sessionStorage.getItem('responseId')
      if (document.visibilityState === 'visible') {
        setTabFocus(true)
      } else {
        setTabFocus(false)
        setTabChanged(tabChanged + 1)
        if (
          (userResponseErrorCode !== 'SUBMISSION_EXISTS' && token) ||
          userResponseId
        ) {
          tabChangeMutation.mutate({})
        }
        setTabSnackBar(true)
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [tabFocus])

  useEffect(() => {
    quizInfoRefetch()
  }, [])

  // useEffect(() => {
  //   if (isProctored) {
  //     audioPermission && videoPermission && streamOpen
  //   }
  // }, [audioPermission, streamOpen, videoPermission])

  const { mutateAsync: mcqSubmissionMutateAsync } = useMCQPostQueryById(
    quiz?.id + 1,
    {
      staleTime: 0,
      onError: (error) => {
        setErrorValue(error?.errorReason)
        setErrors((errorsPrev) => {
          return { ...errorsPrev, runError: false }
        })
      },
    }
  )

  const handleMCQSubmission = async (
    questionId,
    selectedChoice,
    questionType
  ) => {
    await mcqSubmissionMutateAsync({
      questionId,
      type: questionType,
      userIdentifier: sessionStorage.getItem('userID'),
      selectedChoice,
    })
  }

  const closeProctoring = () => {
    proctoringConnectionRefs.current.webRTCPeerConn?.close()
    proctoringConnectionRefs.current.webSocketConn = null
    proctoringConnectionRefs.current?.streams?.getTracks()?.forEach((track) => {
      track.stop()
    })
  }

  const handleSubmit = () => {
    const quizCopy = {
      userID: sessionStorage.getItem('userID'),
      quiz,
      remainingDuration: seconds,
    }
    timeLeftRef.current.stop()
    closeProctoring()
    setQuizSubmission(quizCopy)
    setCanRedirect(true)
  }

  useEffect(() => {
    if (seconds <= 0 && quiz) {
      handleSubmit()
    }
  }, [seconds])

  if (!areCookiesEnabled()) {
    return <Redirect to={`/quiz/${quizId}`} />
  }

  if (canRedirect) {
    return <Redirect to={`/quiz/${quizId}/submit`} />
  }

  if (isQuizInfoError || submissionTime || mutation?.isError) {
    closeProctoring()
    const userResponseErrorType = returnErrors(userResponseErrorCode)
    const quizErrorType = returnErrors(quizErrorCode)
    if (userResponseErrorType === 'ACCESS_DENIED') {
      return <AccessDenied reason={userResponseErrorReason} />
    }
    if (quizErrorType === 'ACCESS_DENIED') {
      return <AccessDenied reason={quizErrorReason} />
    }
    if (userResponseErrorType === 'QUIZ_ALREADY_SUBMITTED') {
      return (
        <QuizAlreadySubmitted
          reason={userResponseErrorReason}
          showResult={showResult}
          responseId={responseId}
          quizTitle={quizTitle}
          quizId={quizId}
        />
      )
    }
    if (quizErrorType === 'QUIZ_ALREADY_SUBMITTED') {
      return (
        <QuizAlreadySubmitted
          reason={quizErrorReason}
          showResult={showResult}
          responseId={responseId}
          quizTitle={quizTitle}
          quizId={quizId}
        />
      )
    }
    if (userResponseErrorType === 'INTERNAL_SERVER_ERROR') {
      return <InternalServerError reason={userResponseErrorReason} />
    }
    if (quizErrorType === 'INTERNAL_SERVER_ERROR') {
      return <InternalServerError reason={quizErrorReason} />
    }
    if (quizErrorType === 'QUIZ_NOT_FOUND')
      return <QuizNotFound reason={quizErrorReason} />

    if (isNonExistingTokenError) {
      return <Redirect to={`/quiz/${quizId}`} />
    }

    if (quizErrorType === 'REDIRECT' || userResponseErrorType === 'REDIRECT') {
      return null
    }

    return <PageNotFound />
  }

  if (isProctored && !streamOpen) {
    return (
      <Grid
        style={{
          height: `${windowSize?.height}px`,
        }}
      >
        <CandidateStream
          setStream={setStream}
          proctoringConnectionRefs={proctoringConnectionRefs}
        />
      </Grid>
    )
  }
  if (streamOpen && (!audioPermission || !videoPermission)) {
    proctoringConnectionRefs.current?.streams?.getTracks()?.forEach((track) => {
      if (track.kind === 'audio') {
        updateAudioPermission(true)
      } else if (track.kind === 'video') {
        updateVideoPermission(true)
      }
    })
  }

  if (sessionStorage.getItem('responseId') !== 'undefined') {
    return (
      <Grid
        container
        direction="column"
        className={classes.root}
        style={{ height: `${windowSize?.height}px` }}
      >
        <CssBaseline />
        <Helmet>
          <title>{`${quiz?.info?.title} - Question ${
            currentQuestion?.index + 1
          }`}</title>
        </Helmet>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={tabSnackBar && tabFocus}
          autoHideDuration={5000}
          onClose={() => setTabSnackBar(false)}
          message="Tab changed!"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setTabSnackBar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          ContentProps={{
            classes: {
              root: classes.tabChangeSnackBar,
            },
          }}
          key="Tab Changed!"
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={successMsgs.submitSuccess}
          autoHideDuration={1000}
          onClose={() =>
            setSuccessMsgs((successMsgsPrev) => {
              return { ...successMsgsPrev, submitSuccess: false }
            })
          }
          message={<>Question submitted successfully!</>}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() =>
                setSuccessMsgs((successMsgsPrev) => {
                  return { ...successMsgsPrev, submitSuccess: false }
                })
              }
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          ContentProps={{
            classes: {
              root: classes.submitSuccessSnackBar,
            },
          }}
          key="Question submitted successfully"
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errors.submitError || errors.runError}
          autoHideDuration={3000}
          onClose={() => {
            if (errors.submitError) {
              setErrors((errorsPrev) => {
                return { ...errorsPrev, submitError: false }
              })
            }
            if (errors.runError) {
              setErrors((errorsPrev) => {
                return { ...errorsPrev, runError: false }
              })
            }
          }}
          message={errorValue}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                if (errors.submitError) {
                  setErrors((errorsPrev) => {
                    return { ...errorsPrev, submitError: false }
                  })
                }
                if (errors.runError) {
                  setErrors((errorsPrev) => {
                    return { ...errorsPrev, runError: false }
                  })
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          ContentProps={{
            classes: {
              root: classes.submitFailedSnackBar,
            },
          }}
          key="Question submission Failed"
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={errors.customInputError}
          autoHideDuration={3000}
          onClose={() =>
            setErrors((errorsPrev) => {
              return { ...errorsPrev, customInputError: false }
            })
          }
          message="Enter a valid input for custom input!"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() =>
                setErrors((errorsPrev) => {
                  return { ...errorsPrev, customInputError: false }
                })
              }
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          key="Enter a valid input!"
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={errors.networkOfflineError}
          autoHideDuration={1000}
          onClose={() =>
            setErrors((errorsPrev) => {
              return { ...errorsPrev, networkOfflineError: false }
            })
          }
          message="Network Connection Lost"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() =>
                setErrors((errorsPrev) => {
                  return { ...errorsPrev, networkOfflineError: false }
                })
              }
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          ContentProps={{
            classes: {
              root: classes.networkOfflineSnackBar,
            },
          }}
          key="Network Connection Lost"
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={successMsgs.networkOnlineSuccess}
          autoHideDuration={1000}
          onClose={() =>
            setSuccessMsgs((successMsgsPrev) => {
              return { ...successMsgsPrev, networkOnlineSuccess: false }
            })
          }
          message="Connected to Network"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() =>
                setSuccessMsgs((successMsgsPrev) => {
                  return { ...successMsgsPrev, networkOnlineSuccess: false }
                })
              }
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          ContentProps={{
            classes: {
              root: classes.networkOnlineSnackBar,
            },
          }}
          key="Connected to Network"
        />
        {openMirrorCamera && (
          <MirrorCameraDialog
            openMirrorCamera={openMirrorCamera}
            setOpenMirrorCamera={setOpenMirrorCamera}
            candidateStream={proctoringConnectionRefs.current.streams}
          />
        )}

        <Grid item>
          {changeQuizHeaderStyle ? (
            <QuizPageHeader
              quiz={quiz}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              setCurrentQuestionFilter={setCurrentQuestionFilter}
              seconds={seconds}
              handleSubmit={handleSubmit}
              handleMCQSubmission={handleMCQSubmission}
              openMirrorCamera={openMirrorCamera}
              setOpenMirrorCamera={setOpenMirrorCamera}
            />
          ) : (
            <QuizPageHeaderMobile
              quiz={quiz}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              setCurrentQuestionFilter={setCurrentQuestionFilter}
              seconds={seconds}
              handleSubmit={handleSubmit}
              handleMCQSubmission={handleMCQSubmission}
              openMirrorCamera={openMirrorCamera}
              setOpenMirrorCamera={setOpenMirrorCamera}
            />
          )}
        </Grid>

        <Grid item className={classes.bodyWrapper}>
          {quiz?.questions?.length > 0 && (
            <QuestionWrapper
              question={getQuestionById(quiz, currentQuestion)}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              currentQuestionFilter={currentQuestionFilter}
              setCurrentQuestionFilter={setCurrentQuestionFilter}
              quiz={quiz}
              setQuiz={setQuiz}
              setErrors={setErrors}
              setErrorValue={setErrorValue}
              setSuccessMsgs={setSuccessMsgs}
              handleMCQSubmission={handleMCQSubmission}
            />
          )}
        </Grid>
      </Grid>
    )
  }
  if (isQuizInfoLoading || mutation?.isLoading) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: `${windowSize?.height}px` }}
        data-testid="quizPage-testId"
      >
        <Loader size="8rem" />
      </Grid>
    )
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: `${windowSize?.height}px` }}
      data-testid="quizPage-testId"
    >
      <Loader size="8rem" />
    </Grid>
  )
}

export default QuizPage
