import React, { useRef, useEffect } from 'react'
// import ReactQuill from 'react-quill'
import Snackbar from '@material-ui/core/Snackbar'

// import { formats, QuillToolbar } from './TextEditorToolBar'
import 'react-quill/dist/quill.snow.css'
import './style.css'

const QuillTextEditor = ({
  textEditorAnswer,
  // textEditorAnswerRef,
  // text,
  question,
  setTextEditorAnswer,
  className,
  // isQuizStarted,
}) => {
  const toolbarRef = useRef(null)
  const textAreaRef = useRef(null)

  const handleChange = (e) => {
    setTextEditorAnswer(e.target.value)
  }

  useEffect(() => {
    if (toolbarRef.current) {
      const toolbarContainer = toolbarRef.current.querySelector(
        `#toolbar_${className}`
      )
      toolbarContainer.classList.add('ql-snow')
    }
  }, [className])

  // const modules = useMemo(() => {
  //   try {
  //     return {
  //       toolbar: {
  //         container: `#toolbar_${className}`,
  //         handlers: {
  //           image: () => {
  //             // const editor = quillRef.current.getEditor()
  //             // const range = editor.getSelection(true)
  //             // editor.setSelection(range)
  //             // editor.focus()

  //             const input = document.createElement('input')
  //             input.setAttribute('type', 'file')
  //             input.setAttribute('accept', 'image/*')
  //             input.click()
  //           },
  //         },
  //       },
  //     }
  //   } catch (error) {
  //     console.error('Error creating modules:', error)
  //     throw error
  //   }
  // }, [className])

  const handleCloseSnackBar = () => {
    // setErrorSnackBar(false)
    // setErrorStatus(null)
  }
  const handler = (event) => {
    event.preventDefault()
  }
  return (
    <>
      {/* <div id={`toolbar_${className}`} data-testid="text-editor"> */}
      <>
        {/* <QuillToolbar className={className} ref={toolbarRef} /> */}
        {/* <ReactQuill
            // readOnly={isQuizStarted}
            className="react-quill-div"
            theme="snow"
            ref={quillRef}
            value={textEditorAnswer}
            onChange={handleChange}
            placeholder="Please enter your answer"
            modules={modules}
            formats={formats}
            data-testid="react-quill"
          /> */}
      </>
      {/* </div> */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexFlow: 'wrap',
        }}
      >
        <textarea
          className={
            question.type === 'essay' ? 'essaytextarea' : 'fillInBlankstextarea'
          }
          ref={textAreaRef}
          value={textEditorAnswer}
          onChange={handleChange}
          onPaste={handler}
          onDrag={handler}
          onDrop={handler}
        />
      </div>

      <Snackbar
        // open={errorSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        {/* <Alert severity="error">
          {getMediaServiceErrorMessage.get(errorStatus)}
        </Alert> */}
      </Snackbar>
    </>
  )
}

export default QuillTextEditor
