import React from 'react'

function QuizNotFoundIllustration() {
  return (
    <svg viewBox="0 0 470 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="01" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="1.-When-we-enter-a-quiz-url-which-doesn't-exist-(-we-have-to-show-quiz-doesn't-exist-with-an-illustration-)"
          transform="translate(1.000000, 15.000000)"
        >
          <g id="Group-10" transform="translate(0.045455, 0.288961)">
            <g id="Group-4-Copy" transform="translate(0.000000, 17.045455)">
              <path
                d="M63.4529627,249.188312 C59.6147646,232.413408 65.8389765,214.361216 82.1255978,195.031735 C106.555529,166.037513 114.145664,159.437396 103.056043,129.822336 C91.9664205,100.207276 65.929194,38.0344053 121.619467,10.4706049 C177.309739,-17.0931956 206.011942,17.4387147 235.261384,26.6290223 C264.510826,35.81933 347.774396,58.3416376 379.844318,109.147111 C411.914245,159.952585 385.66572,159.289837 379.844318,197.773508 C374.02292,236.257179 393.166824,249.188312 369.588021,249.188312 C353.868821,249.188312 251.823801,249.188312 63.4529627,249.188312 Z"
                id="Path-2"
                fill="#DFF1F1"
                fillRule="nonzero"
              />
              <g id="Group-5" transform="translate(128.470017, 97.808442)">
                <polygon
                  id="Triangle"
                  stroke="#FF9D9D"
                  strokeWidth="9.74025974"
                  points="38.6856802 0.244053084 77.3627025 65.7467532 0.00865791396 65.7467532"
                />
                <g
                  id="!"
                  transform="translate(35.308442, 24.350649)"
                  fill="#FF9D9D"
                  fillRule="nonzero"
                  fontFamily="OpenSans-SemiBold, Open Sans"
                  fontSize="21.9155844"
                  fontWeight="500"
                >
                  <text>
                    <tspan x="0.0273238636" y="23.0974931">
                      !
                    </tspan>
                  </text>
                </g>
              </g>
              <g id="Group-6" transform="translate(124.188312, 56.006494)">
                <circle
                  id="Oval"
                  fill="#252B4C"
                  fillRule="nonzero"
                  cx="127.059318"
                  cy="26.5827922"
                  r="26.5827922"
                />
                <path
                  d="M171.247939,185.497175 C185.081833,185.497175 202.250505,149.453968 192.293961,124.818654 C182.337416,100.18334 162.979055,79.1660061 135.697266,79.1660061 C108.415476,79.1660061 103.577342,82.0796631 87.3167261,92.7827902 C71.0561096,103.485917 57.6791498,128.272054 57.6791498,143.155166 C57.6791498,158.038278 63.5465889,185.497175 88.9644152,185.497175 C114.382241,185.497175 157.414044,185.497175 171.247939,185.497175 Z"
                  id="Path-58-Copy"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  fill="#00B8A5"
                  fillRule="nonzero"
                />
                <path
                  d="M73.5065617,126.962261 L103.854916,122.597778 C108.419722,121.9413 112.779782,124.716845 114.117037,129.130479 L122.564935,157.012943 L122.564935,157.012943 L127.898649,180.743363 L105.840187,180.014338 L73.5065617,126.962261 Z"
                  id="Path-19"
                  fill="#069D8E"
                  fillRule="nonzero"
                  opacity="0.286473474"
                />
                <ellipse
                  id="Oval-Copy-2"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  cx="104.7045"
                  cy="45.1142009"
                  rx="7.07608925"
                  ry="9.33441558"
                />
                <ellipse
                  id="Oval-Copy-8-Copy"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  cx="154.426478"
                  cy="46.6212752"
                  rx="9.33441558"
                  ry="10.9577922"
                />
                <path
                  d="M102.596186,30.6992348 C97.6284111,64.5292208 78.7049314,69.218974 81.354849,88.331539 C84.004767,107.444103 90.175793,94.2753093 96.1789099,94.471455 C102.182027,94.6676006 108.60233,90.6873754 110.809903,85.7124493 C113.017477,80.7375235 119.215461,88.7621672 119.215461,85.7124493 C119.215461,82.6627317 120.243289,85.8621465 120.243289,80.5600649 C120.243289,77.0253437 118.275586,72.6199063 114.340182,67.3437516 C109.822702,20.3607498 105.908037,8.1459108 102.596186,30.6992348 Z"
                  id="Path-3"
                  fill="#252B4C"
                  fillRule="nonzero"
                />
                <rect
                  id="Rectangle-Copy-3"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  x="116.535012"
                  y="57.6225455"
                  width="22.7272727"
                  height="34.0909091"
                  rx="11.3636364"
                />
                <path
                  d="M139.262285,72.5179809 C139.262285,98.2353458 126.998777,105.128151 133.216505,114.3981 C139.434233,123.668048 147.410729,128.069875 159.551868,132.07431 C171.693006,136.078746 189.901129,136.300078 189.901129,121.898642 C189.901129,107.497206 172.638985,119.859058 168.460365,101.998937 C164.281746,84.1388162 171.511155,74.5264355 168.076491,64.6267139 C165.786714,58.0268998 160.120967,55.4996088 151.07925,57.0448413 C143.201273,50.215358 139.262285,55.373071 139.262285,72.5179809 Z"
                  id="Path-4"
                  fill="#252B4C"
                  fillRule="nonzero"
                />
                <ellipse
                  id="Oval-Copy-3"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  cx="129.382481"
                  cy="43.4227593"
                  rx="24.5535714"
                  ry="31.25"
                />
                <path
                  d="M133.551091,90.9737995 C128.139835,93.1197338 125.434208,97.0428937 125.434208,102.74328"
                  id="Path-62-Copy"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  strokeLinecap="round"
                />
                <path
                  d="M59.3473985,128.544844 C65.177638,132.37506 69.4935024,142.343545 69.4935024,142.343545"
                  id="Path-63-Copy"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  strokeLinecap="round"
                />
                <circle
                  id="Oval"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  fill="#F2F8F8"
                  fillRule="nonzero"
                  cx="4.46428571"
                  cy="177.081331"
                  r="4.46428571"
                />
                <path
                  d="M5.26675856,167.908459 L31.6466287,167.908459 L31.6466287,172.033095 C31.6466287,179.169066 25.7412936,184.953914 18.4566936,184.953914 C11.1720936,184.953914 5.26675856,179.169066 5.26675856,172.033095 L5.26675856,167.908459 L5.26675856,167.908459 Z"
                  id="Rectangle"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  fill="#F2F8F8"
                  fillRule="nonzero"
                />
                <path
                  d="M111.717711,43.1889679 C114.044322,42.3601928 115.644662,40.829806 115.292171,39.7707538 C114.93968,38.7117008 112.767838,38.5250243 110.441227,39.3537994 C108.114616,40.1825745 106.514276,41.7129614 106.866767,42.7720138 C107.219258,43.8310666 109.3911,44.0177431 111.717711,43.1889679 Z"
                  id="Oval-Copy-4"
                  fill="#252B4C"
                  fillRule="nonzero"
                />
                <path
                  d="M128.316899,44.0946648 C125.990288,43.2658896 124.389948,41.7355032 124.742439,40.6764505 C125.09493,39.6173977 127.266772,39.4307212 129.593383,40.2594963 C131.919994,41.0882715 133.520334,42.6186583 133.167843,43.6777106 C132.815352,44.7367634 130.64351,44.9234399 128.316899,44.0946648 Z"
                  id="Oval-Copy-17-Copy"
                  fill="#252B4C"
                  fillRule="nonzero"
                />
                <circle
                  id="Oval-Copy-5"
                  fill="#252B4C"
                  fillRule="nonzero"
                  cx="112.007903"
                  cy="47.9526266"
                  r="1.01461039"
                />
                <circle
                  id="Oval-Copy-18-Copy"
                  fill="#252B4C"
                  fillRule="nonzero"
                  cx="127.021849"
                  cy="47.6905572"
                  r="1.01461039"
                />
                <path
                  d="M101.092493,43.5260921 C101.092493,36.4688044 104.840849,27.8187936 113.330274,30.0804866 C121.8197,32.3421796 124.139664,35.8610293 124.89788,32.3421796 C125.656096,28.8233298 127.581896,27.7088292 128.480583,28.2660795 C129.379269,28.8233298 133.353364,40.5533835 139.571827,40.1301547 C145.79029,39.7069259 151.322703,37.9909234 152.682751,41.1909736 C154.0428,44.3910239 149.208126,62.7311437 154.303639,63.607041 C154.59551,63.6572123 154.907116,63.713763 155.234832,63.7745 C158.212553,64.326373 160.030671,83.4638275 162.949205,83.0611477 C165.317048,82.7344485 163.217691,75.585252 167.282838,68.6504168 C170.855499,62.5557273 161.308959,60.2054984 159.569244,50.1678263 C157.82953,40.1301547 163.223987,38.8776119 159.569244,30.0804866 C155.914502,21.2833613 149.065141,5.96188713 142.242416,5.96188713 C135.419692,5.96188713 136.508706,8.06092545 131.082401,8.06092545 C125.656096,8.06092545 118.825128,5.24941177 114.653912,8.06092545 C110.482695,10.8724391 101.661378,15.3022761 100.63919,22.1013067 C99.6170016,28.9003374 101.092493,50.5833803 101.092493,43.5260921 Z"
                  id="Path-5"
                  fill="#252B4C"
                  fillRule="nonzero"
                />
                <path
                  d="M143.005939,39.7121184 C146.532265,39.7121184 143.435175,41.6232472 147.129113,52.0616238 C150.823051,62.5 139.363007,67.8668689 140.398802,71.3214708 C141.434596,74.7760722 141.970144,70.4878312 143.005939,72.2091696 C143.696469,73.3567289 147.235434,72.321556 153.622834,69.103651 L156.968375,43.4352995 L155.144418,35.7298437 C144.701215,38.3846935 140.655055,39.7121184 143.005939,39.7121184 Z"
                  id="Path-6"
                  fill="#252B4C"
                  fillRule="nonzero"
                />
                <path
                  d="M158.945593,41.1038316 C157.915669,46.6588718 162.262071,54.3764095 168.076491,61.2129842"
                  id="Path-13"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  strokeLinecap="round"
                />
                <path
                  d="M154.890344,114.326867 C168.45466,119.680474 155.961841,134.02968 162.20825,138.210375 C168.45466,142.39107 158.596707,154.909502 158.596707,163.873692 C158.596707,172.83788 151.17819,172.269 139.823635,172.269 C132.253931,172.269 120.926115,175.276385 105.840187,181.291153"
                  id="Path-10"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  strokeLinecap="round"
                />
                <path
                  d="M75.623539,119.632671 C75.623539,125.426497 73.3996189,146.82141 104.977101,146.759693"
                  id="Path-11"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                  strokeLinecap="round"
                  transform="translate(90.283370, 133.196182) scale(-1, 1) rotate(-131.000000) translate(-90.283370, -133.196182) "
                />
                <polygon
                  id="Path-8"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  points="86.5277488 180.014338 150.304811 180.014338 147.792652 184.073895 107.706841 188.942909 38.1201199 187.137139 34.2333828 181.299948"
                />
                <path
                  d="M146.721367,180.743363 C137.864054,182.00383 129.276394,182.699198 120.958389,182.829467 C112.640384,182.959735 102.391195,182.577927 90.210821,181.684043 L104.596119,180.743363 L146.721367,180.743363 Z"
                  id="Path-9"
                  fill="#DADADA"
                  fillRule="nonzero"
                />
                <path
                  d="M20.3356322,128.562229 L95.3914168,125.846276 C99.3022143,125.70476 102.80684,128.244718 103.889617,132.005297 L118.523324,182.829467 L118.523324,182.829467 L34.4676956,182.829467 L20.3356322,135.671045 L18.7793675,132.895202 C17.955984,131.426567 18.4790632,129.56852 19.9476977,128.745136 C20.0725548,128.675136 20.2021871,128.614015 20.3356322,128.562229 L20.3356322,128.562229 L20.3356322,128.562229 Z"
                  id="Path-7"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                />
                <path
                  d="M64.1293573,166.345735 C66.992007,166.41546 69.3531499,163.233777 69.4031174,159.239248 C69.4530853,155.244718 67.1729559,151.949989 64.3103065,151.880265 C61.4476568,151.81054 59.0865135,154.992223 59.036546,158.986752 C58.986578,162.981281 61.2667075,166.27601 64.1293573,166.345735 Z"
                  id="Oval"
                  fill="#DDEBEB"
                  fillRule="nonzero"
                  transform="translate(64.219832, 159.113000) rotate(-33.000000) translate(-64.219832, -159.113000) "
                />
                <path
                  d="M67.2807561,145.693928 C67.3729026,146.152342 67.4192301,146.634666 67.4130122,147.131759 C67.3819371,149.615955 66.0485877,151.66762 64.2805223,152.187434 C64.1880589,151.728981 64.1417184,151.246595 64.1479371,150.749436 C64.1788421,148.27883 65.4978117,146.236036 67.2514489,145.702429 L67.2807561,145.693928 Z"
                  id="Combined-Shape"
                  fill="#DDEBEB"
                  fillRule="nonzero"
                  transform="translate(65.780475, 148.940681) rotate(-23.000000) translate(-65.780475, -148.940681) "
                />
              </g>
              <polygon
                id="Path-14-Copy-2"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="319.332724 68.1153364 306.101896 68.1153364 306.101896 47.2679878 346.731696 47.2679878 346.731696 89.8609152 329.610749 89.8609152 329.610749 98.6286157 315.630658 98.6286157 315.630658 76.6654854 332.735381 76.6654854 332.735381 60.9582557 319.332724 60.9582557"
              />
              <polygon
                id="Path-14-Copy-3"
                fill="#FFFFFF"
                fillRule="nonzero"
                points="379.769024 98.6595008 369.819428 98.6595008 369.819428 82.9822735 400.373079 82.9822735 400.373079 115.012198 387.49811 115.012198 387.49811 121.605518 376.985067 121.605518 376.985067 105.089222 389.847836 105.089222 389.847836 93.2773689 379.769024 93.2773689"
              />
              <rect
                id="Rectangle-Copy-6"
                fill="#FFFFFF"
                fillRule="nonzero"
                x="315.746753"
                y="104.707792"
                width="13.3928571"
                height="13.7987013"
              />
              <rect
                id="Rectangle-Copy-7"
                fill="#FFFFFF"
                fillRule="nonzero"
                x="377.072371"
                y="126.177065"
                width="10.0714422"
                height="10.3766374"
              />
              <path
                d="M247.061547,152.675112 C242.212515,154.61887 239.787999,158.172416 239.787999,163.335749"
                id="Path-62-Copy"
                stroke="#252B4C"
                strokeWidth="0.811688312"
                strokeLinecap="round"
              />
              <rect
                id="Rectangle"
                fill="#B2CECE"
                fillRule="nonzero"
                x="0"
                y="241.071429"
                width="466.314935"
                height="8.11688312"
                rx="4.05844156"
              />
            </g>
            <polygon
              id="Path-14-Copy-4"
              stroke="#252B4C"
              strokeWidth="0.5"
              fill="#C0DFDF"
              fillRule="nonzero"
              points="317.303503 82.3198819 304.072675 82.3198819 304.072675 61.4725333 344.702475 61.4725333 344.702475 104.065461 327.581528 104.065461 327.581528 112.833161 313.601437 112.833161 313.601437 90.8700308 330.70616 90.8700308 330.70616 75.1628011 317.303503 75.1628011"
            />
            <polygon
              id="Path-14-Copy-5"
              fill="#C0DFDF"
              fillRule="nonzero"
              points="377.739804 112.864046 367.790207 112.864046 367.790207 97.186819 398.343858 97.186819 398.343858 129.216744 385.468889 129.216744 385.468889 135.810063 374.955847 135.810063 374.955847 119.293767 387.818615 119.293767 387.818615 107.481914 377.739804 107.481914"
            />
            <rect
              id="Rectangle-Copy-9"
              stroke="#252B4C"
              strokeWidth="0.5"
              fill="#C0DFDF"
              fillRule="nonzero"
              x="313.717532"
              y="118.912338"
              width="13.3928571"
              height="13.7987013"
            />
            <rect
              id="Rectangle-Copy-10"
              fill="#C0DFDF"
              fillRule="nonzero"
              x="375.04315"
              y="140.38161"
              width="10.0714422"
              height="10.3766374"
            />
            <circle
              id="Oval-Copy-7"
              stroke="#F2F8F8"
              strokeWidth="1.62337662"
              cx="138.798701"
              cy="45.2516234"
              r="45.2516234"
            />
            <line
              x1="138.798701"
              y1="45.2516234"
              x2="159.555636"
              y2="64.0764923"
              id="Path-15-Copy"
              stroke="#F2F8F8"
              strokeWidth="1.62337662"
            />
            <line
              x1="138.798701"
              y1="45.2516234"
              x2="130.453342"
              y2="13.3312531"
              id="Path-16-Copy"
              stroke="#F2F8F8"
              strokeWidth="1.62337662"
            />
            <circle
              id="Oval-Copy-8"
              stroke="#F2F8F8"
              strokeWidth="1.62337662"
              fill="#DFF1F1"
              fillRule="nonzero"
              cx="138.798701"
              cy="45.2516234"
              r="3.32883409"
            />
            <path
              d="M266.546297,81.4283056 C268.062842,76.779582 272.274762,75.7037642 273.851491,81.4283056"
              id="Path-12-Copy"
              stroke="#252B4C"
              strokeWidth="0.811688312"
              strokeLinecap="round"
            />
            <path
              d="M266.546297,79.4781161 C266.546297,77.5279269 268.707656,73.0519481 264.607312,73.0519481"
              id="Path-18-Copy"
              stroke="#252B4C"
              strokeWidth="0.811688312"
              strokeLinecap="round"
            />
            <path
              d="M243.424773,119.727895 C243.424773,125.811688 239.981111,130.631015 244.4316,130.631015"
              id="Path-20-Copy"
              stroke="#252B4C"
              strokeWidth="0.811688312"
              strokeLinecap="round"
            />
            <path
              d="M261.831103,164.025748 C255.645786,177.355783 245.430144,190.35918 255.645786,194.508284 C265.861428,198.657389 266.89448,207.475954 272.24733,207.475954"
              id="Path-22-Copy"
              stroke="#252B4C"
              strokeWidth="0.811688312"
              strokeLinecap="round"
            />
            <circle
              id="Oval-Copy-9"
              fill="#252B4C"
              fillRule="nonzero"
              cx="247.159091"
              cy="137.581169"
              r="2.43506494"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default QuizNotFoundIllustration
