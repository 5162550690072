import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Logo from '../Logo/Logo'
import Timer from '../Timer/Timer'
import { useStyles } from './ReviewPageHeaderStyles'

function ReviewPageHeader({
  quiz,
  seconds,
  getNoOfAnsweredQuestions,
  setPopUp,
}) {
  const classes = useStyles()
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid container alignItems="center" className={classes.logoWrapper}>
        <Logo />
      </Grid>

      <Grid container justify="center" className={classes.timerWrapper}>
        <Grid item className={classes.secondsTimer}>
          <Timer seconds={seconds} />
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.answerAndCloseIconWrapper}
      >
        <Grid item className={classes.answeredWrapper}>
          <Typography
            className={classes.answeredText}
            data-testid="answeredNumber"
          >
            Answered {getNoOfAnsweredQuestions()}/{quiz?.questions?.length}
          </Typography>
          <IconButton
            onClick={() => setPopUp(() => false)}
            className={classes.closeIconWrapper}
          >
            <CloseIcon
              className={classes.closeIcon}
              data-testid="closeIcon-testId"
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReviewPageHeader
