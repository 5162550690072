import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LogoIcon from '../../Icons/LogoIcon'
import LogoIconDarkMode from '../../Icons/LogoIconDarkMode'

const useStyles = makeStyles((theme) => ({
  logo: {
    fontWeight: 600,
  },
  quizzing: {
    color: theme?.palette?.primarySelection?.main,
  },
  pro: {
    color: (props) =>
      props?.home
        ? theme?.palette?.darkTextColor?.main
        : theme?.palette?.lightTextColor?.main,
  },
}))

function Logo({ size, home }) {
  const classes = useStyles({ home })
  if (size !== 'small') {
    return home ? (
      <div data-testid="render-logo-icon">
        <LogoIcon />
      </div>
    ) : (
      <div data-testid="render-dark-mode-icon">
        <LogoIconDarkMode />
      </div>
    )
  }

  return (
    <Typography data-testid="render-logo" className={classes.logo}>
      <span className={classes.quizzing}>T</span>
      <span className={classes.pro}>P</span>
    </Typography>
  )
}

export default Logo
