import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},

  divider: {
    border: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
    width: '100%',
    height: 0,
    marginBottom: '40px',
  },

  startTestContainer: {
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
  },

  countDownTimerContainer: {
    height: '100%',
    width: '100%',
  },

  countDownTimer: {
    fontSize: '20px',
  },

  time: {
    fontWeight: 600,
  },

  quizEnded: {
    fontSize: '24px',
  },

  nameTextFieldWrapper: {
    border: `solid 1px ${theme?.palette?.borders?.main}`,
    borderRadius: 4,
    padding: '0 0 0 15px',
    height: '100%',
  },

  nameTextField: {
    height: '46px',
    padding: 0,
  },

  continueButton: {
    fontWeight: 600,
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    height: '100%',
    width: '104px',
    borderRadius: '5px',
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
  },

  loginButton: {
    cursor: 'pointer',
    fontWeight: 600,
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    height: '100%',
    width: '100%',
    borderRadius: '5px',
    border: 'none',
    padding: '10px',
    outline: 'none',
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
    '@media only screen and (min-width:426px)': {
      width: '196px',
    },
  },
  circularLoader: {
    color: 'whitesmoke',
    marginRight: '10px',
    marginTop: '3px',
  },
  googleIconWrapper: {
    background: 'white',
    width: '30px',
    height: '30px',
    borderRadius: '4px',
    marginRight: '10px',
    paddingTop: '3px',
  },
  googleIconContainer: {
    width: '19.56px',
  },
  loginMsgWrapper: {
    paddingBottom: '1px',
  },
  loginMsg: {
    fontWeight: 600,
  },
  publicQuizOnboardingPage: {
    width: '100%',
    height: '100%',
  },
  inputName: {
    height: '100%',
    width: 'calc(100% - 114px)',
    marginRight: '10px',
  },
  userNameLoader: {
    color: 'whitesmoke',
  },
  quizOnBoardingPage: {
    height: '100%',
    width: '100%',
  },
}))
export { useStyles }
