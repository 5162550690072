import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
    borderRadius: 4,
  },
  iconWrapper: {
    padding: '15px',
    width: 'max-content',
  },
  icon: {
    height: '24px',
    width: '24px',
  },
  contentWrapper: {
    flex: 1,
    paddingRight: '32px',
  },
  questionWrapper: {
    paddingTop: '15px',
    paddingBottom: '12.5px',
  },
  question: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '22px',
    fontSize: '16px',
    '& > *': {
      overflowWrap: 'break-word',
      msWordBreak: 'break-all',
      wordBreak: 'break-word',
      msHyphens: 'auto',
      MozHyphens: 'auto',
      WebkitHyphens: 'auto',
      hyphens: 'auto',
      whiteSpace: 'pre-wrap',
      margin: 0,
      padding: 0,
      listStylePosition: 'inside',
    },
    '& > * > img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    '& > pre': {
      background: 'black',
      color: 'white',
      padding: '5px',
    },
  },

  testCasesPassedAndViewAllTheTestcaseDetailsWrapper: {
    width: '100%',
    borderTop: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  testcasesPassedWrapper: {
    flex: 1,
    padding: '15.5px 10px 15.5px 0px',
  },
  testcasesPassedHeading: {
    fontSize: '12px',
    color: theme?.palette?.resultsPageLightTextColor?.main,
    marginBottom: '4px',
  },
  testcasesPassed: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '19px',
    marginBottom: '10px',
  },
  testCaseDetailsContainer: {
    width: '100%',
  },
  closeIconAndTextWrapper: {
    padding: 0,
  },
  testCasesText: {
    fontSize: '22px',
    height: '26px',
    fontWeight: 700,
    wordBreak: 'break-word',
    color: '#202020',
    paddingLeft: '30px',
  },
  codeTitleHeader: {
    fontSize: '22px',
    height: '26px',
    fontWeight: 700,
    wordBreak: 'break-word',
    color: '#202020',
    paddingLeft: '20px',
  },
  codeEditor: {
    marginTop: '20px',
    padding: 0,
  },
  sampleAndNormalTestCaseWrapper: {
    padding: '0 30px',
    margin: '30px 0',
  },
  resultTestCase: {
    marginBottom: '20px',
  },
  viewAllTheTestcaseDetailsWrapper: {
    paddingRight: '15px',
  },
  viewAllTheTestcaseDetails: {
    fontWeight: 600,
    color: theme?.palette?.primarySelection?.main,
    fontSize: '12px',
    padding: 0,
    width: 'max-content',
    '&:hover': {
      color: 'white',
      backgroundColor: theme?.palette?.primarySelection?.main,
    },
  },
  languageAndViewCodeWrapper: {
    width: '100%',
    borderTop: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  languageWrapper: {
    flex: 1,
    padding: '15.5px 10px 15.5px 0px',
  },
  languageHeading: {
    fontSize: '12px',
    color: theme?.palette?.resultsPageLightTextColor?.main,
    marginBottom: '4px',
  },
  language: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '19px',
    marginBottom: '10px',
  },
  viewCodeWrapper: {
    paddingRight: '15px',
  },
  viewCode: {
    fontWeight: 600,
    color: theme?.palette?.primarySelection?.main,
    fontSize: '12px',
    padding: 0,
    width: 'max-content',
    '&:disabled': {
      color: theme?.palette?.primarySelection?.disabled,
    },
    '&:hover': {
      color: 'white',
      backgroundColor: theme?.palette?.primarySelection?.main,
    },
  },
  marksContainer: {
    padding: '10px',
    width: '100%',
    borderTop: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  marksWrapper: {
    background: theme?.palette?.darkBackground?.main,
    height: '38px',
    borderRadius: 4,
  },
  marks: {
    fontWeight: 600,
  },
  marksHeading: {
    fontSize: '12px',
  },
}))
export { useStyles }
