import React from 'react'

function PageNotFoundIllustration() {
  return (
    <svg viewBox="0 0 470 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#D0ECEB" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="71.2898835%"
          id="linearGradient-2"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#9EC9C8" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="71.2898835%"
          id="linearGradient-3"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#BFDEDD" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="56.7433801%"
          y1="55.5270963%"
          x2="38.7162612%"
          y2="50%"
          id="linearGradient-4"
        >
          <stop stopColor="#FFFFFF" offset="0%" />
          <stop stopColor="#A1D2D0" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="100%"
          x2="50%"
          y2="0%"
          id="linearGradient-5"
        >
          <stop stopColor="#979797" offset="0%" />
          <stop stopColor="#C8C8C8" stopOpacity="0.366641171" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-6"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-7"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-8"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-9"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-8.12081541%"
          y1="50.0035013%"
          x2="102.099013%"
          y2="50%"
          id="linearGradient-10"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-8.12081541%"
          y1="50.0092554%"
          x2="102.099013%"
          y2="50%"
          id="linearGradient-11"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="-8.12081541%"
          y1="50.0041701%"
          x2="102.099013%"
          y2="50%"
          id="linearGradient-12"
        >
          <stop stopColor="#00DDD1" stopOpacity="0" offset="0%" />
          <stop stopColor="#00B8A5" offset="100%" />
        </linearGradient>
      </defs>
      <g id="03" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="When-we-enter-correct-quiz-number-but-some-wrong-route-in-address-bar-(-page-doesn't-exists-or-bad-route)"
          transform="translate(1.000000, 8.000000)"
        >
          <g id="Group-32" transform="translate(0.045455, 0.766234)">
            <path
              d="M67.9172484,282.873377 C64.0790503,266.098473 70.3032622,248.046281 86.5898835,228.7168 C111.019815,199.722578 118.60995,193.122461 107.520328,163.507401 C96.4307062,133.892341 70.3934797,71.7194704 126.083752,44.1556696 C181.774025,16.5918693 210.476227,51.1237796 239.72567,60.3140873 C268.975112,69.5043949 352.238681,92.0267025 384.308604,142.832176 C416.378531,193.63765 390.130006,192.974902 384.308604,231.458573 C378.487205,269.942244 397.63111,282.873377 374.052306,282.873377 C358.333107,282.873377 256.288087,282.873377 67.9172484,282.873377 Z"
              id="Path-2"
              fill="#DFF1F1"
              fillRule="nonzero"
            />
            <rect
              id="Rectangle"
              fill="#B2CECE"
              fillRule="nonzero"
              x="0"
              y="274.756494"
              width="466.314935"
              height="8.11688312"
              rx="4.05844156"
            />
            <polygon
              id="Path-33"
              stroke="#00B8A5"
              strokeWidth="2.43506494"
              opacity="0.133698219"
              points="194.399351 111.201299 256.087662 109.225536 256.087662 77.9220779 319.399351 76.7045455 319.44863 168.019481 357.006915 167.853694 357.006915 247.159091 281.655844 247.159091 281.655844 266.864987 194.399351 266.864987 194.399351 257.305195 165.996583 257.305195 165.996583 210.227273 138.392857 209.415584 138.392857 138.728501 194.399351 138.728501"
            />
            <path
              d="M300.503958,126.895439 L227.710732,148.05472 L227.710732,148.05472 L157.295153,126.907525 C156.865814,126.778586 156.413239,127.022108 156.2843,127.451448 C156.261556,127.527183 156.25,127.605836 156.25,127.684913 L156.25,221.819707 C156.25,222.172249 156.477565,222.484481 156.813177,222.592417 L216.794787,241.883117 L216.794787,241.883117 L239.507966,241.883117 L300.973605,222.589771 C301.311969,222.483563 301.542208,222.169979 301.542208,221.815338 L301.542208,127.674867 C301.542208,127.226584 301.178802,126.863179 300.730519,126.863179 C300.653854,126.863179 300.577576,126.87404 300.503958,126.895439 Z"
              id="Path-24"
              stroke="#252B4C"
              strokeWidth="0.811688312"
              fill="#00B8A5"
              fillRule="nonzero"
            />
            <path
              d="M297.139414,131.102864 L272.936433,131.102864 C265.35267,131.102864 257.920622,133.227773 251.48304,137.236639 L228.724264,151.409175 L228.724264,151.409175 C217.308039,139.068573 207.649313,131.712098 199.748088,129.33975 C191.846864,126.967402 178.961859,127.555107 161.093073,131.102864 L161.093073,218.863337 L217.603505,237.482755 L239.657983,237.482755 L297.139414,218.863337 L297.139414,131.102864 Z"
              id="Path-25"
              fill="url(#linearGradient-1)"
              fillRule="nonzero"
            />
            <path
              d="M171.65978,124.222796 L171.65978,213.718198 C186.158859,215.034933 196.849483,216.955186 203.731651,219.478956 C210.61382,222.002726 219.34088,227.20316 229.912831,235.080259 C244.774651,226.859955 256.002371,221.659521 263.595991,219.478956 C271.18961,217.298391 280.609634,216.56967 291.85606,217.292793 L291.85606,128.787844 C276.885737,127.285218 265.780469,127.85031 258.540254,130.48312 C251.30004,133.115931 241.894477,140.009338 230.323565,151.163341 C221.857456,139.705953 213.857704,132.247454 206.324308,128.787844 C198.790912,125.328235 187.236069,123.806552 171.65978,124.222796 Z"
              id="Path-26-Copy"
              fill="url(#linearGradient-2)"
              fillRule="nonzero"
            />
            <path
              d="M172.980618,125.337642 L172.980618,210.89918 C186.842375,212.158037 197.063082,213.993883 203.642737,216.406718 C210.222393,218.819553 218.565845,223.791397 228.673095,231.322249 C242.881649,223.463278 253.615843,218.491434 260.875677,216.406718 C268.135511,214.322002 277.141468,213.625313 287.893546,214.31665 L287.893546,129.702028 C273.581259,128.265451 262.964134,128.805704 256.04217,131.322787 C249.120207,133.839869 240.128075,140.430269 229.065775,151.093987 C220.971803,140.14022 213.323689,133.009567 206.121431,129.702028 C198.919173,126.394489 187.872235,124.939694 172.980618,125.337642 Z"
              id="Path-26"
              fill="url(#linearGradient-3)"
              fillRule="nonzero"
            />
            <path
              d="M179.584809,121.753247 C192.700618,123.620754 202.178704,126.2768 208.019067,129.721384 C213.859431,133.165968 220.963762,140.290055 229.332059,151.093645 L229.277179,151.022727 L229.336383,151.235595 L229.336383,226.481851 L219.051681,222.893738 C214.151705,219.019946 209.945905,216.164325 206.434282,214.326873 C200.751177,211.353197 191.801353,208.385591 179.584809,205.424054 L179.584809,205.424054 L179.584809,121.753247 Z"
              id="Combined-Shape"
              fill="url(#linearGradient-4)"
              fillRule="nonzero"
              opacity="0.2"
            />
            <path
              d="M228.896103,121.753247 C242.011912,123.620754 251.489998,126.2768 257.330362,129.721384 C263.170726,133.165968 270.275056,140.290055 278.643353,151.093645 L278.588474,151.022727 L278.647678,151.235595 L278.647678,226.481851 L268.362976,222.893738 C263.462999,219.019946 259.257199,216.164325 255.745576,214.326873 C250.062471,211.353197 241.112647,208.385591 228.896103,205.424054 L228.896103,205.424054 L228.896103,121.753247 Z"
              id="Combined-Shape-Copy-2"
              fill="url(#linearGradient-4)"
              fillRule="nonzero"
              opacity="0.2"
              transform="translate(253.771891, 174.117549) scale(-1, 1) translate(-253.771891, -174.117549) "
            />
            <path
              d="M229.116244,236.782022 C234.587335,236.782022 239.02253,238.526779 239.02253,232.988857 C239.02253,227.450935 234.587335,222.961562 229.116244,222.961562 C223.645153,222.961562 219.209957,227.450935 219.209957,232.988857 C219.209957,238.526779 223.645153,236.782022 229.116244,236.782022 Z"
              id="Oval"
              fill="#D5EEED"
              fillRule="nonzero"
            />
            <path
              d="M235.795455,160.714286 C241.416373,154.284788 249.168071,143.668831 273.133117,143.668831"
              id="Path-29"
              stroke="#9FCAC8"
              strokeWidth="0.5"
            />
            <path
              d="M235.795455,165.178571 C241.416373,158.749073 249.168071,148.133117 273.133117,148.133117"
              id="Path-29-Copy"
              stroke="#9FCAC8"
              strokeWidth="0.5"
            />
            <path
              d="M234.983766,169.237013 C240.741709,162.73522 243.593315,159.088856 253.246753,155.032468"
              id="Path-29-Copy-2"
              stroke="#9FCAC8"
              strokeWidth="0.5"
            />
            <path
              d="M235.795455,173.295455 C241.416373,166.865957 249.168071,156.25 273.133117,156.25"
              id="Path-29-Copy-3"
              stroke="#9FCAC8"
              strokeWidth="0.5"
            />
            <path
              d="M235.795455,177.353896 C241.416373,170.924398 249.168071,160.308442 273.133117,160.308442"
              id="Path-29-Copy-4"
              stroke="#9FCAC8"
              strokeWidth="0.5"
            />
            <path
              d="M186.688312,178.165584 L186.688312,200.210362 C193.207744,201.647996 197.781223,202.880577 200.408748,203.908106 C203.036273,204.935634 206.309114,206.77146 210.227273,209.415584 L210.227273,187.821302 C206.330585,185.428022 203.057744,183.688071 200.408748,182.60145 C197.759752,181.514828 193.186274,180.036206 186.688312,178.165584 Z"
              id="Path-30"
              stroke="#9FCAC8"
              strokeWidth="0.5"
            />
            <line
              x1="229.50487"
              y1="150.162338"
              x2="229.50487"
              y2="222.808442"
              id="Path-31"
              stroke="url(#linearGradient-5)"
              strokeWidth="0.5"
              opacity="0.294036156"
            />
            <path
              d="M248.067663,82.8718523 L230.519481,121.753247 C237.430652,118.866422 245.320624,117.014694 254.189397,116.198064 C263.058169,115.381433 270.298698,116.599632 275.910982,119.852661 L281.655844,80.8323693 C279.73301,78.0804866 274.557859,76.7045455 266.130393,76.7045455 C257.702927,76.7045455 251.682017,78.7603145 248.067663,82.8718523 Z"
              id="Path-32"
              fill="#203C57"
              fillRule="nonzero"
              opacity="0.04"
            />
            <path
              d="M162.617331,45.2346518 L148.133117,77.1103896 C153.837576,74.7437135 160.349933,73.2256307 167.67019,72.5561404 C174.990447,71.8866506 180.966756,72.8853543 185.599118,75.5522516 L190.340909,43.5626433 C188.753808,41.3065954 184.482255,40.1785714 177.526251,40.1785714 C170.570247,40.1785714 165.600607,41.8639314 162.617331,45.2346518 Z"
              id="Path-32-Copy"
              fill="#203C57"
              fillRule="nonzero"
              opacity="0.04"
              transform="translate(169.237013, 58.644481) scale(-1, 1) translate(-169.237013, -58.644481) "
            />
            <path
              d="M255.957121,31.3924875 L246.347403,52.7597403 C250.132092,51.1732873 254.452791,50.1556709 259.3095,49.706892 C264.166208,49.2581128 268.13126,49.9275739 271.204653,51.7152739 L274.350649,30.2716906 C273.297668,28.7593947 270.463657,28.0032468 265.848616,28.0032468 C261.233575,28.0032468 257.93641,29.1329937 255.957121,31.3924875 Z"
              id="Path-32-Copy-2"
              fill="#203C57"
              fillRule="nonzero"
              opacity="0.04"
            />
            <path
              d="M296.59702,12.941085 L289.772727,28.0032468 C292.460405,26.8849274 295.528727,26.1675914 298.977694,25.851239 C302.426662,25.5348865 305.242423,26.0068015 307.424978,27.2669838 L309.659091,12.151015 C308.911322,11.0849704 306.898763,10.5519481 303.621416,10.5519481 C300.344067,10.5519481 298.002602,11.348327 296.59702,12.941085 Z"
              id="Path-32-Copy-3"
              fill="#203C57"
              fillRule="nonzero"
              opacity="0.04"
            />
            <path
              d="M248.067663,72.3199042 L230.519481,111.201299 C237.430652,108.314474 245.320624,106.462746 254.189397,105.646116 C263.058169,104.829485 270.298698,106.047684 275.910982,109.300713 L281.655844,70.2804213 C279.73301,67.5285386 274.557859,66.1525974 266.130393,66.1525974 C257.702927,66.1525974 251.682017,68.2083665 248.067663,72.3199042 Z"
              id="Path-32"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <path
              d="M162.617331,34.6827039 L148.133117,66.5584416 C153.837576,64.1917654 160.349933,62.6736826 167.67019,62.0041924 C174.990447,61.3347025 180.966756,62.3334062 185.599118,65.0003036 L190.340909,33.0106953 C188.753808,30.7546474 184.482255,29.6266234 177.526251,29.6266234 C170.570247,29.6266234 165.600607,31.3119835 162.617331,34.6827039 Z"
              id="Path-32-Copy"
              fill="#FFFFFF"
              fillRule="nonzero"
              transform="translate(169.237013, 48.092532) scale(-1, 1) translate(-169.237013, -48.092532) "
            />
            <path
              d="M255.957121,20.8405394 L246.347403,42.2077922 C250.132092,40.6213393 254.452791,39.603723 259.3095,39.154944 C264.166208,38.7061649 268.13126,39.3756256 271.204653,41.1633259 L274.350649,19.7197425 C273.297668,18.2074466 270.463657,17.4512987 265.848616,17.4512987 C261.233575,17.4512987 257.93641,18.5810456 255.957121,20.8405394 Z"
              id="Path-32-Copy-2"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <path
              d="M296.59702,2.38913692 L289.772727,17.4512987 C292.460405,16.3329793 295.528727,15.6156433 298.977694,15.2992909 C302.426662,14.9829385 305.242423,15.4548534 307.424978,16.7150357 L309.659091,1.59906697 C308.911322,0.533022325 306.898763,0 303.621416,0 C300.344067,0 298.002602,0.796378973 296.59702,2.38913692 Z"
              id="Path-32-Copy-3"
              fill="#FFFFFF"
              fillRule="nonzero"
            />
            <circle
              id="Oval"
              fill="#00B8A5"
              fillRule="nonzero"
              opacity="0.543082304"
              cx="319.399351"
              cy="168.019481"
              r="5.27597403"
            />
            <circle
              id="Oval-Copy-10"
              fill="#00B8A5"
              fillRule="nonzero"
              opacity="0.543082304"
              cx="194.399351"
              cy="110.795455"
              r="5.27597403"
            />
            <circle
              id="Oval-Copy-11"
              fill="#00B8A5"
              fillRule="nonzero"
              opacity="0.543082304"
              cx="138.392857"
              cy="208.198052"
              r="5.27597403"
            />
            <circle
              id="Oval-Copy-12"
              fill="#00B8A5"
              fillRule="nonzero"
              opacity="0.543082304"
              cx="194.399351"
              cy="257.305195"
              r="5.27597403"
            />
            <circle
              id="Oval-Copy-13"
              fill="#00B8A5"
              fillRule="nonzero"
              opacity="0.543082304"
              cx="281.655844"
              cy="247.159091"
              r="5.27597403"
            />
            <line
              x1="148.133117"
              y1="33.6850649"
              x2="148.133117"
              y2="139.123374"
              id="Path-34"
              stroke="url(#linearGradient-6)"
              strokeWidth="2.43506494"
              opacity="0.133698219"
            />
            <line
              x1="340.909091"
              y1="105.519481"
              x2="340.909091"
              y2="168.749997"
              id="Path-34-Copy"
              stroke="url(#linearGradient-7)"
              strokeWidth="2.43506494"
              opacity="0.133698219"
            />
            <line
              x1="215.909091"
              y1="55.6006494"
              x2="215.909091"
              y2="111.120127"
              id="Path-34-Copy-6"
              stroke="url(#linearGradient-8)"
              strokeWidth="2.43506494"
              opacity="0.133698219"
            />
            <line
              x1="340.909091"
              y1="247.159091"
              x2="340.909091"
              y2="276.785714"
              id="Path-34-Copy-2"
              stroke="url(#linearGradient-9)"
              strokeWidth="2.43506494"
              opacity="0.133698219"
              transform="translate(340.909091, 261.972403) rotate(180.000000) translate(-340.909091, -261.972403) "
            />
            <line
              x1="68.5876623"
              y1="247.159091"
              x2="165.381494"
              y2="246.956169"
              id="Path-34-Copy-3"
              stroke="url(#linearGradient-10)"
              strokeWidth="2.43506494"
              opacity="0.133698219"
            />
            <line
              x1="88.8798701"
              y1="116.071429"
              x2="148.336039"
              y2="115.868506"
              id="Path-34-Copy-4"
              stroke="url(#linearGradient-11)"
              strokeWidth="2.43506494"
              opacity="0.133698219"
            />
            <line
              x1="297.280844"
              y1="208.198052"
              x2="385.957792"
              y2="207.99513"
              id="Path-34-Copy-5"
              stroke="url(#linearGradient-12)"
              strokeWidth="2.43506494"
              opacity="0.133698219"
              transform="translate(341.619318, 208.096591) scale(-1, 1) translate(-341.619318, -208.096591) "
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PageNotFoundIllustration
