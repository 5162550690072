import { useMutation, useQuery } from 'react-query'
import { quizzes } from '../../Repositories/Quiz/Quiz'

const quizReq = quizzes()
/**
 * this function is to Query get All application
 * @function useQuizTypeGetQueryById
 ** @param {reqId, options} default parameter
 */
/**
export function useQuizInfoGetQueryById(reqId, options) {
  return useQuery(
    ['quizTypeGetQueryById', reqId],
    () => quizReq.getQuizInfoById(reqId),
    { ...options }
  )
}
 */

/**
 * this function is to Query get All application
 * @function useQuizTypeGetQueryById
 ** @param {reqId, options} default parameter
 */
export function useUserResponseGetQueryByQuizId(
  reqId,
  reqUserIdentifier,
  options
) {
  return useQuery(
    ['useUserResponseGetQueryByQuizId', reqId, reqUserIdentifier],
    () => quizReq.getUserResponseByQuizId(reqId, reqUserIdentifier),
    { ...options }
  )
}
/**
 * this function is to Query get All application
 * @function useQuizGetQueryById
 ** @param {reqId, options} default parameter
 */
export function useQuizGetQueryById(reqId, options) {
  return useQuery(
    ['quizGetQueryById', reqId],
    () => quizReq.getQuizById(reqId),
    { ...options }
  )
}

/**
 * this function is to Query get All application
 * @function useQuizQuestionGetQueryById
 ** @param {reqId, options} default parameter
 */
export function useQuizQuestionGetQueryById(reqId, options) {
  return useQuery(
    ['quizQuestionGetQueryById', reqId],
    () => quizReq.getQuizQuestionById(reqId),
    { ...options }
  )
}
/**
 * this function is to get user specific evaluated response
 * @function useQuizGetQueryEvaluatedResponseById
 ** @param {reqId, options} default parameter
 */
export function useQuizGetQueryEvaluatedResponseById(reqId, options) {
  return useQuery(
    ['quizGetQueryEvaluatedResponseById', reqId],
    () => quizReq.getQueryEvaluatedResponseById(reqId),
    { ...options }
  )
}

/**
 * this function is to Query get All application
 * @function useQuizResponsePutQueryById
 ** @param {reqId, options} default parameter
 */
export function useQuizResponsePutQueryById(reqId, options) {
  return useMutation(() => quizReq.putQuizResponseById(reqId), {
    ...options,
  })
}

/**
 * this function is to Query get All application
 * @function useQuizPostQueryById
 ** @param {reqId, options} default parameter
 */
export function useQuizPostQueryById(reqId, options) {
  return useMutation(() => quizReq.postQuizById(reqId), {
    ...options,
  })
}

/**
 * this function is to Query get All application
 * @function useMCQPutQueryById
 ** @param {reqId, options} default parameter
 */
export function useMCQPostQueryById(reqQuestionId, options) {
  return useMutation((args) => quizReq.postMCQById(reqQuestionId, args), {
    ...options,
  })
}

/**
 * this function is to Query get All application
 * @function useCodingQuestionPutQueryById
 ** @param {reqId, options} default parameter
 */
export function useCodingQuestionPostQueryById(reqQuizId, options) {
  return useMutation(
    (args) => quizReq.postCodingQuestionById(reqQuizId, args),
    {
      ...options,
    }
  )
}

/**
 * this function is to Query get All application
 * @function useQuestionSubmissionGetById
 ** @param {reqId, options} default parameter
 */
/** 
 export function useQuestionSubmissionGetQueryById(
  reqId,
  reqQuestionId,
  reqUserIdentifier,

  options
) {
  return useQuery(
    ['questionGetById', reqId, reqQuestionId, reqUserIdentifier],
    () =>
      quizReq.getQuestionSubmisisonById(
        reqId,
        reqQuestionId,
        reqUserIdentifier
      ),
    { ...options }
  )
}
*/

/**
 * this function is to Query get All application
 * @function useTabChangePatchQuery
 ** @param {reqId, options} default parameter
 */
export function useTabChangePatchQuery(reqQuizId, reqResponseId, options) {
  return useMutation(
    (args) => quizReq.patchTabChange(reqQuizId, reqResponseId, args),
    {
      ...options,
    }
  )
}
