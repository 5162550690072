import React from 'react'
import { useAuth } from '@zopsmart/zs-components'
import {
  MOCK_REACT_APP_CLIENT_ID,
  MOCK_BASE_URL,
  REFRESH_TOKEN_URL,
  LOGIN_ENDPOINT,
} from '../../constants'

const Login = ({ quizInfoDetails, handleResponse }) => {
  const tenantID = quizInfoDetails.orgID
  const providers = [
    {
      name: 'Google',
      authClientID: MOCK_REACT_APP_CLIENT_ID,
      onSuccess: handleResponse,
    },
  ]

  const { login } = useAuth(
    'tokenStorage',
    MOCK_BASE_URL,
    LOGIN_ENDPOINT,
    REFRESH_TOKEN_URL,
    providers,
    '',
    '',
    tenantID,
    'sessionStorage'
  )

  return <div>{login()}</div>
}

export default Login
