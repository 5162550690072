import React, { useState } from 'react'
import parse from 'html-react-parser'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CodeEditor from '../CodeEditor/CodeEditor'
import GreenTickCircleOutlinedIcon from '../../Icons/GreenTickCircleOutlinedIcon'
import RedWrongCircleOutlinedIcon from '../../Icons/RedWrongCircleOutlinedIcon'
import GrayThreeDotsCircleOutlinedIcon from '../../Icons/GrayThreeDotsCircleOutlinedIcon'
import YellowTickCircleOutlinedIcon from '../../Icons/YellowTickCircleOutlinedIcon'
import ResultNormalTestcase from '../ResultNormalTestcase/ResultNormalTestcase'
import ResultSampleTestcase from '../ResultSampleTestcase/ResultSampleTestcase'
import { useStyles } from './CodingQuestionResultRowItemMobileStyles'

function CodingQuestionResultRowItemMobile({ question }) {
  const [openViewCodeDialog, setOpenViewCodeDialog] = useState(false)
  const [openViewAllTheTestcaseDetails, setOpenViewAllTheTestcaseDetails] =
    useState(false)
  const doesCodeExist = !!question?.code
  const isCorrect =
    question?.result?.testcasesPassed === question?.result?.totalTestcases
  const isPartiallyCorrect =
    question?.result?.testcasesPassed &&
    question?.result?.testcasesPassed < question?.result?.totalTestcases
  const isWrong = question?.language && question?.result?.testcasesPassed === 0
  const isNotAttempted = !question?.language
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      data-testid="render-component"
    >
      <Grid container className={classes.testCaseDetailsContainer}>
        <Grid container className={classes.iconWrapper}>
          <Grid item data-testid="render-icons" className={classes.icon}>
            {isCorrect ? <GreenTickCircleOutlinedIcon /> : null}
            {isWrong ? <RedWrongCircleOutlinedIcon /> : null}
            {isNotAttempted ? <GrayThreeDotsCircleOutlinedIcon /> : null}
            {isPartiallyCorrect ? <YellowTickCircleOutlinedIcon /> : null}
          </Grid>
        </Grid>
        <Grid
          data-testid="render-question"
          container
          direction="column"
          className={classes.contentWrapper}
        >
          <Grid item className={classes.questionWrapper}>
            <Grid item className={classes.question}>
              {parse(question?.question ?? '')}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.testcasesPassedWrapper}
          >
            <Typography
              data-testid="render-test-cases-passed-text"
              className={classes.testcasesPassedHeading}
            >
              Test cases passed
            </Typography>
            <Typography
              data-testid="render-test-case-ratio"
              className={classes.testcasesPassed}
            >
              {question?.result?.testcasesPassed}/
              {question?.result?.totalTestcases}
            </Typography>
            <Dialog
              fullWidth
              maxWidth="md"
              onClose={() => setOpenViewAllTheTestcaseDetails(() => false)}
              open={openViewAllTheTestcaseDetails}
            >
              <DialogTitle className={classes.closeIconAndTextWrapper}>
                <Grid
                  container
                  justify="flex-end"
                  className={classes.testCaseDetailsContainer}
                >
                  <IconButton
                    data-testid="render-icon-button"
                    onClick={() =>
                      setOpenViewAllTheTestcaseDetails(() => false)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Typography className={classes.testCasesText}>
                  {question?.testcases?.length} test cases
                </Typography>
              </DialogTitle>
              <DialogContent className={classes.sampleAndNormalTestCaseWrapper}>
                <Grid item>
                  {question?.compilationOutput?.codeRunOutputList
                    ? question?.compilationOutput?.codeRunOutputList.map(
                        (testcase, index) => {
                          return (
                            <Grid
                              item
                              key={index.toString(2)}
                              className={classes.resultTestCase}
                            >
                              <ResultSampleTestcase
                                testcase={testcase}
                                index={index}
                                maximumMarks={
                                  question?.testcases?.filter(
                                    (filterTestcase) => {
                                      return (
                                        filterTestcase?.id ===
                                        testcase?.testCaseInfo?.testCaseId
                                      )
                                    }
                                  )?.[0]?.marks
                                }
                              />
                            </Grid>
                          )
                        }
                      )
                    : question?.testcases?.map((testcase, index) => {
                        return (
                          <Grid
                            data-testid="render-test-cases"
                            item
                            key={index.toString(2)}
                            className={classes.resultTestCase}
                          >
                            <ResultNormalTestcase
                              testcase={testcase}
                              index={index}
                              maximumMarks={testcase?.marks}
                            />
                          </Grid>
                        )
                      })}
                </Grid>
              </DialogContent>
            </Dialog>
            <Button
              data-testid="render-button-view-all"
              variant="text"
              className={classes.viewAllTheTestcaseDetails}
              onClick={() => setOpenViewAllTheTestcaseDetails(() => true)}
            >
              View all the test case details
            </Button>
          </Grid>

          <Grid
            data-testid="render-language-modal"
            container
            direction="column"
            className={classes.languageWrapper}
          >
            <Typography
              data-testid="render-laguage-text"
              className={classes.languageHeading}
            >
              Language
            </Typography>
            <Typography
              data-testid="render-question-language"
              className={classes.language}
            >
              {question?.language ? (
                <> {question?.language}</>
              ) : (
                <>Not Selected</>
              )}
            </Typography>
            <Dialog
              fullWidth
              maxWidth="md"
              onClose={() => setOpenViewCodeDialog(() => false)}
              open={openViewCodeDialog}
            >
              <DialogTitle className={classes.closeIconAndTextWrapper}>
                <Grid
                  container
                  justify="flex-end"
                  className={classes.testCaseDetailsContainer}
                >
                  <IconButton
                    data-testid="render-second-icon-button"
                    onClick={() => setOpenViewCodeDialog(() => false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Typography className={classes.testCasesText}>Code</Typography>
              </DialogTitle>
              <DialogContent className={classes.codeEditorContainer}>
                <CodeEditor
                  language={question?.language}
                  isDarkMode
                  code={question?.code}
                  readOnly
                  width="100%"
                />
              </DialogContent>
            </Dialog>
            <Button
              data-testid="render-view-code-button"
              variant="text"
              disabled={!doesCodeExist}
              onClick={() => setOpenViewCodeDialog(() => true)}
              className={classes.viewCode}
            >
              View Code
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.marksContainer}>
        <Grid
          data-testid="render-marks"
          container
          alignItems="center"
          justify="center"
          className={classes.marksWrapper}
        >
          <Typography>
            <span className={classes.marks}>
              {question?.result?.marks}/{question?.maximumMarks}
            </span>{' '}
            Marks
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CodingQuestionResultRowItemMobile
