import React from 'react'
import { Route, Redirect, useRouteMatch } from 'react-router-dom'
import { isLoggedIn } from '../utils'

const ProtectedRoute = (props) => {
  const { exact, path } = props
  const match = useRouteMatch(path)

  return (
    <Route exact={exact} path={path}>
      {isLoggedIn() ? (
        <>{props.children}</>
      ) : (
        <Redirect to={`/quiz/${match?.params?.quizId}`} />
      )}
    </Route>
  )
}

export default ProtectedRoute
