import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  cautionIconWrapper: {
    margin: '0.3rem 0.3rem 0 0',
    color: 'goldenrod',
  },
  warningDialogButtons: {
    margin: '0 0.5rem 0.5rem 0',
  },
  stayButton: {
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
  },
}))
export { useStyles }
