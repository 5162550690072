import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import CustomSelect from '../CustomSelect/CustomSelect'
import { useStyles } from './EditorQuizSettingsDialogStyle'

const fontSizes = [
  { value: 12, label: '12px' },
  { value: 13, label: '13px' },
  { value: 14, label: '14px' },
  { value: 15, label: '15px' },
  { value: 16, label: '16px' },
]

const tabSizes = [
  { value: 2, label: '2 spaces' },
  { value: 4, label: '4 spaces' },
  { value: 8, label: '8 spaces' },
]

const keyBindings = [
  { value: 'vim', label: 'Vim' },
  { value: 'emacs', label: 'Emacs' },
  { value: 'sublime', label: 'Sublime' },
  { value: 'vscode', label: 'VSCode' },
]

function EditorSettingsDialog({
  openEditorSettingsDialog,
  setOpenEditorSettingsDialog,
  editorSettings,
  setEditorSettings,
}) {
  const classes = useStyles()
  const returnGridItem = (text, list, setting) => {
    return (
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.settingsWrapper}
      >
        <Typography data-testid="textMessage-testid">{text}</Typography>
        <Grid item className={classes.select}>
          <CustomSelect
            value={editorSettings?.[setting]}
            onChange={(option) => {
              setEditorSettings((editorSettingsPrev) => {
                return {
                  ...editorSettingsPrev,
                  [setting]: option?.value,
                }
              })
            }}
            options={list}
          />
        </Grid>
      </Grid>
    )
  }
  return (
    <Dialog
      fullWidth
      onClose={() => setOpenEditorSettingsDialog(() => false)}
      open={openEditorSettingsDialog}
    >
      <DialogTitle className={classes.editorSettingsWrapper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" className={classes.editorSettingsHeading}>
              Editor settings
            </Typography>
          </Grid>
          <IconButton
            size="small"
            onClick={() => setOpenEditorSettingsDialog(() => false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column">
          {returnGridItem('Choose a fontsize', fontSizes, 'fontSize')}
          {returnGridItem('Choose a tabsize', tabSizes, 'tabSize')}
          {returnGridItem('Choose a keybinding', keyBindings, 'keyBinding')}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default EditorSettingsDialog
