import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme?.palette?.darkBackground?.main,
  },
  header: {
    height: '46px',
    width: '100%',
    background: theme?.palette?.header?.main,
    paddingLeft: '16px',
  },
  body: {
    height: 'calc(100% - 46px)',
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  illustrationAndTextWrapper: {
    width: '100%',
    paddingBottom: '46px',
    '@media only screen and (min-width:426px)': {
      paddingBottom: '65px',
    },
    '@media only screen and (min-width:769px)': {
      paddingBottom: '85px',
    },
    '@media only screen and (min-width:1025px)': {
      paddingBottom: '100px',
    },
    '@media only screen and (min-width:1800px)': {
      paddingBottom: '125px',
    },
  },
  illustrationWrapper: {
    width: '75%',
    paddingBottom: '20px',
    '@media only screen and (min-width:426px)': {
      width: '55%',
      paddingBottom: '30px',
    },
    '@media only screen and (min-width:769px)': {
      width: '43%',
      paddingBottom: '50px',
    },
    '@media only screen and (min-width:1025px)': {
      width: '33%',
      paddingBottom: '60px',
    },
  },
  textWrapper: {
    width: '75%',
    '@media only screen and (min-width:426px)': {
      width: '55%',
    },
    '@media only screen and (min-width:769px)': {
      width: '45%',
    },
    '@media only screen and (min-width:1025px)': {
      width: '35%',
    },
  },
  text: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    fontSize: '16px',
    textAlign: 'center',
    '@media only screen and (min-width:426px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:1800px)': {
      fontSize: '28px',
    },
  },
  showResultsButton: {
    background: theme?.palette?.secondaryButton?.main,
    color: theme?.palette?.secondaryButton?.contrastText,
    borderRadius: '20px',
    fontWeight: 'bold',
    padding: '0.5rem 1.2rem',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
  },
  showResultsButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '40px',
  },
  loader: {
    marginBottom: '1rem',
  },
}))
export { useStyles }
