import React, { useEffect, useRef } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ReviewPageRowItem from '../ReviewPageRowItem/ReviewPageRowItem'
import ReviewPageRowItemMobile from '../ReviewPageRowItemMobile/ReviewPageRowItemMobile'
import { useStyles } from './ReviewPageTableStyles'

function ReviewPageTable({
  quiz,
  currentQuestion,
  setCurrentQuestion,
  startScrolling,
  setStartScrolling,
  reviewQuestionFilter,
  setCurrentQuestionFilter,
  setPopUp,
  scrolledToQuestionId,
  setScrolledToQuestionId,
}) {
  const classes = useStyles()

  const props = {
    isWidthAboveTabRange: useMediaQuery('only screen and (min-width:769px)'),
  }
  const questionsContainerRef = useRef()
  const questionRefs = useRef({})

  useEffect(() => {
    questionsContainerRef.current.scrollTop = 0
  }, [reviewQuestionFilter])

  useEffect(() => {
    setStartScrolling(() => true)
    if (scrolledToQuestionId) {
      questionRefs?.current?.[currentQuestion?.id]?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [startScrolling])
  return (
    <div className={classes.root}>
      {props?.isWidthAboveTabRange && (
        <div className={classes.questionAndTypeWrapper}>
          <div className={classes.questionNumberHeader}>No.</div>
          <div className={classes.questionTitleHeader}>Question</div>
          <div className={classes.questionTypeHeader}>Type</div>
          <div className={classes.gapBetweenHeaderAndTable} />
        </div>
      )}
      <div
        ref={questionsContainerRef}
        className={classes.reviwPageDetailsWrapper}
      >
        <div className={classes.reviwPageDetails}>
          {quiz?.questions?.map((question, index) => {
            if (
              ((reviewQuestionFilter === 'mcq' ||
                reviewQuestionFilter === 'multi-choice') &&
                (question?.type === 'mcq' ||
                  question?.type === 'multi-choice')) ||
              (reviewQuestionFilter === 'coding' &&
                question?.type === 'coding') ||
              (reviewQuestionFilter === 'essay' &&
                question?.type === 'essay') ||
              (reviewQuestionFilter === 'fill-in-blanks' &&
                question?.type === 'fill-in-blanks') ||
              (reviewQuestionFilter === 'answered' &&
                question?.saved &&
                question?.saved !== 'pending' &&
                question?.saved !== 'failed') ||
              (reviewQuestionFilter === 'unanswered' &&
                (!question?.saved ||
                  question?.saved === 'pending' ||
                  question?.saved === 'failed')) ||
              reviewQuestionFilter === 'all'
            ) {
              return (
                <div
                  key={index.toString(2)}
                  ref={(element) => {
                    questionRefs.current[question?.id] = element
                  }}
                >
                  {props?.isWidthAboveTabRange ? (
                    <ReviewPageRowItem
                      index={index}
                      question={question}
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      setCurrentQuestionFilter={setCurrentQuestionFilter}
                      setPopUp={setPopUp}
                      setScrolledToQuestionId={setScrolledToQuestionId}
                    />
                  ) : (
                    <ReviewPageRowItemMobile
                      index={index}
                      question={question}
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      setCurrentQuestionFilter={setCurrentQuestionFilter}
                      setPopUp={setPopUp}
                      setScrolledToQuestionId={setScrolledToQuestionId}
                    />
                  )}
                </div>
              )
            }

            return null
          })}
        </div>
      </div>
    </div>
  )
}

export default ReviewPageTable
