import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import SampleTestCase from '../SampleTestCase/SampleTestCase'
import CustomTestCase from '../CustomTestCase/CustomTestCase'
import HiddenTestCase from '../HiddenTestCase/HiddenTestCase'
import { useStyles } from './CompilationPassedContentStyles'

function CompilationPassedContent({
  compileMsg,
  resultsStatus,
  editorWrapperWidth,
  consoleBodyHeight,
  customInput,
}) {
  let fontSize

  if (editorWrapperWidth < 350) {
    fontSize = '14px'
  } else if (editorWrapperWidth < 425) {
    fontSize = '16px'
  } else if (editorWrapperWidth < 769) {
    fontSize = '18px'
  } else {
    fontSize = '20px'
  }
  const classes = useStyles(editorWrapperWidth, fontSize)
  const returnContent = () => {
    if (resultsStatus === 'PENDING') {
      return (
        <Grid container alignItems="center">
          <Grid
            data-testid="render-results-test-case-passed"
            item
            className={classes.pendingEvaluationMessage}
          >
            <Typography className={classes.compileResultMsg}>
              (Evaluating test cases....)
            </Typography>
          </Grid>

          <Grid
            data-testid="render-circular-progress"
            item
            className={classes.compileResultMsg}
          >
            <CircularProgress size={fontSize} />
          </Grid>
        </Grid>
      )
    }

    if (
      compileMsg?.result?.testcasesPassed !== 0 &&
      compileMsg?.result?.testcasesPassed === compileMsg?.result?.totalTestcases
    ) {
      return (
        <Typography
          data-testid="render-passed-test-case-equals-total-test-case"
          className={classes.compileResultMsg}
        >
          (All test cases passed {compileMsg?.result?.testcasesPassed}/
          {compileMsg?.result?.totalTestcases})
        </Typography>
      )
    }
    if (
      compileMsg?.result?.testcasesPassed !== 0 &&
      compileMsg?.result?.testcasesPassed < compileMsg?.result?.totalTestcases
    ) {
      return (
        <Typography
          data-testid="render-passed-test-case-less-than-total-test-case"
          className={classes.compileResultMsg}
        >
          (Partially passed {compileMsg?.result?.testcasesPassed}/
          {compileMsg?.result?.totalTestcases})
        </Typography>
      )
    }
    if (
      compileMsg?.result?.testcasesPassed === 0 &&
      compileMsg?.codeRunOutputList?.[0]?.testCaseInfo?.type !== 'custom'
    ) {
      return (
        <Typography
          data-testid="render-no-test-cases"
          className={classes.compileResultMsg}
        >
          (No test cases passed {compileMsg?.result?.testcasesPassed}/
          {compileMsg?.result?.totalTestcases})
        </Typography>
      )
    }

    return null
  }

  if (consoleBodyHeight < 150) {
    return (
      <Grid container className={classes.consoleBodyForMobiles}>
        <Grid
          data-testid="render-console-body-wrapper"
          container
          direction="column"
          className={classes.consoleBodyWrapper}
        >
          <Grid item className={classes.consoleBody} />
          <Grid
            container
            className={classes.compileMsgWrapper}
            direction={editorWrapperWidth < 550 ? 'column' : 'row'}
          >
            <Grid item className={classes.compileMsgContainer}>
              <Typography
                data-testid="render-compilation-passed-text"
                className={classes.compilationPassMsg}
              >
                Compilation Passed
              </Typography>
            </Grid>
            <Grid item data-testid="render-compilation-result">
              {compileMsg?.result?.totalTestcases !== 0 && returnContent()}
            </Grid>
          </Grid>

          <Grid item className={classes.gapBetweenMsgAndTestCase} />

          <Grid container direction="column" className={classes.codeRunOutPut}>
            {compileMsg?.codeRunOutputList?.map((testcase, index) => {
              return (
                <Grid
                  data-testid="render-code-run-output"
                  item
                  key={index.toString(2)}
                  className={classes.content}
                  style={{ width: '100%', marginBottom: '30px' }}
                >
                  {testcase?.testCaseInfo?.status === '' ? (
                    <LinearProgress data-testid="render-linear-progress" />
                  ) : (
                    <>
                      {testcase?.testCaseInfo?.type?.toLowerCase() ===
                        'sample' && (
                        <SampleTestCase
                          data-testid="render-custom-test-case"
                          testcase={testcase}
                          index={index}
                        />
                      )}

                      {testcase?.testCaseInfo?.type?.toLowerCase() ===
                        'custom' && (
                        <CustomTestCase
                          data-testid="render-custom-test-case"
                          testcase={testcase}
                          customInput={customInput}
                        />
                      )}
                      {testcase?.testCaseInfo?.type?.toLowerCase() ===
                        'hidden' && (
                        <HiddenTestCase
                          data-tetsid="render-hidden-test-case"
                          testcase={testcase}
                          index={index}
                        />
                      )}
                    </>
                  )}
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        data-testid="render-wrapper"
        container
        direction={editorWrapperWidth < 550 ? 'column' : 'row'}
        className={classes.compileMsgAndTestCaseWrapper}
      >
        <Grid item className={classes.compileSuccessMsgWrapper}>
          <Typography
            data-testid="render-compilation-passed-text"
            className={classes.compileSuccessMsg}
          >
            Compilation Passed
          </Typography>
        </Grid>
        <Grid item data-testid="render-compilation-total">
          {compileMsg?.result?.totalTestcases !== 0 && returnContent()}
        </Grid>
      </Grid>

      <Grid item className={classes.testCasesContainer}>
        <Grid container direction="column" className={classes.codeRunOutPut}>
          {compileMsg?.codeRunOutputList?.map((testcase, index) => {
            return (
              <Grid
                item
                key={index.toString(2)}
                className={classes.content}
                style={{ width: '100%', marginBottom: '30px' }}
              >
                {testcase?.testCaseInfo?.status === '' ? (
                  <LinearProgress data-testid="render-linear-progress" />
                ) : (
                  <>
                    {testcase?.testCaseInfo?.type?.toLowerCase() ===
                      'sample' && (
                      <SampleTestCase
                        data-testid="render-sample-test-case"
                        testcase={testcase}
                        index={index}
                      />
                    )}
                    {testcase?.testCaseInfo?.type?.toLowerCase() ===
                      'custom' && (
                      <CustomTestCase
                        data-testid="render-custom-case"
                        testcase={testcase}
                        customInput={customInput}
                      />
                    )}
                    {testcase?.testCaseInfo?.type?.toLowerCase() ===
                      'hidden' && (
                      <HiddenTestCase
                        data-testid="render-hidden-test-case"
                        testcase={testcase}
                        index={index}
                      />
                    )}
                  </>
                )}
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CompilationPassedContent
