import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  paper: {
    overflowY: 'unset',
    padding: '2px',
  },
  modal: {
    position: 'fixed',
    zIndex: 1200,
    background: 'white',
    padding: '10px',
    boxShadow: '0px 1px 20px #303030',
    borderRadius: '4px',
  },
  header: {
    height: '5px',
    background: 'white',
  },
  closeButtonWrapper: {
    padding: 0,
    zIndex: 1201,
  },
  closeButton: {
    position: 'absolute',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    left: 'calc(100% - 12px)',
    top: '-7px',
    background: 'white',
    color: 'black',
    '&:hover': {
      background: 'white',
    },
    '@media only screen and (min-width:500px)': {
      width: '24px',
      height: '24px',
      left: 'calc(100% - 15px)',
      top: '-9px',
    },
  },
  closeIcon: {
    padding: '5px',
  },
  contentBoxWrapper: {
    position: 'relative',
    height: '150px',
    width: '200px',
    cursor: 'move',
    '@media only screen and (min-width:320px)': {
      width: '300px',
      height: '225px',
    },
    '@media only screen and (min-width:375px)': {
      width: '350px',
      height: '263px',
    },
    '@media only screen and (min-width:426px)': {
      width: '400px',
      height: '300px',
    },
    '@media only screen and (min-width:2500px)': {
      width: '500px',
      height: '375px',
    },
  },
  loadingWrapper: {
    position: 'absolute',
    background: 'black',
    color: 'white',
    height: '100%',
    width: '100%',
  },
  loading: {
    fontSize: '14px',
    '@media only screen and (min-width:320px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width:375px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:426px)': {
      fontSize: '24px',
    },
    '@media only screen and (min-width:1500px)': {
      fontSize: '28px',
    },
  },
  videoWrapper: {
    height: '100%',
    width: '100%',
    background: 'black',
    overflow: 'hidden',
  },
}))
export { useStyles }
