import React from 'react'

function QuizTimeLeftIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Artboard</title>
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.5"
      >
        <g id="Group-23-Copy">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <g
            id="Group-22"
            transform="translate(4.000000, 2.500000)"
            fill="#FFFFFF"
          >
            <path
              d="M8,2.5 C12.418278,2.5 16,6.081722 16,10.5 C16,14.918278 12.418278,18.5 8,18.5 C3.581722,18.5 0,14.918278 0,10.5 C0,6.081722 3.581722,2.5 8,2.5 Z M8,3.5 C4.13400675,3.5 1,6.63400675 1,10.5 C1,14.3659932 4.13400675,17.5 8,17.5 C11.8659932,17.5 15,14.3659932 15,10.5 C15,6.63400675 11.8659932,3.5 8,3.5 Z"
              id="Oval"
              fillRule="nonzero"
            />
            <polygon
              id="Path-4"
              fillRule="nonzero"
              points="11 0 11 1 5 1 5 0"
            />
            <polygon
              id="Path-5"
              fillRule="nonzero"
              points="8.5 5.5 8.5 9.5 7.5 9.5 7.5 5.5"
            />
            <circle id="Oval" cx="8" cy="10.5" r="2" />
            <polygon
              id="Path-6"
              fillRule="nonzero"
              points="14.4881495 3.19514465 15.2807735 3.80485535 13.7423123 5.80485535 12.9496882 5.19514465"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default QuizTimeLeftIcon
