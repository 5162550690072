/* eslint-disable no-nested-ternary */
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import parse from 'html-react-parser'
import ApprovedIcon from '../../Icons/ApprovedIcon'
import { useStyles } from './CodingQuestionQuestionPanelStyles'

function CodingQuestionQuestionPanel({
  question,
  currentQuestion,
  questionFilterIndices,
  currentQuestionFilter,
  handleBack,
}) {
  const classes = useStyles()
  const isWidthAboveTabRange = useMediaQuery(
    'only screen and (min-width:769px)'
  )
  return (
    <Grid container direction="column" className={classes.leftPanel}>
      <Grid item className={classes.questionAndTestCasesWrapper}>
        <Grid
          container
          direction="column"
          className={classes.questionDetailsContainer}
        >
          <Grid item className={classes.questionDetailsWrapper}>
            <Grid container justify="space-between">
              <Grid
                container
                alignItems="center"
                className={classes.approvedIconAndTextWrapper}
              >
                {question?.saved &&
                  question?.saved !== 'pending' &&
                  question?.saved !== 'failed' && (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.approvedIconAndTextWrapper}
                    >
                      <Grid item className={classes.answeredIcon}>
                        <ApprovedIcon />
                      </Grid>
                      <Grid item className={classes.answeredText}>
                        Answered!
                      </Grid>
                    </Grid>
                  )}
              </Grid>
              <Grid item>
                <Typography>{question?.marks} marks</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.questionWrapper}>
            <Grid item className={classes.question}>
              <span className={classes.questionIndex}>
                {currentQuestion?.index + 1}.&nbsp;
              </span>
              {parse(question?.question ?? '')}
            </Grid>
          </Grid>
          {question?.testcases?.length > 0 && (
            <Grid item className={classes.testCases}>
              <Typography className={classes.testCasesHeader}>
                Sample Test Cases
              </Typography>
              {question?.testcases?.map((testcase, index) => {
                if (testcase?.type?.toLowerCase() === 'sample') {
                  return (
                    <Grid
                      item
                      key={index.toString(2)}
                      className={classes.testCase}
                    >
                      <Typography className={classes.inputOrOutputHeading}>
                        Input:
                      </Typography>
                      <Typography className={classes.inputOrOutputText}>
                        {typeof testcase?.input === 'string'
                          ? testcase?.input
                          : testcase?.input?.length > 0
                          ? testcase?.input?.join(',')
                          : testcase?.input?.[0]}
                      </Typography>
                      <Grid className={classes.inputAndOutputDivider} />
                      <Typography className={classes.inputOrOutputHeading}>
                        Output:
                      </Typography>
                      <Typography className={classes.inputOrOutputText}>
                        {testcase.output}
                      </Typography>
                    </Grid>
                  )
                }
                return null
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
      {isWidthAboveTabRange && (
        <Grid container alignItems="center" className={classes.buttonWrapper}>
          <Grid item className={classes.backButtonWrapper}>
            {questionFilterIndices?.[currentQuestionFilter]?.indexOf(
              currentQuestion?.index
            ) > 0 && (
              <Button
                onClick={handleBack}
                size="small"
                data-testid="questioncontent__backbutton--test"
                className={classes.backButton}
              >
                Previous
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default CodingQuestionQuestionPanel
