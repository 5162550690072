import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './SubmissionAcknowledgementStyles'

function SubmissionAcknowledgement({
  statusIcon,
  isLoading,
  message,
  helperMessage,
}) {
  const classes = useStyles({ isLoading })
  return (
    <>
      {statusIcon}
      <Typography className={classes.mainMessageAcknowledgement}>
        {' '}
        {message}
      </Typography>
      <Typography
        variant="body1"
        className={classes.helperMessageAcknowledgment}
      >
        {helperMessage}
      </Typography>
    </>
  )
}

export default SubmissionAcknowledgement
