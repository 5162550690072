import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './ConsoleHeaderStyles'

function ConsoleHeader({
  handleCustomInputTabSelected,
  handleResultsTabSelected,
  resultsTabSelected,
  buttonsDisabled,
}) {
  const classes = useStyles({
    currentTab: resultsTabSelected ? 'results' : 'custom',
  })
  return (
    <Grid
      data-testid="render-console-header"
      container
      className={classes.tabsWrapper}
    >
      <Grid
        item
        component="button"
        onClick={handleCustomInputTabSelected}
        disabled={buttonsDisabled}
        className={classes.customInputTab}
      >
        <Typography
          data-testid="render-custom-input"
          className={classes.customInputTabText}
        >
          Custom Input
        </Typography>
      </Grid>

      <Grid
        item
        component="button"
        onClick={handleResultsTabSelected}
        disabled={buttonsDisabled}
        className={classes.resultsTab}
      >
        <Typography className={classes.resultsTabText}>Results</Typography>
      </Grid>
    </Grid>
  )
}

export default ConsoleHeader
