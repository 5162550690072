import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  // root: {
  //   boxSizing: 'border-box',
  //   height: '100%',
  //   width: '100%',
  //   padding: '0 15px',
  //   paddingBottom: '14px',
  //   '@media only screen and (min-width:426px)': {
  //     padding: '0 40px',
  //     paddingBottom: '14px',
  //   },
  //   '@media only screen and (min-width:769px)': {
  //     padding: '0 0',
  //     paddingBottom: '14px',
  //   },
  // },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  questionAndTypeWrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '29.5px 0 5px 0',
  },
  questionNumberHeader: {
    boxSizing: 'border-box',
    width: '30px',
    margin: '0 15px 0 12px',
  },
  questionTitleHeader: {
    width: 'calc(100% - 234px)',
    paddingRight: '10px',
  },
  questionTypeHeader: {
    boxSizing: 'border-box',
  },
  gapBetweenHeaderAndTable: {
    boxSizing: 'border-box',
    width: '3rem',
    minWidth: 0,
    margin: '0 1rem',
  },
  reviwPageDetailsWrapper: {
    flex: 1,
    overflowY: 'auto',
    width: '100%',
  },
  reviwPageDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'max-content',
  },
}))
export { useStyles }
