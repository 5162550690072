import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  customTestCaseWrapper: {
    width: '100%',
  },
  resultContainer: {
    width: '100%',
    borderRadius: '4px',
    background: theme?.palette?.darkBackground?.main,
    padding: '5px 0',
  },
  resultWrapper: {
    width: '100%',
    padding: '5px 20px',
    borderBottom: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  resultHeading: {
    fontWeight: 600,
    fontSize: '12px',
    marginBottom: '5px',
  },
  resultText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    fontSize: '12px',
  },
  customTestCaseMsg: {
    width: '100%',
    marginBottom: '10px',
  },
}))
export { useStyles }
