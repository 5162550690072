import React, { useState, useEffect, useRef } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import throttle from 'lodash.throttle'
import { faro } from '@grafana/faro-web-sdk'
import MultipleChoiceQuestion from '../MultipleChoiceQuestion/MultipleChoiceQuestion'
import EssayQuestion from '../EssayQuestion/EssayQuestion'
import CodingQuestionSolutionPanel from '../CodingQuestionSolutionPanel/CodingQuestionSolutionPanel'
import WarnQuestionSubmissionDialog from '../WarnQuestionSubmissionDialog/WarnQuestionSubmissionDialog'
import { getQuestionById } from '../../utils'
import { useCodingQuestionPostQueryById } from '../../common/Queries/quizQueries'
import CodingQuestionQuestionPanel from '../CodingQuestionQuestionPanel/CodingQuestionQuestionPanel'
import QuestionNavigator from '../QuestionNavigator/QuestionNavigator'
import ArtboardIcon from '../../Icons/ArtboardIcon'
import FillInTheBlanksQuestion from '../FillInTheBlanksQuestion/FillInTheBlanksQuestion'
import { useStyles } from './QuestionWrapperStyles'

function QuestionWrapper({
  question,
  currentQuestion,
  setCurrentQuestion,
  currentQuestionFilter,
  setCurrentQuestionFilter,
  quiz,
  setQuiz,
  setErrors,
  setErrorValue,
  handleMCQSubmission,
}) {
  const [backPopUp, setBackPopUp] = useState(false)
  const [textEditorAnswer, setTextEditorAnswer] = useState('')
  const textEditorAnswerRef = useRef()
  const isWidthAboveTabRange = useMediaQuery(
    'only screen and (min-width:769px)'
  )
  const splitterRef = useRef(null)
  const navigatorAndContentRef = useRef(null)
  const editorRef = useRef(null)
  let splitterTouchPointX = 0
  // let splitterTouchPointY = 0
  let navigatorWidth = 0
  useEffect(() => {
    if (
      textEditorAnswer === '' &&
      question?.type === 'essay' &&
      question?.essayAnswer !== undefined
    ) {
      setTextEditorAnswer(question?.essayAnswer)
    }
  }, [question])

  const mouseMovethrottledEvent = React.useCallback(
    throttle((event) => {
      const dx = event.clientX - splitterTouchPointX
      // const dy = event.clientY - splitterTouchPointY
      splitterRef.current.style.cursor = 'col-resize'
      document.body.style.cursor = 'col-resize'
      const newNavigatorWidth =
        ((navigatorWidth + dx) * 100) /
        splitterRef?.current?.parentNode?.getBoundingClientRect()?.width
      navigatorAndContentRef.current.style.width = `${newNavigatorWidth}%`
    }, 100),
    []
  )

  const mousemoveHandler = (event) => {
    mouseMovethrottledEvent(event)
  }

  const mouseupHandler = () => {
    splitterRef?.current?.style?.removeProperty('cursor')
    document?.body?.style?.removeProperty('cursor')
    document?.removeEventListener('mousemove', mousemoveHandler)
    document?.removeEventListener('mouseup', mouseupHandler)
  }

  const mousedownHandler = (e) => {
    splitterTouchPointX = e?.clientX
    // splitterTouchPointY = e?.clientY
    navigatorWidth =
      navigatorAndContentRef?.current?.getBoundingClientRect()?.width
    document?.addEventListener('mousemove', mousemoveHandler)
    document?.addEventListener('mouseup', mouseupHandler)
  }

  useEffect(() => {
    if (question?.type === 'coding') {
      splitterRef.current?.addEventListener('mousedown', mousedownHandler)
    }
  }, [question, isWidthAboveTabRange])

  const [questionFilterIndices, setQuestionFilterIndices] = useState({
    all: quiz?.questions?.map((_, index) => {
      return index
    }),
    mcq: [],
    coding: [],
    essay: [],
    fillInTheBlanks: [],
    answered: [],
    unanswered: [],
  })

  const [questionLoadingStates, setQuestionLoadingStates] = useState({})
  const [changeQuestionNavigatorStyle, setChangeQuestionNavigatorStyle] =
    useState(false)

  const props = {
    changeQuestionNavigatorStyle,
    questionType: question?.type,
    isWidthAboveTabRange,
  }

  const classes = useStyles(props)

  const handleFillQuestionFilterIndicesArray = () => {
    const localAllFilterArray = []
    const localMCQFilterArray = []
    const localFillInTheBlanksArray = []
    const localCodingFilterArray = []
    const localAnsweredFilterArray = []
    const localUnansweredFilterArray = []
    const localEssayFilterArray = []

    quiz?.questions?.forEach((questionItem, index) => {
      localAllFilterArray?.push(index)
      if (questionItem?.type === 'mcq') localMCQFilterArray?.push(index)
      if (questionItem?.type === 'coding') localCodingFilterArray?.push(index)
      if (questionItem?.type === 'essay') localEssayFilterArray?.push(index)
      if (questionItem?.type === 'fill-in-the-blanks')
        localFillInTheBlanksArray?.push(index)
      if (
        questionItem?.saved &&
        questionItem?.saved !== 'pending' &&
        questionItem?.saved !== 'failed'
      )
        localAnsweredFilterArray?.push(index)
      if (
        !questionItem?.saved ||
        questionItem?.saved === 'pending' ||
        questionItem?.saved === 'failed'
      )
        localUnansweredFilterArray?.push(index)
    })

    setQuestionFilterIndices({
      ...questionFilterIndices,
      all: localAllFilterArray,
      mcq: localMCQFilterArray,
      fillInTheBlanks: localFillInTheBlanksArray,
      coding: localCodingFilterArray,
      essay: localEssayFilterArray,
      answered: localAnsweredFilterArray,
      unanswered: localUnansweredFilterArray,
    })
  }

  useEffect(() => {
    const copyQuestionLoadingStates = {}
    quiz?.questions?.forEach((item) => {
      copyQuestionLoadingStates[item?.id] = false
    })
    setQuestionLoadingStates(() => copyQuestionLoadingStates)
  }, [])

  useEffect(() => {
    handleFillQuestionFilterIndicesArray()
  }, [quiz])

  const handleMoveToNearestQuestionInUnansweredFilter = () => {
    if (currentQuestionFilter === 'unanswered') {
      const indexOfCurrentQuestionInUnansweredFilterIndicesArray =
        questionFilterIndices?.unanswered?.indexOf(currentQuestion?.index)
      if (
        indexOfCurrentQuestionInUnansweredFilterIndicesArray ===
        questionFilterIndices?.unanswered?.length - 1
      ) {
        const indexOfPreviousQuestionInQuestionsArrayFromUnansweredFilterIndicesArray =
          questionFilterIndices?.unanswered?.[
            indexOfCurrentQuestionInUnansweredFilterIndicesArray - 1
          ]
        setCurrentQuestion((currentQuestionPrev) => {
          return {
            ...currentQuestionPrev,
            index:
              indexOfPreviousQuestionInQuestionsArrayFromUnansweredFilterIndicesArray,
            id: quiz?.questions?.[
              indexOfPreviousQuestionInQuestionsArrayFromUnansweredFilterIndicesArray
            ]?.id,
          }
        })
      } else {
        const indexOfNextQuestionInQuestionsArrayFromUnansweredFilterIndicesArray =
          questionFilterIndices?.unanswered?.[
            indexOfCurrentQuestionInUnansweredFilterIndicesArray + 1
          ]
        setCurrentQuestion((currentQuestionPrev) => {
          return {
            ...currentQuestionPrev,
            index:
              indexOfNextQuestionInQuestionsArrayFromUnansweredFilterIndicesArray,
            id: quiz?.questions?.[
              indexOfNextQuestionInQuestionsArrayFromUnansweredFilterIndicesArray
            ]?.id,
          }
        })
      }
    }
  }

  const handleMoveToNearestQuestionInAnsweredFilter = () => {
    if (currentQuestionFilter === 'answered') {
      const indexOfCurrentQuestionInAnsweredFilterIndicesArray =
        questionFilterIndices?.answered?.indexOf(currentQuestion?.index)
      if (questionFilterIndices?.answered?.length <= 1) {
        setCurrentQuestion((currentQuestionPrev) => {
          return {
            ...currentQuestionPrev,
            index: null,
            id: null,
          }
        })
      } else if (
        indexOfCurrentQuestionInAnsweredFilterIndicesArray ===
        questionFilterIndices?.answered?.length - 1
      ) {
        const indexOfPreviousQuestionInQuestionsArrayFromAnsweredFilterIndicesArray =
          questionFilterIndices?.answered?.[
            indexOfCurrentQuestionInAnsweredFilterIndicesArray - 1
          ]
        setCurrentQuestion((currentQuestionPrev) => {
          return {
            ...currentQuestionPrev,
            index:
              indexOfPreviousQuestionInQuestionsArrayFromAnsweredFilterIndicesArray,
            id: quiz?.questions?.[
              indexOfPreviousQuestionInQuestionsArrayFromAnsweredFilterIndicesArray
            ]?.id,
          }
        })
      } else {
        const indexOfNextQuestionInQuestionsArrayFromAnsweredFilterIndicesArray =
          questionFilterIndices?.answered?.[
            indexOfCurrentQuestionInAnsweredFilterIndicesArray + 1
          ]
        setCurrentQuestion((currentQuestionPrev) => {
          return {
            ...currentQuestionPrev,
            index:
              indexOfNextQuestionInQuestionsArrayFromAnsweredFilterIndicesArray,
            id: quiz?.questions?.[
              indexOfNextQuestionInQuestionsArrayFromAnsweredFilterIndicesArray
            ]?.id,
          }
        })
      }
    }
  }

  const handleSuccessCodingQuestionSubmission = (successSubmissionResponse) => {
    handleMoveToNearestQuestionInUnansweredFilter()

    setQuiz((quizPrev) => {
      const quizPrevCopy = JSON.parse(JSON.stringify(quizPrev))
      quizPrevCopy.questions = quizPrevCopy?.questions?.map((item) => {
        const copyItem = item
        if (item?.id === successSubmissionResponse?.questionId) {
          copyItem.language = successSubmissionResponse?.language
          copyItem.code = successSubmissionResponse?.code
          copyItem.saved = true
          copyItem.atleastOneSubmissionOccured = true
        }
        return copyItem
      })
      return quizPrevCopy
    })
  }

  const handleErrorQuestionSubmission = (errorSubmissionResponse) => {
    setQuiz((quizPrev) => {
      const quizPrevCopy = JSON.parse(JSON.stringify(quizPrev))
      quizPrevCopy.questions = quizPrevCopy?.questions?.map((item) => {
        const copyItem = item
        if (item?.id === errorSubmissionResponse?.questionId) {
          copyItem.saved = 'failed'
        }

        return copyItem
      })
      return quizPrevCopy
    })

    setCurrentQuestionFilter((currentQuestionFilterPrev) => {
      if (currentQuestionFilterPrev === 'answered') return 'unanswered'
      return currentQuestionFilterPrev
    })
  }

  const handleSettledQuestionSubmission = async (settledSubmissionResponse) => {
    setQuestionLoadingStates((questionLoadingStatesPrev) => {
      const questionLoadingStatesPrevCopy = JSON.parse(
        JSON.stringify(questionLoadingStatesPrev)
      )
      questionLoadingStatesPrevCopy[
        settledSubmissionResponse?.questionId
      ] = false
      return questionLoadingStatesPrevCopy
    })
  }

  const { mutateAsync: codingQuestionSubmissionMutateAsync } =
    useCodingQuestionPostQueryById(quiz?.id, {
      staleTime: 0,
      onSuccess: (_, variables) => {
        handleSuccessCodingQuestionSubmission(variables)
      },
      onError: (error, variables) => {
        setErrorValue(error?.errorReason)
        setErrors((errorsPrev) => {
          return { ...errorsPrev, runError: false }
        })
        handleErrorQuestionSubmission(variables)
      },
      onSettled: (_, $, variables) => {
        setTimeout(() => {
          handleSettledQuestionSubmission(variables)
        }, 2000)
      },
    })

  const handleCodingSubmission = async (questionId, code, language) => {
    setQuestionLoadingStates((questionLoadingStatesPrev) => {
      const questionLoadingStatesPrevCopy = JSON.parse(
        JSON.stringify(questionLoadingStatesPrev)
      )
      questionLoadingStatesPrevCopy[questionId] = true
      return questionLoadingStatesPrevCopy
    })
    await codingQuestionSubmissionMutateAsync({
      questionId,
      type: 'coding',
      userIdentifier: sessionStorage.getItem('userID'),
      code,
      language,
    }).catch((error) => {
      if (faro && faro.api && faro.api.pushError) {
        faro.api.pushError(error)
      }

      if (error?.errorCode === 'ENTITY_NOT_FOUND') {
        setErrorValue('Some error has occurred, Please refresh the page')
        setErrors((errorsPrev) => {
          return { ...errorsPrev, submitError: true }
        })
      }
    })
  }

  const handleYesBackDialog = () => {
    const currentIndexAccordingToQuestionFilter = questionFilterIndices?.[
      currentQuestionFilter
    ]?.indexOf(currentQuestion?.index)
    const toBeSetIndex =
      questionFilterIndices?.[currentQuestionFilter]?.[
        currentIndexAccordingToQuestionFilter - 1
      ]
    setCurrentQuestion((currentQuestionPrev) => {
      return {
        ...currentQuestionPrev,
        index: toBeSetIndex,
        id: quiz?.questions?.[toBeSetIndex]?.id,
      }
    })
    setBackPopUp(() => false)
  }
  const handleBack = () => {
    const currentIndexAccordingToQuestionFilter = questionFilterIndices?.[
      currentQuestionFilter
    ]?.indexOf(currentQuestion?.index)

    if (currentIndexAccordingToQuestionFilter > 0) {
      const toBeSetIndex =
        questionFilterIndices?.[currentQuestionFilter]?.[
          currentIndexAccordingToQuestionFilter - 1
        ]
      const copyCurrentQuestion = getQuestionById(quiz, currentQuestion)
      if (
        (!questionLoadingStates[copyCurrentQuestion?.id] &&
          copyCurrentQuestion?.type === 'essay' &&
          copyCurrentQuestion?.essayAnswer !== textEditorAnswer) ||
        (question?.id !== copyCurrentQuestion?.id &&
          !questionLoadingStates[copyCurrentQuestion?.id] &&
          copyCurrentQuestion?.type === 'coding' &&
          copyCurrentQuestion?.saved === 'pending')
      ) {
        setBackPopUp(() => true)
      } else
        setCurrentQuestion((currentQuestionPrev) => {
          return {
            ...currentQuestionPrev,
            index: toBeSetIndex,
            id: quiz?.questions?.[toBeSetIndex]?.id,
          }
        })
    }
  }

  const handleNext = () => {
    const currentIndexAccordingToQuestionFilter = questionFilterIndices?.[
      currentQuestionFilter
    ]?.indexOf(currentQuestion?.index)
    const currentQuestionIndicesFilterArrayLength =
      questionFilterIndices?.[currentQuestionFilter]?.length
    if (
      currentIndexAccordingToQuestionFilter <
      currentQuestionIndicesFilterArrayLength - 1
    ) {
      const toBeSetIndex =
        questionFilterIndices?.[currentQuestionFilter]?.[
          currentIndexAccordingToQuestionFilter + 1
        ]
      const copyCurrentQuestion = getQuestionById(quiz, currentQuestion)
      if (
        question?.id !== copyCurrentQuestion?.id &&
        !questionLoadingStates[copyCurrentQuestion?.id] &&
        copyCurrentQuestion?.type === 'coding' &&
        copyCurrentQuestion?.saved === 'pending'
      ) {
        setBackPopUp(() => true)
      } else {
        setCurrentQuestion((currentQuestionPrev) => {
          return {
            ...currentQuestionPrev,
            index: toBeSetIndex,
            id: quiz?.questions?.[toBeSetIndex]?.id,
          }
        })
      }
    }
  }

  const returnContent = () => {
    if (!currentQuestion?.index && !currentQuestion?.id) {
      if (currentQuestionFilter === 'all') {
        return (
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.contentBackground}
          >
            <Typography variant="h5">There are no questions here...</Typography>
          </Grid>
        )
      }
      if (currentQuestionFilter === 'fill-in-the-blanks') {
        return (
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.contentBackground}
          >
            <Typography variant="h5">
              There are no Fill In The Blank Questions...
            </Typography>
          </Grid>
        )
      }
      if (currentQuestionFilter === 'mcq') {
        return (
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.contentBackground}
          >
            <Typography variant="h5">There are no MCQs here...</Typography>
          </Grid>
        )
      }
      if (currentQuestionFilter === 'coding') {
        return (
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.contentBackground}
          >
            <Typography variant="h5">
              There are no coding questions here...
            </Typography>
          </Grid>
        )
      }
      if (currentQuestionFilter === 'answered') {
        return (
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.contentBackground}
          >
            <Typography variant="h5" className={classes.instructionMsg}>
              Start answering some questions...
            </Typography>
          </Grid>
        )
      }
      if (currentQuestionFilter === 'unanswered') {
        return (
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.contentBackground}
          >
            <Typography variant="h5" className={classes.instructionMsg}>
              Hurray, You have answered all the questions!
            </Typography>
          </Grid>
        )
      }
    }

    if (question?.type === 'coding' && isWidthAboveTabRange) {
      return (
        <CodingQuestionQuestionPanel
          question={question}
          currentQuestion={currentQuestion}
          questionFilterIndices={questionFilterIndices}
          currentQuestionFilter={currentQuestionFilter}
          handleBack={handleBack}
        />
      )
    }

    if (question?.type === 'coding' && !isWidthAboveTabRange) {
      return (
        <Grid
          container
          direction="column"
          className={classes.codingQuestionSolutionPanelWrapper}
        >
          <Grid item className={classes.codingQuestionSolutionPanelContainer}>
            <CodingQuestionQuestionPanel
              question={question}
              currentQuestion={currentQuestion}
              questionFilterIndices={questionFilterIndices}
              currentQuestionFilter={currentQuestionFilter}
              handleBack={handleBack}
            />
          </Grid>
          <Grid item className={classes.solutionPanelEditor}>
            <CodingQuestionSolutionPanel
              key={question?.id}
              editorRef={editorRef}
              question={question}
              currentQuestion={currentQuestion}
              quiz={quiz}
              setQuiz={setQuiz}
              setErrors={setErrors}
              setErrorValue={setErrorValue}
              handleCodingSubmission={handleCodingSubmission}
              questionLoadingStates={questionLoadingStates}
              questionFilterIndices={questionFilterIndices}
              currentQuestionFilter={currentQuestionFilter}
              setCurrentQuestionFilter={setCurrentQuestionFilter}
              handleBack={handleBack}
              handleNext={handleNext}
              changeQuestionNavigatorStyle={changeQuestionNavigatorStyle}
            />
          </Grid>
        </Grid>
      )
    }
    if (question?.type === 'fill-in-blanks') {
      return (
        <FillInTheBlanksQuestion
          question={question}
          currentQuestion={currentQuestion}
          setQuiz={setQuiz}
          handleMCQSubmission={handleMCQSubmission}
          questionFilterIndices={questionFilterIndices}
          currentQuestionFilter={currentQuestionFilter}
          handleBack={handleBack}
          handleNext={handleNext}
          setTextEditorAnswer={setTextEditorAnswer}
          textEditorAnswerRef={textEditorAnswerRef}
          textEditorAnswer={textEditorAnswer}
          quiz={quiz}
        />
      )
    }
    if (question?.type === 'essay') {
      return (
        <EssayQuestion
          question={question}
          currentQuestion={currentQuestion}
          setQuiz={setQuiz}
          handleMCQSubmission={handleMCQSubmission}
          questionFilterIndices={questionFilterIndices}
          currentQuestionFilter={currentQuestionFilter}
          handleBack={handleBack}
          handleNext={handleNext}
          setTextEditorAnswer={setTextEditorAnswer}
          textEditorAnswerRef={textEditorAnswerRef}
          textEditorAnswer={textEditorAnswer}
          quiz={quiz}
        />
      )
    }

    return (
      <MultipleChoiceQuestion
        key={question?.id}
        question={question}
        currentQuestion={currentQuestion}
        setQuiz={setQuiz}
        handleMCQSubmission={handleMCQSubmission}
        questionLoadingStates={questionLoadingStates}
        questionFilterIndices={questionFilterIndices}
        currentQuestionFilter={currentQuestionFilter}
        handleBack={handleBack}
        handleNext={handleNext}
        changeQuestionNavigatorStyle={changeQuestionNavigatorStyle}
        handleMoveToNearestQuestionInUnansweredFilter={
          handleMoveToNearestQuestionInUnansweredFilter
        }
        handleMoveToNearestQuestionInAnsweredFilter={
          handleMoveToNearestQuestionInAnsweredFilter
        }
      />
    )
  }

  return (
    <>
      <WarnQuestionSubmissionDialog
        openDialog={backPopUp}
        setOpenDialog={setBackPopUp}
        affirmativeHandler={handleYesBackDialog}
      />

      <Grid container className={classes.navigatorAndSolutionPanelWrapper}>
        <Grid
          container
          direction="column"
          className={classes.navigatorAndContentWrapper}
          style={{
            width:
              question?.type === 'coding' && isWidthAboveTabRange
                ? 'calc(30% - 5px)'
                : '100%',
          }}
          ref={navigatorAndContentRef}
        >
          <Grid item className={classes.questionNavigator}>
            <QuestionNavigator
              quiz={quiz}
              setQuiz={setQuiz}
              currentQuestion={currentQuestion}
              setCurrentQuestion={setCurrentQuestion}
              currentQuestionFilter={currentQuestionFilter}
              setCurrentQuestionFilter={setCurrentQuestionFilter}
              questionFilterIndices={questionFilterIndices}
              questionLoadingStates={questionLoadingStates}
              changeQuestionNavigatorStyle={changeQuestionNavigatorStyle}
              setChangeQuestionNavigatorStyle={setChangeQuestionNavigatorStyle}
              handleMCQSubmission={handleMCQSubmission}
              textEditorAnswer={textEditorAnswer}
            />
          </Grid>
          <Grid item className={classes.questionContentWrapper}>
            {returnContent()}
          </Grid>
        </Grid>

        {question?.type === 'coding' && isWidthAboveTabRange && (
          <>
            <Grid
              container
              alignItems="center"
              justify="center"
              className={classes.splitter}
              ref={splitterRef}
            >
              <Grid item className={classes.artBoardIcon}>
                <ArtboardIcon />
              </Grid>
            </Grid>
            <Grid item className={classes.codingSolutionPanel} ref={editorRef}>
              <CodingQuestionSolutionPanel
                key={question?.id}
                editorRef={editorRef}
                question={question}
                currentQuestion={currentQuestion}
                quiz={quiz}
                setQuiz={setQuiz}
                setErrors={setErrors}
                handleCodingSubmission={handleCodingSubmission}
                questionLoadingStates={questionLoadingStates}
                questionFilterIndices={questionFilterIndices}
                currentQuestionFilter={currentQuestionFilter}
                setCurrentQuestionFilter={setCurrentQuestionFilter}
                handleBack={handleBack}
                handleNext={handleNext}
                changeQuestionNavigatorStyle={changeQuestionNavigatorStyle}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default QuestionWrapper
