/* eslint-disable no-nested-ternary */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { useStyles } from './SampleTestCaseStyles'

function SampleTestCase({ testcase, index }) {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      className={classes.sampleTestCaseWrapper}
    >
      {testcase?.testCaseInfo?.status === 'PASSED' && (
        <Grid
          data-testid="render-passed-testcase"
          container
          alignItems="center"
          className={classes.testCaseMsg}
        >
          <Typography className={classes.testcasePassed}>
            Test case {index + 1} passed
          </Typography>
          <DoneIcon className={classes.testcasePassedIcon} />
        </Grid>
      )}
      {testcase?.testCaseInfo?.status === 'FAILED' && (
        <Grid
          data-testid="render-failed-testcase"
          container
          alignItems="center"
          className={classes.testCaseMsg}
        >
          <Typography className={classes.testcaseFailed}>
            Test case {index + 1} failed
          </Typography>
          <ClearIcon className={classes.testcaseFailedIcon} />
        </Grid>
      )}
      <Typography
        data-testid="render-duration"
        variant="body2"
        className={classes.testCaseFinishMsg}
      >
        Finished in {(testcase?.duration / 1000).toFixed(2)} seconds
      </Typography>
      <Grid container direction="column" className={classes.resultContainer}>
        <Grid
          data-testid="render-input-value-and-text"
          item
          className={classes.resultWrapper}
        >
          <Typography className={classes.resultHeading}>Input:</Typography>
          <Typography className={classes.resultText}>
            {typeof testcase?.input === 'string'
              ? testcase?.input
              : testcase?.input?.length > 0
              ? testcase?.input?.join(',')
              : testcase?.input?.[0]}
          </Typography>
        </Grid>
        <Grid item className={classes.resultWrapper}>
          <Typography className={classes.resultHeading}>Output:</Typography>
          <Typography
            data-testid="render-output-lines"
            className={classes.resultText}
          >
            {testcase?.outputLines?.length > 0 ? (
              <>{testcase?.outputLines?.join('\n')}</>
            ) : (
              <>Your code didn&#39;t output anything</>
            )}
          </Typography>
        </Grid>
        <Grid
          data-testid="render-output-text-and-value"
          item
          className={classes.resultWrapper}
        >
          <Typography className={classes.resultHeading}>
            Expected Output:
          </Typography>
          <Typography className={classes.resultText}>
            {testcase?.expectedOutput}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SampleTestCase
