import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'
import { Grid, Button, Snackbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ApprovedIcon from '../../Icons/ApprovedIcon'
import QuillTextEditor from '../TextEditor/TextEditor'
import { useStyles } from './EssayQuestionsStyles'
import { useMCQPostQueryById } from '../../common/Queries/quizQueries'

function EssayQuestion({
  question,
  currentQuestion,
  // quiz,
  setQuiz,
  questionFilterIndices,
  currentQuestionFilter,
  handleBack,
  handleNext,
  setTextEditorAnswer,
  textEditorAnswer,
  // textEditorAnswerRef,
  // handleSaveEssayQuestion
  // handleMoveToNearestQuestionInUnansweredFilter,
  // handleMoveToNearestQuestionInAnsweredFilter
}) {
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [errorValue, setErrorValue] = useState('')
  const [errors, setErrors] = useState()
  const [isClear, setIsClear] = useState(false)

  const classes = useStyles()

  useEffect(() => {
    setTextEditorAnswer(question?.essayAnswer)
  }, [question])

  useEffect(() => {
    if (isClear) {
      setQuiz((quizPrev) => {
        const quizPrevCopy = JSON.parse(JSON.stringify(quizPrev))
        quizPrevCopy.questions = quizPrevCopy?.questions?.map((item) => {
          const copyItem = item
          if (item?.id === currentQuestion?.id) {
            copyItem.essayAnswer = textEditorAnswer

            // if (textEditorAnswer === '') {
            copyItem.saved = false
            // } else {
            //   copyItem.saved = true
            // }
          }
          return copyItem
        })
        return quizPrevCopy
      })
      setIsClear(false)
    }
  }, [isClear])

  const handleClear = () => {
    setTextEditorAnswer('')
    setIsClear(true)
  }

  const { mutateAsync: mcqSubmissionMutateAsync } = useMCQPostQueryById(
    question?.id,
    {
      staleTime: 0,
      onSuccess: () => {
        setOpenSnackBar(true)
      },
      onError: (error) => {
        setErrorValue(error?.errorReason)
        setErrors(true)
      },
    }
  )
  const handleMCQSubmission = async (
    questionId,
    // selectedChoice,
    questionType
  ) => {
    await mcqSubmissionMutateAsync({
      questionId,
      type: questionType,
      userIdentifier: sessionStorage.getItem('userID'),
      essayAnswer: textEditorAnswer?.trim(),
    })
  }

  const handleSave = () => {
    setQuiz((quizPrev) => {
      const quizPrevCopy = JSON.parse(JSON.stringify(quizPrev))
      quizPrevCopy.questions = quizPrevCopy?.questions?.map((item) => {
        const copyItem = item
        if (item?.id === currentQuestion?.id) {
          copyItem.essayAnswer = textEditorAnswer

          if (textEditorAnswer) {
            copyItem.saved = true
          }
        }
        return copyItem
      })
      return quizPrevCopy
    })
    handleMCQSubmission(question?.id, 'essay')
  }

  const handleSnackBar = () => {
    setErrors(false)
  }

  return (
    <Grid container className={classes.root}>
      <Grid container justify="center" className={classes.contentWrapper}>
        <Grid container direction="column" className={classes.content}>
          <Grid
            container
            justify="space-between"
            className={classes.questionDetailsWrapper}
          >
            <Grid
              container
              alignItems="center"
              className={classes.approvedIconContainer}
            >
              {question?.saved && (
                <Grid
                  container
                  alignItems="center"
                  className={classes.approvedIconContainer}
                >
                  <Grid item className={classes.answeredIcon}>
                    <ApprovedIcon />
                  </Grid>
                  <Grid item className={classes.answeredText}>
                    Answered!
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* {question?.type !== 'essay' && (
              <Grid item>
                <Typography>{question?.marks} marks</Typography>
              </Grid>
            )} */}
          </Grid>

          <Grid item className={classes.questionWrapper}>
            <Grid item className={classes.question}>
              <span className={classes.questionDisplay}>
                {currentQuestion?.index >= 0 && currentQuestion?.index + 1}
                .&nbsp;
              </span>
              {parse(question?.question ?? '')}
            </Grid>
          </Grid>

          <Grid>
            <QuillTextEditor
              question={question}
              textEditorAnswer={textEditorAnswer}
              setTextEditorAnswer={setTextEditorAnswer}
              // textEditorAnswerRef={textEditorAnswerRef}
              className={question?.id?.toString()}
              isQuizStarted
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.buttonWrapper}>
        <Grid item className={classes.prevAndNextButtonWrapper}>
          {questionFilterIndices?.[currentQuestionFilter]?.indexOf(
            currentQuestion?.index
          ) > 0 && (
            <Button
              onClick={handleBack}
              size="small"
              data-testid="questioncontent__backbutton--test"
              className={classes.backButton}
            >
              Previous
            </Button>
          )}
        </Grid>

        <Grid container className={classes.prevAndClearButtonWrapper}>
          <Button
            onClick={() => handleClear()}
            className={classes.clearSelectionButton}
          >
            Clear
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              handleSave()
              if (
                questionFilterIndices?.[currentQuestionFilter]?.indexOf(
                  currentQuestion?.index
                ) <
                questionFilterIndices?.[currentQuestionFilter]?.length - 1
              )
                handleNext()
            }}
            disabled={textEditorAnswer?.trim() === ''}
            className={classes.continueButton}
          >
            Save
            {questionFilterIndices?.[currentQuestionFilter]?.indexOf(
              currentQuestion?.index
            ) <
              questionFilterIndices?.[currentQuestionFilter]?.length - 1 && (
              <>
                <span style={{ margin: '0 4px' }}>&#38;</span> Next
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors}
        autoHideDuration={3000}
        onClose={handleSnackBar}
        message={errorValue}
        action={
          <IconButton color="inherit" size="small" onClick={handleSnackBar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          classes: {
            root: classes.submitFailedSnackBar,
          },
        }}
        key="post essay question feedback"
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errors}
        autoHideDuration={3000}
        onClose={handleSnackBar}
        message={errorValue}
        action={
          <IconButton color="inherit" size="small" onClick={handleSnackBar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          classes: {
            root: classes.submitFailedSnackBar,
          },
        }}
        key="post essay question feedback"
      />
      <Snackbar
        className={classes.submitSuccessSnackBar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackBar}
        autoHideDuration={1000}
        onClose={() => setOpenSnackBar(false)}
        message="Answer saved successfully!"
        ContentProps={{
          classes: {
            root: classes.submitSuccessSnackBar,
          },
        }}
        key="Answer Saved successfully"
      />
    </Grid>
  )
}
export default EssayQuestion
