import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import ErrorHandler from './components/ErrorBoundaryFallBack/ErrorHandler'
import { BUGSNAG_KEY } from './constants'
import App from './App'

Bugsnag.start({
  apiKey: BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  // otherOptions: value
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render(
  <ErrorBoundary FallbackComponent={ErrorHandler}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
)
