import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  answeredWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  logoWrapper: {
    width: 'max-content',
    marginRight: 'auto',
    marginLeft: '16px',
  },
  timerWrapper: {
    position: 'absolute',
  },
  secondsTimer: {
    width: 'max-content',
  },
  answerAndCloseIconWrapper: {
    marginLeft: 'auto',
    height: '100%',
    width: 'max-content',
  },
  closeIconWrapper: {
    background: '#393F5C',
    borderRadius: '1px',
    marginLeft: '1rem',
    color: 'white',
  },
  closeIcon: {
    background: '#FFFFF',
  },
  answeredText: {
    color: theme?.palette?.lightTextColor?.main,
  },
}))
export { useStyles }
