import React from 'react'

function ClockIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="clock"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-3">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <g
            id="Group-2"
            transform="translate(4.000000, 4.000000)"
            stroke="#D4D5DB"
          >
            <path
              d="M7.84,0.5 C9.86688503,0.5 11.701885,1.32155748 13.0301638,2.64983623 C14.3584425,3.97811497 15.18,5.81311497 15.1800004,7.83939457 C15.1775471,9.86542985 14.3554133,11.6993547 13.027384,13.027384 C11.6993547,14.3554133 9.86542985,15.1775471 7.84,15.18 C5.81311497,15.18 3.97811497,14.3584425 2.64983623,13.0301638 C1.32155748,11.701885 0.5,9.86688503 0.5,7.84 C0.5,5.81311497 1.32155748,3.97811497 2.64983623,2.64983623 C3.97811497,1.32155748 5.81311497,0.5 7.84,0.5 Z"
              id="Color"
              fillRule="nonzero"
            />
            <polyline
              id="Path-4"
              points="10.9345588 4.74546684 7.84 7.84 7.84 13.0087552"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ClockIcon
