import React, { useEffect, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import { useParams, Redirect } from 'react-router-dom'
import bugsnag from '@bugsnag/js'
import Grid from '@material-ui/core/Grid'

import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined'
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import { useStyles } from './SubmitPageStyles'
import QuizSubmissionContext from '../../Contexts/QuizSubmissionContext'
import Logo from '../Logo/Logo'

import Loader from '../Loader/Loader'
import SubmissionProgressContent from '../SubmissionProgressContent/SubmissionProgressContent'
import {
  useQuizResponsePutQueryById,
  useQuizGetQueryEvaluatedResponseById,
} from '../../common/Queries/quizQueries'
import MediaQueryContext from '../../Contexts/MediaQueryContext'
import useWindowSize from '../../hooks/useWindowSize'
import QuizAlreadySubmitted from '../QuizAlreadySubmitted/QuizAlreadySubmitted'
import { returnErrors, areCookiesEnabled, delay } from '../../utils'

function SubmitPage() {
  const { quizSubmission } = useContext(QuizSubmissionContext)
  const [popUp, setPopUp] = useState(false)

  const [result, setResult] = useState()
  const [submitErrorCode, setSubmitErrorCode] = useState('')
  const [submitErrorReason, setSubmitErrorReason] = useState('')
  const [isEvaluationLoading, setIsEvaluationLoading] = useState('')
  const { quizId } = useParams()
  const props = {
    showResult: result,
    mediaWidth: useContext(MediaQueryContext),
  }
  const windowSize = useWindowSize()
  const classes = useStyles(props)

  const redirectPage = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }
  useEffect(() => {
    window.addEventListener('beforeunload', redirectPage)
    return () => {
      window.removeEventListener('beforeunload', redirectPage)
    }
  }, [])
  const { refetch: refetchEvaluatedResponse } =
    useQuizGetQueryEvaluatedResponseById(quizId, {
      enabled: false,
      onSuccess: (successResponse) => {
        setResult(() => successResponse)
        localStorage.clear()
        Cookies.remove(`${sessionStorage?.userID}_token`)
        setIsEvaluationLoading(() => false)
      },
      onError: () => {
        const waitAndRefetch = async () => {
          await delay(3000)
          refetchEvaluatedResponse()
        }
        waitAndRefetch()
      },
    })
  const mutation = useQuizResponsePutQueryById(quizId, {
    onSuccess: () => {
      if (quizSubmission?.quiz?.quizConfig?.showResult) {
        refetchEvaluatedResponse()
        setIsEvaluationLoading(() => true)
      }
    },
    onError: (errorResponse) => {
      setSubmitErrorCode(errorResponse?.errorCode)
      setSubmitErrorReason(errorResponse?.errorReason)
      // Log error to Bugsnag
      bugsnag.notify(errorResponse?.errorReason, {
        metaData: {
          quizId,
          // Add any other relevant metadata
        },
      })
    },
  })
  const handleSubmit = () => {
    mutation.mutate({})
  }

  useEffect(() => {
    handleSubmit()
  }, [])

  if (!areCookiesEnabled()) {
    return <Redirect to={`/quiz/${quizId}`} data-testid="redirect-element" />
  }

  if (mutation?.isError) {
    const errorType = returnErrors(submitErrorCode)
    if (errorType === 'QUIZ_ALREADY_SUBMITTED') {
      return <QuizAlreadySubmitted reason={submitErrorReason} />
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${quizSubmission?.quiz?.info?.title} - Submit`}</title>
      </Helmet>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.root}
        style={{ height: `${windowSize?.height}px` }}
      >
        <Grid container alignItems="center" className={classes.header}>
          <Grid item className={classes.logoGrid}>
            <Logo />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.content}
        >
          {mutation.isLoading && (
            <SubmissionProgressContent
              isLoading
              statusIcon={
                <Grid item className={classes.loaderGrid}>
                  <Loader size="3rem" />
                </Grid>
              }
              message="Submission in progress..."
              title={quizSubmission?.quiz?.info?.title}
            />
          )}
          {mutation.isSuccess && (
            <SubmissionProgressContent
              isEvaluationloading={isEvaluationLoading}
              statusIcon={
                <CheckCircleOutlineOutlinedIcon
                  style={{
                    fontSize: '3rem',
                    fontWeight: 100,
                    marginBottom: '0.5rem',
                    strokeWidth: 1,
                  }}
                  data-testid="CheckCircleOutlineOutlinedIconBtn"
                />
              }
              message="Test submitted successfully!"
              helperMessage=" Testpaper.app has received your test report"
              buttonHandler={() => setPopUp(true)}
              popUp={popUp}
              setPopUp={setPopUp}
              result={result}
              title={quizSubmission?.quiz?.info?.title}
            />
          )}
          {mutation.isError && (
            <SubmissionProgressContent
              isError
              statusIcon={
                <ReportOutlinedIcon
                  style={{
                    fontSize: '3rem',
                    fontWeight: 100,
                    marginBottom: '0.5rem',
                  }}
                />
              }
              message="Test submission failed!"
              helperMessage="Testpaper.app failed to receive your test report, But don't worry your responses are saved automatically."
              buttonHandler={handleSubmit}
              data-testid="submitPage-testId"
              title={quizSubmission?.quiz?.info?.title}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default SubmitPage
