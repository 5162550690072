import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import WarnQuestionSubmissionDialog from '../WarnQuestionSubmissionDialog/WarnQuestionSubmissionDialog'
import { getQuestionById } from '../../utils'
import { useStyles } from './QuestionNavItemStyles'

function QuestionNavItem({
  quiz,
  question,
  index,
  currentQuestion,
  setCurrentQuestion,
  questionLoadingStates,
  textEditorAnswer,
  // handleMCQSubmission,
}) {
  const [popUp, setPopUp] = useState(false)

  const bgColor = 'white'
  let isSelected = false

  // if (question?.saved) {
  //   if (question?.saved === 'pending') bgColor = 'moccasin'
  //   else if (question?.saved === 'failed') bgColor = '#ff7e6f'
  // }

  if (currentQuestion?.index === index) isSelected = true

  const props = {
    bgColor,
    isSelected,
    isAnswered: question?.saved,
  }
  const classes = useStyles(props)

  const handleCurrentQuestionChange = () => {
    setCurrentQuestion((currentQuestionPrev) => {
      return {
        ...currentQuestionPrev,
        index,
        id: question?.id,
      }
    })
    setPopUp(() => false)
  }
  const handleClick = () => {
    const copyCurrentQuestion = getQuestionById(quiz, currentQuestion)

    if (
      (copyCurrentQuestion?.essayAnswer !== textEditorAnswer &&
        copyCurrentQuestion?.answer !== textEditorAnswer &&
        !questionLoadingStates[copyCurrentQuestion?.id] &&
        (copyCurrentQuestion?.type === 'essay' ||
          copyCurrentQuestion?.type === 'fill-in-blanks')) ||
      (question?.id !== copyCurrentQuestion?.id &&
        !questionLoadingStates[copyCurrentQuestion?.id] &&
        copyCurrentQuestion?.type === 'coding' &&
        copyCurrentQuestion?.saved === 'pending')
    ) {
      setPopUp(() => true)
    } else {
      handleCurrentQuestionChange()
    }

    // if (
    //   question?.id !== copyCurrentQuestion?.id &&
    //   copyCurrentQuestion?.type === 'mcq'
    // ) {
    //   handleMCQSubmission(
    //     copyCurrentQuestion?.id,
    //     copyCurrentQuestion?.selectedChoice
    //   )
    // }
  }
  return (
    <>
      <WarnQuestionSubmissionDialog
        openDialog={popUp}
        setOpenDialog={setPopUp}
        affirmativeHandler={handleCurrentQuestionChange}
      />

      <Grid
        container
        justify="center"
        alignItems="center"
        component="button"
        onClick={handleClick}
        className={classes.button}
      >
        <Typography className={classes.buttonText}>{index + 1}</Typography>
      </Grid>
    </>
  )
}

export default QuestionNavItem
