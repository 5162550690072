import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { useStyles } from './WarnQuestionSubmissionDialogStyles'

function WarnQuestionSubmissionDialog({
  openDialog,
  setOpenDialog,
  affirmativeHandler,
}) {
  const classes = useStyles()
  return (
    <Dialog
      data-testid="render-warn-question"
      open={openDialog}
      onClose={() => setOpenDialog(() => false)}
    >
      <DialogTitle data-testid="render-open">
        <Grid container>
          <Grid item className={classes.cautionIconWrapper}>
            <ReportProblemOutlinedIcon />
          </Grid>
          <Grid item>Caution</Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <DialogContentText data-testid="render-content">
          This question has some unsaved changes. Before navigating to another
          question, it is recommended that the current question be submitted. So
          that the changes are saved with the server. Submitted questions can
          always be edited and submitted again.
        </DialogContentText>
      </DialogContent>

      <DialogActions
        data-testid="render-actions"
        className={classes.warningDialogButtons}
      >
        <Button onClick={affirmativeHandler}>Leave without submitting</Button>
        <Button
          data-testid="render-button"
          className={classes.stayButton}
          onClick={() => setOpenDialog(() => false)}
        >
          Stay
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WarnQuestionSubmissionDialog
