import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    paddingTop: '5px',
    background: theme?.palette?.lightBackground?.main,
    width: '100%',
    '@media only screen and (min-width:769px)': {
      paddingLeft: '24px',
    },
  },
  customInputTab: {
    position: 'relative',
    outline: 'none',
    top: '4px',
    padding: 0,
    border: 'none',
    background: 'none',
    flex: 1,
    cursor: 'pointer',
    paddingBottom: (props) => (props?.currentTab === 'custom' ? '5px' : '9px'),
    borderBottom: (props) =>
      props?.currentTab === 'custom'
        ? `solid 4px ${theme?.palette?.primarySelection?.main}`
        : 'none',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
      marginRight: '30px',
    },
  },
  customInputTabText: {
    fontSize: '12px',
    '@media only screen and (min-width:426px)': {
      fontSize: '14px',
    },
  },
  resultsTab: {
    position: 'relative',
    top: '4px',
    outline: 'none',
    border: 'none',
    padding: 0,
    background: 'none',
    flex: 1,
    cursor: 'pointer',
    paddingBottom: (props) => (props?.currentTab === 'results' ? '5px' : '9px'),
    borderBottom: (props) =>
      props?.currentTab === 'results'
        ? `solid 4px ${theme?.palette?.primarySelection?.main}`
        : 'none',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
    },
  },
  resultsTabText: {
    fontSize: '12px',
    '@media only screen and (min-width:426px)': {
      fontSize: '14px',
    },
  },
}))
export { useStyles }
