import React, { useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Draggable from 'react-draggable'
import { useStyles } from './MirrorCameraDialogStyles'

function MirrorCameraDialog({
  openMirrorCamera,
  setOpenMirrorCamera,
  candidateStream,
}) {
  const candidateStreamRef = useRef(null)

  const classes = useStyles({ candidateStreamRef })

  useEffect(() => {
    if (candidateStream === null) {
      return
    }

    if (candidateStreamRef.current === null) return

    candidateStreamRef.current.srcObject = candidateStream
  }, [candidateStream, candidateStreamRef.current])

  if (openMirrorCamera) {
    return (
      <Draggable
        axis="both"
        handle=".handle"
        defaultPosition={{ x: 0, y: 10 }}
        position={null}
        grid={[25, 25]}
        scale={1}
      >
        <div
          className={classes.modal}
          data-testid="mirror-camera-dialog-testId"
        >
          <div className={classes.header} />
          <div
            className={classes.closeButtonWrapper}
            data-testid="mirror-camera-closeBtn-testId"
          >
            <IconButton
              data-testid="render-icon-button"
              onClick={() => setOpenMirrorCamera(() => false)}
              className={classes.closeButton}
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
          <Grid className={`${classes.contentBoxWrapper} handle`}>
            {!candidateStreamRef.current && (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.loadingWrapper}
              >
                <Typography className={classes.loading}>Loading....</Typography>
              </Grid>
            )}

            <Grid className={classes.videoWrapper}>
              <video
                ref={candidateStreamRef}
                data-testid="mirror-camera-videoBtn-testId"
                height="100%"
                width="100%"
                autoPlay
                muted
              />
            </Grid>
          </Grid>
        </div>
      </Draggable>
    )
  }

  return null
}

export default MirrorCameraDialog
