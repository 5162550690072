import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  title: {
    boxSizing: 'border-box',
    background: theme?.palette?.header?.main,
    overflow: 'hidden',
    height: '86px',
    color: 'black',
  },
  logoWrapper: {
    height: '46px',
    width: '100%',
  },
  logo: {
    width: 'max-content',
    marginRight: 'auto',
    marginLeft: '16px',
  },
  quizDetails: {
    width: 'max-content',
  },
  cameraIcon: {
    padding: 0,
    height: '24px',
    width: '24px',
  },
  videoIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '0rem 1rem',
    borderRight: `solid 1px ${theme?.palette?.headerDividerLines?.main}`,
  },
  instructionsIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: '0rem 1rem',
    borderRight: `solid 1px ${theme?.palette?.headerDividerLines?.main}`,
  },
  closeIconAndInstructionsWrapper: {
    padding: 0,
  },
  closeIconWrapper: { width: '100%' },
  instructionIcon: {
    color: theme?.palette?.borders?.main,
  },
  instructionsHeader: {
    fontSize: '22px',
    fontWeight: 700,
    wordBreak: 'break-word',
    color: '#202020',
    paddingLeft: '40px',
    paddingBottom: '20px',
  },
  instructionsPointsWrapper: {
    padding: '0px 40px 35px 40px',
  },
  instructionsPoints: {
    fontSize: '14px',
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
  },
  instructionsIcon: {
    padding: 0,
    height: '1.6rem',
    width: '1.6rem',
  },
  timerAndAnsweredWrapper: {
    height: '40px',
    width: '100%',
    borderTop: `solid 1px ${theme?.palette?.headerDividerLines?.main}`,
  },
  timerWrapper: {
    height: '100%',
    width: '50%',
    borderRight: `solid 1px ${theme?.palette?.headerDividerLines?.main}`,
  },
  answeredWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '50%',
  },
  answeredText: {
    color: theme?.palette?.lightTextColor?.main,
  },
  submitButtonWrapper: {
    padding: '5px',
  },
  submitButton: {
    background: theme?.palette?.secondaryButton?.main,
    color: theme?.palette?.secondaryButton?.contrastText,
    fontWeight: theme?.palette?.secondaryButton?.fontWeight,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
  },
}))
export { useStyles }
