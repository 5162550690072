import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  customInputRoot: {
    width: '100%',
    height: '100%',
    paddingTop: '12.5px',
    '@media only screen and (min-width:426px)': {
      paddingTop: '29.5px',
    },
  },
  customInputGrid: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  customInputPlaceHolder: {
    width: '100%',
    minHeight: '100%',
    background: 'white',
  },
  customInputContainer: {
    padding: '10px',
    background: theme?.palette?.darkBackground?.main,
    borderRadius: 4,
    height: 'max-content',
  },
  customInputWrapper: {
    border: `solid 1px ${theme?.palette?.borders?.main}`,
    borderRadius: 4,
  },

  customInput: {
    padding: '5px 15px',
    display: 'flex',
    justifyContent: 'spaceBetween',
    alignItems: 'center',
  },
  instructionMsgWrapper: {
    height: '100%',
    width: '100%',
  },
  instructionMsg: {
    fontSize: '16px',
  },
}))
export { useStyles }
