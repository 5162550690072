import React from 'react'
import parse from 'html-react-parser'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import GrayThreeDotsCircleOutlinedIcon from '../../Icons/GrayThreeDotsCircleOutlinedIcon'
import GreenTickCircleOutlinedIcon from '../../Icons/GreenTickCircleOutlinedIcon'
import { useStyles } from './EssayResultRowItemStyles'

function EssayResultRowItem({ question }) {
  const classes = useStyles()
  const isAttempted = question?.result?.userResponse.length > 0
  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.iconWrapper}>
        <Grid item className={classes.icon}>
          {isAttempted ? (
            <GreenTickCircleOutlinedIcon />
          ) : (
            <GrayThreeDotsCircleOutlinedIcon />
          )}
        </Grid>
      </Grid>
      <Grid container direction="column" className={classes.contentWrapper}>
        <Grid item className={classes.questionWrapper}>
          <Grid item className={classes.question}>
            {parse(question?.question)}
          </Grid>
        </Grid>
        <Grid container className={classes.resultWrapper}>
          <Typography className={classes.submittedAnswerWrapper}>
            {question?.result?.userResponse}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.marksContainer}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.marksWrapper}
        >
          <Typography className={classes.marks}>N/A</Typography>
          <Typography className={classes.marksHeading}>Marks</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EssayResultRowItem
