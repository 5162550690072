import React from 'react'

function CreateIcon() {
  return (
    <svg viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Create"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-22">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="40"
            height="40"
          />
          <g
            id="Group-4"
            transform="translate(6.000000, 1.000000)"
            fillRule="nonzero"
          >
            <path
              d="M19.716247,-1.73194792e-13 L30,11.819345 L30,31.6056338 C30,34.9193423 27.3137085,37.6056338 24,37.6056338 L6,37.6056338 C2.6862915,37.6056338 0,34.9193423 0,31.6056338 L0,6 C0,2.6862915 2.6862915,-1.73194792e-13 6,-1.73194792e-13 L19.716247,-1.73194792e-13 Z M18.805,1.999 L6,2 C3.85780461,2 2.10892112,3.68396847 2.00489531,5.80035966 L2,6 L2,31.6056338 C2,33.7478292 3.68396847,35.4967127 5.80035966,35.6007385 L6,35.6056338 L24,35.6056338 C26.1421954,35.6056338 27.8910789,33.9216653 27.9951047,31.8052741 L28,31.6056338 L28,12.568 L18.805,1.999 Z"
              id="Rectangle"
              fill="#000000"
            />
            <polygon
              id="Path-4"
              fill="#000000"
              points="20.4366197 0.982723063 20.436 10.83 28.9620104 10.8309859 28.9620104 12.8309859 18.4366197 12.8309859 18.4366197 0.982723063"
            />
            <polygon
              id="Path-5"
              fill="#00B7A4"
              points="16 17.7464789 16 29.1549296 14 29.1549296 14 17.7464789"
            />
            <polygon
              id="Path-5"
              fill="#00B7A4"
              points="20.7042254 22.4507042 20.7042254 24.4507042 9.29577465 24.4507042 9.29577465 22.4507042"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default CreateIcon
