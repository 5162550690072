import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import CustomSelect from '../CustomSelect/CustomSelect'
import ReviewPageHeaderMobile from '../ReviewPageHeaderMobile/ReviewPageHeaderMobile'
import ReviewPageHeader from '../ReviewPageHeader/ReviewPageHeader'
import ReviewPageTable from '../ReviewPageTable/ReviewPageTable'
import useWindowSize from '../../hooks/useWindowSize'
import { useStyles } from './ReviewPageStyles'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function ReviewPage({
  quiz,
  currentQuestion,
  setCurrentQuestion,
  setCurrentQuestionFilter,
  getNoOfAnsweredQuestions,
  popUp,
  setPopUp,
  seconds,
  handleSubmit,
  scrolledToQuestionId,
  setScrolledToQuestionId,
  reviewQuestionFilter,
  setReviewQuestionFilter,
}) {
  const [startScrolling, setStartScrolling] = useState(false)

  const questionRefs = useRef({})
  const isWidthAboveTabRange = useMediaQuery(
    'only screen and (min-width:769px)'
  )
  const props = {
    isWidthAboveTabRange,
  }
  const windowSize = useWindowSize()
  const classes = useStyles(props)

  useEffect(() => {
    setStartScrolling(() => true)
    if (scrolledToQuestionId) {
      questionRefs?.current?.[currentQuestion?.id]?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [startScrolling])

  const updateQuestionFiltersArray = () => {
    const returnFilterArray = []
    returnFilterArray.push({ value: 'all', label: 'All Questions' })
    if (
      quiz?.questions?.filter((question) => {
        return question?.type === 'mcq'
      })?.length > 0
    )
      returnFilterArray.push({ value: 'mcq', label: 'MCQ' })

    if (
      quiz?.questions?.filter((question) => {
        return question?.type === 'fill-in-blanks'
      })?.length > 0
    )
      returnFilterArray.push({
        value: 'fill-in-blanks',
        label: 'Fill In The Blanks',
      })

    if (
      quiz?.questions?.filter((question) => {
        return question?.type === 'coding'
      })?.length > 0
    )
      returnFilterArray.push({ value: 'coding', label: 'Coding Questions' })
    if (
      quiz?.questions?.filter((question) => {
        return question?.type === 'essay'
      })?.length > 0
    )
      returnFilterArray.push({ value: 'essay', label: 'Essay Questions' })
    returnFilterArray.push({ value: 'answered', label: 'Answered Questions' })

    returnFilterArray.push({
      value: 'unanswered',
      label: 'Unanswered Questions',
    })
    return returnFilterArray
  }

  const questionFilters = updateQuestionFiltersArray()

  const returnTableContent = () => {
    if (
      reviewQuestionFilter === 'answered' &&
      !(
        quiz?.questions?.filter((question) => {
          return (
            question?.saved &&
            question?.saved !== 'pending' &&
            question?.saved !== 'failed'
          )
        })?.length > 0
      )
    ) {
      return (
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.quizInstructionsWrapper}
        >
          <Typography variant="h5" className={classes.quizInstructionsMsg}>
            Start answering some questions...
          </Typography>
        </Grid>
      )
    }
    if (
      reviewQuestionFilter === 'unanswered' &&
      !(
        quiz?.questions?.filter((question) => {
          return (
            !question?.saved ||
            question?.saved === 'pending' ||
            question?.saved === 'failed'
          )
        })?.length > 0
      )
    ) {
      return (
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.quizInstructionsWrapper}
        >
          <Typography variant="h5" className={classes.quizInstructionsMsg}>
            Hurray, You have answered all the questions!
          </Typography>
        </Grid>
      )
    }
    return (
      <ReviewPageTable
        quiz={quiz}
        currentQuestion={currentQuestion}
        setCurrentQuestion={setCurrentQuestion}
        startScrolling={startScrolling}
        setStartScrolling={setStartScrolling}
        reviewQuestionFilter={reviewQuestionFilter}
        setCurrentQuestionFilter={setCurrentQuestionFilter}
        setPopUp={setPopUp}
        scrolledToQuestionId={scrolledToQuestionId}
        setScrolledToQuestionId={setScrolledToQuestionId}
      />
    )
  }

  return (
    <Dialog fullScreen open={popUp} TransitionComponent={Transition}>
      <Helmet>
        <title>{`${quiz?.info?.title} - Review & Submit`}</title>
      </Helmet>
      <div
        className={classes.root}
        style={{
          height: `${windowSize?.height}px`,
        }}
      >
        <div className={classes.title}>
          {isWidthAboveTabRange ? (
            <ReviewPageHeader
              quiz={quiz}
              seconds={seconds}
              getNoOfAnsweredQuestions={getNoOfAnsweredQuestions}
              setPopUp={setPopUp}
            />
          ) : (
            <ReviewPageHeaderMobile
              quiz={quiz}
              seconds={seconds}
              getNoOfAnsweredQuestions={getNoOfAnsweredQuestions}
              setPopUp={setPopUp}
            />
          )}
        </div>

        {isWidthAboveTabRange ? (
          <div className={classes.reviewAndSelectContainer}>
            <div className={classes.reviewAndSelectWrapper}>
              <Typography className={classes.reviewAndSubmitTestText}>
                Review &#38; Submit Test
              </Typography>

              <div className={classes.questionFilterSelectWrapper}>
                <CustomSelect
                  value={reviewQuestionFilter}
                  onChange={(option) => {
                    setReviewQuestionFilter(() => option?.value)
                  }}
                  options={questionFilters}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.reviewAndSelectWrapper}>
            <div className={classes.questionFilterSelectContainer}>
              <div className={classes.questionFilterSelectWrapper}>
                <CustomSelect
                  value={reviewQuestionFilter}
                  onChange={(option) => {
                    setReviewQuestionFilter(() => option?.value)
                  }}
                  options={questionFilters}
                />
              </div>
            </div>
            <div className={classes.reviewAndSubmitTestTextWrapper}>
              <Typography className={classes.reviewAndSubmitTestText}>
                Review &#38; Submit Test
              </Typography>
            </div>
          </div>
        )}

        <div className={classes.content}>{returnTableContent()}</div>

        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => setPopUp(() => false)}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} className={classes.submitTestButton}>
            Submit Test
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ReviewPage
