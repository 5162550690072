import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
  },
  homePageHeaderContainer: {
    width: '100%',
  },
  homePageFooterContainer: {
    marginTop: 'auto',
  },
  pricingPlanContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '40px 20px 50px 20px',
    '@media only screen and (min-width:320px)': {
      padding: '40px 0px 50px 0px',
    },
    '@media only screen and (min-width:375px)': {
      padding: '40px 0px 50px 0px',
    },
    '@media only screen and (min-width:425px)': {
      padding: '60px 0px 60px 0px',
    },
    '@media only screen and (min-width:650px)': {
      padding: '60px 0px 70px 0px',
    },
    '@media only screen and (min-width:768px)': {
      padding: '80px 0px 80px 0px',
    },
    '@media only screen and (min-width:1024px)': {
      padding: '80px 0px 80px 0px',
    },
    '@media only screen and (min-width:1440px)': {
      padding: '80px 0px 80px 0px',
    },
  },
  pricingPlanHeadingWrapper: {
    paddingBottom: '10px',
  },
  pricingPlanHeading: {
    fontWeight: 700,
    fontSize: '24px',
    textAlign: 'center',
    color: theme?.palette?.darkerTextColor?.main,
    '@media only screen and (min-width:426px)': {
      fontSize: '28px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '32px',
    },
    '@media only screen and (min-width:1025px)': {
      fontSize: '36px',
    },
    '@media only screen and (min-width:1400px)': {
      fontSize: '38px',
    },
  },
  pricingPlanCaptionWrapper: {
    paddingBottom: '40px',
    '@media only screen and (min-width:426px)': {
      paddingBottom: '50px',
    },
  },
  pricingPlanCaption: {
    color: theme?.palette?.greenTextColor?.main,
    textAlign: 'center',
    '@media only screen and (min-width:769px)': {
      fontSize: '16px',
    },
  },
  pricingCardsContainer: {
    display: 'flex',
    width: '100%',
    overflowY: 'auto',
    height: 'max-content',
  },
  pricingCardsWrapper: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    width: 'max-content',
    height: 'max-content',
    paddingBottom: '15px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  pricingCardWrapper: {
    minHeight: 'max-content',
    width: '180px',
    '@media only screen and (min-width:275px)': {
      width: '200px',
    },
    '@media only screen and (min-width:320px)': {
      width: '215px',
    },
    '@media only screen and (min-width:375px)': {
      width: '230px',
    },
    '@media only screen and (min-width:425px)': {
      width: '240px',
    },
    '@media only screen and (min-width:600px)': {
      width: '300px',
    },
  },
  leftPaddingPricingCardWrapper: {
    padding: '0px 10px 0px 20px',
    '@media only screen and (min-width:275px)': {
      padding: '0px 13px 0px 20px',
    },
    '@media only screen and (min-width:320px)': {
      padding: '0px 13px 0px 25px',
    },
    '@media only screen and (min-width:375px)': {
      padding: '0px 13px 0px 30px',
    },
    '@media only screen and (min-width:425px)': {
      padding: '0px 13px 0px 30px',
    },
    '@media only screen and (min-width:600px)': {
      padding: '0px 13px 0px 40px',
    },
    '@media only screen and (min-width:768px)': {
      padding: '0px 13px 0px 40px',
    },
    '@media only screen and (min-width:1024px)': {
      padding: '0px 13px 0px 70px',
    },
    '@media only screen and (min-width:1440px)': {
      padding: '0px 13px 0px 70px',
    },
    '@media only screen and (min-width:2200px)': {
      padding: '0px 13px 0px 20px',
    },
  },
  paddingPricingCardWrapper: {
    padding: '0px 13px 0px 13px',
  },
  rightPaddingPricingCardWrapper: {
    padding: '0px 20px 0px 13px',
  },
  pricingTableAndFooterWrapper: {
    display: 'flex',
    flex: 1,
    width: '100%',
    overflowY: 'scroll',
    height: 0,
  },
  pricingTableAndFooterWrapperChild: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'max-content',
  },
}))
export { useStyles }
