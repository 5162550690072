import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import HelveticaNeue from './Assets/Fonts/HelveticaNeue.ttf'

const helveticaNeue = {
  fontFamily: 'HelveticaNeue',
  fontDisplay: 'swap',

  src: `
    local('HelveticaNeue'),
    url(${HelveticaNeue}) format('ttf')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const theme = responsiveFontSizes(
  createMuiTheme({
    typography: {
      fontFamily: ['HelveticaNeue', '"Helvetica Neue"', 'Helvetica'].join(','),

      button: {
        textTransform: 'none',
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [helveticaNeue],
          },
        },
      },
    },

    overrides: {
      MuiTypography: {
        body1: {
          fontSize: '14px',
        },
      },
      MuiButton: {
        root: {
          fontSize: '14px',
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '0.9rem',
          color: '#FFFFFF',
          backgroundColor: '#000000',
          padding: '0.7rem 0.7rem',
          borderRadius: '3px',
        },
        arrow: {
          color: '#000000',
          '&::before': {
            backgroundColor: '#000000',
          },
        },
      },
      MuiRadio: {
        root: {
          color: '#000000',
        },
        colorSecondary: {
          '&$checked': {
            color: '#00B7A4',
          },
        },
      },

      MuiInputBase: {
        input: {
          paddingLeft: '10px',
          paddingRight: 0,
          paddingBottom: '5px',
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'white',
            borderColor: 'brown',
          },

          '&:before': {
            borderColor: 'orange',
          },
          '&:after': {
            borderColor: 'green',
          },
        },
      },
    },

    palette: {
      text: { primary: '#262B4B' },
      primary: { main: '#262B4B' },
      secondary: { main: '#000000', contrastText: 'FFFFFF' },

      lightHighlight: { main: '#F0F0FD' },
      footer: { main: '#17173F', contrastText: 'whitesmoke' },
      quizHeader: { main: '#26225E', contrastText: 'whitesmoke' },
      successColor: { main: 'forestgreen' },
      partialSuccessColor: { main: 'goldenrod' },

      primarySelection: {
        main: '#00B7A4',
        contrastText: '#FFFFFF',
        disabled: 'rgba(0, 183, 164, 0.4)',
      },

      errorColor: { main: '#D70022' },

      loader: { main: '#00B7A4' },
      header: { main: '#262B4B', contrastText: '#FFFFFF' },
      footerGradient: {
        main: 'linear-gradient(0deg, rgba(38, 43, 75, 0.95), rgba(17, 93, 97, 0.95))',
      },
      footerDivider: { main: '#48646B' },

      bronzePlanHeadingColor: { main: '#3D6F7E' },
      silverPlanHeadingColor: { main: '#224960' },
      goldPlanHeadingColor: { main: '#202648' },
      bronzePricingBackground: { main: 'rgb(0, 184, 164, 0.6)' },
      silverPricingBackground: { main: 'rgb(0, 184, 164, 0.8)' },
      goldPricingBackground: { main: 'rgb(0, 184, 164)' },
      darkCell: { main: 'rgba(0, 0, 0, 0.02)' },

      darkTextColor: { main: '#262B4B', disabled: 'rgba(38, 43, 75, 0.5)' },
      darkerTextColor: { main: '#2A2A2A' },
      darkestTextColor: { main: '#000000' },
      lightTextColor: { main: '#FFFFFF' },
      grayTextColor: { main: '#8E8E8E' },
      greenTextColor: { main: '#00B7A4' },
      onboardingPageGrayTextColor: { main: 'rgba(256,256,256,0.5)' },
      onboardingPageBodyTextColor: { main: '#202020' },
      resultsPageLightTextColor: { main: '#262A4C' },

      lightBackground: { main: '#FFFFFF', contrastText: '#262B4B' },
      darkBackground: { main: '#F3F7F7', contrastText: '#262B4B' },
      darkerBackground: { main: '#F6FCFB' },

      borders: { main: '#D4D5DB' },
      lightBorders: { main: '#E9E9ED' },
      dividerLines: { main: '#E6E6E6' },
      lightDividerLines: { main: '#EDEFF0' },
      headerDividerLines: { main: '#383D5A' },
      onboardingPageDividerLines: { main: 'rgba(230, 230, 230, 0.1)' },

      lightShadow: { main: 'rgba(232, 232, 232, 0.5)' },
      darkShadow: { main: 'rgba(0, 0, 0, 0.24)' },

      highlight: { main: 'rgba(0, 183, 164, 0.1)' },

      primaryButton: {
        main: '#00B7A4',
        contrastText: '#FFFFFF',
        hover: '#009685',
        fontWeight: 600,
      },
      secondaryButton: {
        main: '#FFFFFF',
        contrastText: '#00B7A4',
        border: '#00B7A4',
        hover: '#F3F7F7',
        fontWeight: 600,
      },
      tertiaryButton: {
        main: '#D4D5DB',
        contrastText: '#262B4B',
        fontWeight: 600,
      },
      codeEditorBackground: {
        lightMode: '#FFFFFF',
        darkMode: '#282a36',
      },
    },
  })
)
export default theme
