import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  paper: {
    overflowY: 'unset',
    width: 'max-content',
    borderRadius: '15px',
  },
  dialogActions: {
    padding: '0px 2px 0px 0px',
    '@media only screen and (min-width:1025px)': {
      padding: '5px 2px 0px 0px',
    },
  },
  closeIcon: {
    fontSize: '20px',
  },
  closeButton: {
    position: 'absolute',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    left: 'calc(100% - 12px)',
    top: '-7px',
    background: 'white',
    color: 'black',
    '&:hover': {
      background: 'white',
    },
    '@media only screen and (min-width:500px)': {
      width: '24px',
      height: '24px',
      left: 'calc(100% - 15px)',
      top: '-9px',
    },
  },
  dialogContent: {
    padding: '0px 15px 15px 15px',
    '@media only screen and (min-width:426px)': {
      padding: '5px 25px 25px 25px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '10px 35px 35px 35px',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '10px 45px 45px 45px',
    },
  },
  contentBoxWrapper: {
    position: 'relative',
    height: (props) => props?.videoHeight,
    width: (props) => props?.videoWidth,
  },
  loadingWrapper: {
    position: 'absolute',
    background: 'black',
    color: 'white',
    height: '100%',
    width: '100%',
    borderRadius: '15px',
  },
  loading: {
    fontSize: '14px',
    '@media only screen and (min-width:320px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width:375px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:426px)': {
      fontSize: '24px',
    },
    '@media only screen and (min-width:1500px)': {
      fontSize: '28px',
    },
  },
  videoWrapper: {
    height: '100%',
    width: '100%',
    background: 'black',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '15px',
  },
  video: {
    '&::-webkit-media-controls-current-time-display': {
      display: 'none',
    },
    '&::-webkit-media-controls-timeline': {
      display: 'none',
    },
  },
}))
export { useStyles }
