import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Logo from '../Logo/Logo'
import {
  secondsToMinutes,
  convertInstructionsToPoints,
  questionsCount,
} from '../../utils'
import QuizOnboardingPageCTA from '../QuizOnboardingPageCTA/QuizOnboardingPageCTA'
import ClockIcon from '../../Icons/ClockIcon'
import QuestionsIcon from '../../Icons/QuestionsIcon'
import CameraOnIcon from '../../Icons/CameraOnIcon'
import { useStyles } from './QuizOnboardingPageStyles'

function QuizOnboardingPage({
  quizInfoDetails,
  username,
  setUsername,
  signIn,
  handlePublicQuizStart,
  isQuizStarted,
  setIsQuizStarted,
  isQuizEnded,
  isProctored,
  startCapturingVideo,
  setOpenTestVideoDialog,
  setIsLogin,
  isLogin,
}) {
  const classes = useStyles({
    isQuizStarted,
    isQuizEnded,
    isLogin,
    codingQuestions: quizInfoDetails?.questionCount?.coding,
  })

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column" className={classes.leftContainer}>
        <Grid
          container
          direction="column"
          justify="center"
          className={classes.onboardingPageWrapper}
        >
          <Grid item className={classes.logo}>
            <Logo size="big" />
            {isProctored ? (
              <div className={classes.testVideoWrapper}>
                <Button
                  startIcon={
                    <div className={classes.cameraOnIcon}>
                      <CameraOnIcon />
                    </div>
                  }
                  onClick={() => {
                    setOpenTestVideoDialog(true)
                    startCapturingVideo()
                  }}
                  className={classes.testVideo}
                >
                  Test Video
                </Button>
              </div>
            ) : null}
          </Grid>

          <Grid
            container
            alignItems="center"
            className={classes.titleAndDetailsWrapper}
          >
            <Grid
              container
              className={classes.quizInfoDetailsWrapper}
              direction="column"
            >
              <Typography className={classes.title}>
                {quizInfoDetails?.info?.title}
              </Typography>
              <Grid container>
                <Grid item className={classes.durationIcon}>
                  <ClockIcon />
                </Grid>
                <Typography className={classes.durationText}>
                  {secondsToMinutes(quizInfoDetails?.time?.duration)}
                </Typography>
              </Grid>
              <Grid container className={classes.questionDetailsWrapper}>
                <Grid item className={classes.questionsIcon}>
                  <QuestionsIcon />
                </Grid>
                <Grid item style={{ flex: 1 }}>
                  <Typography className={classes.questionsText}>
                    {questionsCount(quizInfoDetails?.questionCount)} Questions
                  </Typography>
                  {quizInfoDetails?.questionCount?.mcq ? (
                    <Typography className={classes.multipleChoiceQuestionsText}>
                      <span className={classes.questionsCount}>
                        {quizInfoDetails?.questionCount?.mcq}{' '}
                      </span>
                      {quizInfoDetails?.questionCount?.mcq > 1 ? (
                        <> Multiple Choice Questions </>
                      ) : (
                        <> Multiple Choice Question</>
                      )}
                    </Typography>
                  ) : null}

                  {quizInfoDetails?.questionCount?.coding ? (
                    <Typography className={classes.codingQuestionsText}>
                      <span className={classes.questionsCount}>
                        {quizInfoDetails?.questionCount?.coding}{' '}
                      </span>
                      {quizInfoDetails?.questionCount?.coding > 1 ? (
                        <> Coding Questions </>
                      ) : (
                        <> Coding Question </>
                      )}
                    </Typography>
                  ) : null}

                  {quizInfoDetails?.questionCount?.fillInTheBlanks ? (
                    <Typography className={classes.codingQuestionsText}>
                      <span className={classes.questionsCount}>
                        {quizInfoDetails?.questionCount?.fillInTheBlanks}{' '}
                      </span>
                      {quizInfoDetails?.questionCount?.fillInTheBlanks > 1 ? (
                        <> Fill In The Blanks Questions </>
                      ) : (
                        <> Fill In The Blank Question</>
                      )}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.copyrightWrapper}
        >
          TestPaper.app &#169; 2020-2023
        </Grid>
      </Grid>
      <Grid
        container
        direction={
          isLogin && (!isQuizStarted || isQuizEnded)
            ? 'column-reverse'
            : 'column'
        }
        className={classes.rightContainer}
      >
        <Grid
          container
          direction="column"
          justify="center"
          className={classes.quizDetailsWrapper}
        >
          <Grid item className={classes.quizDetailsContainer}>
            <Grid item className={classes.quizDetails}>
              <Grid item className={classes.instructionsAndDescriptionWrapper}>
                <Typography className={classes.description}>
                  {quizInfoDetails?.info?.description}
                </Typography>
                <Grid className={classes.divider} />
                <Typography className={classes.instructionsHeading}>
                  Instructions
                </Typography>

                <Typography className={classes.quizInstructions}>
                  {convertInstructionsToPoints(
                    quizInfoDetails?.info?.instructions
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.ctaWrapper}>
          <QuizOnboardingPageCTA
            setIsLogin={setIsLogin}
            isLogin={isLogin}
            quizInfoDetails={quizInfoDetails}
            username={username}
            setUsername={setUsername}
            signIn={signIn}
            handlePublicQuizStart={handlePublicQuizStart}
            isQuizStarted={isQuizStarted}
            setIsQuizStarted={setIsQuizStarted}
            isQuizEnded={isQuizEnded}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuizOnboardingPage
