import React from 'react'

function InstructionsIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="instruction"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.5"
      >
        <g id="Group" fill="#FFFFFF" fillRule="nonzero">
          <g id="utility/messaging/info">
            <path
              d="M12.314115,10.504215 L11.275635,10.504215 C10.9442642,10.504215 10.675635,10.7728442 10.675635,11.104215 C10.675635,11.4355858 10.9442642,11.704215 11.275635,11.704215 L11.296785,11.704215 L10.106775,13.85577 L9.88659,14.26272 C9.78689045,14.4621007 9.72752591,14.6791998 9.7119,14.90157 C9.6813786,15.3495327 9.82633807,15.7917971 10.116105,16.13478 C10.4054678,16.4805116 10.8201046,16.69741 11.269125,16.73793 C11.66949,16.75752 11.879145,16.74333 12.19353,16.747725 L12.19848,16.747725 C12.4128394,16.7489361 12.6115617,16.6356959 12.7197903,16.4506608 C12.8280188,16.2656257 12.8293111,16.036907 12.7231803,15.8506608 C12.6170494,15.6644146 12.4196194,15.5489361 12.20526,15.547725 L12.1935,15.547725 L11.380335,15.54306 C11.2451266,15.5324305 11.1201225,15.4670486 11.03427,15.362055 C10.9452933,15.2577972 10.9003459,15.123025 10.90893,14.98623 C10.9134543,14.9188975 10.9318384,14.8532253 10.96293,14.79333 L11.156835,14.436555 L12.839265,11.39475 C12.8881108,11.3057318 12.9138607,11.2058885 12.91416,11.10435 C12.91416,10.945195 12.8509651,10.7925527 12.7384339,10.6800047 C12.6259028,10.5674567 12.47327,10.504215 12.314115,10.504215 Z M13.37787,9.541725 C13.9097451,9.541725 14.340915,9.11055507 14.340915,8.57868 C14.340915,8.04680493 13.9097451,7.615635 13.37787,7.615635 C12.8459949,7.615635 12.414825,8.04680493 12.414825,8.57868 C12.414825,9.11055507 12.8459949,9.541725 13.37787,9.541725 Z M12,2.8125 C6.92588386,2.8125 2.8125,6.92588386 2.8125,12 C2.8125,17.0741161 6.92588386,21.1875 12,21.1875 C17.0741161,21.1875 21.1875,17.0741161 21.1875,12 C21.181359,6.92842971 17.0715703,2.81864096 12,2.8125 Z M12,20.0625 C7.5472042,20.0625 3.9375,16.4527958 3.9375,12 C3.9375,7.5472042 7.5472042,3.9375 12,3.9375 C16.4527958,3.9375 20.0625,7.5472042 20.0625,12 C20.05754,16.4507397 16.4507397,20.05754 12,20.0625 Z"
              id="Color"
            />
            <rect
              id="info"
              fillOpacity="0"
              x="0"
              y="0"
              width="24"
              height="24"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default InstructionsIcon
