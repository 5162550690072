/* eslint-disable no-param-reassign */
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import VideocamIcon from '@material-ui/icons/Videocam'
import { Grid, Typography, Button } from '@material-ui/core'
import {
  MOCK_BASE_URL,
  CANDIDATE_ENDPOINT,
  PROCTORING_ENDPOINT,
} from '../../constants'
import Logo from '../Logo/Logo'
import useWindowSize from '../../hooks/useWindowSize'
import { useStyles } from './CandidateStreamStyles'

export default function CandidateStream({
  setStream,
  proctoringConnectionRefs,
}) {
  const username = sessionStorage.getItem('userID')
  const classes = useStyles()
  const windowSize = useWindowSize()
  const [allowCameraDisabled, setAllowCameraDisabled] = useState(false)

  const token = Cookies.get(`${sessionStorage.getItem('userID')}_token`)
  const history = useHistory()
  const pingIntervalRef = useRef(false)
  const candidateData = useRef({
    offer: {},
    candidates: [],
  })

  const configuration = {
    iceServers: [
      {
        urls: [
          'stun:stun.l.google.com:19302',
          'stun:stun1.l.google.com:19302',
          'stun:stun2.l.google.com:19302',
        ],
      },
    ],
  }
  const sendData = (data) => {
    proctoringConnectionRefs.current.webSocketConn?.send(JSON.stringify(data))
  }

  const handleSignallingData = (data) => {
    const remoteDesc = new RTCSessionDescription(data.answer)

    proctoringConnectionRefs.current.webRTCPeerConn.setRemoteDescription(
      remoteDesc
    )
    data.candidates.forEach((candidate) => {
      proctoringConnectionRefs.current.webRTCPeerConn.addIceCandidate(candidate)
    })
  }

  const createAndSendOffer = (peer) => {
    peer
      .createOffer()
      .then((offer) => {
        peer.setLocalDescription(offer)
        candidateData.current.offer = offer
      })
      .catch((err) => {
        throw new Error('could not create candiate crediantials', err)
      })
  }

  const videoError = () => {
    history.go(-1)
  }

  const startCall = () => {
    const mediaConstraints = {
      video: {
        aspectRatio: 1.33333,
      },
      audio: true,
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = (constraints) => {
        const getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia
        if (!getUserMedia) {
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser')
          )
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    }
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then((stream) => {
        proctoringConnectionRefs.current.streams = stream
        setStream(true)

        const peer = new RTCPeerConnection(configuration)
        proctoringConnectionRefs.current.webRTCPeerConn = peer

        peer.addEventListener(
          'connectionstatechange',
          () => {
            if (
              proctoringConnectionRefs.current.webRTCPeerConn
                .connectionState === 'disconnected' ||
              proctoringConnectionRefs.current.webRTCPeerConn
                .connectionState === 'failed'
            ) {
              if (
                proctoringConnectionRefs.current.webSocketConn?.readyState === 3
              ) {
                proctoringConnectionRefs.current.webSocketConn.close()
              }
              startCall()
            }
          },
          false
        )

        proctoringConnectionRefs.current.streams
          .getTracks()
          ?.forEach((track) => {
            peer.addTrack(track, stream)
          })
        peer.onicecandidate = (e) => {
          if (e.candidate == null) {
            if (candidateData.current.candidates.length > 0) {
              sendData(candidateData.current)
            }
            return
          }
          candidateData.current.candidates.push(e.candidate)
        }
        createAndSendOffer(peer)
      })
      .catch((err) => {
        console.error(err)
        videoError()
      })
  }
  const setupWebsocket = () => {
    setAllowCameraDisabled(true)
    const webSocketUrl = `wss:${MOCK_BASE_URL?.split(':')[1]}`
    proctoringConnectionRefs.current.webSocketConn = new WebSocket(
      `${webSocketUrl}${CANDIDATE_ENDPOINT}${PROCTORING_ENDPOINT}?email=${username}`
    )
    proctoringConnectionRefs.current.webSocketConn.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data?.pong) {
        return
      }
      if (
        proctoringConnectionRefs.current.webRTCPeerConn.connectionState !==
        'connected'
      ) {
        handleSignallingData(data)
      }
    }
    proctoringConnectionRefs.current.webSocketConn.onopen = () => {
      sendData({ authentication: token })
      pingIntervalRef.current = setInterval(() => {
        sendData({ ping: true })
      }, 50000)
      if (
        proctoringConnectionRefs.current.webRTCPeerConn?.connectionState !==
        'connected'
      ) {
        startCall()
      }
    }
    proctoringConnectionRefs.current.webSocketConn.onclose = () => {
      clearInterval(pingIntervalRef.current)
    }
  }

  return (
    <Grid
      className={classes.root}
      style={{ height: `${windowSize?.height}px` }}
      data-testid="candidate-stream"
    >
      <Grid container alignItems="center" className={classes.header}>
        <Logo />
      </Grid>
      <Grid
        container
        direction="column"
        className={classes.body}
        alignItems="center"
        justify="center"
      >
        <Typography className={classes.videoCamWrapper}>
          <VideocamIcon className={classes.videoCamIcon} />
        </Typography>
        <Typography className={classes.allowCameraWrapper}>
          Allow Camera
        </Typography>
        <Typography className={classes.requestPermissionMessage}>
          You need to use the camera to proceed with this quiz
        </Typography>
        <Button
          className={classes.allowBtn}
          disabled={allowCameraDisabled}
          onClick={setupWebsocket}
        >
          Allow
        </Button>
      </Grid>
    </Grid>
  )
}
