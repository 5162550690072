import React from 'react'

function QuestionsIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="questions"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-3-Copy">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <g id="Group-4" transform="translate(4.000000, 4.000000)">
            <circle id="Oval" stroke="#D4D5DB" cx="8" cy="8" r="7.5" />
            <path
              d="M8.82033333,10.086 L8.82033333,9.657 C8.82033333,9.46633333 8.84783333,9.29583333 8.90283333,9.1455 C8.95783333,8.99516667 9.03116667,8.85583333 9.12283333,8.7275 C9.2145,8.59916667 9.31716667,8.47816667 9.43083333,8.3645 C9.5445,8.25083333 9.66,8.139 9.77733333,8.029 C9.89466667,7.91166667 10.0101667,7.79066667 10.1238333,7.666 C10.2375,7.54133333 10.3383333,7.40566667 10.4263333,7.259 C10.5143333,7.11233333 10.5858333,6.94916667 10.6408333,6.7695 C10.6958333,6.58983333 10.7233333,6.38633333 10.7233333,6.159 C10.7233333,5.807 10.6665,5.49533333 10.5528333,5.224 C10.4391667,4.95266667 10.2796667,4.7235 10.0743333,4.5365 C9.869,4.3495 9.62516667,4.2065 9.34283333,4.1075 C9.0605,4.0085 8.747,3.959 8.40233333,3.959 C8.021,3.959 7.67633333,4.025 7.36833333,4.157 C7.06033333,4.289 6.8,4.47416667 6.58733333,4.7125 C6.37466667,4.95083333 6.2115,5.23133333 6.09783333,5.554 C5.98416667,5.87666667 5.931,6.225 5.93833333,6.599 L6.87333333,6.599 C6.87333333,6.34233333 6.90266667,6.104 6.96133333,5.884 C7.02,5.664 7.1135,5.47333333 7.24183333,5.312 C7.37016667,5.15066667 7.52966667,5.02233333 7.72033333,4.927 C7.911,4.83166667 8.13833333,4.784 8.40233333,4.784 C8.79833333,4.784 9.11183333,4.89766667 9.34283333,5.125 C9.57383333,5.35233333 9.704,5.66766667 9.73333333,6.071 C9.748,6.34233333 9.715,6.57333333 9.63433333,6.764 C9.55366667,6.95466667 9.4455,7.12883333 9.30983333,7.2865 C9.17416667,7.44416667 9.0275,7.5945 8.86983333,7.7375 C8.71216667,7.8805 8.56183333,8.03633333 8.41883333,8.205 C8.27583333,8.37366667 8.15483333,8.56616667 8.05583333,8.7825 C7.95683333,8.99883333 7.9,9.261 7.88533333,9.569 L7.88533333,10.086 L8.82033333,10.086 Z M8.95233333,12 L8.95233333,10.779 L7.73133333,10.779 L7.73133333,12 L8.95233333,12 Z"
              id="?"
              fill="#D4D5DB"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default QuestionsIcon
