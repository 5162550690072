import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
  },
  title: {
    position: 'fixed',
    boxSizing: 'border-box',
    background: 'white',
    padding: '0.5rem 2rem',
    marginTop: '0.3vh',
  },
  timer: {
    background: 'black',
    border: 'solid 1px whitesmoke',
    width: '9rem',
    padding: '0.25rem',
  },
  timerInput: {
    color: 'white',
    textAlign: 'center',
  },
  bodyWrapper: {
    boxSizing: 'border-box',
    height: 'calc(100% - 86px)',
    width: '100%',
    overflowY: 'hidden',
    '@media only screen and (min-width:1000px)': {
      height: 'calc(100% - 46px)',
    },
  },

  content: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  submitDialogContent: { padding: '2rem' },
  submitDialogTitle: { textAlign: 'center' },
  submitDialogActions: { justifyContent: 'center' },
  tabChangeSnackBar: { background: 'goldenrod', color: 'black' },
  runFailedSnackBar: { background: 'crimson', color: 'whitesmoke' },
  submitSuccessSnackBar: {
    background: theme?.palette?.primarySelection?.main,
    color: 'whitesmoke',
  },
  submitFailedSnackBar: { background: 'crimson', color: 'whitesmoke' },
  networkOfflineSnackBar: { background: 'crimson', color: 'whitesmoke' },
  networkOnlineSnackBar: {
    background: 'forestgreen',
    color: 'whitesmoke',
  },
}))
export { useStyles }
