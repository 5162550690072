import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useAuth } from '@zopsmart/zs-components'
import { MOCK_BASE_URL, LOGIN_ENDPOINT, REFRESH_TOKEN_URL } from '../constants'
import PageNotFound from '../components/PageNotFound/PageNotFound'
import HomePage from '../components/HomePage/HomePage'
import HomePagePricing from '../components/HomePagePricing/HomePagePricing'
import QuizOnboardingPageWrapper from '../components/QuizOnboardingPageWrapper/QuizOnboardingPageWrapper'
import QuizPage from '../components/QuizPage/QuizPage'
import SubmitPage from '../components/SubmitPage/SubmitPage'
import ProtectedRoute from './ProtectedRoute'

function Routes() {
  useAuth(
    'tokenStorage',
    MOCK_BASE_URL,
    LOGIN_ENDPOINT,
    REFRESH_TOKEN_URL,
    [],
    '',
    '',
    'sessionStorage'
  )
  return (
    <Switch>
      <ProtectedRoute exact path="/quiz/:quizId/submit">
        <SubmitPage />
      </ProtectedRoute>

      <ProtectedRoute exact path="/quiz/:quizId/questions">
        <QuizPage />
      </ProtectedRoute>

      <Route exact path="/quiz/:quizId">
        <QuizOnboardingPageWrapper />
      </Route>

      <Route exact path="/">
        <HomePage />
      </Route>

      <Route exact path="/home">
        <Redirect to="/" />
      </Route>

      <Route exact path="/pricing">
        <HomePagePricing />
      </Route>

      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  )
}

export default Routes
