import React, { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Logo from '../Logo/Logo'
import { secondsToMinutes, convertInstructionsToPoints } from '../../utils'
import QuizOnboardingPageCTA from '../QuizOnboardingPageCTA/QuizOnboardingPageCTA'
import ClockIcon from '../../Icons/ClockIcon'
import QuestionsIcon from '../../Icons/QuestionsIcon'
import CameraOnIcon from '../../Icons/CameraOnIcon'
import { useStyles } from './QuizOnboardingPageMobleStyles'

function QuizOnboardingPageMobile({
  quizInfoDetails,
  username,
  setUsername,
  loginMutation,
  handlePublicQuizStart,
  isQuizStarted,
  setIsQuizStarted,
  isQuizEnded,
  isProctored,
  startCapturingVideo,
  setOpenTestVideoDialog,
  setIsLogin,
  isLogin,
}) {
  const [descriptionViewMoreClicked, setDescriptionViewMoreClicked] =
    useState(false)
  const [instructionsViewMoreClicked, setInstructionsViewMoreClicked] =
    useState(false)

  const [showDescriptionViewMore, setShowDescriptionViewMore] = useState(false)
  const [showInstructionsViewMore, setShowInstructionsViewMore] =
    useState(false)

  const descriptionRef = useRef()
  const instructionsRef = useRef()

  const classes = useStyles({
    isQuizStarted,
    isQuizEnded,
    isLogin,
    codingQuestions: quizInfoDetails?.questionCount?.coding,
  })

  useEffect(() => {
    if (
      descriptionRef?.current?.clientHeight <
      descriptionRef?.current?.scrollHeight
    ) {
      setShowDescriptionViewMore(() => true)
    }
  }, [descriptionRef, descriptionRef?.current?.clientWidth])

  useEffect(() => {
    if (
      instructionsRef?.current?.clientHeight <
      instructionsRef?.current?.scrollHeight
    ) {
      setShowInstructionsViewMore(() => true)
    }
  }, [instructionsRef, instructionsRef?.current?.clientWidth])

  return (
    <Grid
      container
      direction={
        isLogin && (!isQuizStarted || isQuizEnded) ? 'column-reverse' : 'column'
      }
      className={classes.root}
    >
      <Grid item className={classes.quizInfoWrapper}>
        <Grid container direction="column" className={classes.topContainer}>
          <Grid item className={classes.logo}>
            <Logo />
            {isProctored ? (
              <div className={classes.testVideoWrapper}>
                <Button
                  startIcon={
                    <div className={classes.cameraOnIcon}>
                      <CameraOnIcon />
                    </div>
                  }
                  onClick={() => {
                    setOpenTestVideoDialog(true)
                    startCapturingVideo()
                  }}
                  className={classes.testVideo}
                >
                  Test Video
                </Button>
              </div>
            ) : null}
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classes.titleAndDetailsWrapper}
          >
            <Grid
              container
              className={classes.questionAndDurationDetails}
              direction="column"
            >
              <Typography className={classes.title}>
                {quizInfoDetails?.info?.title}
              </Typography>
              <Grid container>
                <Grid item className={classes.durationIcon}>
                  <ClockIcon />
                </Grid>
                <Typography className={classes.durationText}>
                  {secondsToMinutes(quizInfoDetails?.time?.duration)}
                </Typography>
              </Grid>
              <Grid container className={classes.questionDetails}>
                <Grid item className={classes.questionsIcon}>
                  <QuestionsIcon
                    inputProps={{ 'data-testid': 'questionIcon-testId' }}
                  />
                </Grid>
                <Grid className={classes.questionCountDetails} item>
                  <Typography className={classes.questionsText}>
                    {quizInfoDetails?.questionCount?.total} Questions
                  </Typography>
                  {quizInfoDetails?.questionCount?.mcq ? (
                    <Typography className={classes.multipleChoiceQuestionsText}>
                      <span className={classes.questionsCount}>
                        {quizInfoDetails?.questionCount?.mcq}{' '}
                      </span>
                      {quizInfoDetails?.questionCount?.mcq > 1 ? (
                        <> Multiple Choice Questions </>
                      ) : (
                        <> Multiple Choice Question</>
                      )}
                    </Typography>
                  ) : null}

                  {quizInfoDetails?.questionCount?.coding ? (
                    <Typography
                      className={classes.codingQuestionsText}
                      data-testid="codingQuestion-testId"
                    >
                      <span className={classes.questionsCount}>
                        {quizInfoDetails?.questionCount?.coding}{' '}
                      </span>
                      {quizInfoDetails?.questionCount?.coding > 1 ? (
                        <> Coding Questions </>
                      ) : (
                        <> Coding Question </>
                      )}
                    </Typography>
                  ) : null}

                  {quizInfoDetails?.questionCount?.fillInTheBlanks ? (
                    <Typography className={classes.codingQuestionsText}>
                      <span className={classes.questionsCount}>
                        {quizInfoDetails?.questionCount?.fillInTheBlanks}{' '}
                      </span>
                      {quizInfoDetails?.questionCount?.fillInTheBlanks > 1 ? (
                        <> Fill In The Blanks Questions </>
                      ) : (
                        <> Fill In The Blank Question</>
                      )}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.bottomContainer}>
          <Grid item className={classes.descriptionContainer}>
            {descriptionViewMoreClicked ? (
              <Typography className={classes.description}>
                {quizInfoDetails?.info?.description}
              </Typography>
            ) : (
              <>
                {' '}
                <Grid item className={classes.descriptionWrapper}>
                  <Typography
                    className={classes.descriptionBeforeViewMore}
                    ref={descriptionRef}
                  >
                    {quizInfoDetails?.info?.description}
                  </Typography>{' '}
                </Grid>
                {showDescriptionViewMore ? (
                  <Button
                    variant="text"
                    className={classes.viewMore}
                    onClick={() => setDescriptionViewMoreClicked(() => true)}
                    data-testid="descriptionButton-testId"
                  >
                    View More
                  </Button>
                ) : null}
              </>
            )}
          </Grid>

          <Grid className={classes.divider} />
          <Typography className={classes.instructionsHeading}>
            Instructions
          </Typography>
          <Grid item className={classes.instructionsContainer}>
            {instructionsViewMoreClicked ? (
              <Typography className={classes.instructions}>
                {convertInstructionsToPoints(
                  quizInfoDetails?.info?.instructions
                )}
              </Typography>
            ) : (
              <>
                <Grid item className={classes.instructionsWrapper}>
                  <Typography
                    className={classes.instructionsBeforeViewMore}
                    ref={instructionsRef}
                  >
                    {convertInstructionsToPoints(
                      quizInfoDetails?.info?.instructions
                    )}
                  </Typography>{' '}
                </Grid>
                {showInstructionsViewMore ? (
                  <Button
                    variant="text"
                    className={classes.viewMore}
                    onClick={() => setInstructionsViewMoreClicked(() => true)}
                    data-testid="instructionButton-testId"
                  >
                    View More
                  </Button>
                ) : null}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.ctaWrapper}>
        <QuizOnboardingPageCTA
          quizInfoDetails={quizInfoDetails}
          setIsLogin={setIsLogin}
          isLogin={isLogin}
          username={username}
          setUsername={setUsername}
          loginMutation={loginMutation}
          handlePublicQuizStart={handlePublicQuizStart}
          isQuizStarted={isQuizStarted}
          setIsQuizStarted={setIsQuizStarted}
          isQuizEnded={isQuizEnded}
        />
      </Grid>
    </Grid>
  )
}

export default QuizOnboardingPageMobile
