/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import {
  ConsoleInstrumentation,
  ViewInstrumentation,
  WebVitalsInstrumentation,
  ErrorsInstrumentation,
  initializeFaro as initFaro,
  ConsoleTransport,
  FetchTransport,
  SessionInstrumentation,
  getWebInstrumentations,
} from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction'
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request'

import { FARO_APP_NAME, FARO_APP_URL } from './constants'

export const initializeFaro = () => {
  if (typeof window !== 'undefined') {
    const BUGSNAG_URL = new RegExp('https://sessions.bugsnag.com/*')
    const ignoreUrls = [FARO_APP_URL, BUGSNAG_URL]
    const backendStageUrlRegex = new RegExp(
      'https://api.staging.testpaper.app/*'
    )
    const backendProdUrlRegex = new RegExp('https://api.testpaper.app/*')
    const propagateTraceHeaderCorsUrls = [
      backendStageUrlRegex,
      backendProdUrlRegex,
    ] // Requests to these URLs will have tracing headers attached.

    const faro = initFaro({
      instrumentations: [
        new ErrorsInstrumentation(),
        new WebVitalsInstrumentation(),
        new ViewInstrumentation(),
        new ConsoleInstrumentation({
          disabledLevels: [],
        }),
        new SessionInstrumentation(),
        ...getWebInstrumentations({
          captureConsole: true,
          captureConsoleDisabledLevels: [],
        }),
        new TracingInstrumentation({
          instrumentations: [
            new DocumentLoadInstrumentation(),
            new FetchInstrumentation({
              ignoreUrls,
              propagateTraceHeaderCorsUrls,
            }),
            new XMLHttpRequestInstrumentation({
              ignoreUrls,
              propagateTraceHeaderCorsUrls,
            }),
            new UserInteractionInstrumentation(),
          ],
          resourceAttributes: {
            'service.name': FARO_APP_NAME,
          },
        }),
      ],
      transports: [
        new FetchTransport({
          url: FARO_APP_URL,
        }),
        new ConsoleTransport(),
      ],
      app: {
        name: FARO_APP_NAME,
        version: '1.0.0',
      },
    })
    return faro
  }
}
