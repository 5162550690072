import axios from 'axios'
import Cookies from 'js-cookie'
import { MOCK_BASE_URL, REFRESH_TOKEN_URL } from '../constants'
import { decodeJwt, getRequestHeader } from '../utils'
import { HttpErrors } from './httpErrors'

export class Fetcher {
  /**
   * this function is to get data using fetch
   * @function refreshAuthData
   ** @param {url} default parameter
   */
  static instance

  static isRefreshing = false

  // constructor() {
  //   // Ensure the cleanup function is called when the instance is destroyed
  //   if (typeof window !== 'undefined') {
  //     window.addEventListener('beforeunload', () => {
  //       this.cleanup()
  //     })
  //   }
  // }

  static getInstance() {
    if (this.instance !== undefined) return this.instance
    this.instance = new Fetcher()
    return this.instance
  }

  refreshAuthData = async () => {
    const url = `${MOCK_BASE_URL + REFRESH_TOKEN_URL}`
    if (this.isRefreshing) {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.isRefreshing) {
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
    }
    this.isRefreshing = true
    const responseData = JSON.parse(sessionStorage.getItem('tokenStorage'))
    const reqData = {
      refreshToken: responseData?.refreshToken,
      tenantID: responseData?.tenants,
    }
    try {
      const response = await axios({
        method: 'post',
        url,
        data: reqData,
        headers: { 'Content-Type': 'application/json' },
      })
      const { accessToken } = response?.data?.data // Assuming the access token is in the response data
      const loggedInUserInfo = decodeJwt(accessToken)
      Cookies.set(`${loggedInUserInfo?.sub}_token`, accessToken)
      sessionStorage.setItem('userID_token', accessToken)
      const result = JSON.parse(sessionStorage.getItem('tokenStorage'))
      result.accessToken = accessToken
      sessionStorage.setItem('tokenStorage', JSON.stringify(result))
      this.isRefreshing = false
    } catch (error) {
      const slug = localStorage.getItem('slug')
      window.location.href = `/quiz/${slug}`
      throw new Error('Error in Getting Refresh token call')
    }
    return null
  }

  /**
   * this function is to get data using fetch
   * @function getData
   ** @param {url} default parameter
   */

  getData = async (url, ct = 1) => {
    try {
      const config = {
        method: 'get',
        url,
        headers: getRequestHeader(),
      }

      const response = await axios(config)
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        try {
          await this.refreshAuthData()
          return this.getData(url, ct + 1)
        } catch (refreshError) {
          console.error('Error refreshing authentication:', refreshError)
          throw refreshError
        }
      }
      throw new HttpErrors(
        error?.response?.data?.errors[0]?.code || 'Error in get call',
        error?.response?.data?.errors[0]?.reason ||
          'Something went wrong while fetching the data',
        error?.response?.status
      )
    }
  }

  /**
   * this function is to update data using fetch
   * @function putData
   ** @param {url,reqBody} default parameter
   */

  putData = async (url, reqData, ct = 1) => {
    try {
      const config = {
        method: 'put',
        url,
        headers: getRequestHeader(),
        data: reqData,
      }

      if (!reqData) {
        delete config.data
      }

      const response = await axios(config)
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        try {
          await this.refreshAuthData()
          return this.putData(url, reqData, ct + 1)
        } catch (refreshError) {
          console.error('Error refreshing authentication:', refreshError)
          throw refreshError
        }
      }
      throw new HttpErrors(
        error?.response?.data?.errors[0]?.code || 'Error in put call',
        error?.response?.data?.errors[0]?.reason ||
          'Something went wrong while sending the data',
        error?.response?.status
      )
    }
  }

  /**
   * this function is to post data using fetch
   * @function postData
   ** @param {url,reqBody} default parameter
   */

  postData = async (url, reqData, ct = 1) => {
    try {
      const config = {
        method: 'post',
        url,
        headers: getRequestHeader(),
        data: reqData,
      }

      if (!reqData) {
        delete config.data
      }

      const response = await axios(config)
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        try {
          await this.refreshAuthData()
          return this.postData(url, reqData, ct + 1)
        } catch (refreshError) {
          console.error('Error refreshing authentication:', refreshError)
          throw refreshError
        }
      }

      throw new HttpErrors(
        error?.response?.data?.errors[0]?.code || 'Error in post call',
        error?.response?.data?.errors[0]?.reason ||
          'Something went wrong while sending the data',
        error?.response?.status
      )
    }
  }

  /**
   * this function is to patch data using fetch
   * @function patchData
   ** @param {url,reqBody} default parameter
   */

  patchData = async (url, reqData, ct = 1) => {
    try {
      const config = {
        method: 'patch',
        url,
        headers: getRequestHeader(),
        data: reqData,
      }

      if (!reqData) {
        delete config.data
      }

      const response = await axios(config)
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        try {
          await this.refreshAuthData()
          return this.patchData(url, reqData, ct + 1)
        } catch (refreshError) {
          console.error('Error refreshing authentication:', refreshError)
          throw refreshError
        }
      }
      throw new HttpErrors(
        error?.response?.data?.errors[0]?.code || 'Error in patch call',
        error?.response?.data?.errors[0]?.reason ||
          'Something went wrong while sending the data',
        error?.response?.status
      )
    }
  }

  /**
   * this function is to delete data using fetch
   * @function deleteData
   ** @param {url} default parameter
   */

  deleteData = async (url, ct = 1) => {
    try {
      const response = await axios({
        method: 'delete',
        url,
        headers: getRequestHeader(),
      })
      return response
    } catch (error) {
      if (error?.response?.status === 401 && ct === 1) {
        try {
          await this.refreshAuthData()
          return this.deleteData(url, ct + 1)
        } catch (refreshError) {
          console.error('Error refreshing authentication:', refreshError)
          throw refreshError
        }
      }
      throw new HttpErrors(
        error?.response?.data?.errors[0]?.code || 'Error in delete call',
        error?.response?.data?.errors[0]?.reason ||
          'Something went wrong while deleting ',
        error?.response?.status
      )
    }
  }
}

export const createFetcher = () => Fetcher.getInstance()
