import React from 'react'

function IndiaFlagIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" viewBox="0 0 900 600">
      <path fill="#f93" d="M0 0H900V600H0z" />
      <path fill="#fff" d="M0 200H900V600H0z" />
      <path fill="#128807" d="M0 400H900V600H0z" />
      <g transform="matrix(4,0,0,4,450,300)">
        <circle r="20" fill="#008" />
        <circle r="17.5" fill="#fff" />
        <circle r="3.5" fill="#008" />
        <g id="d">
          <g id="c">
            <g id="b">
              <g id="a" fill="#008">
                <circle transform="rotate(7.5,-8.75,133.4992)" r=".875" />
                <path d="M 0,17.5 0.6,7 C 0.6,7 0,2 0,2 0,2 -0.6,7 -0.6,7 Z" />
              </g>
              <use
                height="100%"
                width="100%"
                transform="rotate(15)"
                xlinkHref="#a"
              />
            </g>
            <use
              height="100%"
              width="100%"
              transform="rotate(30)"
              xlinkHref="#b"
            />
          </g>
          <use
            height="100%"
            width="100%"
            transform="rotate(60)"
            xlinkHref="#c"
          />
        </g>
        <use
          height="100%"
          width="100%"
          transform="rotate(120)"
          xlinkHref="#d"
        />
        <use
          height="100%"
          width="100%"
          transform="rotate(-120)"
          xlinkHref="#d"
        />
      </g>
    </svg>
  )
}

export default IndiaFlagIcon
