import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  contentWrapper: {
    boxSizing: 'border-box',
    overflowY: 'auto',
    height: 'calc(100% - 46px)',
    width: '100%',
    background: theme?.palette?.darkBackground?.main,
  },
  submitFailedSnackBar: { background: 'crimson', color: 'whitesmoke' },
  content: {
    width: '100%',
    height: 'max-content',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingTop: '31px',
    '@media only screen and (min-width:426px)': {
      paddingRight: '50px',
      paddingLeft: '50px',
    },
    '@media only screen and (min-width:769px)': {
      width: '65%',
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  submitSuccessSnackBar: {
    background: theme?.palette?.primarySelection?.main,
    color: 'white',
  },

  questionNavigator: {
    boxSizing: 'border-box',

    background: '#F0F0F0',

    width: '100%',
    height: '2.5rem',
  },

  questionDetailsWrapper: {
    width: '100%',
    marginBottom: '8px',
  },
  approvedIconContainer: {
    width: 'max-content',
  },
  answeredIcon: {
    marginRight: '0.3rem',
    width: '17.44px',
    height: '16.86px',
  },
  answeredText: {
    color: theme?.palette?.grayTextColor?.main,
    fontSize: '11px',
  },
  questionWrapper: {
    marginBottom: '24px',
  },
  question: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '22px',
    fontSize: '20px',
    '& > *': {
      overflowWrap: 'break-word',
      msWordBreak: 'break-all',
      wordBreak: 'break-word',
      msHyphens: 'auto',
      MozHyphens: 'auto',
      WebkitHyphens: 'auto',
      hyphens: 'auto',
      whiteSpace: 'pre-wrap',
      margin: 0,
      padding: 0,
      listStylePosition: 'inside',
    },
    '& > * > img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    '& > *:nth-child(2)': {
      display: 'inline',
    },
    '& > pre': {
      background: 'black',
      color: 'white',
      padding: '5px',
    },
  },
  questionDisplay: {
    display: 'inline',
  },
  choiceWrapper: {
    boxSizing: 'border-box',
    width: '100%',
  },
  buttonWrapper: {
    boxSizing: 'border-box',
    height: '46px',
    padding: '5px',
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
    borderTop: `solid 1px ${theme?.palette?.borders?.main}`,
  },
  prevAndNextButtonWrapper: {
    height: '100%',
    marginRight: 'auto',
    width: 'max-content',
  },
  prevAndClearButtonWrapper: {
    height: '100%',
    marginLeft: 'auto',
    width: 'max-content',
  },
  backButton: {
    background: theme?.palette?.secondaryButton?.main,
    color: theme?.palette?.secondaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
    padding: '0 1rem',
    height: '100%',
    fontWeight: theme?.palette?.secondaryButton?.fontWeight,
  },
  clearSelectionButton: {
    background: theme?.palette?.tertiaryButton?.main,
    color: theme?.palette?.tertiaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.tertiaryButton?.main}`,
    width: '85px',
    height: '100%',
    fontWeight: theme?.palette?.tertiaryButton?.fontWeight,
    marginRight: '0.3rem',
  },
  nextButton: {
    height: '100%',
  },
  saveButton: {
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
    // width: '99px',
    height: '100%',
    width: '85px',
    fontWeight: theme?.palette?.primaryButton?.fontWeight,
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
  },
  saveButtonWrapper: {
    paddingRight: '12px',
    height: '100%',
  },
  continueButton: {
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
    width: (props) => {
      if (props?.editorWrapperWidth > 319) {
        return '148px'
      }
      return '108px'
    },
    height: '100%',
    fontWeight: theme?.palette?.primaryButton?.fontWeight,
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
  },
}))
export { useStyles }
