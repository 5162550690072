/* eslint-disable no-nested-ternary */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './ResultNormalTestCaseStyles'

function ResultNormalTestcase({ testcase, index, maximumMarks }) {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      <Grid
        container
        justify="space-between"
        className={classes.testCaseWrapper}
      >
        <Typography className={classes.testcaseAndIndex}>
          Test case {index + 1}
        </Typography>
        {maximumMarks ? (
          <Typography>
            <span
              className={classes.marksDisplay}
              data-testid="maxMarks-testId"
            >
              0/{maximumMarks}
            </span>{' '}
            Marks
          </Typography>
        ) : (
          <Typography>
            <span
              className={classes.marksDisplay}
              data-testid="maxZeroMArks-testId"
            >
              0
            </span>{' '}
            Marks
          </Typography>
        )}
      </Grid>
      <Grid item className={classes.resultContainer}>
        <Grid container direction="column" className={classes.resultWrapper}>
          <Grid container direction="column" className={classes.inputWrapper}>
            <Typography className={classes.inputHeading}>Input:</Typography>
            <Typography className={classes.inputText}>
              {typeof testcase?.input === 'string'
                ? testcase?.input
                : testcase?.input?.length > 0
                ? testcase?.input?.join(',')
                : testcase?.input?.[0]}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.expectedOutputWrapper}
          >
            <Typography className={classes.expectedOutputHeading}>
              Expected Output:
            </Typography>
            <Typography className={classes.expectedOutputText}>
              {testcase?.output}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResultNormalTestcase
