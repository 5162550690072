import React, { useRef, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import useWindowSize from '../../hooks/useWindowSize'
import { useStyles } from './TestVideoDialogStyles'

function TestVideoDialog({
  candidateStream,
  openTestVideoDialog,
  setOpenTestVideoDialog,
}) {
  const candidateStreamRef = useRef(null)
  const windowSize = useWindowSize()
  const videoHeight = windowSize?.height * 0.6
  const videoWidth = windowSize?.width * 0.7

  const classes = useStyles({ candidateStreamRef, videoHeight, videoWidth })

  useEffect(() => {
    if (candidateStream === null) {
      return
    }

    if (candidateStreamRef.current === null) return

    candidateStreamRef.current.srcObject = candidateStream
  }, [candidateStream, candidateStreamRef.current])

  return (
    <Dialog
      open={openTestVideoDialog}
      onClose={() => setOpenTestVideoDialog(false)}
      classes={{ paper: classes.paper }}
      maxWidth="xl"
    >
      <DialogActions className={classes.dialogActions}>
        <IconButton
          data-testid="render-test-video-icon"
          onClick={() => setOpenTestVideoDialog(false)}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </DialogActions>

      <DialogContent className={classes.dialogContent}>
        <Grid className={classes.contentBoxWrapper}>
          {!candidateStreamRef.current && (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.loadingWrapper}
            >
              <Typography className={classes.loading}>Loading....</Typography>
            </Grid>
          )}

          <Grid className={classes.videoWrapper}>
            <video
              ref={candidateStreamRef}
              height="100%"
              width="100%"
              autoPlay
              muted
              controls
              disablePictureInPicture
              className={classes.video}
              data-testid="video-testId"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default TestVideoDialog
