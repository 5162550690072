import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
    borderRadius: 4,
  },
  iconWrapper: {
    padding: '15px',
    width: 'max-content',
  },
  icon: {
    height: '24px',
    width: '24px',
  },
  contentWrapper: {
    flex: 1,
    paddingRight: '20px',
    '@media only screen and (min-width:321px)': {
      paddingRight: '26px',
    },
    '@media only screen and (min-width:426px)': {
      paddingRight: '32px',
    },
  },
  questionWrapper: {
    paddingTop: '15px',
    paddingBottom: '12.5px',
  },
  question: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '22px',
    fontSize: '16px',
    '& > *': {
      overflowWrap: 'break-word',
      msWordBreak: 'break-all',
      wordBreak: 'break-word',
      msHyphens: 'auto',
      MozHyphens: 'auto',
      WebkitHyphens: 'auto',
      hyphens: 'auto',
      whiteSpace: 'pre-wrap',
      margin: 0,
      padding: 0,
      listStylePosition: 'inside',
    },
    '& > * > img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    '& > pre': {
      background: 'black',
      color: 'white',
      padding: '5px',
    },
  },

  selectedChoiceWrapper: {
    width: '100%',
    padding: '9.5px 0px 9.5px 0px',
    borderTop: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  selectedChoiceHeading: {
    fontSize: '12px',
    color: theme?.palette?.resultsPageLightTextColor?.main,
    marginBottom: '4px',
  },
  selectedChoice: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '19px',
  },
  correctChoiceWrapper: {
    width: '100%',
    padding: '9.5px 0px 9.5px 0px',
    borderTop: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  correctChoiceHeading: {
    fontSize: '12px',
    color: theme?.palette?.resultsPageLightTextColor?.main,
    marginBottom: '4px',
  },
  correctChoice: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '19px',
  },
  marksContainer: {
    padding: '10px',
    width: '100%',
    borderTop: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  marksWrapper: {
    background: theme?.palette?.darkBackground?.main,
    height: '38px',
    borderRadius: 4,
  },
  marks: {
    fontWeight: 600,
  },
  marksHeading: {
    fontSize: '12px',
  },
}))
export { useStyles }
