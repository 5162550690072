import React from 'react'

function ShortlistIcon() {
  return (
    <svg viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Shortlist"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-20">
          <rect
            id="Rectangle-Copy-12"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="40"
            height="40"
          />
          <g
            id="Group-6"
            transform="translate(2.000000, 4.000000)"
            fillRule="nonzero"
          >
            <path
              d="M21.0413793,20.2275862 C25.923182,20.2275862 29.8971707,24.1143999 30.0375422,28.962224 L30.0413793,29.2275862 L30.0413793,32.7586207 L28.0413793,32.7586207 L28.0413793,29.2275862 C28.0413793,25.4421345 25.0365959,22.3584875 21.2820291,22.2316452 L21.0413793,22.2275862 L9,22.2275862 C5.21454828,22.2275862 2.13090132,25.2323696 2.00405902,28.9869365 L2,29.2275862 L2,32.7586207 L0,32.7586207 L0,29.2275862 C0,24.3457835 3.88681368,20.3717948 8.73463775,20.2314233 L9,20.2275862 L21.0413793,20.2275862 Z"
              id="Path-10"
              fill="#000000"
            />
            <path
              d="M15.5448276,0 C20.1040334,0 23.8,3.63730045 23.8,8.12413793 C23.8,12.6109754 20.1040334,16.2482759 15.5448276,16.2482759 C10.9856218,16.2482759 7.28965517,12.6109754 7.28965517,8.12413793 C7.28965517,3.63730045 10.9856218,0 15.5448276,0 Z M15.5448276,2 C12.0830917,2 9.28965517,4.74909626 9.28965517,8.12413793 C9.28965517,11.4991796 12.0830917,14.2482759 15.5448276,14.2482759 C19.0065635,14.2482759 21.8,11.4991796 21.8,8.12413793 C21.8,4.74909626 19.0065635,2 15.5448276,2 Z"
              id="Oval"
              fill="#000000"
            />
            <polygon
              id="Path-11"
              fill="#00B7A4"
              points="35.3073159 9.23737954 36.6926841 10.6798618 28.1095558 18.9231465 23.538781 14.8188864 24.8750121 13.3307713 28.063 16.193"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ShortlistIcon
