import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './CustomTestCaseStyles'

function CustomTestCase({ testcase, customInput }) {
  const classes = useStyles()

  return (
    <Grid
      data-testid="render-custom-test-case"
      container
      direction="column"
      className={classes.customTestCaseWrapper}
    >
      <Typography variant="body2" className={classes.customTestCaseMsg}>
        Finished in {(testcase?.duration / 1000).toFixed(2)} seconds
      </Typography>
      <Grid container direction="column" className={classes.resultContainer}>
        <Grid item className={classes.resultWrapper}>
          <Typography className={classes.resultHeading}>Input:</Typography>
          <Typography className={classes.resultText}>{customInput}</Typography>
        </Grid>
        <Grid item className={classes.resultWrapper}>
          <Typography className={classes.resultHeading}>Output:</Typography>
          <Typography className={classes.resultText}>
            {testcase?.outputLines?.length > 0 ? (
              <>{testcase?.outputLines?.join('\n')}</>
            ) : (
              <>Your code didn&#39;t output anything</>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomTestCase
