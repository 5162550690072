import React from 'react'

function SettingsIcon() {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Quiz-screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-30">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <path
            d="M13.642407,4 C13.8479648,4 14.0165222,4.148 14.0535226,4.336 L14.0535226,4.336 L14.3618594,6.456 C14.8634205,6.66 15.3279811,6.928 15.7514302,7.244 L15.7514302,7.244 L17.7987861,6.44 C17.9837882,6.368 18.1975683,6.44 18.3003472,6.612 L18.3003472,6.612 L19.9448098,9.384 C20.0475887,9.56 20.002366,9.776 19.846142,9.896 L19.846142,9.896 L18.1071229,11.22 C18.1400121,11.476 18.1646791,11.736 18.1646791,12 C18.1646791,12.264 18.1400121,12.524 18.1071229,12.78 L18.1071229,12.78 L19.8420309,14.104 C19.9982548,14.228 20.0434776,14.444 19.9406986,14.616 L19.9406986,14.616 L18.2962361,17.388 C18.1934572,17.56 17.979677,17.628 17.794675,17.56 L17.794675,17.56 L15.7473191,16.756 C15.32387,17.076 14.8593093,17.34 14.3577482,17.544 L14.3577482,17.544 L14.0494115,19.664 C14.0165222,19.852 13.8479648,20 13.642407,20 L13.642407,20 L10.3534818,20 C10.147924,20 9.97936661,19.852 9.94647736,19.664 L9.94647736,19.664 L9.63814063,17.544 C9.13657955,17.34 8.67201887,17.072 8.24856976,16.756 L8.24856976,16.756 L6.20121385,17.56 C6.01621181,17.632 5.80243168,17.56 5.69965277,17.388 L5.69965277,17.388 L4.0551902,14.616 C3.95241129,14.44 3.99763401,14.224 4.15385795,14.104 L4.15385795,14.104 L5.88876596,12.78 C5.85587671,12.524 5.83120977,12.264 5.83120977,12 C5.83120977,11.736 5.85587671,11.476 5.88876596,11.22 L5.88876596,11.22 L4.15385795,9.896 C3.99763401,9.772 3.95241129,9.556 4.0551902,9.384 L4.0551902,9.384 L5.69965277,6.612 C5.80243168,6.44 6.01621181,6.372 6.20121385,6.44 L6.20121385,6.44 L8.24856976,7.244 C8.67201887,6.924 9.13657955,6.66 9.63814063,6.456 L9.63814063,6.456 L9.94647736,4.336 C9.97936661,4.148 10.147924,4 10.3534818,4 L10.3534818,4 Z M11.9979444,9.2 C10.4069269,9.2 9.12013492,10.452 9.12013492,12 C9.12013492,13.548 10.4069269,14.8 11.9979444,14.8 C13.588962,14.8 14.8757539,13.548 14.8757539,12 C14.8757539,10.452 13.588962,9.2 11.9979444,9.2 Z"
            id="Combined-Shape"
            fill="#262B4B"
            fillRule="nonzero"
            opacity="0.699999988"
          />
        </g>
      </g>
    </svg>
  )
}

export default SettingsIcon
