import React from 'react'
// import { Link } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import HomePageBannerIllustration from '../../Assets/Illustrations/HomePageBannerIllustration'
import CreateIcon from '../../Icons/CreateIcon'
import ConductIcon from '../../Icons/ConductIcon'
import ShortlistIcon from '../../Icons/ShortlistIcon'
import FeatureOneIllustration from '../../Assets/Illustrations/FeatureOneIllustration'

import useWindowSize from '../../hooks/useWindowSize'
import HomePageHeader from '../HomePageHeader/HomePageHeader'
import HomePageFooter from '../HomePageFooter/HomePageFooter'
import { ADMIN_URL } from '../../constants'
import { useStyles } from './HomePageStyles'

function HomePage() {
  const windowSize = useWindowSize()

  const isWidthAboveTabRange = useMediaQuery(
    'only screen and (min-width:769px)'
  )
  const classes = useStyles()
  return (
    <div
      data-testid="render-home-page"
      className={classes.root}
      style={{
        height: `${windowSize?.height}px`,
      }}
    >
      <div className={classes.homePageHeaderWrapper}>
        <HomePageHeader />
      </div>

      <div className={classes.homePageWrapper}>
        <div className={classes.homePageContainer}>
          <div className={classes.mainContentContainer}>
            <div className={classes.mainContentWrapper}>
              <div className={classes.mainContentLeftBox}>
                <Typography className={classes.mainContentHeading}>
                  Quizzing,
                  <br />
                  Made Easy
                </Typography>

                <Typography className={classes.mainContentSubtitle}>
                  Create and conduct quizzes in no time
                </Typography>
                <div>
                  <a
                    href={`${ADMIN_URL}/login`}
                    className={classes.createQuizButton}
                  >
                    <Button className={classes.mainContentButton}>
                      Create a Quiz
                    </Button>
                  </a>
                </div>
              </div>
              <div className={classes.mainContentIconWrapper}>
                <HomePageBannerIllustration />
              </div>
            </div>
          </div>

          <div className={classes.stepsContainer}>
            <div className={classes.stepsWrapper}>
              <div className={classes.stepWrapper}>
                <div className={classes.stepIconAndWingWrapper}>
                  {isWidthAboveTabRange ? (
                    <div className={classes.emptyStepWing} />
                  ) : null}
                  <div className={classes.stepIconWrapper}>
                    <div className={classes.stepIcon}>
                      <CreateIcon />
                    </div>
                  </div>
                  <div className={classes.stepWing} />
                </div>
                <div className={classes.stepHeadingAndDescriptionWrapper}>
                  <Typography className={classes.stepHeading}>
                    Create
                  </Typography>
                  <div className={classes.stepDescription}>
                    Create a quiz right from scratch targeted at any job role in
                    minutes.
                  </div>
                </div>
              </div>
              <div className={classes.stepWrapper}>
                <div className={classes.stepIconAndWingWrapper}>
                  {isWidthAboveTabRange ? (
                    <div className={classes.stepWing} />
                  ) : null}
                  <div className={classes.stepIconWrapper}>
                    <div className={classes.stepIcon}>
                      <ConductIcon />
                    </div>
                  </div>
                  <div className={classes.stepWing} />
                </div>
                <div className={classes.stepHeadingAndDescriptionWrapper}>
                  <Typography className={classes.stepHeading}>
                    Conduct
                  </Typography>
                  <div className={classes.stepDescription}>
                    Conduct a quiz and test proficiency of a candidate in
                    multiple topics and languages.
                  </div>
                </div>
              </div>
              <div className={classes.stepWrapper}>
                <div className={classes.stepIconAndWingWrapper}>
                  {isWidthAboveTabRange ? (
                    <div className={classes.stepWing} />
                  ) : null}
                  <div className={classes.stepIconWrapper}>
                    <div className={classes.stepIcon}>
                      <ShortlistIcon />
                    </div>
                  </div>
                  <div className={classes.emptyStepWing} />
                </div>
                <div
                  className={classes.stepHeadingAndDescriptionWrapper}
                  style={{ paddingBottom: 0 }}
                >
                  <Typography className={classes.stepHeading}>
                    Shortlist
                  </Typography>
                  <div className={classes.stepDescription}>
                    Shortlist candidates based on cutoff scores and view their
                    perfomance.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.featuresContainer}>
            <Typography className={classes.featuresHeading}>
              Key Features
            </Typography>
            <div className={classes.featuresHeadingDividerWrapper}>
              <div className={classes.featuresHeadingDivider} />
            </div>
            <div className={classes.featuresWrapper}>
              <div className={classes.featureWrapper}>
                <div className={classes.featureLeftOrTopContentContainer}>
                  <div className={classes.featureIconWrapper}>
                    <FeatureOneIllustration />{' '}
                  </div>
                </div>

                <div className={classes.featureRightOrBottomContentContainer}>
                  <div className={classes.featureContentWrapper}>
                    <div className={classes.featureIndex}>1</div>

                    <Typography className={classes.featureHeading}>
                      Hassle-free Quiz Management
                    </Typography>
                    <div className={classes.featureDescriptionWrapper}>
                      <Typography className={classes.featureDescription}>
                        We have a simple dashboard to manage all your quizzes.
                        Create, Edit and Publish Quizzes with multiple question
                        types. You can also configure who can attempt your
                        quizzes. All quiz submissions are automatically
                        evaluated.
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.featureTypeTwoWrapper}>
                <div
                  className={classes.featureTypeTwoLeftOrBottomContentContainer}
                >
                  <div className={classes.featureContentWrapper}>
                    <div className={classes.featureIndex}>2</div>

                    <Typography className={classes.featureHeading}>
                      Easy quiz taking experience
                    </Typography>
                    <div className={classes.featureDescriptionWrapper}>
                      <Typography className={classes.featureDescription}>
                        Intuitive interface allows candidates to login and
                        attempt a quiz with just a single click. Candidates can
                        navigate through different sections of a quiz and review
                        their answers before submission.
                      </Typography>
                    </div>
                  </div>
                </div>

                <div
                  className={classes.featureTypeTwoRightOrTopContentContainer}
                >
                  <div className={classes.featureIconWrapper}>
                    <img src="/icons/feature-two.svg" alt="Feature Two" />
                  </div>
                </div>
              </div>

              <div className={classes.featureWrapper}>
                <div className={classes.featureLeftOrTopContentContainer}>
                  <div className={classes.featureIconWrapper}>
                    <img src="/icons/feature-three.svg" alt="Feature Three" />
                  </div>
                </div>

                <div className={classes.featureRightOrBottomContentContainer}>
                  <div className={classes.featureContentWrapper}>
                    <div className={classes.featureIndex}>3</div>

                    <Typography
                      className={classes.featureHeading}
                      style={{ maxWidth: '20ch' }}
                    >
                      Test Coding Skills
                    </Typography>
                    <div className={classes.featureDescriptionWrapper}>
                      <Typography className={classes.featureDescription}>
                        Create and evaluate a coding question in multiple
                        languages. User friendly code editor with themes and
                        syntax highlighting.
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classes.featureTypeTwoWrapper}
                style={{ paddingBottom: 0 }}
              >
                <div
                  className={classes.featureTypeTwoLeftOrBottomContentContainer}
                >
                  <div className={classes.featureContentWrapper}>
                    <div className={classes.featureIndex}>4</div>

                    <Typography className={classes.featureHeading}>
                      Live Online Proctoring
                    </Typography>
                    <div className={classes.featureDescriptionWrapper}>
                      <Typography className={classes.featureDescription}>
                        Now quizzes can be conducted in a live proctored
                        environment. This ensures candidates integrity avoiding
                        any form of malpractice.
                      </Typography>
                    </div>
                  </div>
                </div>

                <div
                  className={classes.featureTypeTwoRightOrTopContentContainer}
                >
                  <div className={classes.featureIconWrapper}>
                    <img src="/icons/feature-four.svg" alt="Feature Four" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.lastSectionContainer}>
            <div className={classes.lastSectionWrapper}>
              <div className={classes.lastSectionContentWrapper}>
                <Typography className={classes.lastSectionContent}>
                  Quizzing in a virtual setup is made easier than ever!
                </Typography>
              </div>
              <div className={classes.lastSectionButtonWrapper}>
                <div className={classes.lastSectionCreateQuizWrapper}>
                  <a
                    href={`${ADMIN_URL}/login`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button className={classes.lastSectionCreateQuizButton}>
                      Create a Quiz
                    </Button>
                  </a>
                </div>

                {/* <div className={classes.lastSectionViewPricingWrapper}>
                  <Link to="/pricing" style={{ textDecoration: 'none' }}>
                    <Button className={classes.lastSectionViewPricingButton}>
                      View Pricing
                    </Button>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>

          <HomePageFooter />
        </div>
      </div>
    </div>
  )
}

export default HomePage
