import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  hiddenTestCaseWrapper: {
    width: '100%',
  },
  testCaseMsg: { width: '100%', marginBottom: '8px' },
  finishMsg: {
    width: '100%',
    marginBottom: '10px',
  },
  testcasePassed: {
    fontWeight: 'bold',
    color: theme?.palette?.primarySelection?.main,
  },
  testcasePassedIcon: {
    fontSize: '1rem',
    color: theme?.palette?.primarySelection?.main,
    marginLeft: '0.2rem',
  },
  testcaseFailed: {
    fontWeight: 'bold',
    color: theme?.palette?.errorColor?.main,
  },
  testcaseFailedIcon: {
    fontSize: '1rem',
    color: theme?.palette?.errorColor?.main,
    marginLeft: '0.2rem',
  },
  resultContainer: {
    width: '100%',
    borderRadius: '4px',
    background: theme?.palette?.darkBackground?.main,
  },
  iconContainer: {
    padding: '16px 0px 16px 16px',
    marginRight: '5px',
  },
  iconWrapper: {
    width: '24px',
  },
  hiddenTestcaseText: {
    padding: '20px 0',
  },
}))
export { useStyles }
