import {
  MOCK_BASE_URL,
  CANDIDATE_ENDPOINT,
  COMPILE_ENDPOINT,
} from '../../constants'
import { createFetcher } from '../../Fetcher/Fetcher'

/**
 * this function is to make url according to request parameters
 * @function urlBuilder
 ** @param {url, reqParam} default parameter
 */

export const urlBuilder = () => {}
/**
 * Creates a new Quiz.
 * @Applications
 */
export class Compile {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Compile(
        MOCK_BASE_URL,
        CANDIDATE_ENDPOINT,
        COMPILE_ENDPOINT,
        createFetcher()
      )
    }
    return this.instance
  }

  constructor(baseUrl, candidateEndpoint, endpoint, fetcherObj) {
    this.base_url = baseUrl
    this.candidateEndpoint = candidateEndpoint
    this.endpoint = endpoint
    this.fetcher = fetcherObj
  }
  /**
   * this function is to get all applications
   * @function postCompile
   ** @param {reqParam} default parameter
   */

  async postCompile(reqData) {
    const url = `${this.base_url}${this.candidateEndpoint}${this.endpoint}`

    const response = await this.fetcher.postData(url, reqData)

    if (response?.data?.errors?.length >= 0) {
      return response?.data
    }
    return response?.data?.data
  }

  /**
   * this function is to get all applications
   * @function getCompile
   ** @param {reqParam} default parameter
   */

  async getCompile(reqId) {
    if (reqId) {
      const url = `${this.base_url}${this.candidateEndpoint}${this.endpoint}/${reqId}`
      const response = await this.fetcher.getData(url)
      return response?.data?.data
    }
    return 'Missing reqId'
  }
}

export const codeRun = () => Compile.getInstance()
