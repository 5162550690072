import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    background: theme?.palette?.darkBackground?.main,
    overflow: 'hidden',
  },
  header: {
    background: theme?.palette?.header?.main,
    height: '46px',
    width: '100%',
    paddingLeft: '1rem',
  },
  closeIconWrapper: {
    height: '100%',
    width: 'max-content',
    marginLeft: 'auto',
  },
  closeIcon: {
    background: '#393F5C',
    borderRadius: '1px',
    width: '46px',
    color: 'white',
    height: '100%',
  },
  closeIconStyle: {
    background: '#FFFFF',
  },
  appBarCloseButton: {
    color: 'whitesmoke',
    '&:hover': { color: theme.palette.errorColor.main },
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },

  resultPageDialog: {
    background: theme?.palette?.darkBackground?.main,
    width: '100%',
  },

  marksWrapper: {
    width: '100%',
    height: '54px',
    background: theme?.palette?.lightBackground?.main,
    borderBottom: `solid 1px ${theme?.palette?.dividerLines?.main}`,
    '@media only screen and (min-width:426px)': {
      height: '76px',
    },
    '@media only screen and (min-width:769px)': {
      height: '96px',
    },
  },

  evaluatedMarks: {
    fontSize: '16px',
    fontWeight: 600,
    '@media only screen and (min-width:426px)': {
      fontSize: '20px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '30px',
    },
  },

  resultPageWrapper: {
    height: 0,
    flex: 1,
    width: '100%',
  },
}))
export { useStyles }
