import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import { faro } from '@grafana/faro-web-sdk'

const localizedFormat = require('dayjs/plugin/localizedFormat')

export const areCookiesEnabled = () => {
  let cookieEnabled = !!navigator?.cookieEnabled

  if (typeof navigator?.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie'
    cookieEnabled = document?.cookie.indexOf('testcookie') !== -1
  }
  return cookieEnabled
}

export const htmlToString = (html) => {
  const divContainer = document.createElement('div')
  divContainer.innerHTML = html
  return divContainer.textContent || divContainer.innerText || ''
}
export const returnErrors = (errorCode) => {
  if (
    errorCode?.toUpperCase() === 'ENTITY_NOT_FOUND' ||
    errorCode?.toUpperCase() === 'BAD_REQUEST'
  ) {
    return 'QUIZ_NOT_FOUND'
  }
  if (
    errorCode?.toUpperCase() === 'FORBIDDEN' ||
    errorCode?.toUpperCase() === 'FORBIDDEN'
  ) {
    return 'ACCESS_DENIED'
  }
  if (
    errorCode?.toUpperCase() === 'DB_ERROR' ||
    errorCode?.toUpperCase() === 'INTERNAL_SERVER_ERROR'
  ) {
    return 'INTERNAL_SERVER_ERROR'
  }
  if (
    errorCode?.toUpperCase() === 'SUBMISSION_EXISTS' ||
    errorCode?.toUpperCase() === 'CONFLICT'
  ) {
    return 'QUIZ_ALREADY_SUBMITTED'
  }
  if (errorCode?.toUpperCase() === 'REDIRECT') {
    return 'REDIRECT'
  }
  return 'PAGE_NOT_FOUND'
}

export const convertInstructionsToPoints = (instructions) => {
  let pointsString = ''
  instructions?.split('\n')?.forEach((instruction, index) => {
    pointsString += `${index + 1}. ${instruction}\n\n`
  })
  return pointsString
}

export const getRequestHeader = () => {
  const token = JSON.parse(sessionStorage.getItem('tokenStorage'))
  if (token?.accessToken) {
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token?.accessToken}`,
    }
  }
  return { 'Content-Type': 'application/json' }
}

export const convertISOToLocalDateTime = (dateTime) => {
  dayjs.extend(localizedFormat)
  return dayjs(dateTime)?.format('lll')
}

export const isDateTimeInThePast = (startTime) => {
  return new Date() >= new Date(dayjs(startTime))
}

export const isDateTimeInTheFuture = (endTime) => {
  return new Date() > new Date(dayjs(endTime))
}

export const getSecondsRemainingFromDateTimeToNow = (dateTime) => {
  return (new Date(dayjs(dateTime)) - new Date()) / 1000
}

export const aceModesForLanguages = (language) => {
  if (language === 'c' || language === 'cpp') return 'c_cpp'
  return language
}

export const isLoggedIn = () => {
  if (areCookiesEnabled())
    return sessionStorage.getItem('isLoggedIn') === 'true'
  return false
}

export const getQuestionById = (quiz, currentQuestion) => {
  const returnQuestion = quiz?.questions?.filter((item) => {
    return item?.id === currentQuestion?.id
  })

  return returnQuestion?.[0]
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms))

export const secondsToMinutes = (secs) => {
  const minutes = Math.floor(secs / 60)
  let finalTimeFormat = ''
  if (minutes >= 1) {
    finalTimeFormat += `${minutes} minutes`
  } else {
    finalTimeFormat += `${secs} seconds`
  }

  return finalTimeFormat
}

export const secondsToTime = (secs) => {
  let hours = Math.floor(secs / (60 * 60))
  if (hours < 10) {
    hours = `0${hours}`
  }

  const divisorForMinutes = secs % (60 * 60)
  let minutes = Math.floor(divisorForMinutes / 60)
  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  const divisorForSeconds = divisorForMinutes % 60
  let seconds = Math.ceil(divisorForSeconds)
  if (seconds < 10) {
    seconds = `0${seconds}`
  }

  const time = `${hours}:${minutes}:${seconds}`

  return time
}

export const pricingTableData = {
  data: [
    {
      id: 1,
      title: 'Public Quiz',
      subHeading: ' Create & share instantly',
      amount: 'Free',
      amountSubHeading: 'For unlimited users',
      features: [
        {
          description: 'Surveys & Guest mode quizzes',
        },
        {
          description: 'Multiple choice questions',
        },
        {
          description: 'True & False questions',
        },
        {
          description: 'Tab switch count',
        },
        {
          description: 'Candidate guest login',
        },
        {
          description: 'Instant results',
        },
      ],
    },
    {
      id: 2,
      title: 'Private Quiz ',
      subHeading: 'Tests on the go',
      amount: '1C',
      amountSubHeading: 'Per quiz attempt',
      features: [
        {
          description: 'Interviews & assessments',
        },
        {
          description: 'Multiple choice questions',
        },
        {
          description: 'True & False questions',
        },
        {
          description: 'Tab switch count',
        },
        {
          description: 'Candidate Google OAuth login',
        },
        {
          description: 'Instant results',
        },
        {
          description: 'Invite specific candidates',
        },
      ],
    },
    {
      id: 3,
      title: 'Private Quiz',
      subHeading: 'w/ Coding questions',
      amount: '3C',
      amountSubHeading: 'Per quiz attempt',
      features: [
        {
          description: 'Interviews & assessments',
        },
        {
          description: 'Multiple choice questions',
        },
        {
          description: 'True & False questions',
        },
        {
          description: 'Tab switch count',
        },
        {
          description: 'Candidate Google OAuth login',
        },
        {
          description: 'Instant results',
        },
        {
          description: 'Invite specific candidates',
        },
        {
          description: 'Coding questions',
        },
        {
          description: 'Six programming languages',
        },
      ],
    },
    {
      id: 4,
      title: 'Private Quiz',
      subHeading: 'w/ Proctoring',
      amount: '5C',
      amountSubHeading: 'Per quiz attempt',
      features: [
        {
          description: 'Interviews & assessments',
        },
        {
          description: 'Multiple choice questions',
        },
        {
          description: 'True & False questions',
        },
        {
          description: 'Tab switch count',
        },
        {
          description: 'Candidate Google OAuth login',
        },
        {
          description: 'Instant results',
        },
        {
          description: 'Invite specific candidates        ',
        },
        {
          description: 'Proctoring enabled',
        },
        {
          description: 'Real-time monitoring',
        },
      ],
    },
    {
      id: 5,
      title: 'Private Quiz',
      subHeading: 'w/ Proctoring & Coding questions',
      amount: '7C',
      amountSubHeading: 'Per quiz attempt',
      features: [
        {
          description: 'Interviews & assessments',
        },
        {
          description: 'Multiple choice questions',
        },
        {
          description: 'True & False questions',
        },
        {
          description: 'Tab switch count',
        },
        {
          description: 'Candidate Google OAuth login',
        },
        {
          description: 'Instant results',
        },
        {
          description: 'Invite specific candidates        ',
        },
        {
          description: 'Proctoring enabled',
        },
        {
          description: 'Real-time monitoring',
        },
        {
          description: 'Coding questions',
        },
        {
          description: 'Six programming languages',
        },
      ],
    },
  ],
}

export const questionsCount = (QuestionArr) => {
  const QuestionArrResult = QuestionArr?.map((queCnt) => {
    if (QuestionArr?.length !== 0) {
      return queCnt.count
    }
    return 0
  })
  return QuestionArrResult?.reduce(
    (total, currentValue) => total + currentValue,
    0
  )
}

export const decodeJwt = (jwtToken) => {
  try {
    const decodedInformation = jwtDecode(jwtToken)
    return decodedInformation
  } catch (error) {
    if (faro && faro.api && faro.api.pushError) {
      faro.api.pushError(error)
    }
    return null
  }
}
