import React, { useState, useEffect, useRef, useContext } from 'react'
import { useParams } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useResizeDetector } from 'react-resize-detector'
import { Snackbar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import HelpIcon from '@material-ui/icons/Help'

import LockIcon from '../../Icons/LockIcon'
import PreviousIcon from '../../Icons/PreviousIcon'
import RestoreIcon from '../../Icons/RestoreIcon'
import SettingsIcon from '../../Icons/SettingsIcon'
import LightThemeIcon from '../../Icons/LightThemeIcon'
import DarkThemeIcon from '../../Icons/DarkThemeIcon'

import CustomSelect from '../CustomSelect/CustomSelect'
import CodeEditor from '../CodeEditor/CodeEditor'
import ConsoleHeader from '../ConsoleHeader/ConsoleHeader'
import ConsoleBody from '../ConsoleBody/ConsoleBody'
import EditorSettingsDialog from '../EditorSettingsDialog/EditorSettingsDialog'
import ConfirmCodeRestoreOrRetrieveDialog from '../ConfirmCodeRestoreOrRetrieveDialog/ConfirmCodeRestoreOrRetrieveDialog'
import {
  useCompilePostQuery,
  useCompileGetQuery,
} from '../../common/Queries/compileQueries'
import {
  // useUserResponseGetQueryByQuizId,
  useQuizPostQueryById,
} from '../../common/Queries/quizQueries'
import MediaQueryContext from '../../Contexts/MediaQueryContext'

import { delay } from '../../utils'
import { useStyles } from './CodingQuestionSolutionPanelStyles'

function CodingQuestionSolutionPanel({
  question,
  currentQuestion,
  quiz,
  setQuiz,
  setErrors,
  handleCodingSubmission,
  questionLoadingStates,
  questionFilterIndices,

  currentQuestionFilter,
  setCurrentQuestionFilter,
  handleNext,
  editorRef,
}) {
  const maxCompilationStatusCheckCalls = 90
  const [getCompliationStatusCallsCount, setGetCompliationStatusCallsCount] =
    useState(0)
  const [enableSnackBar, setEnableSnackBar] = useState({
    error: false,
    warning: false,
  })
  const [snackBarData, setSnackBarData] = useState(false)
  const [localCode, setLocalCode] = useState('')
  const [disableRunCode, setDisableRunCode] = useState(false)
  const [localLanguage, setLocalLanguage] = useState('golang')
  const [compileMsg, setCompileMsg] = useState('')
  const [isDarkMode, setIsDarkMode] = useState(true)
  const [customInput, setCustomInput] = useState(
    question?.testcases?.[0]?.input
  )
  const [customOutput, setCustomOutput] = useState(
    question?.testcases?.[0]?.output
  )
  const [consoleOpened, setConsoleOpened] = useState(false)
  const [customInputTabSelected, setCustomInputTabSelected] = useState(false)
  const [resultsTabSelected, setResultsTabSelected] = useState(false)
  const [editorSettings, setEditorSettings] = useState({
    fontSize: 14,
    tabSize: 4,
    keyBinding: 'vscode',
  })
  const [openEditorSettingsDialog, setOpenEditorSettingsDialog] =
    useState(false)
  const [
    openConfirmRetrieveCodeSubmission,
    setOpenConfirmRetrieveCodeSubmission,
  ] = useState(false)
  const [openConfirmRestoreCodeStub, setOpenConfirmRestoreCodeStub] =
    useState(false)
  const [timeWhenRunClicked, setTimeWhenRunClicked] = useState(
    performance?.now()
  )
  const [showTimeLimitExceededError, setShowTimeLimitExceededError] =
    useState(false)
  const requestIdRef = useRef()
  const isCompileLoadingLock = useRef()
  const { quizId } = useParams()

  const {
    width: editorWrapperWidth,
    height: editorWrapperHeight,
    ref: editorWrapperRef,
  } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 16,
  })

  const { height: consoleBodyHeight, ref: consoleBodyRef } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 16,
  })

  const isWidthAboveTabRange = useMediaQuery(
    'only screen and (min-width:769px)'
  )

  const props = {
    padding: '1rem',
    consoleOpened,
    compileMsg,
    mediaWidth: useContext(MediaQueryContext),
    editorWrapperWidth,
    isDarkMode,
  }
  const classes = useStyles(props)
  const popUp = localStorage.getItem('reviewPagePopUp')

  useEffect(() => {
    if (!question?.code) setLocalCode(question?.stubs?.[0]?.functionSignature)
    if (!question?.language) setLocalLanguage(question?.stubs?.[0]?.language)
    if (question?.code) setLocalCode(question?.code)
    if (question?.language) setLocalLanguage(question?.language)
  }, [])

  const reportCodeExecutionToGA = (requestIdToBeReported) => {
    const timeWhenExecutionCompleted = performance?.now()
    const timeTakenToCompleteExecution = Math?.round(
      timeWhenExecutionCompleted - timeWhenRunClicked
    )
    window?.gtag('event', 'code_execution', {
      quiz_id: `${quizId}`,
      question_id: `${currentQuestion?.id}`,
      request_id: `${requestIdToBeReported}`,
      user_identifier: `${sessionStorage.getItem('userID')}`,
      execution_time: `${timeTakenToCompleteExecution} ms`,
    })
  }

  const mutation = useCompilePostQuery({
    staleTime: 0,
    onSuccess: (successResponse) => {
      if (successResponse?.errors?.length >= 0) {
        if (successResponse?.errors?.[0]?.errorCode === 'ENTITY_NOT_FOUND') {
          setDisableRunCode(false)
          setSnackBarData('Some error has occurred, Please refresh the page')
          setEnableSnackBar((errorsPrev) => {
            return { ...errorsPrev, error: true }
          })
          return
        }
        setSnackBarData(successResponse?.errors?.[0]?.reason)
        setEnableSnackBar((errorsPrev) => {
          return { ...errorsPrev, warning: true }
        })
        setDisableRunCode(false)
        return
      }
      requestIdRef.current = successResponse
      isCompileLoadingLock.current = true
    },
    onError: (error) => {
      if (error?.errorCode === 'ENTITY_NOT_FOUND') {
        setDisableRunCode(false)
        setSnackBarData('Some error has occurred, Please refresh the page')
        setEnableSnackBar((errorsPrev) => {
          return { ...errorsPrev, error: true }
        })
        return
      }
      setDisableRunCode(false)
      setSnackBarData(error?.errorReason)
      setEnableSnackBar((errorsPrev) => {
        return { ...errorsPrev, error: true }
      })
    },
  })

  const handleRun = () => {
    setGetCompliationStatusCallsCount(0)
    setDisableRunCode(true)
    setShowTimeLimitExceededError(() => false)
    if (questionLoadingStates[question?.id]) return
    if (customInputTabSelected && !customInput) {
      setErrors((errorsPrev) => {
        return { ...errorsPrev, customInputError: true }
      })
      return
    }

    setCompileMsg(() => '')
    setConsoleOpened(() => false)
    setTimeWhenRunClicked(() => performance?.now())

    if (customInputTabSelected) {
      mutation.mutate({
        id: question?.id,
        customOutput: customOutput?.trim(),
        customInput,
        code: localCode,
        language: localLanguage,
      })
    } else {
      mutation.mutate({
        id: question?.id,
        code: localCode,
        language: localLanguage,
      })
    }
  }

  const {
    data: compileData,
    isLoading: isCompileLoading,
    refetch: compileGetQuery,
  } = useCompileGetQuery(requestIdRef?.current, {
    enabled: false,
    staleTime: Infinity,
    retry: 0,
    onSuccess: (compileSuccessData) => {
      setGetCompliationStatusCallsCount(getCompliationStatusCallsCount + 1)
      setCompileMsg(() => compileSuccessData)
      setConsoleOpened(() => true)
      setResultsTabSelected(() => true)
      setCustomInputTabSelected(() => false)
      setEnableSnackBar((errorsPrev) => {
        return { ...errorsPrev, error: false }
      })
      if (
        (compileSuccessData?.status === 'PENDING' ||
          compileSuccessData === 'Missing reqId') &&
        getCompliationStatusCallsCount < maxCompilationStatusCheckCalls - 1
      ) {
        const waitAndRefetch = async () => {
          await delay(500)
          compileGetQuery()
        }
        waitAndRefetch()
      } else {
        if (
          getCompliationStatusCallsCount ===
            maxCompilationStatusCheckCalls - 1 ||
          compileSuccessData?.status === 'TIME_LIMIT_EXCEEDED'
        ) {
          setShowTimeLimitExceededError(() => true)
        }
        setDisableRunCode(false)
        reportCodeExecutionToGA(requestIdRef.current)
        isCompileLoadingLock.current = false
      }
    },
    onError: (compileErrorData) => {
      setGetCompliationStatusCallsCount(getCompliationStatusCallsCount + 1)
      if (compileErrorData?.errorCode === 'TIME_LIMIT_EXCEEDED') {
        setDisableRunCode(() => false)
        setShowTimeLimitExceededError(() => true)
        return
      }
      if (compileErrorData?.errorCode === 'ENTITY_NOT_FOUND') {
        setDisableRunCode(false)
        setSnackBarData('Some error has occurred, Please refresh the page')
        setEnableSnackBar((errorsPrev) => {
          return { ...errorsPrev, error: true }
        })
        return
      }
      if (
        compileErrorData?.errorCode === 'COMPILATION_REQUEST_PENDING' &&
        popUp === 'false' &&
        getCompliationStatusCallsCount < maxCompilationStatusCheckCalls - 1 &&
        compileErrorData?.errorCode !== 'TIME_LIMIT_EXCEEDED'
      ) {
        const waitAndRefetch = async () => {
          await delay(500)
          compileGetQuery()
        }
        waitAndRefetch()
      } else {
        if (
          getCompliationStatusCallsCount ===
            maxCompilationStatusCheckCalls - 1 ||
          compileErrorData?.errorCode === 'TIME_LIMIT_EXCEEDED'
        ) {
          setShowTimeLimitExceededError(() => true)
        }
        setShowTimeLimitExceededError(() => true)
        setDisableRunCode(false)
        if (popUp === false) {
          setSnackBarData(compileErrorData?.errorReason)
        }
        setEnableSnackBar((errorsPrev) => {
          if (popUp === 'false') {
            return { ...errorsPrev, error: true }
          }
          return ''
        })
      }
    },
  })

  useEffect(() => {
    if (mutation?.isSuccess) {
      if (
        mutation?.data?.errors?.length >= 0 &&
        mutation?.data?.errors[0]?.code === 'REQUEST_ACCEPTED'
      ) {
        return
      }
      compileGetQuery()
    }
  }, [mutation?.isSuccess])

  const handleCustomInputReset = (value) => {
    if (value === 'customInput') {
      setCustomInput(question?.testcases?.[0]?.input)
    } else {
      setCustomOutput(question?.testcases?.[0]?.output)
    }
  }
  const handleSubmit = () => {
    if (questionLoadingStates[question?.id]) return
    setConsoleOpened(() => false)
    setShowTimeLimitExceededError(() => false)
    setQuiz((quizPrev) => {
      const quizPrevCopy = JSON.parse(JSON.stringify(quizPrev))

      quizPrevCopy.questions = quizPrevCopy?.questions?.map((item) => {
        const copyItem = item
        if (item?.id === currentQuestion?.id) {
          copyItem.saved = 'pending'
        }

        return copyItem
      })
      return quizPrevCopy
    })

    handleCodingSubmission(question?.id, localCode, localLanguage)
  }

  const handleRetrieveSubmissionSuccess = (retrievedQuizData) => {
    const result = JSON.parse(JSON.stringify(retrievedQuizData))
    const questionSubmission = result?.questions?.filter(
      (questionData) => questionData?.type === 'coding'
    )[0]
    setLocalCode(() => questionSubmission?.code?.functionSignature)
    setLocalLanguage(() => questionSubmission?.code?.language)
    setQuiz((quizPrev) => {
      const quizPrevCopy = JSON.parse(JSON.stringify(quizPrev))
      quizPrevCopy.questions = quizPrevCopy?.questions?.map((item) => {
        const copyItem = item
        if (item?.id === currentQuestion?.id) {
          copyItem.language = questionSubmission?.language

          const modifiedCodeLanguageStorage = item?.codeLanguageStorage?.map(
            (storageItem) => {
              const copyStorageItem = storageItem
              if (storageItem?.language === questionSubmission?.language) {
                copyStorageItem.code = questionSubmission?.code
              }
              return copyStorageItem
            }
          )
          copyItem.codeLanguageStorage = modifiedCodeLanguageStorage
          copyItem.code = questionSubmission?.code
          copyItem.saved = true
        }

        return copyItem
      })
      return quizPrevCopy
    })

    setCurrentQuestionFilter((currentQuestionFilterPrev) => {
      if (currentQuestionFilterPrev === 'unanswered') return 'answered'
      return currentQuestionFilterPrev
    })
  }

  const mutationPostQuizById = useQuizPostQueryById(quizId, {
    onSuccess: (data) => handleRetrieveSubmissionSuccess(data),
    onError: (postQuizError) => {
      setSnackBarData(postQuizError?.errorReason)
      setEnableSnackBar((errorsPrev) => {
        return { ...errorsPrev, error: true }
      })
    },
  })

  const handleCustomInputTabSelected = () => {
    if (questionLoadingStates[question?.id]) return
    setCustomInputTabSelected(() => true)

    setConsoleOpened(() => true)
    setResultsTabSelected(() => false)
    setShowTimeLimitExceededError(() => false)
  }

  const handleResultsTabSelected = () => {
    if (questionLoadingStates[question?.id]) return
    setConsoleOpened(() => true)
    setCustomInputTabSelected(() => false)
    setResultsTabSelected(() => true)
    setShowTimeLimitExceededError(() => false)
  }

  const handleConsoleUp = () => {
    if (questionLoadingStates[question?.id]) return
    setConsoleOpened(() => true)
    if (!resultsTabSelected && !customInputTabSelected) {
      if (compileMsg) {
        setResultsTabSelected(() => true)
      } else setCustomInputTabSelected(() => true)
    }
  }

  const handleConsoleDown = () => {
    setConsoleOpened(() => false)
    setResultsTabSelected(() => false)
    setCustomInputTabSelected(() => false)
    setShowTimeLimitExceededError(() => false)
  }

  const handleRestoreCodeStub = () => {
    const copyCodeStub = question?.stubs?.filter((codeStub) => {
      return codeStub?.language === localLanguage
    })?.[0]?.functionSignature
    setLocalCode(() => copyCodeStub)

    setQuiz((quizPrev) => {
      const quizPrevcopy = JSON.parse(JSON.stringify(quizPrev))
      quizPrevcopy.questions = quizPrevcopy?.questions?.map((item) => {
        const copyItem = item
        if (item?.id === currentQuestion?.id) {
          copyItem.language = localLanguage

          const modifiedCodeLanguageStorage = item?.codeLanguageStorage?.map(
            (storageItem) => {
              const copyStorageItem = storageItem
              if (storageItem?.language === localLanguage) {
                copyStorageItem.functionSignature = copyCodeStub
              }
              return copyStorageItem
            }
          )
          copyItem.codeLanguageStorage = modifiedCodeLanguageStorage
          copyItem.functionSignature = copyCodeStub
          copyItem.saved = false
        }

        return copyItem
      })
      return quizPrevcopy
    })

    setCurrentQuestionFilter((currentQuestionFilterPrev) => {
      if (currentQuestionFilterPrev === 'answered') return 'unanswered'
      return currentQuestionFilterPrev
    })
  }

  const handleLanguageChange = (option) => {
    if (questionLoadingStates[question?.id]) return
    const quizCopy = JSON.parse(JSON.stringify(quiz))

    const filteredQuestion = quizCopy?.questions?.filter((item) => {
      return item?.id === currentQuestion?.id
    })

    const filteredItem = filteredQuestion?.[0]?.codeLanguageStorage?.filter(
      (item) => {
        return item?.language === option?.value
      }
    )

    if (filteredItem?.length === 0) return
    setLocalLanguage(() => option?.value)
    setLocalCode(() => filteredItem?.[0]?.code)
  }

  const onCodeEditorChange = (newValue) => {
    setLocalCode(() => newValue)

    setQuiz((quizPrev) => {
      const quizPrevCopy = JSON.parse(JSON.stringify(quizPrev))
      quizPrevCopy.questions = quizPrevCopy?.questions?.map((item) => {
        const copyItem = item
        if (item?.id === currentQuestion?.id) {
          copyItem.language = localLanguage

          const modifiedCodeLanguageStorage = item?.codeLanguageStorage?.map(
            (storageItem) => {
              const copyStorageItem = storageItem
              if (storageItem?.language === localLanguage) {
                copyStorageItem.code = newValue
              }
              return copyStorageItem
            }
          )
          copyItem.codeLanguageStorage = modifiedCodeLanguageStorage
          copyItem.code = newValue
          copyItem.saved = 'pending'
        }

        return copyItem
      })
      return quizPrevCopy
    })

    setCurrentQuestionFilter((currentQuestionFilterPrev) => {
      if (currentQuestionFilterPrev === 'answered') return 'unanswered'
      return currentQuestionFilterPrev
    })
  }
  const handleSnackBar = () => {
    if (enableSnackBar?.error) {
      setEnableSnackBar((errorsPrev) => {
        return { ...errorsPrev, error: false }
      })
    }
    if (enableSnackBar?.warning) {
      setEnableSnackBar((errorsPrev) => {
        return { ...errorsPrev, warning: false }
      })
    }
  }
  return (
    <Grid container className={classes.root}>
      <Grid
        container
        direction="column"
        className={classes.editorConfigsContainer}
      >
        <Grid
          container
          justify="space-between"
          className={classes.editorConfigs}
        >
          <Grid
            container
            alignItems="center"
            style={{
              padding: editorWrapperWidth > 425 ? '5px 0px 5px 10px' : '5px',
              height: '40px',
              flex: 1,
              width: editorWrapperWidth > 425 ? 'max-content' : 'unset',
            }}
          >
            <Grid item className={classes.languageSelect}>
              <CustomSelect
                value={localLanguage}
                isDisabled={questionLoadingStates[question?.id]}
                onChange={handleLanguageChange}
                options={question?.languages}
              />
            </Grid>

            {isWidthAboveTabRange && editorWrapperWidth > 425 && (
              <Tooltip title="Select a language" arrow placement="right">
                <HelpIcon className={classes.langToolTip} />
              </Tooltip>
            )}
          </Grid>

          <Grid item className={classes.editorConfigsContainer}>
            <Grid
              container
              alignItems="center"
              className={classes.editorConfigsContainer}
            >
              <Grid
                container
                alignItems="center"
                className={classes.retrieveCodeSubmissionContainer}
              >
                <ConfirmCodeRestoreOrRetrieveDialog
                  openDialog={openConfirmRetrieveCodeSubmission}
                  setOpenDialog={setOpenConfirmRetrieveCodeSubmission}
                  affirmativeHandler={() => {
                    // retrieveSubmissionRefetch()
                    mutationPostQuizById.mutate({})
                    setOpenConfirmRetrieveCodeSubmission(false)
                  }}
                  content="Your code will be discarded and replaced with your last submitted code."
                />
                <Tooltip arrow title="Retrieve previous submission">
                  <span>
                    <IconButton
                      size="small"
                      disabled={
                        !question?.atleastOneSubmissionOccured ||
                        questionLoadingStates[question?.id]
                      }
                      onClick={() => setOpenConfirmRetrieveCodeSubmission(true)}
                    >
                      {mutationPostQuizById?.isLoading ? (
                        <CircularProgress
                          size="1rem"
                          className={classes.codeRetrieveLoader}
                        />
                      ) : (
                        <Grid item className={classes.previousIconWrapper}>
                          <PreviousIcon
                            disabled={
                              !question?.atleastOneSubmissionOccured ||
                              questionLoadingStates[question?.id]
                            }
                          />
                        </Grid>
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid
                container
                alignItems="center"
                className={classes.restoreCodeStubContainer}
              >
                <ConfirmCodeRestoreOrRetrieveDialog
                  openDialog={openConfirmRestoreCodeStub}
                  setOpenDialog={setOpenConfirmRestoreCodeStub}
                  affirmativeHandler={() => {
                    handleRestoreCodeStub()
                    setOpenConfirmRestoreCodeStub(false)
                  }}
                  content="Your code will be discarded and replaced with default code stub of the current selected language"
                />
                <Tooltip arrow title="Restore default code stub">
                  <span>
                    <IconButton
                      disabled={questionLoadingStates[question?.id]}
                      size="small"
                      onClick={() => setOpenConfirmRestoreCodeStub(true)}
                    >
                      <Grid item className={classes.previousIconWrapper}>
                        <RestoreIcon
                          disabled={questionLoadingStates[question?.id]}
                        />
                      </Grid>
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.editorSettingsContainer}
              >
                <EditorSettingsDialog
                  openEditorSettingsDialog={openEditorSettingsDialog}
                  setOpenEditorSettingsDialog={setOpenEditorSettingsDialog}
                  editorSettings={editorSettings}
                  setEditorSettings={setEditorSettings}
                />
                <Tooltip arrow title="Code editor settings">
                  <IconButton
                    size="small"
                    onClick={() => setOpenEditorSettingsDialog(true)}
                  >
                    <Grid item className={classes.previousIconWrapper}>
                      <SettingsIcon />
                    </Grid>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid
                container
                alignItems="center"
                className={classes.themeContainer}
              >
                <Tooltip
                  arrow
                  title={`Change to ${isDarkMode ? 'light' : 'dark'} mode`}
                >
                  <IconButton
                    size="small"
                    onClick={() => setIsDarkMode(!isDarkMode)}
                  >
                    {isDarkMode ? (
                      <Grid item className={classes.previousIconWrapper}>
                        <LightThemeIcon />
                      </Grid>
                    ) : (
                      <Grid item className={classes.previousIconWrapper}>
                        <DarkThemeIcon />
                      </Grid>
                    )}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.editorAndConsoleWrapper}
        >
          <Grid
            item
            className={classes.editorWrapper}
            ref={editorWrapperRef}
            style={{ position: 'relative' }}
          >
            {questionLoadingStates[question?.id] && (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.blurredWrapper}
              >
                <Grid item className={classes.lockIconWrapper}>
                  <LockIcon isDarkMode={isDarkMode} />
                </Grid>
              </Grid>
            )}

            <CodeEditor
              language={localLanguage}
              isDarkMode={isDarkMode}
              code={localCode}
              onCodeEditorChange={onCodeEditorChange}
              editorSettings={editorSettings}
              readOnly={questionLoadingStates[question?.id]}
              width={`${editorWrapperWidth}px`}
              height={`${editorWrapperHeight}px`}
            />
          </Grid>

          {consoleOpened && (
            <Grid item className={classes.consoleHeaderWrapper}>
              <ConsoleHeader
                handleCustomInputTabSelected={handleCustomInputTabSelected}
                customInputTabSelected={customInputTabSelected}
                handleResultsTabSelected={handleResultsTabSelected}
                resultsTabSelected={resultsTabSelected}
                consoleOpened={consoleOpened}
                handleConsoleDown={handleConsoleDown}
                handleConsoleUp={handleConsoleUp}
                buttonsDisabled={questionLoadingStates[question?.id]}
              />
            </Grid>
          )}

          {consoleOpened && !showTimeLimitExceededError && (
            <Grid
              item
              className={classes.consoleBodyWrapper}
              ref={consoleBodyRef}
              style={{
                maxWidth: editorRef.current?.getBoundingClientRect().width,
              }}
            >
              <ConsoleBody
                customInputTabSelected={customInputTabSelected}
                customInput={customInput}
                setCustomInput={setCustomInput}
                resultsTabSelected={resultsTabSelected}
                compileMsg={compileMsg}
                compileData={compileData}
                editorWrapperWidth={editorWrapperWidth}
                consoleBodyHeight={consoleBodyHeight}
                handleCustomInputReset={handleCustomInputReset}
                setCustomOutput={setCustomOutput}
                customOutput={customOutput}
                showTimeLimitExceededError={showTimeLimitExceededError}
              />
            </Grid>
          )}
          {consoleOpened && showTimeLimitExceededError && (
            <Grid
              item
              className={classes.consoleBodyWrapper}
              ref={consoleBodyRef}
              style={{
                maxWidth: editorRef.current?.getBoundingClientRect().width,
              }}
            >
              <ConsoleBody
                customInputTabSelected={customInputTabSelected}
                customInput={customInput}
                setCustomInput={setCustomInput}
                resultsTabSelected={resultsTabSelected}
                compileMsg={compileMsg}
                compileData={compileData}
                editorWrapperWidth={editorWrapperWidth}
                consoleBodyHeight={consoleBodyHeight}
                handleCustomInputReset={handleCustomInputReset}
                setCustomOutput={setCustomOutput}
                customOutput={customOutput}
                showTimeLimitExceededError={showTimeLimitExceededError}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          container
          direction="row"
          justify="space-between"
          className={classes.buttonWrapper}
        >
          <Tooltip title="Custom Input and Results" arrow placement="right">
            <span className={classes.editorConfigsContainer}>
              <Button
                disabled={questionLoadingStates[question?.id]}
                onClick={consoleOpened ? handleConsoleDown : handleConsoleUp}
                endIcon={
                  consoleOpened ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                style={{ height: '100%', padding: 0 }}
              >
                Console
              </Button>
            </span>
          </Tooltip>

          <Grid container className={classes.runCodeButtonWrapper}>
            <Button
              variant="outlined"
              onClick={handleRun}
              disabled={
                disableRunCode ||
                questionLoadingStates[question?.id] ||
                localCode === ''
              }
              className={classes.runCodeButton}
            >
              {disableRunCode ||
              (isCompileLoading && isCompileLoadingLock.current) ? (
                <CircularProgress size="1.5rem" />
              ) : (
                <> {editorWrapperWidth > 376 ? <>Run Code</> : <>Run</>} </>
              )}
            </Button>

            <Button
              variant="outlined"
              onClick={() => {
                handleSubmit()
                if (
                  questionFilterIndices?.[currentQuestionFilter]?.indexOf(
                    currentQuestion?.index
                  ) <
                  questionFilterIndices?.[currentQuestionFilter]?.length - 1
                )
                  handleNext()
              }}
              disabled={
                disableRunCode ||
                questionLoadingStates[question?.id] ||
                localCode === ''
              }
              className={classes.continueButton}
            >
              {questionLoadingStates[question?.id] ? (
                <CircularProgress
                  size="1.5rem"
                  className={classes.questionLoader}
                />
              ) : (
                <>
                  Save
                  {questionFilterIndices?.[currentQuestionFilter]?.indexOf(
                    currentQuestion?.index
                  ) <
                    questionFilterIndices?.[currentQuestionFilter]?.length -
                      1 && (
                    <>
                      {editorWrapperWidth > 319 ? (
                        <> &#38; Next </>
                      ) : (
                        <>
                          <span style={{ fontSize: '14px' }}> &#38; </span> Next
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={enableSnackBar?.error || enableSnackBar?.warning}
        autoHideDuration={3000}
        onClose={handleSnackBar}
        message={snackBarData}
        action={
          <IconButton color="inherit" size="small" onClick={handleSnackBar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          classes: {
            // eslint-disable-next-line no-nested-ternary
            root: enableSnackBar?.error
              ? classes.submitFailedSnackBar
              : enableSnackBar?.warning
              ? classes.tabChangeSnackBar
              : '',
          },
        }}
        key="Coding Question Feedback"
      />
      {/* <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={enableSnackBar?.warning}
        autoHideDuration={3000}
        onClose={handleSnackBar}
        message={snackBarData}
        action={
          <IconButton color="inherit" size="small" onClick={handleSnackBar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        ContentProps={{
          classes: {
            root: classes.tabChangeSnackBar,
          },
        }}
        key="Coding Question Feedback"
      /> */}
    </Grid>
  )
}

export default CodingQuestionSolutionPanel
