import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme?.palette?.darkBackground?.main,
    width: '100%',
    padding: '21px 20px 33px 20px',
    height: '100%',
    '@media only screen and (min-width:426px)': {
      padding: '30px 40px 43px 40px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '30px 80px 53px 80px',
    },
    '@media only screen and (min-width:1024px)': {
      padding: '30px 135px 63px 135px',
    },
    '@media only screen and (min-width:2000px)': {
      padding: '60px 175px 83px 175px',
    },
  },

  tabsWrapper: {
    boxSizing: 'border-box',
    width: '100%',
    borderBottom: `solid 1px ${theme?.palette?.dividerLines?.main}`,
  },
  mcqTab: {
    padding: 0,
    border: 'none',
    background: 'none',
    outline: 'none',
    flex: 1,
    cursor: 'pointer',
    paddingBottom: (props) => (props?.currentTab === 'mcq' ? '6px' : '10px'),
    marginBottom: '-4px',
    borderBottom: (props) =>
      props?.currentTab === 'mcq'
        ? `solid 4px ${theme?.palette?.primarySelection?.main}`
        : 'none',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
      marginRight: (props) =>
        props?.areCodingQuestionsPresent ||
        props?.areEssayQuestionsPresent ||
        props?.areFillInTheBlanksPresent
          ? '30px'
          : 0,
    },
  },

  fillInTheBlanksTab: {
    padding: 0,
    border: 'none',
    background: 'none',
    outline: 'none',
    flex: 1,
    cursor: 'pointer',
    paddingBottom: (props) =>
      props?.currentTab === 'fill-in-the-blanks' ? '6px' : '10px',
    marginBottom: '-4px',
    borderBottom: (props) =>
      props?.currentTab === 'fill-in-the-blanks'
        ? `solid 4px ${theme?.palette?.primarySelection?.main}`
        : 'none',
    '@media only screen and (min-width:769px)': {
      flex: 'none',
      marginRight: (props) =>
        props?.areCodingQuestionsPresent || props?.areEssayQuestionsPresent
          ? '30px'
          : 0,
    },
  },

  mcqTabText: {
    fontSize: '12px',
    '@media only screen and (min-width:426px)': {
      fontSize: '14px',
    },
  },
  codingTab: {
    border: 'none',
    padding: 0,
    background: 'none',
    outline: 'none',
    flex: 1,
    cursor: 'pointer',
    paddingBottom: (props) => (props?.currentTab === 'coding' ? '6px' : '10px'),
    marginBottom: '-4px',
    borderBottom: (props) =>
      props?.currentTab === 'coding'
        ? `solid 4px ${theme?.palette?.primarySelection?.main}`
        : 'none',

    '@media only screen and (min-width:769px)': {
      flex: 'none',
      marginRight: (props) => (props?.areEssayQuestionsPresent ? '30px' : 0),
    },
  },
  essayTab: {
    border: 'none',
    padding: 0,
    background: 'none',
    outline: 'none',
    flex: 1,
    cursor: 'pointer',
    paddingBottom: (props) => (props?.currentTab === 'essay' ? '6px' : '10px'),
    marginBottom: '-4px',
    borderBottom: (props) =>
      props?.currentTab === 'essay'
        ? `solid 4px ${theme?.palette?.primarySelection?.main}`
        : 'none',

    '@media only screen and (min-width:769px)': {
      flex: 'none',
      marginRight: (props) => (props?.areFillInTheBlanksPresent ? '30px' : 0),
    },
  },
  codingTabText: {
    fontSize: '12px',
    '@media only screen and (min-width:426px)': {
      fontSize: '14px',
    },
  },
  tableContainer: {
    height: 0,
    flex: 1,
    paddingTop: '30px',
  },
  tableWrapper: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  resultsPageWrapper: {
    width: '100%',
    height: 'max-content',
  },
  quizResults: {
    marginBottom: '16px',
  },
}))
export { useStyles }
