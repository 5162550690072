import React from 'react'

function CameraOffIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="camera-off"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group">
          <rect
            id="camera"
            fillOpacity="0"
            fill="#FFFFFF"
            fillRule="nonzero"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <rect
            id="Rectangle"
            stroke="#9295A5"
            x="3.5"
            y="5.5"
            width="13"
            height="13"
            rx="3"
          />
          <path
            d="M20.5,6.79461698 L20.5,17.205383 L16.5,15.285383 L16.5,8.71461698 L20.5,6.79461698 Z"
            id="Rectangle"
            stroke="#9295A5"
          />
          <circle id="Oval" fill="#9295A5" cx="13" cy="9" r="1" />
        </g>
        <polygon
          id="Path"
          fill="#9295A5"
          fillRule="nonzero"
          points="1.90906482 2 22.3857213 18.3631668 21.4766564 19.5563144 1 3.19314758"
        />
      </g>
    </svg>
  )
}

export default CameraOffIcon
