import React from 'react'

function PreviousIcon({ disabled }) {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Quiz-screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-31">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <path
            d="M11.1141363,7.90573234 L11.1141363,12.8856278 L15.2759061,12.8856278 L15.2759061,11.1070937 L12.8926704,11.1070937 L12.8926704,7.90573234 L11.1141363,7.90573234 Z M17.6591417,6.34062235 C14.5344258,3.21979255 9.4723809,3.21979255 6.34766499,6.34062235 L5.77853408,6.93643127 L4,5.15789719 L4,10.2178266 L9.03325145,10.2178266 L7.00572259,8.19029779 L7.60153151,7.59448887 C9.64906588,5.56766365 12.8198979,5.21071747 15.2668035,6.73159562 C17.7137091,8.25247377 18.7972453,11.2537294 17.8861791,13.9869282 C16.9751128,16.720127 14.3075304,18.4710098 11.4374791,18.2195689 C8.56742767,17.968128 6.24492979,15.7800719 5.82299743,12.9300911 L4.04446335,12.9300911 C4.51845839,16.9651897 7.94056378,20.004937 12.0034034,19.9997707 C15.2382899,19.9969188 18.1533866,18.0469865 19.3909392,15.0581802 C20.6284918,12.0693739 19.9451304,8.62945506 17.6591417,6.34062235 Z"
            id="Shape"
            fill={disabled ? '#7e7f82' : '#262B4B'}
            fillRule="nonzero"
            opacity="0.699999988"
          />
        </g>
      </g>
    </svg>
  )
}

export default PreviousIcon
