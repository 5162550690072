import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  timer: {
    width: 'max-content',
    textAlign: 'center',
  },
  time: {
    marginLeft: '8px',
    fontWeight: '500',
    color: theme?.palette?.primarySelection?.main,
  },
}))
export { useStyles }
