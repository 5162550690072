import React from 'react'
import Typography from '@material-ui/core/Typography'
import Brightness1Icon from '@material-ui/icons/Brightness1'
import { useStyles } from './HomePagePricingCardStyles'

function HomePagePricingCard({ pricingPlan }) {
  const classes = useStyles()

  return (
    <div className={classes?.root} data-testid="render-pricing-card">
      <div className={classes.planNameContainer}>
        <Typography className={classes.planName}>
          {pricingPlan?.title}
        </Typography>
        <Typography className={classes.titleSubHeading}>
          {pricingPlan?.subHeading}
        </Typography>
      </div>
      <div className={classes.amountSubHeadingWrapper}>
        <div className={classes.amountWrapper}>
          <Typography className={classes.amount}>
            {pricingPlan?.amount}
          </Typography>
        </div>
        <Typography className={classes.amountSubHeading}>
          {pricingPlan?.amountSubHeading}
        </Typography>
      </div>
      <div className={classes.featuresWrapper}>
        {pricingPlan?.features?.map((feature, index) => {
          return (
            <div
              style={{
                width: '100%',
                paddingBottom:
                  index + 1 === pricingPlan?.features?.length ? '0px' : '20px',
              }}
              key={index?.toString(2)}
            >
              <div className={classes.featuresChildWrapper}>
                <div className={classes.brightness1IconWrapper}>
                  <Brightness1Icon className={classes.brightness1Icon} />
                </div>
                <div className={classes.featuresTextWrapper}>
                  <Typography className={classes.descriptionText}>
                    {feature?.description}
                  </Typography>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default HomePagePricingCard
