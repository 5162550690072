import React from 'react'
import Grid from '@material-ui/core/Grid'
import './Loader.css'

// const useStyles = makeStyles((theme) => ({
//   circular: {
//     position: 'relative',
//   },
//   circularChild: {
//     width: '100%',
//     height: '100%',
//     position: 'absolute',
//     left: 0,
//     top: 0,
//     '&:before': {
//       background: theme.palette.loader.main,
//       content: '',
//       display: 'block',
//       margin: '0 auto',
//       width: '15%',
//       height: '15%',
//       borderRadius: '100%',
//       '-webkit-animation': '$circleBounceDelay 1.2s infinite ease-in-out both',
//       animation: '$circleBounceDelay 1.2s infinite ease-in-out both',
//     },
//   },
//   circleTwo: {
//     '-webkit-transform': 'rotate(30deg)',
//     '-ms-transform': 'rotate(30deg)',
//     transform: 'rotate(30deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-1.1s',
//       'animation-delay': '-1.1s',
//     },
//   },
//   circleThree: {
//     '-webkit-transform': 'rotate(60deg)',
//     '-ms-transform': 'rotate(60deg)',
//     transform: 'rotate(60deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-1.0s',
//       'animation-delay': '-1.0s',
//     },
//   },
//   circleFour: {
//     '-webkit-transform': 'rotate(90deg)',
//     '-ms-transform': 'rotate(90deg)',
//     transform: 'rotate(90deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.9s',
//       'animation-delay': '-0.9s',
//     },
//   },
//   circleFive: {
//     '-webkit-transform': 'rotate(120deg)',
//     '-ms-transform': 'rotate(120deg)',
//     transform: 'rotate(120deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.8s',
//       'animation-delay': '-0.8s',
//     },
//   },

//   circleSix: {
//     '-webkit-transform': 'rotate(150deg)',
//     '-ms-transform': 'rotate(150deg)',
//     transform: 'rotate(150deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.7s',
//       'animation-delay': '-0.7s',
//     },
//   },
//   circleSeven: {
//     '-webkit-transform': 'rotate(180deg)',
//     '-ms-transform': 'rotate(180deg)',
//     transform: 'rotate(180deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.6s',
//       'animation-delay': '-0.6s',
//     },
//   },
//   circleEight: {
//     '-webkit-transform': 'rotate(210deg)',
//     '-ms-transform': 'rotate(210deg)',
//     transform: 'rotate(210deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.5s',
//       'animation-delay': '-0.5s',
//     },
//   },
//   circleNine: {
//     '-webkit-transform': 'rotate(240deg)',
//     '-ms-transform': 'rotate(240deg)',
//     transform: 'rotate(240deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.4s',
//       'animation-delay': '-0.4s',
//     },
//   },
//   circleTen: {
//     '-webkit-transform': 'rotate(270deg)',
//     '-ms-transform': 'rotate(270deg)',
//     transform: 'rotate(270deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.3s',
//       'animation-delay': '-0.3s',
//     },
//   },
//   circleEleven: {
//     '-webkit-transform': 'rotate(300deg)',
//     '-ms-transform': 'rotate(300deg)',
//     transform: 'rotate(300deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.2s',
//       'animation-delay': '-0.2s',
//     },
//   },
//   circleTwelve: {
//     '-webkit-transform': 'rotate(330deg)',
//     '-ms-transform': 'rotate(330deg)',
//     transform: 'rotate(330deg)',
//     '&:before': {
//       '-webkit-animation-delay': '-0.1s',
//       'animation-delay': '-0.1s',
//     },
//   },

//   '@-webkit-keyframes circleBounceDelay': {
//     '0%, 80%, 100%': {
//       '-webkit-transform': 'scale(0)',
//       transform: 'scale(0)',
//     },
//     '40%': {
//       '-webkit-transform': 'scale(1)',
//       transform: 'scale(1)',
//     },
//   },

//   '@keyframes circleBounceDelay': {
//     '0%, 80%, 100%': {
//       '-webkit-transform': 'scale(0)',
//       transform: 'scale(0)',
//     },
//     '40%': {
//       '-webkit-transform': 'scale(1)',
//       transform: 'scale(1)',
//     },
//   },
// }))

function Loader({ size }) {
  return (
    <Grid
      data-testid="render-loader"
      className="sk-circle"
      style={{ height: size, width: size }}
    >
      <Grid className="sk-circle1 sk-child" />
      <Grid className="sk-circle2 sk-child" />
      <Grid className="sk-circle3 sk-child" />
      <Grid className="sk-circle4 sk-child" />
      <Grid className="sk-circle5 sk-child" />
      <Grid className="sk-circle6 sk-child" />
      <Grid className="sk-circle7 sk-child" />
      <Grid className="sk-circle8 sk-child" />
      <Grid className="sk-circle9 sk-child" />
      <Grid className="sk-circle10 sk-child" />
      <Grid className="sk-circle11 sk-child" />
      <Grid className="sk-circle12 sk-child" />
    </Grid>
  )
}

export default Loader
