import React from 'react'

function ConductIcon() {
  return (
    <svg viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Conduct"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-21">
          <rect
            id="Rectangle-Copy-12"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="40"
            height="40"
          />
          <g
            id="Group-5"
            transform="translate(3.000000, 4.000000)"
            fillRule="nonzero"
          >
            <polygon
              id="Path-7"
              fill="#00B7A4"
              points="1.69190536 0.278011795 13.0696831 11.1817155 11.6858724 12.6256919 0.308094637 1.72198821"
            />
            <polygon
              id="Path-6"
              fill="#000000"
              points="0.0258785573 1.22602525 -0.340871754 -0.354591685 25.0084407 5.79980606 27.6488906 18.8024523 25.688895 19.200469 23.301 7.443 2.341 2.354 7.246 23.501 18.7141584 25.5201736 18.3673231 27.4898703 5.59816345 25.2414046"
            />
            <path
              d="M14.7481481,10.4814815 C16.8427392,10.4814815 18.5407407,12.1264205 18.5407407,14.1555556 C18.5407407,16.1846906 16.8427392,17.8296296 14.7481481,17.8296296 C12.6535571,17.8296296 10.9555556,16.1846906 10.9555556,14.1555556 C10.9555556,12.1264205 12.6535571,10.4814815 14.7481481,10.4814815 Z M14.7481481,12.4814815 C13.7441669,12.4814815 12.9555556,13.2454487 12.9555556,14.1555556 C12.9555556,15.0656624 13.7441669,15.8296296 14.7481481,15.8296296 C15.7521294,15.8296296 16.5407407,15.0656624 16.5407407,14.1555556 C16.5407407,13.2454487 15.7521294,12.4814815 14.7481481,12.4814815 Z"
              id="Oval"
              fill="#00B7A4"
            />
            <path
              d="M34.4444312,21.6850331 L28.130901,15.7282644 L15.4421245,28.2766781 L21.6675262,34.1485897 L34.4444312,21.6850331 Z M28.163,18.508 L31.555,21.709 L21.644,31.377 L18.32,28.242 L28.163,18.508 Z"
              id="Path-9"
              fill="#000000"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ConductIcon
