import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Grid, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import CloseIcon from '@material-ui/icons/Close'
import InstructionsIcon from '../../Icons/InstructionsIcon'
import CameraOffIcon from '../../Icons/CameraOffIcon'
import CameraOnIcon from '../../Icons/CameraOnIcon'
import Timer from '../Timer/Timer'
import Logo from '../Logo/Logo'
import ReviewPage from '../ReviewPage/ReviewPage'
import { getQuestionById, convertInstructionsToPoints } from '../../utils'
import IsProctoredContext from '../../Contexts/IsProctoredContext'
import { useStyles } from './QuizPageHeaderStyles'

function QuizPageHeader({
  quiz,
  currentQuestion,
  setCurrentQuestion,
  setCurrentQuestionFilter,
  seconds,
  handleSubmit,
  handleMCQSubmission,
  openMirrorCamera,
  setOpenMirrorCamera,
}) {
  const [popUp, setPopUp] = useState(false)
  const [scrolledToQuestionId, setScrolledToQuestionId] = useState()
  const [reviewQuestionFilter, setReviewQuestionFilter] = useState('all')
  const [openInstructions, setOpenInstructions] = useState(false)
  const { isProctored } = useContext(IsProctoredContext)

  const classes = useStyles()
  const getNoOfAnsweredQuestions = () => {
    return quiz?.questions?.filter((question) => {
      return (
        question?.saved &&
        question?.saved !== 'pending' &&
        question?.saved !== 'failed'
      )
    })?.length
  }

  const handleReviewAndSubmitClick = () => {
    setPopUp(() => true)
    const copyCurrentQuestion = getQuestionById(quiz, currentQuestion)

    // We can replace the below if conditions by checking if the copyCurrentQuestion is present or not and calling the handleMcqSubmission
    // if(copyCurrentQuestion){
    //   handleMCQSubmission(
    //     copyCurrentQuestion?.id,
    //     copyCurrentQuestion?.selectedChoice,
    //     copyCurrentQuestion?.type
    //   )
    // }

    if (
      copyCurrentQuestion?.type === 'mcq' ||
      copyCurrentQuestion?.type === 'multi-choice'
    ) {
      handleMCQSubmission(
        copyCurrentQuestion?.id,
        copyCurrentQuestion?.selectedChoice,
        copyCurrentQuestion?.type
      )
    }
  }

  useEffect(() => {
    localStorage.setItem('reviewPagePopUp', popUp)
  }, [popUp])

  return (
    <Grid container alignItems="center" className={classes.title}>
      <Grid container alignItems="center" className={classes.logoWrapper}>
        <Logo />
      </Grid>

      <Grid container justify="center" className={classes.timerWrapper}>
        <Grid item className={classes.secondsTimer}>
          <Timer seconds={seconds} />
        </Grid>
      </Grid>
      {popUp ? (
        <ReviewPage
          quiz={quiz}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          setCurrentQuestionFilter={setCurrentQuestionFilter}
          getNoOfAnsweredQuestions={getNoOfAnsweredQuestions}
          popUp={popUp}
          setPopUp={setPopUp}
          seconds={seconds}
          handleSubmit={handleSubmit}
          scrolledToQuestionId={scrolledToQuestionId}
          setScrolledToQuestionId={setScrolledToQuestionId}
          reviewQuestionFilter={reviewQuestionFilter}
          setReviewQuestionFilter={setReviewQuestionFilter}
        />
      ) : null}

      <Grid
        container
        justify="flex-end"
        alignItems="center"
        className={classes.quizzDetailsWrapper}
      >
        {isProctored && (
          <Grid item className={classes.videoIconWrapper}>
            <Tooltip title="Camera" arrow>
              <IconButton
                onClick={() =>
                  setOpenMirrorCamera((openMirrorCameraPrev) => {
                    return !openMirrorCameraPrev
                  })
                }
                className={classes.cameraIcon}
              >
                {openMirrorCamera ? <CameraOnIcon /> : <CameraOffIcon />}
              </IconButton>
            </Tooltip>
          </Grid>
        )}

        <Grid item className={classes.instructionsIconWrapper}>
          <Dialog
            fullWidth
            open={openInstructions}
            onClose={() => setOpenInstructions(() => false)}
          >
            <Helmet>
              <title>{`${quiz?.info?.title} - Instructions`}</title>
            </Helmet>
            <DialogTitle className={classes.instructionsWrapper}>
              <Grid
                container
                justify="flex-end"
                className={classes.closeIconWrapper}
              >
                <IconButton onClick={() => setOpenInstructions(() => false)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Typography className={classes.instructionsHeader}>
                Instructions
              </Typography>
            </DialogTitle>

            <DialogContent className={classes.instructionsPointsWrapper}>
              <Typography className={classes.instructionsPoints}>
                {convertInstructionsToPoints(
                  sessionStorage.getItem('instructions')
                )}
              </Typography>
            </DialogContent>
          </Dialog>
          <Tooltip title="Instructions" arrow>
            <IconButton
              onClick={() => setOpenInstructions(() => true)}
              className={classes.instructionsIcon}
            >
              <InstructionsIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item className={classes.answeredWrapper}>
          <Typography className={classes.answeredText}>
            Answered {getNoOfAnsweredQuestions()}/{quiz?.questions?.length}
          </Typography>
        </Grid>
        <Grid item className={classes.submitButtonWrapper}>
          <Button
            variant="outlined"
            onClick={handleReviewAndSubmitClick}
            className={classes.submitButton}
            data-testid="review-submit-button"
          >
            Review &#38; Submit Test
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuizPageHeader
