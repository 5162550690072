import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import parse from 'html-react-parser'
import { useStyles } from './ReviewPageRowItemStyles'

function ReviewPageRowItem({
  index,
  question,

  setCurrentQuestion,
  setCurrentQuestionFilter,
  setPopUp,
  setScrolledToQuestionId,
}) {
  const props = {
    isSolved:
      question?.saved &&
      question?.saved !== 'pending' &&
      question?.saved !== 'failed',
  }
  const classes = useStyles(props)
  const handleModify = () => {
    setCurrentQuestion((currentQuestionPrev) => {
      return {
        ...currentQuestionPrev,
        index,
        id: question?.id,
      }
    })
    setCurrentQuestionFilter(() => 'answered')
    setScrolledToQuestionId(() => question?.id)
    setPopUp(() => false)
  }
  const handleSolve = () => {
    setCurrentQuestion((currentQuestionPrev) => {
      return {
        ...currentQuestionPrev,
        index,
        id: question?.id,
      }
    })
    setCurrentQuestionFilter(() => 'unanswered')
    setScrolledToQuestionId(() => question?.id)
    setPopUp(() => false)
  }

  const getQuestionType = () => {
    if (question?.type === 'multi-choice' || question?.type === 'mcq')
      return 'MCQ'
    if (question?.type === 'coding') return 'Coding'
    return question?.type
  }

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.indexWrapper}
      >
        <Typography className={classes.indexNumber}>{index + 1}</Typography>
      </Grid>
      <Grid className={classes.questionWrapper} item>
        <Grid className={classes.question}>
          {parse(question?.question ?? '')}
        </Grid>
      </Grid>
      <Grid item className={classes.questionTypeWrapper}>
        <Typography variant="body2">{getQuestionType()}</Typography>
      </Grid>
      <Grid item className={classes.modifyAndSolveButtonWrapper}>
        {props.isSolved ? (
          <Button className={classes.modifyButton} onClick={handleModify}>
            Modify
          </Button>
        ) : (
          <Button className={classes.solveButton} onClick={handleSolve}>
            Solve
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default ReviewPageRowItem
