import React, { useState, useEffect, useContext, useRef } from 'react'
import { Helmet } from 'react-helmet'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { useAuth } from '@zopsmart/zs-components'
import Snackbar from '@material-ui/core/Snackbar'
import CloseIcon from '@material-ui/icons/Close'
import Cookies from 'js-cookie'
import { useStyles } from './QuizOnboardingPageWrapperStyles'
import {
  useQuizGetQueryById,
  useQuizPostQueryById,
} from '../../common/Queries/quizQueries'
import QuizAlreadySubmitted from '../QuizAlreadySubmitted/QuizAlreadySubmitted'

import IsProctoredContext from '../../Contexts/IsProctoredContext'

import Loader from '../Loader/Loader'
import QuizNotFound from '../QuizNotFound/QuizNotFound'
import PageNotFound from '../PageNotFound/PageNotFound'
import useWindowSize from '../../hooks/useWindowSize'
import {
  isDateTimeInThePast,
  isDateTimeInTheFuture,
  returnErrors,
  areCookiesEnabled,
  decodeJwt,
  isLoggedIn,
} from '../../utils'
import {
  MOCK_BASE_URL,
  REFRESH_TOKEN_URL,
  LOGIN_ENDPOINT,
} from '../../constants'
import QuizOnboardingPage from '../QuizOnboardingPage/QuizOnboardingPage'
import QuizOnboardingPageMobile from '../QuizOnboardingPageMobile/QuizOnboardingPageMobile'
import AccessDenied from '../AccessDenied/AccessDenied'
import InternalServerError from '../InternalServerError/InternalServerError'
import CookiesNotAllowed from '../CookiesNotAllowed/CookiesNotAllowed'
import TestVideoDialog from '../TestVideoDialog/TestVideoDialog'

function QuizOnboardingPageWrapper() {
  const [isLogin, setIsLogin] = useState(isLoggedIn())
  const [quizInfoDetails, setQuizInfoDetails] = useState('')
  const [isQuizStarted, setIsQuizStarted] = useState(false)
  const [isQuizEnded, setIsQuizEnded] = useState(false)
  const [logInFailed, setLogInFailed] = useState(false)
  const [startQuizFailed, setStartQuizFailed] = useState(false)
  const [username, setUsername] = useState('')
  const [usernameError, setUsernameError] = useState(false)
  const [openTestVideoDialog, setOpenTestVideoDialog] = useState(false)
  const [isStreamOpen, setIsStreamOpen] = useState(false)
  const [audioPermission, updateAudioPermission] = useState(false)
  const [videoPermission, updateVideoPermission] = useState(false)
  const [isCameraDisabled, setIsCameraDisabled] = useState(false)
  const [isNonExistingTokenError, setIsNonExistingTokenError] = useState(false)
  const [quizErrorCode, setQuizErrorCode] = useState('')
  const [, setAuthError] = useState(false)
  const [quizErrorReason, setQuizErrorReason] = useState('')
  const [userResponseErrorCode, setUserResponseErrorCode] = useState('')
  const [userResponseErrorReason, setUserResponseErrorReason] = useState('')
  const alertUser = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }
  const [showResult, setShowResult] = useState(false)
  const [quizTitle, setQuizTitle] = useState('')
  const [responseId] = useState('')
  const proctoringConnectionRefs = useRef({
    streams: null,
  })
  const history = useHistory()

  const { quizId } = useParams()
  const { isProctored, setIsProctored } = useContext(IsProctoredContext)

  const windowSize = useWindowSize()

  const isWidthAboveTabRange = useMediaQuery(
    'only screen and (min-width:769px)'
  )

  const classes = useStyles()

  const videoError = () => {
    setOpenTestVideoDialog(() => false)
    updateAudioPermission(() => false)
    updateVideoPermission(() => false)
    setIsCameraDisabled(() => true)
  }

  const startCapturingVideo = () => {
    const mediaConstraints = {
      video: {
        aspectRatio: 1.33333,
      },
      audio: true,
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = (constraints) => {
        const getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia
        if (!getUserMedia) {
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser')
          )
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    }
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then((stream) => {
        proctoringConnectionRefs.current.streams = stream
        setIsStreamOpen(() => true)
      })
      .catch((err) => {
        console.error(err)
        videoError()
      })
  }

  if (isStreamOpen && (!audioPermission || !videoPermission)) {
    proctoringConnectionRefs.current?.streams?.getTracks()?.forEach((track) => {
      if (track.kind === 'audio') {
        updateAudioPermission(true)
      } else if (track.kind === 'video') {
        updateVideoPermission(true)
      }
    })
  }

  const closeProctoring = () => {
    proctoringConnectionRefs.current?.streams?.getTracks()?.forEach((track) => {
      setIsStreamOpen(() => false)
      track.stop()
    })
  }

  React.useEffect(() => {
    if (!openTestVideoDialog) {
      closeProctoring()
    }
  }, [openTestVideoDialog])

  const {
    isLoading: isQuizInfoLoading,
    isError: isQuizInfoError,
    isSuccess: isQuizInfoSuccess,
    refetch: quizInfoRefetch,
    error: quizInfoError,
  } = useQuizGetQueryById(quizId, {
    enabled: false,
    staleTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (successResponse) => {
      setQuizTitle(successResponse?.info?.title)
      setShowResult(successResponse?.quizConfig?.showResult)
      setQuizInfoDetails(() => successResponse)
      setIsProctored(() => successResponse?.quizConfig?.isProctored)
      sessionStorage.setItem('quizType', successResponse?.info?.type)
      localStorage.setItem('slug', successResponse?.info?.slug)
      sessionStorage?.setItem(
        'instructions',
        successResponse?.info?.instructions
      )
      if (successResponse?.time?.start) {
        setIsQuizStarted(() => {
          return isDateTimeInThePast(successResponse?.time?.start)
        })
      } else setIsQuizStarted(() => true)

      if (successResponse?.info?.isClosed) setIsQuizEnded(() => true)
      else if (successResponse?.time?.end) {
        setIsQuizEnded(() => {
          return isDateTimeInTheFuture(successResponse?.time?.end)
        })
      } else setIsQuizEnded(() => false)
    },
    onError: (errorResponse) => {
      if (errorResponse?.errorCode === 'REDIRECT') {
        const correctSlug = errorResponse?.errorReason?.split('to: ')?.[1]
        window.location.href = `/quiz/${correctSlug}`
        return null
      }
      return null
    },
  })

  useEffect(() => {
    if (areCookiesEnabled()) {
      if (!isLoggedIn()) sessionStorage?.clear()
      localStorage?.clear()
      quizInfoRefetch()
    }
  }, [])
  const tenantID = quizInfoDetails.orgID
  const handleResponse = (response) => {
    const loggedInUserInfo = decodeJwt(response?.accessToken)
    if (loggedInUserInfo) {
      Cookies.set(`${loggedInUserInfo?.sub}_token`, response?.accessToken)
      Cookies.set('refresh_token', response?.refreshToken)
      sessionStorage.setItem('isLoggedIn', 'true')
      sessionStorage.setItem('userID', loggedInUserInfo?.sub)
      sessionStorage.setItem('userID_token', response?.accessToken)
      if (isQuizStarted) return history.push(`/quiz/${quizId}/questions`)
      setIsLogin(true)
    }
    return ''
  }
  let providers
  if (username) {
    providers = [
      {
        name: 'Guest',
        username,
        onSuccess: handleResponse,
      },
    ]
  }
  const { guestLogin } = useAuth(
    'tokenStorage',
    MOCK_BASE_URL,
    LOGIN_ENDPOINT,
    REFRESH_TOKEN_URL,
    providers,
    '',
    '',
    tenantID,
    'sessionStorage'
  )
  const handlePublicQuizStart = () => {
    if (username) {
      providers = [
        {
          name: 'Guest',
          username,
          onSuccess: handleResponse,
        },
      ]
      guestLogin()
    } else {
      setUsernameError(() => true)
    }
  }

  const mutation = useQuizPostQueryById(quizId, {
    onError: (err) => {
      window.removeEventListener('beforeunload', alertUser)
      if (err?.statusCode === 401) {
        setIsNonExistingTokenError(true)
        setAuthError(true)
      }
      if (err?.statusCode !== 401) {
        setQuizErrorCode(err?.errorCode)
        setQuizErrorReason(err?.errorReason)
      }
      if (
        err?.errorCode === 'CONFLICT' ||
        err?.errorCode === 'SUBMISSION_EXISTS'
      ) {
        setUserResponseErrorCode(err?.errorCode)
        setUserResponseErrorReason(err?.errorReason)
      }
    },
  })
  useEffect(() => {
    if (isLogin) mutation.mutate()
  }, [isLogin])

  useEffect(() => {
    if (mutation?.data && mutation?.isSuccess) {
      const successResponse = mutation?.data
      if (successResponse?.info?.submissionTime) {
        setUserResponseErrorCode('SUBMISSION_EXISTS')
        setUserResponseErrorReason('You have already submitted the quiz.')
      }
      setAuthError(false)
    }
  }, [mutation?.data, mutation?.isSuccess])

  if (mutation?.isError) {
    closeProctoring()
    const userResponseErrorType = returnErrors(userResponseErrorCode)
    const quizErrorType = returnErrors(quizErrorCode)
    if (userResponseErrorType === 'ACCESS_DENIED') {
      return <AccessDenied reason={userResponseErrorReason} />
    }
    if (quizErrorType === 'ACCESS_DENIED') {
      return <AccessDenied reason={quizErrorReason} />
    }
    if (userResponseErrorType === 'QUIZ_ALREADY_SUBMITTED') {
      return (
        <QuizAlreadySubmitted
          reason={userResponseErrorReason}
          showResult={showResult}
          responseId={responseId}
          quizTitle={quizTitle}
          quizId={quizId}
        />
      )
    }
    if (quizErrorType === 'QUIZ_ALREADY_SUBMITTED') {
      return (
        <QuizAlreadySubmitted
          reason={quizErrorReason}
          showResult={showResult}
          responseId={responseId}
          quizTitle={quizTitle}
          quizId={quizId}
        />
      )
    }
    if (userResponseErrorType === 'INTERNAL_SERVER_ERROR') {
      return <InternalServerError reason={userResponseErrorReason} />
    }
    if (quizErrorType === 'INTERNAL_SERVER_ERROR') {
      return <InternalServerError reason={quizErrorReason} />
    }
    if (quizErrorType === 'QUIZ_NOT_FOUND')
      return <QuizNotFound reason={quizErrorReason} />

    if (isNonExistingTokenError) {
      return <Redirect to={`/quiz/${quizId}`} />
    }

    if (quizErrorType === 'REDIRECT' || userResponseErrorType === 'REDIRECT') {
      return null
    }

    return <PageNotFound />
  }

  if (!areCookiesEnabled()) {
    return (
      <CookiesNotAllowed reason="Allow all cookies and site data to continue" />
    )
  }

  if (isQuizInfoError) {
    const errorType = returnErrors(quizInfoError.errorCode)
    if (errorType === 'QUIZ_NOT_FOUND')
      return <QuizNotFound reason={quizInfoError.errorReason} />
    if (errorType === 'ACCESS_DENIED') {
      return <AccessDenied reason={quizInfoError.errorReason} />
    }
    if (errorType === 'INTERNAL_SERVER_ERROR') {
      return <InternalServerError reason={quizInfoError.errorReason} />
    }
    if (errorType === 'REDIRECT') {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: `${windowSize?.height}px` }}
        >
          <Loader size="8rem" />
        </Grid>
      )
    }
    return <PageNotFound />
  }

  if (isQuizInfoSuccess) {
    return (
      <Grid
        className={classes.root}
        style={{
          height: `${windowSize?.height}px`,
        }}
        data-testid="quizOnBoardingPageSuccess-testId"
      >
        <Helmet>
          <title>{quizInfoDetails?.info?.title}</title>
        </Helmet>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={logInFailed}
          autoHideDuration={3000}
          onClose={() => setLogInFailed(() => false)}
          message="Login Failed"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setLogInFailed(() => false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          key="Login Failed"
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={usernameError}
          autoHideDuration={3000}
          onClose={() => setUsernameError(() => false)}
          message="Enter a valid name"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setUsernameError(() => false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          key="Enter a valid name"
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={startQuizFailed}
          autoHideDuration={3000}
          onClose={() => setStartQuizFailed(() => false)}
          message="Quiz failed to start!"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setStartQuizFailed(() => false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          key="Quiz failed to start!"
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isCameraDisabled}
          autoHideDuration={3000}
          onClose={() => setIsCameraDisabled(() => false)}
          message="Allow camera and microphone access!"
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={() => setIsCameraDisabled(() => false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          key="Allow camera and microphone access!"
        />

        <TestVideoDialog
          candidateStream={proctoringConnectionRefs.current.streams}
          openTestVideoDialog={openTestVideoDialog}
          setOpenTestVideoDialog={setOpenTestVideoDialog}
        />

        {isWidthAboveTabRange ? (
          <QuizOnboardingPage
            quizInfoDetails={quizInfoDetails}
            username={username}
            setUsername={setUsername}
            handlePublicQuizStart={handlePublicQuizStart}
            isQuizStarted={isQuizStarted}
            setIsQuizStarted={setIsQuizStarted}
            isQuizEnded={isQuizEnded}
            isProctored={isProctored}
            startCapturingVideo={startCapturingVideo}
            setOpenTestVideoDialog={setOpenTestVideoDialog}
            setIsLogin={setIsLogin}
            isLogin={isLogin}
          />
        ) : (
          <QuizOnboardingPageMobile
            quizInfoDetails={quizInfoDetails}
            username={username}
            setUsername={setUsername}
            handlePublicQuizStart={handlePublicQuizStart}
            isQuizStarted={isQuizStarted}
            setIsQuizStarted={setIsQuizStarted}
            isQuizEnded={isQuizEnded}
            isProctored={isProctored}
            startCapturingVideo={startCapturingVideo}
            setOpenTestVideoDialog={setOpenTestVideoDialog}
            setIsLogin={setIsLogin}
            isLogin={isLogin}
          />
        )}
      </Grid>
    )
  }
  if (isQuizInfoLoading) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: `${windowSize?.height}px` }}
      >
        <Loader size="8rem" />
      </Grid>
    )
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: `${windowSize?.height}px` }}
      data-testid="quizOnBoardingPageLoading-testId"
    >
      <Loader size="8rem" />
    </Grid>
  )
}

export default QuizOnboardingPageWrapper
