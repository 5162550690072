import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  multiChoice: {
    color: '#00B7A4',
  },
  choice: {
    boxSizing: 'border-box',
    justifyContent: 'flex-start',
    width: '100%',
    background: theme?.palette?.secondaryButton?.main,
    color: '#00000',
    border: (props) =>
      props.isSelected || props?.isMultiSelected
        ? `solid 1px ${theme?.palette?.primarySelection?.main}`
        : 'solid 1px #FFFFFF',
    marginBottom: '0.7rem',
    '&:hover': {
      border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
      background: theme?.palette?.secondaryButton?.main,
    },
  },

  choiceText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
  },
}))

export { useStyles }
