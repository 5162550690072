import React from 'react'

function AccessDeniedIllustration() {
  return (
    <svg viewBox="0 0 470 300" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path
          d="M0,0 L50.7305195,0 L45.3260158,42.7171985 C45.0694006,44.7454854 43.3441266,46.2662338 41.2996709,46.2662338 L10.0317399,46.2662338 C8.00860755,46.2662338 6.29444846,44.7761899 6.01281412,42.7727561 L0,0 L0,0 Z"
          id="path-1"
        />
        <path
          d="M15.9711209,9.71506518 C16.2834248,3.41247175 13.9628404,0.261175081 9.00936802,0.261175081 C1.57915901,0.261175081 0.0785733766,6.5118071 0.0785733766,10.4478813 C0.0785733766,12.2130346 -0.396016721,16.7155087 1.59964927,23.3396592 C2.24702224,25.4884648 3.94514156,29.1842846 6.69400641,34.4271185 C19.3361558,30.6344738 27.1405401,24.9401499 30.1071594,17.3441468 C34.5570888,5.95014213 25.5129971,3.96302127 22.0518071,5.57208295 C19.7443469,6.64479071 17.7174516,8.02578478 15.9711209,9.71506518 Z"
          id="path-3"
        />
        <path
          d="M14.1110925,0 C17.4981201,0 20.2438486,2.69646685 20.2438486,6.02272727 C20.2438486,7.49696372 19.7044878,8.84748584 18.8089838,9.89436327 L25.6615459,9.89448052 C26.7822537,9.89448052 27.6907666,10.8029936 27.6907666,11.9237013 L27.6907666,19.4805195 C27.6907666,20.6012272 26.7822537,21.5097403 25.6615459,21.5097403 L2.12258482,21.5097403 C1.00187703,21.5097403 0.0933640422,20.6012272 0.0933640422,19.4805195 L0.0933640422,11.9237013 C0.0933640422,10.8029936 1.00187703,9.89448052 2.12258482,9.89448052 L9.4132013,9.89436327 C8.51769724,8.84748584 7.97833644,7.49696372 7.97833644,6.02272727 C7.97833644,2.69646685 10.7240645,0 14.1110925,0 Z M14.1110925,3.87175325 C13.1433701,3.87175325 12.3588762,4.64217236 12.3588762,5.59253247 C12.3588762,6.54289257 13.1433701,7.31331169 14.1110925,7.31331169 C15.0788145,7.31331169 15.8633084,6.54289257 15.8633084,5.59253247 C15.8633084,4.64217236 15.0788145,3.87175325 14.1110925,3.87175325 Z"
          id="path-5"
        />
      </defs>
      <g id="02" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="when-we-enter-a-quiz-if-we-get-access-denied-error-(-access-denied-with-some-illustration-)"
          transform="translate(1.000000, 16.000000)"
        >
          <g id="Group-28" transform="translate(0.045455, 0.288961)">
            <g
              id="Group-10-Copy-3"
              transform="translate(66.558442, 0.000000)"
              fill="#DFF1F1"
              fillRule="nonzero"
            >
              <path
                d="M1.09802948,249.188312 C-2.74016841,232.413408 3.48404321,214.361216 19.7706645,195.031735 C44.2005962,166.037513 51.7907313,159.437396 40.7011092,129.822336 C29.6114872,100.207276 3.57426063,38.0344053 59.2645337,10.4706049 C114.954806,-17.0931956 143.657009,17.4387147 172.90645,26.6290223 C202.155892,35.81933 285.419462,58.3416376 317.489385,109.147111 C349.559312,159.952585 323.310787,159.289837 317.489385,197.773508 C311.667986,236.257179 330.811891,249.188312 307.233087,249.188312 C291.513888,249.188312 189.468868,249.188312 1.09802948,249.188312 Z"
                id="Mask"
              />
            </g>
            <g id="Group-27" transform="translate(233.360390, 106.737013)">
              <path
                d="M20.2922078,52.6605564 C40.4606384,52.6605564 51.8242748,64.5653186 54.3831169,88.3748421"
                id="Path-14"
                stroke="#252B4C"
                strokeWidth="0.811688312"
              />
              <path
                d="M0,50.6313356 L28.8149351,51.8637094 C28.8149351,62.766444 24.012446,66.5746465 14.4074675,63.2883166 C4.80248916,60.0019866 0,55.7829931 0,50.6313356 Z"
                id="Path-25"
                fill="#00B8A5"
                fillRule="nonzero"
              />
              <line
                x1="63.8353364"
                y1="21.8164008"
                x2="63.1938843"
                y2="88.3748421"
                id="Path-21"
                stroke="#252B4C"
                strokeWidth="0.811688312"
              />
              <path
                d="M75.758666,87.968998 C74.0367309,64.7596514 74.367515,49.0341989 76.7510187,40.7926404 C77.3211465,38.8212797 78.0520126,36.9982532 78.9436161,35.3235608 C83.2057658,27.3179696 93.1499034,24.2820905 101.156656,28.5420586 C105.702401,30.9606085 107.426834,36.6062851 105.008284,41.1520304 C104.906257,41.3437934 104.79756,41.5319322 104.682391,41.7161023"
                id="Path-22"
                stroke="#252B4C"
                strokeWidth="0.811688312"
              />
              <path
                d="M85.6678689,87.5631538 C85.101916,74.8467037 91.4485572,68.4884785 104.707792,68.4884785"
                id="Path-23"
                stroke="#252B4C"
                strokeWidth="0.811688312"
              />
              <path
                d="M96.5909091,66.4592577 L125.405844,67.6916315 C125.405844,78.5943661 120.603355,82.4025686 110.998377,79.1162386 C101.393398,75.8299087 96.5909091,71.6109152 96.5909091,66.4592577 Z"
                id="Path-25-Copy"
                fill="#00B8A5"
                fillRule="nonzero"
                transform="translate(110.998377, 73.358608) scale(-1, 1) translate(-110.998377, -73.358608) "
              />
              <g id="Path-38" transform="translate(43.425325, 87.968998)">
                <g
                  id="Mask"
                  fill="#F2F8F8"
                  fillRule="nonzero"
                  stroke="#252B4C"
                  strokeWidth="0.811688312"
                >
                  <path
                    d="M0,0 L50.7305195,0 L45.3260158,42.7171985 C45.0694006,44.7454854 43.3441266,46.2662338 41.2996709,46.2662338 L10.0317399,46.2662338 C8.00860755,46.2662338 6.29444846,44.7761899 6.01281412,42.7727561 L0,0 L0,0 Z"
                    id="path-1"
                  />
                </g>
                <g id="Clipped">
                  <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <g id="path-1" />
                  <polygon
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                    opacity="0.0339752907"
                    mask="url(#mask-2)"
                    points="16.7587113 -14.6103895 17.7667778 46.4044395 6.49163019 46.5081477 2.46208693 -6.64570126"
                  />
                </g>
              </g>
              <path
                d="M106.833716,31.6553726 C107.145683,25.3666208 104.827606,22.2222449 99.8794863,22.2222449 C92.4573069,22.2222449 90.9583426,28.4591495 90.9583426,32.3865793 C90.9583426,34.1478561 90.4842651,38.640442 92.4777745,45.2500446 C93.1244486,47.3941311 94.8207326,51.081834 97.5666271,56.3131538 C110.195115,52.5288385 117.991065,46.8470203 120.954479,39.2676995 C125.3996,27.898718 116.365281,25.9159612 112.907832,27.5214891 C110.602865,28.591841 108.57816,29.9698022 106.833716,31.6553726 Z"
                id="Path-24"
                fill="#00B8A5"
                fillRule="nonzero"
                transform="translate(106.534091, 39.267699) scale(-1, 1) translate(-106.534091, -39.267699) "
              />
              <g id="Path-37" transform="translate(40.244957, 0.000000)">
                <g
                  id="Mask"
                  transform="translate(21.202838, 22.067353) rotate(20.000000) translate(-21.202838, -22.067353) translate(5.202838, 4.567353)"
                  fill="#00B8A5"
                  fillRule="nonzero"
                >
                  <path
                    d="M15.9711209,9.71506518 C16.2834248,3.41247175 13.9628404,0.261175081 9.00936802,0.261175081 C1.57915901,0.261175081 0.0785733766,6.5118071 0.0785733766,10.4478813 C0.0785733766,12.2130346 -0.396016721,16.7155087 1.59964927,23.3396592 C2.24702224,25.4884648 3.94514156,29.1842846 6.69400641,34.4271185 C19.3361558,30.6344738 27.1405401,24.9401499 30.1071594,17.3441468 C34.5570888,5.95014213 25.5129971,3.96302127 22.0518071,5.57208295 C19.7443469,6.64479071 17.7174516,8.02578478 15.9711209,9.71506518 Z"
                    id="path-3"
                  />
                </g>
                <g id="Clipped" transform="translate(5.275974, 4.464286)">
                  <mask id="mask-4" fill="white">
                    <use xlinkHref="#path-3" />
                  </mask>
                  <g id="path-3" />
                  <polygon
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                    opacity="0.0339752907"
                    mask="url(#mask-4)"
                    points="14.6631048 -1.11189661 14.6631048 29.5719916 11.3772093 33.4470518 2.82294874 10.3890267 7.66723259 -9.81216433"
                  />
                </g>
              </g>
            </g>
            <g id="Group-23" transform="translate(0.000000, 69.033507)">
              <g id="Group-25" transform="translate(142.956537, 0.000000)">
                <rect
                  id="Rectangle"
                  fill="#00B8A5"
                  fillRule="nonzero"
                  x="32.3681389"
                  y="15.4210991"
                  width="115.665584"
                  height="156.655844"
                  rx="2.02922078"
                />
                <rect
                  id="Rectangle-Copy-2"
                  stroke="#252B4C"
                  strokeWidth="0.5"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                  strokeLinecap="round"
                  x="40.4831457"
                  y="23.3045597"
                  width="99.8376623"
                  height="140.422078"
                  rx="2.02922078"
                />
                <circle
                  id="Oval"
                  fill="#EFEFEF"
                  fillRule="nonzero"
                  cx="61.8766234"
                  cy="48.7012987"
                  r="8.92857143"
                />
                <circle
                  id="Oval-Copy-14"
                  fill="#EFEFEF"
                  fillRule="nonzero"
                  cx="61.8766234"
                  cy="75.8928571"
                  r="8.92857143"
                />
                <path
                  d="M58.8391119,49.4476618 L59.6333132,50.3149926 C59.9173753,50.6252117 60.3913615,50.6670299 60.7253526,50.41134 L65.4365003,46.8046772 L65.4365003,46.8046772"
                  id="Path-35"
                  stroke="#00B8A5"
                  strokeWidth="1.62337662"
                  strokeLinecap="round"
                  transform="translate(62.137806, 48.691608) rotate(-11.000000) translate(-62.137806, -48.691608) "
                />
                <path
                  d="M58.8391119,76.6392202 L59.6333132,77.506551 C59.9173753,77.8167702 60.3913615,77.8585883 60.7253526,77.6028984 L65.4365003,73.9962357 L65.4365003,73.9962357"
                  id="Path-35-Copy"
                  stroke="#00B8A5"
                  strokeWidth="1.62337662"
                  strokeLinecap="round"
                  transform="translate(62.137806, 75.883166) rotate(-11.000000) translate(-62.137806, -75.883166) "
                />
                <circle
                  id="Oval-Copy-15"
                  fill="#EFEFEF"
                  fillRule="nonzero"
                  cx="61.8766234"
                  cy="103.084416"
                  r="8.92857143"
                />
                <circle
                  id="Oval-Copy-16"
                  fill="#EFEFEF"
                  fillRule="nonzero"
                  cx="61.8766234"
                  cy="130.275974"
                  r="8.92857143"
                />
                <g
                  id="Group-26"
                  transform="translate(78.110390, 43.831169)"
                  fill="#EFEFEF"
                  fillRule="nonzero"
                >
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="36.525974"
                    height="3.6525974"
                    rx="1.8262987"
                  />
                  <rect
                    id="Rectangle-Copy-13"
                    x="0"
                    y="27.1915584"
                    width="37.3376623"
                    height="3.6525974"
                    rx="1.8262987"
                  />
                  <rect
                    id="Rectangle-Copy-12"
                    x="0"
                    y="6.89935065"
                    width="46.6720779"
                    height="3.6525974"
                    rx="1.8262987"
                  />
                  <rect
                    id="Rectangle-Copy-14"
                    x="0"
                    y="34.0909091"
                    width="46.6720779"
                    height="3.6525974"
                    rx="1.8262987"
                  />
                  <rect
                    id="Rectangle-Copy-16"
                    x="0"
                    y="54.3831169"
                    width="25.1623377"
                    height="3.6525974"
                    rx="1.8262987"
                  />
                  <rect
                    id="Rectangle-Copy-15"
                    x="0"
                    y="61.2824675"
                    width="46.6720779"
                    height="3.6525974"
                    rx="1.8262987"
                  />
                  <rect
                    id="Rectangle-Copy-18"
                    x="0"
                    y="81.5746753"
                    width="30.8441558"
                    height="3.6525974"
                    rx="1.8262987"
                  />
                  <rect
                    id="Rectangle-Copy-17"
                    x="0"
                    y="88.474026"
                    width="46.6720779"
                    height="3.6525974"
                    rx="1.8262987"
                  />
                </g>
                <path
                  d="M32.3681389,84.7814278 L33.4864085,81.7067707 C33.7928552,80.8642021 34.7243159,80.4295897 35.5668841,80.7360365 C35.8985297,80.8566579 36.1818373,81.0820645 36.3739026,81.3781205 L79.9943929,148.616224 C81.0922922,150.308562 80.6104034,152.570497 78.9180645,153.668397 C78.3261558,154.052395 77.6356993,154.256743 76.930142,154.256743 L32.3681389,154.256743 L32.3681389,154.256743 L32.3681389,84.7814278 Z"
                  id="Path-36"
                  fill="#000000"
                  fillRule="nonzero"
                  opacity="0.0339752907"
                />
                <g
                  id="Group-5"
                  transform="translate(41.367143, 162.298396) scale(-1, 1) rotate(-112.000000) translate(-41.367143, -162.298396) translate(18.867143, 127.798396)"
                  fillRule="nonzero"
                >
                  <g id="Group-3" fill="#00B8A5">
                    <polygon
                      id="Path-9"
                      points="3.83414298 55.2885816 0 68.5090593 11.0397942 59.2228028"
                    />
                    <path
                      d="M35.1612922,3.93662552 L43.1306485,8.52934058 L44.4078178,6.16531441 C45.1081234,4.86905564 45.0794079,3.31215879 44.3317565,2.04143224 C43.5886863,0.778492285 42.2121096,0 40.7219732,0 L40.4744823,0 C38.8161606,0 37.2686909,0.814320142 36.3552671,2.1676417 L35.1612922,3.93662552 L35.1612922,3.93662552 Z"
                      id="Path-11"
                    />
                  </g>
                  <polygon
                    id="Path-13"
                    fill="#FFFFFF"
                    points="4.22473567 54.1846339 11.9810426 58.4946323 42.2601522 9.45382788 34.3947841 5.04296059"
                  />
                  <polygon
                    id="Combined-Shape"
                    fill="#00B8A5"
                    points="34.3947841 5.04296059 36.1538989 6.05070219 5.83678661 55.0828961 4.22473567 54.1846339"
                  />
                  <polygon
                    id="Combined-Shape-Copy"
                    fill="#00B8A5"
                    points="37.4479108 6.74882869 39.2070256 7.75657029 8.88991331 56.7887642 7.27786242 55.890502"
                  />
                  <polygon
                    id="Combined-Shape-Copy-2"
                    fill="#00B8A5"
                    points="40.5010375 8.45469683 42.2601522 9.46243839 11.9430401 58.4946323 10.3309891 57.5963701"
                  />
                </g>
                <g
                  id="Mask"
                  transform="translate(75.675325, 0.000000)"
                  fill="#00B8A5"
                  fillRule="nonzero"
                >
                  <path
                    d="M14.1110925,0 C17.4981201,0 20.2438486,2.69646685 20.2438486,6.02272727 C20.2438486,7.49696372 19.7044878,8.84748584 18.8089838,9.89436327 L25.6615459,9.89448052 C26.7822537,9.89448052 27.6907666,10.8029936 27.6907666,11.9237013 L27.6907666,19.4805195 C27.6907666,20.6012272 26.7822537,21.5097403 25.6615459,21.5097403 L2.12258482,21.5097403 C1.00187703,21.5097403 0.0933640422,20.6012272 0.0933640422,19.4805195 L0.0933640422,11.9237013 C0.0933640422,10.8029936 1.00187703,9.89448052 2.12258482,9.89448052 L9.4132013,9.89436327 C8.51769724,8.84748584 7.97833644,7.49696372 7.97833644,6.02272727 C7.97833644,2.69646685 10.7240645,0 14.1110925,0 Z M14.1110925,3.87175325 C13.1433701,3.87175325 12.3588762,4.64217236 12.3588762,5.59253247 C12.3588762,6.54289257 13.1433701,7.31331169 14.1110925,7.31331169 C15.0788145,7.31331169 15.8633084,6.54289257 15.8633084,5.59253247 C15.8633084,4.64217236 15.0788145,3.87175325 14.1110925,3.87175325 Z"
                    id="path-5"
                  />
                </g>
                <g
                  id="Path-2-Copy-Clipped"
                  transform="translate(75.675325, 0.000000)"
                >
                  <mask id="mask-6" fill="white">
                    <use xlinkHref="#path-5" />
                  </mask>
                  <g id="path-5" />
                  <path
                    d="M26.6196976,8.81444464 C26.6196976,12.5585292 13.8138287,22.0228149 3.21882183,22.0228149 C-3.84451583,22.0228149 -1.04464245,25.6344565 11.618442,32.8577394 L38.9684712,24.8313663 L34.3958547,10.4075552 C29.21175,6.84942508 26.6196976,6.31838823 26.6196976,8.81444464 Z"
                    id="Path-2-Copy"
                    fill="#07A696"
                    fillRule="nonzero"
                    mask="url(#mask-6)"
                  />
                </g>
              </g>
              <rect
                id="Rectangle"
                fill="#B2CECE"
                fillRule="nonzero"
                x="0"
                y="172.180651"
                width="466.314935"
                height="8.11688312"
                rx="4.05844156"
              />
            </g>
            <g
              id="Group-5"
              transform="translate(145.698052, 161.525974)"
              fillRule="nonzero"
            >
              <polygon
                id="Triangle"
                stroke="#D70022"
                strokeWidth="8.11688312"
                fill="#FFFFFF"
                points="31.8587662 0.280642248 63.5132614 53.9772727 0.204271104 53.9772727"
              />
              <g
                id="!"
                transform="translate(28.814935, 17.045455)"
                fill="#D70022"
                fontFamily="OpenSans-SemiBold, Open Sans"
                fontSize="21.9155844"
                fontWeight="500"
              >
                <text>
                  <tspan x="0" y="23">
                    !
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AccessDeniedIllustration
