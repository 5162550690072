import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MCQResultRowItem from '../MCQResultRowItem/MCQResultRowItem'
import MCQResultRowItemMobile from '../MCQResultRowItemMobile/MCQResultRowItemMobile'
import CodingQuestionResultRowItem from '../CodingQuestionResultRowItem/CodingQuestionResultRowItem'
import CodingQuestionResultRowItemTab from '../CodingQuestionResultRowItemTab/CodingQuestionResultRowItemTab'
import CodingQuestionResultRowItemMobile from '../CodingQuestionResultRowItemMobile/CodingQuestionResultRowItemMobile'
import EssayResultRowItem from '../EssayResultRowItem/EssayResultRowItem'
import EssayResultRowItemMobile from '../EssayResultRowItemMobile/EssayResultRowItemMobile'
import FillInTheBlanksResultRowItem from '../FillInTheBlanksResultRowItem/FillInTheBlanksResultRowItem'
import FillInTheBlanksResultRowItemMobile from '../FillInTheBlanksResultRowItemMobile/FillInTheBlanksResultRowItemMobile'
import { useStyles } from './ResultPageStyles'

function ResultPage({ result, title }) {
  const areMCQPresent = result?.evaluatedResult?.mcq?.length > 0
  const areCodingQuestionsPresent = result?.evaluatedResult?.coding?.length > 0
  const areFillInTheBlanksPresent =
    result?.evaluatedResult?.fillInBlanks?.length > 0
  const areEssayQuestionsPresent = result?.evaluatedResult?.essay?.length > 0

  const [currentTab, setCurrentTab] = useState('')

  useEffect(() => {
    if (areMCQPresent) {
      setCurrentTab('mcq')
    } else if (areCodingQuestionsPresent) {
      setCurrentTab('coding')
    } else if (areEssayQuestionsPresent) {
      setCurrentTab('essay')
    } else if (areFillInTheBlanksPresent) {
      setCurrentTab('fill-in-the-blanks')
    }
  }, [])

  const questionsResultContainerRef = useRef()
  useEffect(() => {
    if (currentTab) {
      questionsResultContainerRef.current.scrollTop = 0
    }
  }, [])

  const isWidthAboveMediumMobileRange = useMediaQuery(
    'only screen and (min-width:365px)'
  )

  const isWidthAboveSmallTabRange = useMediaQuery(
    'only screen and (min-width:650px)'
  )

  const isWidthAboveSmallLaptopRange = useMediaQuery(
    'only screen and (min-width:950px)'
  )
  const classes = useStyles({
    currentTab,
    areCodingQuestionsPresent,
    areEssayQuestionsPresent,
    areFillInTheBlanksPresent,
  })
  const returnTable = () => {
    if (currentTab === 'mcq') {
      return (
        <>
          {result?.evaluatedResult?.mcq?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              {isWidthAboveSmallTabRange ? (
                <MCQResultRowItem question={question} />
              ) : (
                <MCQResultRowItemMobile question={question} />
              )}
            </Grid>
          ))}
        </>
      )
    }
    if (currentTab === 'essay') {
      return (
        <>
          {result?.evaluatedResult?.essay?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              {isWidthAboveSmallTabRange ? (
                <EssayResultRowItem question={question} />
              ) : (
                <EssayResultRowItemMobile question={question} />
              )}
            </Grid>
          ))}
        </>
      )
    }

    if (currentTab === 'fill-in-the-blanks') {
      return (
        <>
          {result?.evaluatedResult?.fillInBlanks?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              {isWidthAboveSmallTabRange ? (
                // Need to create component for the results
                <FillInTheBlanksResultRowItem question={question} />
              ) : (
                // Need to create component for the results
                <FillInTheBlanksResultRowItemMobile question={question} />
              )}
            </Grid>
          ))}
        </>
      )
    }

    if (currentTab === 'coding') {
      if (isWidthAboveSmallLaptopRange) {
        return (
          <>
            {result?.evaluatedResult?.coding?.map((question, index) => (
              <Grid
                item
                key={index.toString(2)}
                className={classes.quizResults}
              >
                <CodingQuestionResultRowItem question={question} />
              </Grid>
            ))}
          </>
        )
      }
      if (isWidthAboveSmallTabRange) {
        return (
          <>
            {result?.evaluatedResult?.coding?.map((question, index) => (
              <Grid
                item
                key={index.toString(2)}
                className={classes.quizResults}
              >
                <CodingQuestionResultRowItemTab question={question} />
              </Grid>
            ))}
          </>
        )
      }

      return (
        <>
          {result?.evaluatedResult?.coding?.map((question, index) => (
            <Grid item key={index.toString(2)} className={classes.quizResults}>
              <CodingQuestionResultRowItemMobile question={question} />
            </Grid>
          ))}
        </>
      )
    }

    return null
  }

  return (
    <Grid container direction="column" className={classes.root}>
      <Helmet>
        <title>{`${title} - Results `}</title>
      </Helmet>

      <Grid container className={classes.tabsWrapper}>
        {areMCQPresent ? (
          <Grid
            item
            component="button"
            onClick={() => setCurrentTab('mcq')}
            className={classes.mcqTab}
          >
            {isWidthAboveMediumMobileRange ? (
              <Typography className={classes.mcqTabText}>
                Multiple Choice Questions
              </Typography>
            ) : (
              <Typography className={classes.mcqTabText}>MCQ</Typography>
            )}
          </Grid>
        ) : null}
        {areCodingQuestionsPresent ? (
          <Grid
            item
            component="button"
            onClick={() => setCurrentTab('coding')}
            className={classes.codingTab}
          >
            {isWidthAboveMediumMobileRange ? (
              <Typography className={classes.codingTabText}>
                Coding Questions
              </Typography>
            ) : (
              <Typography className={classes.codingTabText}>Coding</Typography>
            )}
          </Grid>
        ) : null}

        {areEssayQuestionsPresent ? (
          <Grid
            item
            component="button"
            onClick={() => setCurrentTab('essay')}
            className={classes.essayTab}
          >
            {isWidthAboveMediumMobileRange ? (
              <Typography className={classes.essayTabText}>
                Essay Questions
              </Typography>
            ) : (
              <Typography className={classes.essayTabText}>Essay</Typography>
            )}
          </Grid>
        ) : null}

        {areFillInTheBlanksPresent ? (
          <Grid
            item
            component="button"
            onClick={() => setCurrentTab('fill-in-the-blanks')}
            className={classes.fillInTheBlanksTab}
          >
            {isWidthAboveMediumMobileRange ? (
              <Typography className={classes.fillInTheBlanksTabText}>
                Fill In The Blanks Questions
              </Typography>
            ) : (
              <Typography className={classes.fillInTheBlanksTabText}>
                Fill In The Blanks
              </Typography>
            )}
          </Grid>
        ) : null}
      </Grid>

      <Grid item className={classes.tableContainer}>
        <Grid
          item
          ref={questionsResultContainerRef}
          className={classes.tableWrapper}
        >
          <Grid
            container
            direction="column"
            className={classes.resultsPageWrapper}
          >
            {returnTable()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResultPage
