import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: 'max-content',
    width: '100%',
    border: `solid 1px ${theme?.palette?.lightBorders?.main}`,
    borderRadius: '20px',
  },
  planNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '15px 0px',
    background: theme?.palette?.planNameBackground?.main,
    borderRadius: '20px 20px 0 0',
  },
  planName: {
    paddingLeft: '22px',
    color: 'black',
    fontSize: '16px',
    fontWeight: 600,
  },
  amountWrapper: {
    display: 'flex',
    paddingBottom: '10px',
    color: 'black',
  },
  amount: {
    paddingLeft: '22px',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '105%',
    color: theme?.palette?.greenTextColor?.main,
    '@media only screen and (min-width:769px)': {
      fontSize: '28px',
    },
  },
  amountSubHeading: {
    paddingBottom: '30px',
    paddingLeft: '22px',
    color: theme?.palette?.darkerTextColor?.main,
  },
  featuresWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 10px',
    background: theme?.palette?.darkBackground?.main,
    borderRadius: '0 0 20px 20px',
    height: 'max-content',
    flex: 1,
  },
  brightness1Icon: {
    paddingBottom: '4px',
    paddingLeft: '10px',
    fontSize: '20px',
    color: '#252B4C',
  },
  descriptionText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    color: theme?.palette?.darkerTextColor?.main,
    textAlign: 'left',
    fontSize: '12px',
    '@media only screen and (min-width:600px)': {
      fontSize: '14px',
    },
  },
  titleSubHeading: {
    paddingLeft: '22px',
    color: '#262A4C',
    fontSize: '14px',
  },
  amountSubHeadingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 0px',
    background: 'white',
  },
  featuresChildWrapper: {
    display: 'flex',
    width: '100%',
  },
  brightness1IconWrapper: {
    width: 'max-content',
    padding: '2px 10px 0px 0px',
  },
  featuresTextWrapper: {
    width: 0,
    flex: 1,
  },
}))
export { useStyles }
