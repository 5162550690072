import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
    width: '100%',
    padding: '0 15px',
    boxShadow: '0 2px 4px rgb(167, 167, 167, 0.1)',
    '@media only screen and (min-width:426px)': {
      padding: '0 25px',
      height: '55px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '0 40px',
      height: '65px',
    },
  },
  homeWrapper: {
    color: 'inherit',
    textDecoration: 'none',
    paddingRight: '20px',
    '@media only screen and (min-width:426px)': {
      paddingRight: '30px',
    },
  },
  pricingWrapper: {
    color: 'inherit',
    textDecoration: 'none',
  },
  activeSelection: {
    color: theme?.palette?.primarySelection?.main,
  },
  logoContaier: {
    textDecoration: 'none',
  },
}))
export { useStyles }
