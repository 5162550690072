import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './CompilationFailedContentStyles'

function CompilationFailedContent({
  compileMsg,
  editorWrapperWidth,
  consoleBodyHeight,
}) {
  let fontSize

  if (editorWrapperWidth < 350) {
    fontSize = '14px'
  } else if (editorWrapperWidth < 425) {
    fontSize = '16px'
  } else if (editorWrapperWidth < 769) {
    fontSize = '18px'
  } else {
    fontSize = '20px'
  }

  const classes = useStyles({ fontSize })
  if (consoleBodyHeight < 150) {
    return (
      <Grid container className={classes.consoleBodyForMobiles}>
        <Grid
          container
          direction="column"
          className={classes.compilationResultBody}
        >
          <Grid item className={classes.compilationFailedResultMessage}>
            <Typography
              className={classes.compilationFailedText}
              data-testid="compilationFailed-testId"
            >
              Compilation Failed
            </Typography>
          </Grid>

          <Grid item className={classes.compilationResultMessage}>
            <Typography>Message:</Typography>
          </Grid>

          <Grid item className={classes.messageWrapper}>
            <Typography className={classes.message}>
              {compileMsg?.codeCompileOutput?.outputLines?.join('\n')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid
      container
      direction="column"
      className={classes.compilationResultBodyForBigScreens}
    >
      <Grid item className={classes.compilationResultFailed}>
        <Typography className={classes.compilationFailedText}>
          Compilation Failed
        </Typography>
      </Grid>

      <Grid item className={classes.compilationResultMessage}>
        <Typography>Message:</Typography>
      </Grid>

      <Grid item className={classes.messageContainer}>
        <Grid item className={classes.messageWrapper}>
          <Typography className={classes.message}>
            {compileMsg?.codeCompileOutput?.outputLines?.join('\n')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CompilationFailedContent
