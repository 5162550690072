import React from 'react'

function LightThemeIcon() {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Quiz-screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-34">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <circle
            id="Oval"
            fill="#262B4B"
            opacity="0.699999988"
            cx="12.5"
            cy="12"
            r="4"
          />
          <g
            id="Group-28"
            opacity="0.699999988"
            transform="translate(11.500000, 4.000000)"
            fill="#262B4B"
          >
            <rect id="Rectangle" x="0" y="0" width="2" height="3" />
            <rect id="Rectangle-Copy-14" x="0" y="13" width="2" height="3" />
          </g>
          <g
            id="Group-29"
            opacity="0.699999988"
            transform="translate(4.000000, 10.500000)"
            fill="#262B4B"
          >
            <rect
              id="Rectangle"
              transform="translate(2.000000, 1.500000) rotate(-90.000000) translate(-2.000000, -1.500000) "
              x="1"
              y="0"
              width="2"
              height="3"
            />
            <rect
              id="Rectangle-Copy-14"
              transform="translate(15.000000, 1.500000) rotate(-90.000000) translate(-15.000000, -1.500000) "
              x="14"
              y="0"
              width="2"
              height="3"
            />
          </g>
          <g
            id="Group-28"
            opacity="0.699999988"
            transform="translate(12.500000, 12.000000) rotate(-45.000000) translate(-12.500000, -12.000000) translate(11.500000, 4.000000)"
            fill="#262B4B"
          >
            <rect id="Rectangle" x="0" y="0" width="2" height="3" />
            <rect id="Rectangle-Copy-14" x="0" y="13" width="2" height="3" />
          </g>
          <g
            id="Group-29"
            opacity="0.699999988"
            transform="translate(12.500000, 12.000000) rotate(-45.000000) translate(-12.500000, -12.000000) translate(4.000000, 10.500000)"
            fill="#262B4B"
          >
            <rect
              id="Rectangle"
              transform="translate(2.000000, 1.500000) rotate(-90.000000) translate(-2.000000, -1.500000) "
              x="1"
              y="0"
              width="2"
              height="3"
            />
            <rect
              id="Rectangle-Copy-14"
              transform="translate(15.000000, 1.500000) rotate(-90.000000) translate(-15.000000, -1.500000) "
              x="14"
              y="0"
              width="2"
              height="3"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LightThemeIcon
