import React from 'react'

function LogoIconDarkMode() {
  return (
    <svg
      width="145px"
      height="21px"
      viewBox="0 0 145 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 26</title>
      <g
        id="Test-paper-Logo"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Logo-Dark-Copy" transform="translate(-97.000000, -25.000000)">
          <g id="Group-26" transform="translate(97.000000, 25.000000)">
            <g id="Group-30">
              <polygon
                id="Path-13"
                fill="#FFFFFF"
                points="6.65866241 20 13.2553886 20 13.2553886 6.78837366 6.65866241 3.82528156e-16 -5.70314475e-16 0 0 6.78837366 6.62769428 6.78837366"
              />
              <polygon
                id="Path-14"
                fill="#00B7A4"
                points="13.2553886 6.78837366 20 6.78837366 20 -1.55675088e-16 13.2553886 0"
              />
              <polygon
                id="Path-16"
                fill="#000000"
                opacity="0.095837"
                points="0 6.78837366 6.62769428 6.78837366 6.62769428 -1.55675088e-16 0 0"
              />
            </g>
            <g
              id="Testpaper.app"
              transform="translate(28.544000, 4.848000)"
              fillRule="nonzero"
            >
              <polygon
                id="Path"
                fill="#00B7A4"
                points="7.552 0 7.552 1.184 4.512 1.184 4.512 11.152 3.056 11.152 3.056 1.184 0 1.184 0 0"
              />
              <path
                d="M17.344,6.432 C17.344,6.70933333 17.328,7.00266667 17.296,7.312 L10.288,7.312 C10.3413333,8.176 10.6373333,8.85066667 11.176,9.336 C11.7146667,9.82133333 12.368,10.064 13.136,10.064 C13.7653333,10.064 14.2906667,9.91733333 14.712,9.624 C15.1333333,9.33066667 15.4293333,8.93866667 15.6,8.448 L17.168,8.448 C16.9333333,9.29066667 16.464,9.976 15.76,10.504 C15.056,11.032 14.1813333,11.296 13.136,11.296 C12.304,11.296 11.56,11.1093333 10.904,10.736 C10.248,10.3626667 9.73333333,9.832 9.36,9.144 C8.98666667,8.456 8.8,7.65866667 8.8,6.752 C8.8,5.84533333 8.98133333,5.05066667 9.344,4.368 C9.70666667,3.68533333 10.216,3.16 10.872,2.792 C11.528,2.424 12.2826667,2.24 13.136,2.24 C13.968,2.24 14.704,2.42133333 15.344,2.784 C15.984,3.14666667 16.4773333,3.64533333 16.824,4.28 C17.1706667,4.91466667 17.344,5.632 17.344,6.432 Z M15.84,6.128 C15.84,5.57333333 15.7173333,5.096 15.472,4.696 C15.2266667,4.296 14.8933333,3.992 14.472,3.784 C14.0506667,3.576 13.584,3.472 13.072,3.472 C12.336,3.472 11.7093333,3.70666667 11.192,4.176 C10.6746667,4.64533333 10.3786667,5.296 10.304,6.128 L15.84,6.128 Z"
                id="Shape"
                fill="#00B7A4"
              />
              <path
                d="M22.368,11.296 C21.696,11.296 21.0933333,11.1813333 20.56,10.952 C20.0266667,10.7226667 19.6053333,10.4026667 19.296,9.992 C18.9866667,9.58133333 18.816,9.10933333 18.784,8.576 L20.288,8.576 C20.3306667,9.01333333 20.536,9.37066667 20.904,9.648 C21.272,9.92533333 21.7546667,10.064 22.352,10.064 C22.9066667,10.064 23.344,9.94133333 23.664,9.696 C23.984,9.45066667 24.144,9.14133333 24.144,8.768 C24.144,8.384 23.9733333,8.09866667 23.632,7.912 C23.2906667,7.72533333 22.7626667,7.54133333 22.048,7.36 C21.3973333,7.18933333 20.8666667,7.016 20.456,6.84 C20.0453333,6.664 19.6933333,6.40266667 19.4,6.056 C19.1066667,5.70933333 18.96,5.25333333 18.96,4.688 C18.96,4.24 19.0933333,3.82933333 19.36,3.456 C19.6266667,3.08266667 20.0053333,2.78666667 20.496,2.568 C20.9866667,2.34933333 21.5466667,2.24 22.176,2.24 C23.1466667,2.24 23.9306667,2.48533333 24.528,2.976 C25.1253333,3.46666667 25.4453333,4.13866667 25.488,4.992 L24.032,4.992 C24,4.53333333 23.816,4.16533333 23.48,3.888 C23.144,3.61066667 22.6933333,3.472 22.128,3.472 C21.6053333,3.472 21.1893333,3.584 20.88,3.808 C20.5706667,4.032 20.416,4.32533333 20.416,4.688 C20.416,4.976 20.5093333,5.21333333 20.696,5.4 C20.8826667,5.58666667 21.1173333,5.736 21.4,5.848 C21.6826667,5.96 22.0746667,6.08533333 22.576,6.224 C23.2053333,6.39466667 23.7173333,6.56266667 24.112,6.728 C24.5066667,6.89333333 24.8453333,7.14133333 25.128,7.472 C25.4106667,7.80266667 25.5573333,8.23466667 25.568,8.768 C25.568,9.248 25.4346667,9.68 25.168,10.064 C24.9013333,10.448 24.5253333,10.7493333 24.04,10.968 C23.5546667,11.1866667 22.9973333,11.296 22.368,11.296 Z"
                id="Path"
                fill="#00B7A4"
              />
              <path
                d="M29.392,3.584 L29.392,8.752 C29.392,9.17866667 29.4826667,9.48 29.664,9.656 C29.8453333,9.832 30.16,9.92 30.608,9.92 L31.68,9.92 L31.68,11.152 L30.368,11.152 C29.5573333,11.152 28.9493333,10.9653333 28.544,10.592 C28.1386667,10.2186667 27.936,9.60533333 27.936,8.752 L27.936,3.584 L26.8,3.584 L26.8,2.384 L27.936,2.384 L27.936,0.176 L29.392,0.176 L29.392,2.384 L31.68,2.384 L31.68,3.584 L29.392,3.584 Z"
                id="Path"
                fill="#00B7A4"
              />
              <path
                d="M34.896,4 C35.184,3.49866667 35.6133333,3.08 36.184,2.744 C36.7546667,2.408 37.4186667,2.24 38.176,2.24 C38.9546667,2.24 39.6613333,2.42666667 40.296,2.8 C40.9306667,3.17333333 41.4293333,3.69866667 41.792,4.376 C42.1546667,5.05333333 42.336,5.84 42.336,6.736 C42.336,7.62133333 42.1546667,8.41066667 41.792,9.104 C41.4293333,9.79733333 40.9306667,10.336 40.296,10.72 C39.6613333,11.104 38.9546667,11.296 38.176,11.296 C37.4293333,11.296 36.7706667,11.128 36.2,10.792 C35.6293333,10.456 35.1946667,10.0373333 34.896,9.536 L34.896,15.312 L33.44,15.312 L33.44,2.384 L34.896,2.384 L34.896,4 Z M40.848,6.736 C40.848,6.07466667 40.7146667,5.49866667 40.448,5.008 C40.1813333,4.51733333 39.8213333,4.144 39.368,3.888 C38.9146667,3.632 38.416,3.504 37.872,3.504 C37.3386667,3.504 36.8453333,3.63466667 36.392,3.896 C35.9386667,4.15733333 35.576,4.536 35.304,5.032 C35.032,5.528 34.896,6.10133333 34.896,6.752 C34.896,7.41333333 35.032,7.992 35.304,8.488 C35.576,8.984 35.9386667,9.36266667 36.392,9.624 C36.8453333,9.88533333 37.3386667,10.016 37.872,10.016 C38.416,10.016 38.9146667,9.88533333 39.368,9.624 C39.8213333,9.36266667 40.1813333,8.984 40.448,8.488 C40.7146667,7.992 40.848,7.408 40.848,6.736 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <path
                d="M43.712,6.736 C43.712,5.84 43.8933333,5.05333333 44.256,4.376 C44.6186667,3.69866667 45.1173333,3.17333333 45.752,2.8 C46.3866667,2.42666667 47.0933333,2.24 47.872,2.24 C48.64,2.24 49.3066667,2.40533333 49.872,2.736 C50.4373333,3.06666667 50.8586667,3.48266667 51.136,3.984 L51.136,2.384 L52.608,2.384 L52.608,11.152 L51.136,11.152 L51.136,9.52 C50.848,10.032 50.4186667,10.456 49.848,10.792 C49.2773333,11.128 48.6133333,11.296 47.856,11.296 C47.0773333,11.296 46.3733333,11.104 45.744,10.72 C45.1146667,10.336 44.6186667,9.79733333 44.256,9.104 C43.8933333,8.41066667 43.712,7.62133333 43.712,6.736 Z M51.136,6.752 C51.136,6.09066667 51.0026667,5.51466667 50.736,5.024 C50.4693333,4.53333333 50.1093333,4.15733333 49.656,3.896 C49.2026667,3.63466667 48.704,3.504 48.16,3.504 C47.616,3.504 47.12,3.632 46.672,3.888 C46.224,4.144 45.8666667,4.51733333 45.6,5.008 C45.3333333,5.49866667 45.2,6.07466667 45.2,6.736 C45.2,7.408 45.3333333,7.992 45.6,8.488 C45.8666667,8.984 46.224,9.36266667 46.672,9.624 C47.12,9.88533333 47.616,10.016 48.16,10.016 C48.704,10.016 49.2026667,9.88533333 49.656,9.624 C50.1093333,9.36266667 50.4693333,8.984 50.736,8.488 C51.0026667,7.992 51.136,7.41333333 51.136,6.752 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <path
                d="M56.528,4 C56.816,3.49866667 57.2453333,3.08 57.816,2.744 C58.3866667,2.408 59.0506667,2.24 59.808,2.24 C60.5866667,2.24 61.2933333,2.42666667 61.928,2.8 C62.5626667,3.17333333 63.0613333,3.69866667 63.424,4.376 C63.7866667,5.05333333 63.968,5.84 63.968,6.736 C63.968,7.62133333 63.7866667,8.41066667 63.424,9.104 C63.0613333,9.79733333 62.5626667,10.336 61.928,10.72 C61.2933333,11.104 60.5866667,11.296 59.808,11.296 C59.0613333,11.296 58.4026667,11.128 57.832,10.792 C57.2613333,10.456 56.8266667,10.0373333 56.528,9.536 L56.528,15.312 L55.072,15.312 L55.072,2.384 L56.528,2.384 L56.528,4 Z M62.48,6.736 C62.48,6.07466667 62.3466667,5.49866667 62.08,5.008 C61.8133333,4.51733333 61.4533333,4.144 61,3.888 C60.5466667,3.632 60.048,3.504 59.504,3.504 C58.9706667,3.504 58.4773333,3.63466667 58.024,3.896 C57.5706667,4.15733333 57.208,4.536 56.936,5.032 C56.664,5.528 56.528,6.10133333 56.528,6.752 C56.528,7.41333333 56.664,7.992 56.936,8.488 C57.208,8.984 57.5706667,9.36266667 58.024,9.624 C58.4773333,9.88533333 58.9706667,10.016 59.504,10.016 C60.048,10.016 60.5466667,9.88533333 61,9.624 C61.4533333,9.36266667 61.8133333,8.984 62.08,8.488 C62.3466667,7.992 62.48,7.408 62.48,6.736 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <path
                d="M73.888,6.432 C73.888,6.70933333 73.872,7.00266667 73.84,7.312 L66.832,7.312 C66.8853333,8.176 67.1813333,8.85066667 67.72,9.336 C68.2586667,9.82133333 68.912,10.064 69.68,10.064 C70.3093333,10.064 70.8346667,9.91733333 71.256,9.624 C71.6773333,9.33066667 71.9733333,8.93866667 72.144,8.448 L73.712,8.448 C73.4773333,9.29066667 73.008,9.976 72.304,10.504 C71.6,11.032 70.7253333,11.296 69.68,11.296 C68.848,11.296 68.104,11.1093333 67.448,10.736 C66.792,10.3626667 66.2773333,9.832 65.904,9.144 C65.5306667,8.456 65.344,7.65866667 65.344,6.752 C65.344,5.84533333 65.5253333,5.05066667 65.888,4.368 C66.2506667,3.68533333 66.76,3.16 67.416,2.792 C68.072,2.424 68.8266667,2.24 69.68,2.24 C70.512,2.24 71.248,2.42133333 71.888,2.784 C72.528,3.14666667 73.0213333,3.64533333 73.368,4.28 C73.7146667,4.91466667 73.888,5.632 73.888,6.432 Z M72.384,6.128 C72.384,5.57333333 72.2613333,5.096 72.016,4.696 C71.7706667,4.296 71.4373333,3.992 71.016,3.784 C70.5946667,3.576 70.128,3.472 69.616,3.472 C68.88,3.472 68.2533333,3.70666667 67.736,4.176 C67.2186667,4.64533333 66.9226667,5.296 66.848,6.128 L72.384,6.128 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <path
                d="M77.264,3.808 C77.52,3.30666667 77.8853333,2.91733333 78.36,2.64 C78.8346667,2.36266667 79.4133333,2.224 80.096,2.224 L80.096,3.728 L79.712,3.728 C78.08,3.728 77.264,4.61333333 77.264,6.384 L77.264,11.152 L75.808,11.152 L75.808,2.384 L77.264,2.384 L77.264,3.808 Z"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M82.24,11.248 C81.9626667,11.248 81.728,11.152 81.536,10.96 C81.344,10.768 81.248,10.5333333 81.248,10.256 C81.248,9.97866667 81.344,9.744 81.536,9.552 C81.728,9.36 81.9626667,9.264 82.24,9.264 C82.5066667,9.264 82.7333333,9.36 82.92,9.552 C83.1066667,9.744 83.2,9.97866667 83.2,10.256 C83.2,10.5333333 83.1066667,10.768 82.92,10.96 C82.7333333,11.152 82.5066667,11.248 82.24,11.248 Z"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M84.592,6.736 C84.592,5.84 84.7733333,5.05333333 85.136,4.376 C85.4986667,3.69866667 85.9973333,3.17333333 86.632,2.8 C87.2666667,2.42666667 87.9733333,2.24 88.752,2.24 C89.52,2.24 90.1866667,2.40533333 90.752,2.736 C91.3173333,3.06666667 91.7386667,3.48266667 92.016,3.984 L92.016,2.384 L93.488,2.384 L93.488,11.152 L92.016,11.152 L92.016,9.52 C91.728,10.032 91.2986667,10.456 90.728,10.792 C90.1573333,11.128 89.4933333,11.296 88.736,11.296 C87.9573333,11.296 87.2533333,11.104 86.624,10.72 C85.9946667,10.336 85.4986667,9.79733333 85.136,9.104 C84.7733333,8.41066667 84.592,7.62133333 84.592,6.736 Z M92.016,6.752 C92.016,6.09066667 91.8826667,5.51466667 91.616,5.024 C91.3493333,4.53333333 90.9893333,4.15733333 90.536,3.896 C90.0826667,3.63466667 89.584,3.504 89.04,3.504 C88.496,3.504 88,3.632 87.552,3.888 C87.104,4.144 86.7466667,4.51733333 86.48,5.008 C86.2133333,5.49866667 86.08,6.07466667 86.08,6.736 C86.08,7.408 86.2133333,7.992 86.48,8.488 C86.7466667,8.984 87.104,9.36266667 87.552,9.624 C88,9.88533333 88.496,10.016 89.04,10.016 C89.584,10.016 90.0826667,9.88533333 90.536,9.624 C90.9893333,9.36266667 91.3493333,8.984 91.616,8.488 C91.8826667,7.992 92.016,7.41333333 92.016,6.752 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <path
                d="M97.408,4 C97.696,3.49866667 98.1253333,3.08 98.696,2.744 C99.2666667,2.408 99.9306667,2.24 100.688,2.24 C101.466667,2.24 102.173333,2.42666667 102.808,2.8 C103.442667,3.17333333 103.941333,3.69866667 104.304,4.376 C104.666667,5.05333333 104.848,5.84 104.848,6.736 C104.848,7.62133333 104.666667,8.41066667 104.304,9.104 C103.941333,9.79733333 103.442667,10.336 102.808,10.72 C102.173333,11.104 101.466667,11.296 100.688,11.296 C99.9413333,11.296 99.2826667,11.128 98.712,10.792 C98.1413333,10.456 97.7066667,10.0373333 97.408,9.536 L97.408,15.312 L95.952,15.312 L95.952,2.384 L97.408,2.384 L97.408,4 Z M103.36,6.736 C103.36,6.07466667 103.226667,5.49866667 102.96,5.008 C102.693333,4.51733333 102.333333,4.144 101.88,3.888 C101.426667,3.632 100.928,3.504 100.384,3.504 C99.8506667,3.504 99.3573333,3.63466667 98.904,3.896 C98.4506667,4.15733333 98.088,4.536 97.816,5.032 C97.544,5.528 97.408,6.10133333 97.408,6.752 C97.408,7.41333333 97.544,7.992 97.816,8.488 C98.088,8.984 98.4506667,9.36266667 98.904,9.624 C99.3573333,9.88533333 99.8506667,10.016 100.384,10.016 C100.928,10.016 101.426667,9.88533333 101.88,9.624 C102.333333,9.36266667 102.693333,8.984 102.96,8.488 C103.226667,7.992 103.36,7.408 103.36,6.736 Z"
                id="Shape"
                fill="#FFFFFF"
              />
              <path
                d="M108.224,4 C108.512,3.49866667 108.941333,3.08 109.512,2.744 C110.082667,2.408 110.746667,2.24 111.504,2.24 C112.282667,2.24 112.989333,2.42666667 113.624,2.8 C114.258667,3.17333333 114.757333,3.69866667 115.12,4.376 C115.482667,5.05333333 115.664,5.84 115.664,6.736 C115.664,7.62133333 115.482667,8.41066667 115.12,9.104 C114.757333,9.79733333 114.258667,10.336 113.624,10.72 C112.989333,11.104 112.282667,11.296 111.504,11.296 C110.757333,11.296 110.098667,11.128 109.528,10.792 C108.957333,10.456 108.522667,10.0373333 108.224,9.536 L108.224,15.312 L106.768,15.312 L106.768,2.384 L108.224,2.384 L108.224,4 Z M114.176,6.736 C114.176,6.07466667 114.042667,5.49866667 113.776,5.008 C113.509333,4.51733333 113.149333,4.144 112.696,3.888 C112.242667,3.632 111.744,3.504 111.2,3.504 C110.666667,3.504 110.173333,3.63466667 109.72,3.896 C109.266667,4.15733333 108.904,4.536 108.632,5.032 C108.36,5.528 108.224,6.10133333 108.224,6.752 C108.224,7.41333333 108.36,7.992 108.632,8.488 C108.904,8.984 109.266667,9.36266667 109.72,9.624 C110.173333,9.88533333 110.666667,10.016 111.2,10.016 C111.744,10.016 112.242667,9.88533333 112.696,9.624 C113.149333,9.36266667 113.509333,8.984 113.776,8.488 C114.042667,7.992 114.176,7.408 114.176,6.736 Z"
                id="Shape"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogoIconDarkMode
