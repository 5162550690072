import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './TimeLimitExceededContentStyles'

function TimeLimitExceededContent({ editorWrapperWidth, consoleBodyHeight }) {
  let fontSize

  if (editorWrapperWidth < 350) {
    fontSize = '14px'
  } else if (editorWrapperWidth < 425) {
    fontSize = '16px'
  } else if (editorWrapperWidth < 769) {
    fontSize = '18px'
  } else {
    fontSize = '20px'
  }

  const classes = useStyles({ fontSize })
  if (consoleBodyHeight < 150) {
    return (
      <Grid container className={classes.consoleBodyForMobiles}>
        <Grid
          container
          direction="column"
          className={classes.compilationResultBody}
        >
          <Grid item className={classes.compilationFailedResultMessage}>
            <Typography
              className={classes.compilationFailedText}
              data-testid="compilationFailed-testId"
            >
              Time limit exceeded
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid
      container
      direction="column"
      className={classes.compilationResultBodyForBigScreens}
    >
      <Grid item className={classes.compilationResultFailed}>
        <Typography className={classes.compilationFailedText}>
          Time limit exceeded
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TimeLimitExceededContent
