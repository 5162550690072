import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import { IconButton, Paper, Tooltip } from '@material-ui/core'
import CodingQuestionResult from '../CodingQuestionResult/CodingQuestionResult'
import { useStyles } from './ConsoleBodyStyles'
import RestoreIcon from '../../Icons/RestoreIcon'

function ConsoleBody({
  customInputTabSelected,
  customInput,
  setCustomInput,
  resultsTabSelected,
  compileMsg,
  handleCustomInputReset,
  editorWrapperWidth,
  consoleBodyHeight,
  setCustomOutput,
  customOutput,
  showTimeLimitExceededError,
}) {
  const classes = useStyles()
  if (customInputTabSelected) {
    return (
      <Grid item className={classes.customInputRoot}>
        <Grid item className={classes.customInputGrid}>
          <Typography style={{ padding: '12px 0px' }}>Input</Typography>
          <Grid item className={classes.customInputContainer}>
            <Grid className={classes.customInputWrapper}>
              <Paper elevation={0} className={classes.customInput}>
                <InputBase
                  value={customInput}
                  multiline
                  rows={consoleBodyHeight < 150 ? 1 : 3}
                  rowsMax={100}
                  onChange={(event) =>
                    setCustomInput(event.target.value.split(','))
                  }
                  className={classes.customInputPlaceHolder}
                  data-testid="custom-input"
                />
                <Tooltip title="Reset">
                  <IconButton
                    style={{ padding: '0px', height: '24px' }}
                    onClick={() => handleCustomInputReset('customInput')}
                  >
                    <RestoreIcon />
                  </IconButton>
                </Tooltip>
              </Paper>
            </Grid>
          </Grid>
          <Typography style={{ padding: '14px 0px 12px 0px' }}>
            Expected Output
          </Typography>
          <Grid item className={classes.customInputContainer}>
            <Grid className={classes.customInputWrapper}>
              <Paper elevation={0} className={classes.customInput}>
                <InputBase
                  value={customOutput}
                  multiline
                  rows={consoleBodyHeight < 150 ? 1 : 3}
                  rowsMax={100}
                  onChange={(event) => setCustomOutput(event.target.value)}
                  className={classes.customInputPlaceHolder}
                  data-testid="custom-input"
                />
                <Tooltip title="Reset">
                  <IconButton
                    style={{ padding: '0px', height: '24px' }}
                    onClick={() => handleCustomInputReset('customOutput')}
                  >
                    <RestoreIcon />
                  </IconButton>
                </Tooltip>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  if (resultsTabSelected) {
    if (compileMsg) {
      return (
        <CodingQuestionResult
          compileMsg={compileMsg}
          resultsStatus={compileMsg?.status}
          editorWrapperWidth={editorWrapperWidth}
          consoleBodyHeight={consoleBodyHeight}
          customInput={customInput}
          showTimeLimitExceededError={showTimeLimitExceededError}
        />
      )
    }

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.instructionMsgWrapper}
      >
        <Typography
          className={classes.instructionMsg}
          data-testid="runCode-testId"
        >
          Click on <q>Run Code</q> to see results.
        </Typography>
      </Grid>
    )
  }
  return null
}

export default ConsoleBody
