import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
  },
  mainContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '40px 40px',
    '@media only screen and (min-width:426px)': {
      padding: '60px 60px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '80px 0',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '100px 0',
    },
    '@media only screen and (min-width:1400px)': {
      padding: '125px 0',
    },
  },
  mainContentWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '@media only screen and (min-width:769px)': {
      flexDirection: 'row',
      width: '90%',
    },
    '@media only screen and (min-width:1025px)': {
      flexDirection: 'row',
      width: '85%',
    },
    '@media only screen and (min-width:1400px)': {
      flexDirection: 'row',
      width: '80%',
    },
  },
  mainContentLeftBox: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '40px',
    '@media only screen and (min-width:426px)': {
      paddingTop: '65px',
    },
    '@media only screen and (min-width:769px)': {
      width: 0,
      flex: 1,
      paddingTop: 0,
    },
  },
  mainContentHeading: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '125%',
    '@media only screen and (min-width:426px)': {
      fontSize: '24px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '30px',
    },
    '@media only screen and (min-width:1025px)': {
      fontSize: '40px',
    },
    '@media only screen and (min-width:1400px)': {
      fontSize: '50px',
    },
  },
  mainContentSubtitle: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    fontSize: '14px',
    padding: '10px 0px 20px 0px',
    '@media only screen and (min-width:426px)': {
      fontSize: '15px',
      padding: '15px 0px 25px 0px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '15px',
      padding: '20px 0px 30px 0px',
    },
    '@media only screen and (min-width:1025px)': {
      fontSize: '16px',
      padding: '20px 0px 35px 0px',
    },
    '@media only screen and (min-width:1400px)': {
      fontSize: '16px',
      padding: '20px 0px 40px 0px',
    },
  },
  mainContentButton: {
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    fontWeight: theme?.palette?.primaryButton?.fontWeight,
    padding: '10px 25px',
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
    '@media only screen and (min-width:769px)': {
      padding: '15px 25px',
    },
  },
  mainContentIconWrapper: {
    width: '100%',

    '@media only screen and (min-width:769px)': {
      minWidth: '470px',
      width: '50%',
    },
    '@media only screen and (min-width:1025px)': {
      minWidth: '550px',
      width: '50%',
    },
    '@media only screen and (min-width:1400px)': {
      minWidth: '750px',
      width: '50%',
      maxWidth: '1100px',
    },
  },
  stepsContainer: {
    display: 'flex',
    justifyContent: 'center',
    background: theme?.palette?.darkerBackground?.main,
    width: '100%',
    padding: '40px 30px',
    '@media only screen and (min-width:426px)': {
      padding: '60px 40px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '80px 0',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '85px 0',
    },
    '@media only screen and (min-width:1400px)': {
      padding: '95px 0',
    },
  },
  stepsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '@media only screen and (min-width:426px)': {
      width: 'max-content',
    },
    '@media only screen and (min-width:769px)': {
      flexDirection: 'row',
      alignItems: 'stretch',
      width: '95%',
    },
    '@media only screen and (min-width:1025px)': {
      flexDirection: 'row',
      alignItems: 'stretch',
      width: '90%',
    },
    '@media only screen and (min-width:1400px)': {
      flexDirection: 'row',
      alignItems: 'stretch',
      width: '85%',
    },
  },
  stepWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '@media only screen and (min-width:769px)': {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: 1,
    },
  },
  stepIconAndWingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media only screen and (min-width:769px)': {
      width: '100%',
      flexDirection: 'row',
      paddingBottom: '30px',
    },
  },
  emptyStepWing: {
    display: 'flex',
    flex: 1,
    width: 0,
    '@media only screen and (min-width:769px)': {
      flex: 1,
      height: 0,
    },
  },
  stepWing: {
    display: 'flex',
    borderLeft: `2px ${theme?.palette?.darkTextColor?.main} dotted`,
    flex: 1,
    width: 0,
    '@media only screen and (min-width:769px)': {
      flex: 1,
      height: 0,
      borderTop: `2px ${theme?.palette?.darkTextColor?.main} dotted`,
      borderLeft: 'none',
      marginRight: '1px',
    },
  },
  stepIconWrapper: {
    background: 'white',
    padding: '15px',
    borderRadius: '100px',
    boxShadow: `0px 7px 9px ${theme?.palette?.lightShadow?.main}`,
  },
  stepIcon: {
    width: '40px',
    height: '40px',
  },
  stepHeadingAndDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    padding: '20px 0 45px 20px',
    '@media only screen and (min-width:769px)': {
      padding: 0,
      alignItems: 'center',
    },
  },
  stepHeading: {
    fontSize: '16px',
    fontWeight: 600,
    paddingBottom: '10px',
    color: theme?.palette?.darkerTextColor?.main,
    '@media only screen and (min-width:426px)': {
      fontSize: '18px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '20px',
      paddingBottom: '20px',
    },
  },
  stepDescription: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    padding: 0,
    fontSize: '12px',
    maxWidth: '65ch',
    color: theme?.palette?.darkerTextColor?.main,
    '@media only screen and (min-width:426px)': {
      fontSize: '14px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '0 45px',
      textAlign: 'center',
      fontSize: '14px',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '0 65px',
      textAlign: 'center',
      fontSize: '14px',
    },
    '@media only screen and (min-width:1400px)': {
      padding: '0 90px',
      textAlign: 'center',
      fontSize: '14px',
    },
  },
  featuresContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '40px 40px',
    '@media only screen and (min-width:426px)': {
      padding: '60px 60px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '80px 0',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '85px 0',
    },
    '@media only screen and (min-width:1400px)': {
      padding: '95px 0',
    },
  },
  featuresHeading: {
    fontSize: '24px',
    fontWeight: 700,
    paddingBottom: '20px',
    '@media only screen and (min-width:426px)': {
      fontSize: '28px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '30px',
    },
    '@media only screen and (min-width:1025px)': {
      fontSize: '34px',
    },
    '@media only screen and (min-width:1400px)': {
      fontSize: '38px',
    },
  },
  featuresHeadingDividerWrapper: {
    paddingBottom: '40px',
    '@media only screen and (min-width:375px)': {
      paddingBottom: '50px',
    },
    '@media only screen and (min-width:426px)': {
      paddingBottom: '60px',
    },
    '@media only screen and (min-width:769px)': {
      paddingBottom: '70px',
    },
  },
  featuresHeadingDivider: {
    width: '40px',
    height: '4px',
    background: theme?.palette?.primarySelection?.main,
  },
  featuresWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '@media only screen and (min-width:769px)': {
      width: '90%',
    },
    '@media only screen and (min-width:1025px)': {
      width: '85%',
    },
    '@media only screen and (min-width:1400px)': {
      width: '80%',
    },
  },

  featureWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '60px',
    '@media only screen and (min-width:426px)': {
      paddingBottom: '80px',
    },
    '@media only screen and (min-width:769px)': {
      flexDirection: 'row',
      paddingBottom: '80px',
    },
  },
  featureTypeTwoWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '60px',
    '@media only screen and (min-width:426px)': {
      paddingBottom: '80px',
    },
    '@media only screen and (min-width:769px)': {
      flexDirection: 'row',
      paddingBottom: '80px',
    },
  },
  featureLeftOrTopContentContainer: {
    width: '100%',
    paddingBottom: '20px',
    '@media only screen and (min-width:650px)': {
      width: '90%',
    },
    '@media only screen and (min-width:769px)': {
      width: '53%',
      padding: '0 40px 0 0',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '0 60px 0 0',
    },
    '@media only screen and (min-width:1400px)': {
      padding: '0 90px 0 0',
    },
  },
  featureTypeTwoLeftOrBottomContentContainer: {
    width: 'max-content',
    maxWidth: '100%',

    '@media only screen and (min-width:769px)': {
      width: '53%',
      maxWidth: '53%',
      padding: '0 40px',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '0 60px',
    },
    '@media only screen and (min-width:1400px)': {
      padding: '0 90px',
    },
  },
  featureIconWrapper: {
    width: '100%',

    '@media only screen and (min-width:1400px)': {
      maxWidth: '950px',
    },
  },
  featureRightOrBottomContentContainer: {
    display: 'flex',
    maxWidth: '100%',
    '@media only screen and (min-width:769px)': {
      width: 0,
      flex: 1,
    },
  },
  featureTypeTwoRightOrTopContentContainer: {
    display: 'flex',
    width: '100%',
    paddingBottom: '20px',
    '@media only screen and (min-width:769px)': {
      width: 0,
      flex: 1,
      paddingBottom: 0,
    },
  },
  featureContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  featureIndex: {
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    fontSize: '20px',
    padding: '4px 13px',
    borderRadius: 4,
    width: 'max-content',
    '@media only screen and (min-width:769px)': {
      fontSize: '28px',
      padding: '0px 13px',
    },
  },
  featureHeading: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '125%',
    color: theme?.palette?.darkerTextColor?.main,
    paddingTop: '10px',
    maxWidth: '15ch',
    '@media only screen and (min-width:769px)': {
      fontSize: '24px',
    },

    '@media only screen and (min-width:1025px)': {
      fontSize: '28px',
    },
  },
  featureDescriptionWrapper: {
    width: '100%',
    paddingTop: '20px',
  },
  featureDescription: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    width: '100%',
    maxWidth: '70ch',
    fontSize: '12px',
    color: theme?.palette?.darkerTextColor?.main,
    '@media only screen and (min-width:1025px)': {
      fontSize: '14px',
      maxWidth: '58ch',
    },
  },
  lastSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    background: theme?.palette?.darkerBackground?.main,
    padding: '40px 40px',
    '@media only screen and (min-width:426px)': {
      padding: '60px 50px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '90px 0',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '95px 0',
    },
    '@media only screen and (min-width:1400px)': {
      padding: '100px 0',
    },
  },
  lastSectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  lastSectionContentWrapper: {
    paddingBottom: '30px',
  },
  lastSectionContent: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    fontSize: '20px',
    maxWidth: '30ch',
    '@media only screen and (min-width:426px)': {
      fontSize: '24px',
    },
    '@media only screen and (min-width:769px)': {
      fontSize: '30px',
    },
    '@media only screen and (min-width:1025px)': {
      fontSize: '34px',
    },
    '@media only screen and (min-width:1400px)': {
      fontSize: '38px',
    },
  },
  lastSectionButtonWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '@media only screen and (min-width:365px)': {
      flexDirection: 'row',
    },
  },
  lastSectionCreateQuizWrapper: {
    '@media only screen and (min-width:365px)': {
      paddingRight: '10px',
    },
  },
  lastSectionCreateQuizButton: {
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    fontWeight: theme?.palette?.primaryButton?.fontWeight,
    padding: '10px 25px',
    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
    '@media only screen and (min-width:769px)': {
      padding: '15px 25px',
    },
  },
  lastSectionViewPricingWrapper: {
    paddingBottom: '10px',
    '@media only screen and (min-width:365px)': {
      paddingRight: 0,
    },
  },
  lastSectionViewPricingButton: {
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
    color: theme?.palette?.primarySelection?.main,
    fontWeight: 600,
    padding: '9px 25px',
    width: '100%',
    '@media only screen and (min-width:365px)': {
      width: 'max-content',
    },
    '@media only screen and (min-width:769px)': {
      padding: '14px 24px',
    },
  },
  homePageHeaderWrapper: {
    width: '100%',
  },
  homePageWrapper: {
    display: 'flex',
    flex: 1,
    width: '100%',
    overflowY: 'scroll',
    height: 0,
  },
  homePageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
  },
  createQuizButton: {
    textDecoration: 'none',
  },
}))
export { useStyles }
