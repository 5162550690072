import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  compilationFailedText: {
    fontWeight: 600,
    color: theme?.palette?.errorColor?.main,
    fontSize: (props) => props?.fontSize,
  },
  consoleBodyForMobiles: {
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  compilationResultBody: {
    height: 'max-content',
    width: '100%',
  },
  compilationResultBodyForBigScreens: {
    height: '100%',
    width: '100%',
  },
  compilationResultFailed: {
    paddingTop: '14px',
    paddingBottom: '15px',
  },
  compilationFailedResultMessage: {
    paddingTop: '14px',
    paddingBottom: '15px',
  },
  compilationResultMessage: {
    paddingBottom: '10px',
  },
  messageContainer: {
    flex: 1,
    overflowY: 'auto',
  },

  messageWrapper: {
    background: theme?.palette?.darkBackground?.main,
    borderRadius: 4,
    padding: '10px',
  },
  message: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    fontSize: '12px',
  },
}))
export { useStyles }
