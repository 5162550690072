import React from 'react'

function GrayThreeDotsCircleOutlinedIcon() {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Gray-icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-2">
          <path
            d="M12,2.8125 C17.0717859,2.81812136 21.1818786,6.92821413 21.1875,12 C21.1875,17.0741161 17.0741161,21.1875 12,21.1875 C6.92588386,21.1875 2.8125,17.0741161 2.8125,12 C2.8125,6.92588386 6.92588386,2.8125 12,2.8125 Z M12,3.9375 C7.5472042,3.9375 3.9375,7.5472042 3.9375,12 C3.9375,16.4527958 7.5472042,20.0625 12,20.0625 C16.4507397,20.05754 20.05754,16.4507397 20.0625,12 C20.0625,7.5472042 16.4527958,3.9375 12,3.9375 Z"
            id="Color"
            fill="#515570"
            fillRule="nonzero"
          />
          <rect
            id="failure"
            fillOpacity="0"
            fill="#FFFFFF"
            fillRule="nonzero"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <circle id="Oval" fill="#515570" cx="9" cy="12" r="1" />
          <circle id="Oval" fill="#515570" cx="12" cy="12" r="1" />
          <circle id="Oval" fill="#515570" cx="15" cy="12" r="1" />
        </g>
      </g>
    </svg>
  )
}

export default GrayThreeDotsCircleOutlinedIcon
