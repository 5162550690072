export class HttpErrors {
  constructor(errorCode, errorReason, statusCode) {
    this.isHttpError = true
    this.name = 'Http Error'
    this.errorCode = errorCode
    this.errorReason = errorReason
    this.statusCode = statusCode
  }
}

export const isHttpError = (resp) => resp && resp.isHttpError === true
