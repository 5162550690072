import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  leftPanel: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },

  questionAndTestCasesWrapper: {
    boxSizing: 'border-box',
    background: theme?.palette?.darkBackground?.main,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '31px 20px 0 20px',
    '@media only screen and (min-width:769px)': {
      height: 'calc(100% - 46px)',
      padding: '40px 20px 0 20px',
    },
  },
  questionDetailsWrapper: {
    marginBottom: '8px',
    width: '100%',
  },
  answeredIcon: {
    marginRight: '0.3rem',
    width: '17.44px',
    height: '16.86px',
  },
  answeredText: {
    color: theme?.palette?.grayTextColor?.main,
    fontSize: '11px',
  },
  questionWrapper: {
    boxSizing: 'border-box',

    width: '100%',
    overflowWrap: 'break-word',
    marginBottom: '24px',
    '@media only screen and (min-width:426px)': {
      width: '65%',
    },
    '@media only screen and (min-width:769px)': {
      width: '75%',
    },
  },
  questionDetailsContainer: {
    width: '100%',
    height: 'max-content',
  },
  question: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '22px',
    fontSize: '20px',
    '& > *': {
      overflowWrap: 'break-word',
      msWordBreak: 'break-all',
      wordBreak: 'break-word',
      msHyphens: 'auto',
      MozHyphens: 'auto',
      WebkitHyphens: 'auto',
      hyphens: 'auto',
      whiteSpace: 'pre-wrap',
      margin: 0,
      padding: 0,
      listStylePosition: 'inside',
    },
    '& > * > img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    '& > *:nth-child(2)': {
      display: 'inline',
    },
    '& > pre': {
      background: 'black',
      color: 'white',
      padding: '5px',
    },
  },

  testCases: {
    boxSizing: 'border-box',
    width: '100%',
  },
  testCase: {
    boxSizing: 'border-box',

    background: 'white',
    marginBottom: '1rem',
    width: '100%',
  },
  inputOrOutputHeading: {
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '0.5rem 5% 0 5%',
  },
  inputOrOutputText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    padding: '0.1rem 5% 0.5rem 5%',
  },
  inputAndOutputDivider: {
    border: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
    height: 0,
    width: '100%',
    borderWidth: 'thin',
  },
  buttonWrapper: {
    boxSizing: 'border-box',
    height: '46px',
    padding: '5px',
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
    borderTop: `solid 1px ${theme?.palette?.borders?.main}`,
  },
  backButton: {
    background: theme?.palette?.secondaryButton?.main,
    color: theme?.palette?.secondaryButton?.contrastText,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
    padding: '0 1rem',
    height: '100%',
    fontWeight: theme?.palette?.secondaryButton?.fontWeight,
  },
  approvedIconAndTextWrapper: {
    width: 'max-content',
  },
  questionIndex: {
    display: 'inline',
  },
  testCasesHeader: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  backButtonWrapper: {
    height: '100%',
    marginRight: 'auto',
    width: 'max-content',
  },
}))
export { useStyles }
