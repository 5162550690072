import React, { useState, useEffect, useRef } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import Grid from '@material-ui/core/Grid'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import QuestionNavItem from '../QuestionNavItem/QuestionNavItem'
import CustomSelect from '../CustomSelect/CustomSelect'
import { getQuestionById } from '../../utils'
import { useStyles } from './QuestionNavigatorStyles'

function QuestionNavigator({
  quiz,
  setQuiz,
  currentQuestion,
  setCurrentQuestion,
  currentQuestionFilter,
  setCurrentQuestionFilter,
  questionFilterIndices,
  questionLoadingStates,
  changeQuestionNavigatorStyle,
  setChangeQuestionNavigatorStyle,
  handleMCQSubmission,
  textEditorAnswer,
}) {
  const [isScrollable, setIsScrollable] = useState(false)
  const [scrolledRightMost, setScrolledRightMost] = useState(false)
  const [scrolledLeftMost, setScrolledLeftMost] = useState(false)
  const [scrolling, setScrolling] = useState(0)

  const classes = useStyles({
    isScrollable,
    changeQuestionNavigatorStyle,
    scrolledLeftMost,
  })
  const {
    width: questionNavigatorWidth,
    height: questionNavigatorHeight,
    ref: questionNavigatorRef,
  } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 16,
  })

  const updateQuestionFiltersArray = () => {
    const returnFilterArray = []
    returnFilterArray.push({ value: 'all', label: 'All Questions' })
    if (
      quiz?.questions?.filter((question) => {
        return question?.type === 'mcq'
      })?.length > 0
    )
      returnFilterArray.push({ value: 'mcq', label: 'MCQ' })

    if (
      quiz?.questions?.filter((question) => {
        return question?.type === 'coding'
      })?.length > 0
    )
      returnFilterArray.push({ value: 'coding', label: 'Coding Questions' })
    if (
      quiz?.questions?.filter((question) => {
        return question?.type === 'fill-in-blanks'
      })?.length > 0
    )
      returnFilterArray.push({
        value: 'fill-in-blanks',
        label: 'Fill In The Blanks',
      })
    if (
      quiz?.questions?.filter((question) => {
        return question?.type === 'essay'
      })?.length > 0
    )
      returnFilterArray.push({ value: 'essay', label: 'Essay Questions' })
    returnFilterArray.push({ value: 'answered', label: 'Answered Questions' })

    returnFilterArray.push({
      value: 'unanswered',
      label: 'Unanswered Questions',
    })
    return returnFilterArray
  }

  const questionFilters = updateQuestionFiltersArray()
  const navigatorButtonsScrollerRef = useRef()
  const navigatorButtonRefs = useRef({})

  useEffect(() => {
    navigatorButtonRefs?.current?.[currentQuestion?.id]?.scrollIntoView({
      block: 'nearest',
      inline: 'center',
      behavior: 'smooth',
    })
    if (
      navigatorButtonsScrollerRef.current.clientWidth <
      navigatorButtonsScrollerRef.current.scrollWidth
    ) {
      setIsScrollable(() => true)
    } else {
      setIsScrollable(() => false)
    }
    if (
      navigatorButtonsScrollerRef.current.clientWidth <
      navigatorButtonsScrollerRef.current.scrollWidth
    ) {
      setIsScrollable(() => true)
    } else {
      setIsScrollable(() => false)
    }
  }, [
    quiz,
    currentQuestion,
    currentQuestionFilter,
    navigatorButtonRefs,
    navigatorButtonsScrollerRef,
    questionNavigatorWidth,
    questionNavigatorHeight,
  ])

  useEffect(() => {
    if (
      navigatorButtonsScrollerRef.current.scrollLeft +
        navigatorButtonsScrollerRef.current.clientWidth ===
      navigatorButtonsScrollerRef.current.scrollWidth
    ) {
      setScrolledRightMost(() => true)
    } else {
      setScrolledRightMost(() => false)
    }
    if (navigatorButtonsScrollerRef.current.scrollLeft === 0) {
      setScrolledLeftMost(() => true)
    } else {
      setScrolledLeftMost(() => false)
    }
    if (questionNavigatorWidth < 312) {
      setChangeQuestionNavigatorStyle(true)
    } else {
      setChangeQuestionNavigatorStyle(false)
    }
  }, [scrolling, questionNavigatorWidth, questionNavigatorHeight])

  const handleLeftClick = () => {
    navigatorButtonsScrollerRef.current.scrollLeft += -33
  }

  const handleRightClick = () => {
    navigatorButtonsScrollerRef.current.scrollLeft += 33
  }

  const handleDefaultQuestionToDisplayOnFilterChange = (
    updatedQuestionFilter
  ) => {
    let areMCQPresent = false
    let areCodingPresent = false
    let areEssayPresent = false
    let areFillInTheBlanks = false
    let areAnsweredQuestionsPresent = false
    let areUnAnsweredQuestionsPresent = false

    if (
      updatedQuestionFilter === 'all' &&
      questionFilterIndices?.all?.indexOf(currentQuestion?.index) === -1
    ) {
      setCurrentQuestion((currentQuestionPrev) => {
        return {
          ...currentQuestionPrev,
          index: 0,
          id: quiz?.questions?.[0]?.id,
        }
      })
    }

    if (
      updatedQuestionFilter === 'mcq' &&
      questionFilterIndices?.mcq?.indexOf(currentQuestion?.index) === -1
    ) {
      for (let index = 0; index < quiz?.questions?.length; index++) {
        if (quiz?.questions?.[index]?.type === 'mcq') {
          areMCQPresent = true
          setCurrentQuestion((currentQuestionPrev) => {
            return {
              ...currentQuestionPrev,
              index,
              id: quiz?.questions?.[index]?.id,
            }
          })
          break
        }
      }
    }

    if (
      updatedQuestionFilter === 'coding' &&
      questionFilterIndices?.coding?.indexOf(currentQuestion?.index) === -1
    ) {
      for (let index = 0; index < quiz?.questions?.length; index++) {
        if (quiz?.questions?.[index]?.type === 'coding') {
          areCodingPresent = true
          setCurrentQuestion((currentQuestionPrev) => {
            return {
              ...currentQuestionPrev,
              index,
              id: quiz?.questions?.[index]?.id,
            }
          })
          break
        }
      }
    }
    // Need to check the fill in the blanks condition
    if (
      updatedQuestionFilter === 'fill-in-blanks' &&
      questionFilterIndices?.fillInTheBlanks?.indexOf(
        currentQuestion?.index
      ) === -1
    ) {
      for (let index = 0; index < quiz?.questions?.length; index++) {
        if (quiz?.questions?.[index]?.type === 'fill-in-blanks') {
          areFillInTheBlanks = true
          setCurrentQuestion((currentQuestionPrev) => {
            return {
              ...currentQuestionPrev,
              index,
              id: quiz?.questions?.[index]?.id,
            }
          })
          break
        }
      }
    }
    if (
      updatedQuestionFilter === 'essay' &&
      questionFilterIndices?.essay?.indexOf(currentQuestion?.index) === -1
    ) {
      for (let index = 0; index < quiz?.questions?.length; index++) {
        if (quiz?.questions?.[index]?.type === 'essay') {
          areEssayPresent = true
          setCurrentQuestion((currentQuestionPrev) => {
            return {
              ...currentQuestionPrev,
              index,
              id: quiz?.questions?.[index]?.id,
            }
          })
          break
        }
      }
    }
    if (
      updatedQuestionFilter === 'answered' &&
      questionFilterIndices?.answered?.indexOf(currentQuestion?.index) === -1
    ) {
      for (let index = 0; index < quiz?.questions?.length; index++) {
        if (
          quiz?.questions?.[index]?.saved &&
          quiz?.questions?.[index]?.saved !== 'pending' &&
          quiz?.questions?.[index]?.saved !== 'failed'
        ) {
          areAnsweredQuestionsPresent = true
          setCurrentQuestion((currentQuestionPrev) => {
            return {
              ...currentQuestionPrev,
              index,
              id: quiz?.questions?.[index]?.id,
            }
          })
          break
        }
      }
    }

    if (
      updatedQuestionFilter === 'unanswered' &&
      questionFilterIndices?.unanswered?.indexOf(currentQuestion?.index) === -1
    ) {
      for (let index = 0; index < quiz?.questions?.length; index++) {
        if (
          !quiz?.questions?.[index]?.saved ||
          quiz?.questions?.[index]?.saved === 'pending' ||
          quiz?.questions?.[index]?.saved === 'failed'
        ) {
          areUnAnsweredQuestionsPresent = true
          setCurrentQuestion((currentQuestionPrev) => {
            return {
              ...currentQuestionPrev,
              index,
              id: quiz?.questions?.[index]?.id,
            }
          })
          break
        }
      }
    }

    if (
      (!areMCQPresent &&
        updatedQuestionFilter === 'mcq' &&
        questionFilterIndices?.mcq?.indexOf(currentQuestion?.index) === -1) ||
      (!areCodingPresent &&
        updatedQuestionFilter === 'coding' &&
        questionFilterIndices?.coding?.indexOf(currentQuestion?.index) ===
          -1) ||
      (!areEssayPresent &&
        updatedQuestionFilter === 'essay' &&
        questionFilterIndices?.essay?.indexOf(currentQuestion?.index) === -1) ||
      (!areFillInTheBlanks &&
        updatedQuestionFilter === 'fill-in-blanks' &&
        questionFilterIndices?.fillInTheBlanks?.indexOf(
          currentQuestion?.index
        ) === -1) ||
      (!areAnsweredQuestionsPresent &&
        updatedQuestionFilter === 'answered' &&
        questionFilterIndices?.answered?.indexOf(currentQuestion?.index) ===
          -1) ||
      (!areUnAnsweredQuestionsPresent &&
        updatedQuestionFilter === 'unanswered' &&
        questionFilterIndices?.unanswered?.indexOf(currentQuestion?.index) ===
          -1)
    ) {
      setCurrentQuestion((currentQuestionPrev) => {
        return {
          ...currentQuestionPrev,
          index: null,
          id: null,
        }
      })
    }
  }

  const handleFilterChange = (option) => {
    setCurrentQuestionFilter(() => option?.value)
    handleDefaultQuestionToDisplayOnFilterChange(option?.value)
    const copyCurrentQuestion = getQuestionById(quiz, currentQuestion)
    if (copyCurrentQuestion?.type === 'mcq') {
      handleMCQSubmission(
        copyCurrentQuestion?.id,
        copyCurrentQuestion?.selectedChoice,
        copyCurrentQuestion?.type
      )
    }
    if (copyCurrentQuestion?.type === 'multi-choice') {
      handleMCQSubmission(
        copyCurrentQuestion?.id,
        copyCurrentQuestion?.selectedChoice,
        copyCurrentQuestion?.type
      )
    }
    if (copyCurrentQuestion?.type === 'fill-in-blanks') {
      handleMCQSubmission(
        copyCurrentQuestion?.id,
        copyCurrentQuestion?.selectedChoice,
        copyCurrentQuestion?.type
      )
    }
  }

  return (
    <Grid
      container
      direction={changeQuestionNavigatorStyle ? 'column' : 'row'}
      ref={questionNavigatorRef}
      className={classes.root}
    >
      <Grid item className={classes.questionFilterWrapper}>
        <Grid item className={classes.questionCustomSelect}>
          <CustomSelect
            value={currentQuestionFilter}
            onChange={handleFilterChange}
            options={questionFilters}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.buttonWrapper}>
        {isScrollable && (
          <Grid item className={classes.leftButtonWrapper}>
            <Grid
              container
              alignItems="center"
              justify="center"
              component="button"
              className={classes.leftButton}
              disabled={scrolledLeftMost}
              onClick={handleLeftClick}
            >
              <ArrowBackIosIcon className={classes.leftButtonIcon} />
            </Grid>
          </Grid>
        )}

        <Grid
          ref={navigatorButtonsScrollerRef}
          item
          className={classes.navigatorButtonsContainer}
          onScroll={() => setScrolling(scrolling + 1)}
        >
          <Grid container className={classes.navigatorButtonsWrapper}>
            {quiz?.questions?.map((question, index) => {
              if (
                ((currentQuestionFilter === 'mcq' || 'multi-choice') &&
                  (question?.type === 'mcq' || 'multi-choice')) ||
                (currentQuestionFilter === 'coding' &&
                  question?.type === 'coding') ||
                (currentQuestionFilter === 'fill-in-blanks' &&
                  question?.type === 'fill-in-blanks') ||
                (currentQuestionFilter === 'essay' &&
                  question?.type === 'essay') ||
                (currentQuestionFilter === 'answered' &&
                  question?.saved &&
                  question?.saved !== 'pending' &&
                  question?.saved !== 'failed') ||
                (currentQuestionFilter === 'unanswered' &&
                  (!question?.saved ||
                    question?.saved === 'pending' ||
                    question?.saved === 'failed')) ||
                currentQuestionFilter === 'all'
              ) {
                return (
                  <Grid
                    item
                    key={index.toString(2)}
                    ref={(element) => {
                      navigatorButtonRefs.current[question?.id] = element
                    }}
                    className={classes.item}
                    style={{
                      marginRight: index === quiz?.questions?.length - 1 && 0,
                    }}
                  >
                    <QuestionNavItem
                      quiz={quiz}
                      setQuiz={setQuiz}
                      question={question}
                      index={index}
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      questionLoadingStates={questionLoadingStates}
                      handleMCQSubmission={handleMCQSubmission}
                      textEditorAnswer={textEditorAnswer}
                    />
                  </Grid>
                )
              }

              return null
            })}
          </Grid>
        </Grid>

        {isScrollable && (
          <Grid item className={classes.rightButtonWrapper}>
            <Grid
              container
              alignItems="center"
              justify="center"
              component="button"
              className={classes.rightButton}
              disabled={scrolledRightMost}
              onClick={handleRightClick}
            >
              <ArrowForwardIosIcon className={classes.rightButtonIcon} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default QuestionNavigator
