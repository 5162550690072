import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import CompilationFailedContent from '../CompilationFailedContent/CompilationFailedContent'
import CompilationPassedContent from '../CompilationPassedContent/CompilationPassedContent'
import TimeLimitExceededContent from '../TimeLimitExceededContent/TimeLimitExceededContent'
import { useStyles } from './CodingQuestionResultStyles'

function CodingQuestionResult({
  compileMsg,
  resultsStatus,
  editorWrapperWidth,
  consoleBodyHeight,
  customInput,
  showTimeLimitExceededError,
}) {
  const classes = useStyles()
  const returnCodingQuestionResultContent = (status) => {
    if (status?.toUpperCase() === 'COMPILATION_FAILED') {
      return (
        <CompilationFailedContent
          compileMsg={compileMsg}
          editorWrapperWidth={editorWrapperWidth}
          consoleBodyHeight={consoleBodyHeight}
        />
      )
    }
    if (status?.toUpperCase() === 'COMPILATION_PASSED') {
      return (
        <CompilationPassedContent
          compileMsg={compileMsg}
          resultsStatus={resultsStatus}
          editorWrapperWidth={editorWrapperWidth}
          consoleBodyHeight={consoleBodyHeight}
          customInput={customInput}
        />
      )
    }
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.circularProgress}
        data-testid="coding-question-result"
      >
        <CircularProgress size="3rem" />
      </Grid>
    )
  }
  if (showTimeLimitExceededError) {
    return (
      <TimeLimitExceededContent
        editorWrapperWidth={editorWrapperWidth}
        consoleBodyHeight={consoleBodyHeight}
      />
    )
  }
  if (
    compileMsg?.codeRunOutputList?.length <= 0 &&
    compileMsg?.codeCompileOutput?.status !== 'COMPILATION_FAILED'
  ) {
    return (
      <Grid
        data-testid="render-circular-progress"
        container
        justify="center"
        alignItems="center"
        className={classes.circularProgress}
      >
        <CircularProgress size="3rem" />
      </Grid>
    )
  }
  return returnCodingQuestionResultContent(
    compileMsg?.codeCompileOutput?.status
  )
}

export default CodingQuestionResult
