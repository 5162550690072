import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Logo from '../Logo/Logo'
import Timer from '../Timer/Timer'
import { useStyles } from './ReviewPageHeaderMobileStyles'

function ReviewPageHeaderMobile({
  quiz,
  seconds,
  getNoOfAnsweredQuestions,
  setPopUp,
}) {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      data-testid="render-root"
    >
      <Grid
        container
        className={classes.logoWrapper}
        data-testid="render-logo-wrapper"
      >
        <Grid
          container
          alignItems="center"
          className={classes.logo}
          data-testid="render-logo"
        >
          <Logo />
        </Grid>

        <IconButton
          onClick={() => setPopUp(() => false)}
          className={classes.closeIconWrapper}
          data-testid="check-onclick"
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Grid>

      <Grid
        container
        className={classes.timerAndAnsweredWrapper}
        data-testid="render-timer-and-answered-wrapper"
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.timerWrapper}
          data-testid="render-timer"
        >
          <Timer seconds={seconds} />
        </Grid>

        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.answeredWrapper}
          style={{ height: '100%', width: '50%' }}
          data-testid="render-questions"
        >
          <Typography variant="body2" className={classes.answeredText}>
            Answered {getNoOfAnsweredQuestions()}/{quiz?.questions?.length}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReviewPageHeaderMobile
