import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { secondsToMinutes } from '../../utils'
import QuizTimeLeftIcon from '../../Icons/QuizTimeLeftIcon'
import { useStyles } from './TimerStyles'

function Timer({ seconds }) {
  const classes = useStyles()

  return seconds > 0 ? (
    <Grid
      data-testid="render"
      container
      className={classes.timer}
      alignItems="center"
    >
      <QuizTimeLeftIcon />
      <Typography className={classes.time}>
        {`${secondsToMinutes(seconds)} left`}
      </Typography>
    </Grid>
  ) : null
}

export default Timer
