import React, { useEffect, useState, useRef } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import parse from 'html-react-parser'
import ApprovedIcon from '../../Icons/ApprovedIcon'
import Choice from '../Choice/Choice'
import { useStyles } from './MultipleChoiceQuestionStyles'

function MultipleChoiceQuestion({
  question,
  currentQuestion,
  setQuiz,
  handleMCQSubmission,
  questionFilterIndices,
  currentQuestionFilter,
  handleBack,
  handleNext,
  handleMoveToNearestQuestionInUnansweredFilter,
  handleMoveToNearestQuestionInAnsweredFilter,
}) {
  const [selectedChoice, setSelectedChoice] = useState()
  const [multiSelectedChoice, setMultiSelectedChoice] = useState([])
  const multiSelectedChoiceRef = useRef([])
  const selectedChoiceRef = useRef()

  const isWidthAboveSmallMobileRange = useMediaQuery(
    'only screen and (min-width:321px)'
  )

  const classes = useStyles({})

  useEffect(() => {
    if (question?.type === 'multi-choice') {
      setMultiSelectedChoice(() => question?.selectedChoice)
    }
    if (question?.type.toUpperCase() === 'MCQ') {
      setSelectedChoice(() => question?.selectedChoice)
    }
  }, [question])

  useEffect(() => {
    selectedChoiceRef.current = selectedChoice
  }, [selectedChoice])
  useEffect(() => {
    multiSelectedChoiceRef.current = multiSelectedChoice
  }, [multiSelectedChoice])
  const handleServerSubmit = (choice, questionType) => {
    if (questionType === 'multi-choice' && !choice) {
      handleMCQSubmission(question?.id, [''], questionType)
    } else {
      handleMCQSubmission(question?.id, choice, questionType)
    }
  }

  useEffect(() => {
    return () => {
      if (question?.type.toUpperCase() === 'MCQ') {
        handleServerSubmit(selectedChoiceRef.current, 'mcq')
      }
      if (question?.type === 'multi-choice') {
        handleServerSubmit(multiSelectedChoiceRef.current, 'multi-choice')
      }
      if (question?.type === 'fill-in-the-blanks') {
        handleServerSubmit(multiSelectedChoiceRef.current, 'fill-in-the-blanks')
      }
    }
  }, [currentQuestion])

  const handleQuizSave = (choice) => {
    setQuiz((quizPrev) => {
      const quizPrevCopy = JSON.parse(JSON.stringify(quizPrev))
      quizPrevCopy.questions = quizPrevCopy?.questions?.map((item) => {
        const copyItem = item
        if (item?.id === currentQuestion?.id) {
          copyItem.selectedChoice = choice

          if (choice) {
            copyItem.saved = true
            if (copyItem.type !== 'multi-choice')
              handleMoveToNearestQuestionInUnansweredFilter()
          } else {
            copyItem.saved = false
            if (copyItem.type !== 'multi-choice')
              handleMoveToNearestQuestionInAnsweredFilter()
          }
        }

        return copyItem
      })
      return quizPrevCopy
    })
  }

  const handleClear = (questionType) => {
    if (questionType === 'multi-choice') {
      setMultiSelectedChoice(() => [])
      handleQuizSave('')
      multiSelectedChoiceRef.current = []
    } else {
      setSelectedChoice(() => '')
      handleQuizSave('')
      selectedChoiceRef.current = ''
    }
  }

  const handleSubmit = (choice, questionType) => {
    if (questionType === 'multi-choice') {
      if (multiSelectedChoice?.includes(choice)) {
        const temp = multiSelectedChoice.filter((item) => item !== choice)
        if (temp.length === 0) {
          handleClear()
          return
        }
        setMultiSelectedChoice(() => temp)
        multiSelectedChoiceRef.current = temp
        handleQuizSave(temp)
      } else {
        const tempState = multiSelectedChoice ? [...multiSelectedChoice] : []
        tempState.push(choice)
        setMultiSelectedChoice(() => tempState)
        handleQuizSave(tempState)
      }
    } else {
      setSelectedChoice(() => choice)
      handleQuizSave(choice)
      selectedChoiceRef.current = choice
    }
  }

  return (
    <Grid container className={classes.root}>
      <Grid container justify="center" className={classes.contentWrapper}>
        <Grid container direction="column" className={classes.content}>
          <Grid
            container
            justify="space-between"
            className={classes.questionDetailsWrapper}
          >
            <Grid
              container
              alignItems="center"
              className={classes.approvedIconContainer}
            >
              {question?.saved &&
                question?.saved !== 'pending' &&
                question?.saved !== 'failed' && (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.approvedIconContainer}
                  >
                    <Grid item className={classes.answeredIcon}>
                      <ApprovedIcon />
                    </Grid>
                    <Grid item className={classes.answeredText}>
                      Answered!
                    </Grid>
                  </Grid>
                )}
            </Grid>
            <Grid item>
              <Typography>{question?.marks} marks</Typography>
            </Grid>
          </Grid>

          <Grid item className={classes.questionWrapper}>
            <Grid item className={classes.question}>
              <span className={classes.questionDisplay}>
                {currentQuestion?.index + 1}.&nbsp;
              </span>
              {parse(question?.question ?? '')}
            </Grid>
          </Grid>

          <Grid container direction="column" className={classes.choiceWrapper}>
            {question?.choices?.map((choice, index) => {
              return (
                <Choice
                  questionType={question?.type}
                  key={index.toString(2)}
                  selectedChoice={selectedChoice}
                  choice={choice}
                  buttonHandler={() => handleSubmit(choice, question?.type)}
                  multiSelectedChoice={multiSelectedChoice}
                />
              )
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="center" className={classes.buttonWrapper}>
        <Grid item className={classes.prevAndNextButtonWrapper}>
          {questionFilterIndices?.[currentQuestionFilter]?.indexOf(
            currentQuestion?.index
          ) > 0 && (
            <Button
              onClick={handleBack}
              size="small"
              data-testid="questioncontent__backbutton--test"
              className={classes.backButton}
            >
              Previous
            </Button>
          )}
        </Grid>

        <Grid container className={classes.prevAndClearButtonWrapper}>
          <Button
            onClick={() => handleClear(question?.type)}
            className={classes.clearSelectionButton}
          >
            {isWidthAboveSmallMobileRange ? <>Clear Selection</> : <>Clear</>}
          </Button>
          <Grid item className={classes.nextButton}>
            {questionFilterIndices?.[currentQuestionFilter]?.indexOf(
              currentQuestion?.index
            ) <
              questionFilterIndices?.[currentQuestionFilter]?.length - 1 && (
              <Button
                onClick={() => {
                  handleNext()
                }}
                size="small"
                data-testid="questioncontent__nextbutton--test"
                className={classes.continueButton}
              >
                Next
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MultipleChoiceQuestion
