import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { useStyles } from './ConfirmCodeRestoreOrRetrieveStyles'

function ConfirmCodeRestoreOrRetrieveDialog({
  openDialog,
  setOpenDialog,
  affirmativeHandler,
  content,
}) {
  const classes = useStyles()
  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(() => false)}>
      <DialogTitle>Are you sure?</DialogTitle>

      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>

      <DialogActions className={classes.warningDialogButtons}>
        <Button
          className={classes.cancelButton}
          onClick={() => setOpenDialog(() => false)}
        >
          Cancel
        </Button>
        <Button className={classes.confirmButton} onClick={affirmativeHandler}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmCodeRestoreOrRetrieveDialog
