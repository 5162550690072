import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  questionNavigator: {
    boxSizing: 'border-box',

    background: '#F0F0F0',

    width: '100%',
    height: 'max-content',
  },
  navigatorAndSolutionPanelWrapper: {
    width: '100%',
    height: '100%',
    flexWrap: (props) =>
      props?.questionType === 'coding' && props?.isWidthAboveTabRange
        ? 'nowrap'
        : 'wrap',
  },
  navigatorAndContentWrapper: {
    height: '100%',
    boxSizing: 'border-box',
  },
  questionContentWrapper: {
    height: 0,
    flex: 1,
    width: '100%',
  },
  questionContent: {
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
  },
  contentBackground: {
    background: theme?.palette?.darkBackground?.main,
    width: '100%',
    height: '100%',
  },
  instructionMsg: {
    textAlign: 'center',
    padding: '0 20px',
  },
  codingQuestionSolutionPanelWrapper: {
    width: '100%',
    height: '100%',
  },
  codingQuestionSolutionPanelContainer: {
    width: '100%',
    height: '35%',
  },
  solutionPanelEditor: {
    width: '100%',
    height: '65%',
  },
  splitter: {
    background: theme?.palette?.borders?.main,
    width: '5px',
    height: '100%',
    '&:hover': {
      cursor: 'col-resize',
    },
  },
  artBoardIcon: {
    width: '3px',
  },
  codingSolutionPanel: {
    flex: 1,
    height: '100%',
    overflowX: 'hidden',
  },
}))
export { useStyles }
