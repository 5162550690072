import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  testCaseWrapper: {
    width: '100%',
    marginBottom: '8px',
  },
  testcaseAndIndex: {
    color: theme?.palette?.resultsPageLightTextColor?.main,
    fontWeight: 600,
  },
  marksDisplay: {
    fontWeight: 600,
  },
  resultContainer: {
    width: '100%',
    background: theme?.palette?.darkBackground?.main,
    padding: '10px',
    borderRadius: '4px',
  },
  resultWrapper: {
    width: '100%',
    background: theme?.palette?.lightBackground?.main,
    borderRadius: '4px',
  },
  inputWrapper: {
    padding: '22px',
    borderBottom: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  inputHeading: {
    fontWeight: 600,
    fontSize: '12px',
    marginBottom: '5px',
  },
  inputText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '19px',
  },
  outputWrapper: {
    padding: '22px',
    borderBottom: `solid 1px ${theme?.palette?.lightDividerLines?.main}`,
  },
  outputHeading: {
    fontWeight: 600,
    fontSize: '12px',
    marginBottom: '5px',
  },
  outputText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '19px',
  },
  expectedOutputWrapper: {
    padding: '22px',
  },
  expectedOutputHeading: {
    fontWeight: 600,
    fontSize: '12px',
    marginBottom: '5px',
  },
  expectedOutputText: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '19px',
  },
}))
export { useStyles }
