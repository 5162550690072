import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Logo from '../Logo/Logo'
import { useStyles } from './HomePageHeaderStyles'

function HomePageHeader() {
  const classes = useStyles()

  return (
    <div className={classes.root} data-testid="render-home-page-header">
      <div>
        <Link to="/" className={classes.logoContaier}>
          <Logo home />
        </Link>
      </div>
      <div>
        <NavLink
          exact
          to="/"
          className={classes.homeWrapper}
          activeClassName={classes.activeSelection}
        >
          Home
        </NavLink>
        <NavLink
          exact
          to="/pricing"
          className={classes.pricingWrapper}
          activeClassName={classes.activeSelection}
        >
          Pricing
        </NavLink>
      </div>
    </div>
  )
}

export default HomePageHeader
