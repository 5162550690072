import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
  },
  header: {
    background: theme?.palette?.header?.main,
    height: '46px',
    width: '100%',
  },
  content: {
    background: theme?.palette?.darkBackground?.main,
    height: 'calc(100% - 46px)',
    padding: '1rem',
  },
  logoGrid: {
    marginLeft: '16px',
  },
  loaderGrid: { marginBottom: '1rem' },
}))
export { useStyles }
