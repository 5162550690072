import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '5px',
    background: theme?.palette?.lightBackground?.main,
    borderBottom: `solid 1px ${theme?.palette?.dividerLines?.main}`,
  },
  questionFilterWrapper: {
    paddingRight: (props) => (props?.changeQuestionNavigatorStyle ? 0 : '5px'),
    width: (props) =>
      props?.changeQuestionNavigatorStyle ? '100%' : 'max-content',
  },
  questionCustomSelect: {
    height: '30px',
    width: (props) => (props?.changeQuestionNavigatorStyle ? '100%' : '193px'),
    border: 'solid 1px lightgray',
    borderRadius: '5px',
  },
  buttonWrapper: {
    height: '30px',
    width: (props) => (props?.changeQuestionNavigatorStyle ? '100%' : 0),
    flex: (props) => (props?.changeQuestionNavigatorStyle ? 'none' : 1),
    marginTop: (props) => props?.changeQuestionNavigatorStyle && '5px',
  },
  leftButtonWrapper: {
    paddingRight: '5px',
  },
  rightButtonWrapper: {
    paddingLeft: '5px',
  },
  navigatorButtonsContainer: {
    width: 0,
    flex: 1,
    height: '100%',
    overflowY: 'hidden',
    overflowX: 'auto',
    scrollBehavior: 'smooth',
    overflow: '-moz-scrollbars-none',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: '0 !important',
      display: 'none',
    },
  },
  navigatorButtonsWrapper: {
    height: '100%',
    width: 'max-content',
  },
  item: {
    marginRight: '0.3rem',
    height: '100%',
  },
  questionFilterSelect: {
    height: '100%',
    width: '9rem',
    textAlign: 'left',
    marginRight: '0.3rem',
    borderRadius: '5px',
    border: `solid 1px ${theme?.palette?.borders?.main}`,
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  leftButton: {
    boxSizing: 'border-box',
    height: '30px',
    width: '30px',
    outline: 'none',
    background: theme?.palette?.lightBackground?.main,
    borderRadius: '4px',
    padding: '0 2px 0 8px',
    border: `solid 1px ${theme?.palette?.borders?.main}`,
  },

  leftButtonIcon: {
    fontSize: '0.9rem',
    color: (props) => (props?.scrolledLeftMost ? 'lightgray' : 'gray'),
  },
  rightButton: {
    boxSizing: 'border-box',
    height: '30px',
    width: '30px',
    outline: 'none',
    background: theme?.palette?.lightBackground?.main,
    borderRadius: '4px',
    border: `solid 1px ${theme?.palette?.borders?.main}`,
  },
  rightButtonIcon: {
    fontSize: '0.9rem',
    color: (props) => (props?.scrolledRightMost ? 'lightgray' : 'gray'),
  },
}))
export { useStyles }
