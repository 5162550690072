import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  mainMessageAcknowledgement: {
    fontSize: '35px',
    marginBottom: (props) => (props?.isLoading ? '4.5rem' : '0.5rem'),
    textAlign: 'center',
  },
  helperMessageAcknowledgment: {
    marginBottom: '3rem',
    textAlign: 'center',
  },
}))
export { useStyles }
