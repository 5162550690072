import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Cookies from 'js-cookie'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Button } from '@material-ui/core'
import Logo from '../Logo/Logo'
import QuizSubmittedIllustration from '../../Assets/Illustrations/QuizSubmittedIllustration'
import useWindowSize from '../../hooks/useWindowSize'
import { useQuizGetQueryEvaluatedResponseById } from '../../common/Queries/quizQueries'
import ShowResultsFullScreenDialog from '../ShowResultsFullScreenDialog/ShowResultsFullScreenDialog'
import { delay } from '../../utils'
import SubmissionAcknowledgement from '../SubmissionProgressContent/SubmissionAcknowledgement'
import Loader from '../Loader/Loader'
import { useStyles } from './QuizAlreadySubmittedStyles'

function QuizAlreadySubmitted({ reason, showResult, quizTitle, quizId }) {
  const windowSize = useWindowSize()
  const classes = useStyles()
  const [result, setResult] = useState()
  const [isEvaluationLoader, setIsEvaluationLoader] = useState(false)
  const [popUp, setPopUp] = useState(false)

  const { refetch: refetchEvaluatedResponse } =
    useQuizGetQueryEvaluatedResponseById(quizId, {
      enabled: false,
      onSuccess: (successResponse) => {
        setResult(() => successResponse)
        localStorage.clear()
        Cookies.remove(`${sessionStorage?.userID}_token`)
        setIsEvaluationLoader(() => false)
      },
      onError: () => {
        const waitAndRefetch = async () => {
          await delay(3000)
          refetchEvaluatedResponse()
        }
        waitAndRefetch()
      },
    })

  useEffect(() => {
    if (showResult) {
      setIsEvaluationLoader(() => true)
      refetchEvaluatedResponse()
    }
  }, [showResult])

  useEffect(() => {
    window?.gtag('event', 'quiz_load_failed', {
      event_category: 'Quiz already submitted',
      event_label: 'Quiz already submitted',
    })
  }, [])

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      style={{ height: `${windowSize?.height}px` }}
    >
      <Helmet>
        <title>Quiz already submitted</title>
      </Helmet>
      <Grid container alignItems="center" className={classes.header}>
        <Logo />
      </Grid>
      <Grid container alignItems="center" className={classes.body}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.illustrationAndTextWrapper}
        >
          <Grid item className={classes.illustrationWrapper}>
            <QuizSubmittedIllustration />
          </Grid>
          <Grid item className={classes.textWrapper}>
            <Typography className={classes.text}>{reason}</Typography>
          </Grid>
          {isEvaluationLoader && showResult && (
            <SubmissionAcknowledgement
              statusIcon={
                <Grid item className={classes.loader}>
                  <Loader size="3rem" />
                </Grid>
              }
              isLoading={isEvaluationLoader}
              message="Evaluation is in progress"
            />
          )}

          <Grid className={classes.showResultsButtonWrapper}>
            {showResult && !isEvaluationLoader && (
              <Button
                onClick={() => setPopUp(() => true)}
                endIcon={<KeyboardArrowDownIcon style={{ color: '#000000' }} />}
                data-testid="Btnsubmitsuccesspage-testId"
                className={classes.showResultsButton}
              >
                Show Results
              </Button>
            )}
          </Grid>
        </Grid>
        <>
          <ShowResultsFullScreenDialog
            popUp={popUp}
            setPopUp={setPopUp}
            result={result}
            title={quizTitle}
          />
        </>
      </Grid>
    </Grid>
  )
}

export default QuizAlreadySubmitted
