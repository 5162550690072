import React from 'react'

function ArtboardIcon() {
  return (
    <svg viewBox="0 0 2 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group-6"
          transform="translate(1.000000, 17.000000) rotate(-270.000000) translate(-1.000000, -17.000000) translate(-16.000000, 16.000000)"
          fill="#262B4B"
        >
          <rect id="Rectangle" x="0" y="0" width="2" height="2" />
          <rect id="Rectangle" x="4" y="0" width="2" height="2" />
          <rect id="Rectangle" x="8" y="0" width="2" height="2" />
          <rect id="Rectangle" x="12" y="0" width="2" height="2" />
          <rect id="Rectangle" x="16" y="0" width="2" height="2" />
          <rect id="Rectangle" x="20" y="0" width="2" height="2" />
          <rect id="Rectangle" x="24" y="0" width="2" height="2" />
          <rect id="Rectangle" x="28" y="0" width="2" height="2" />
          <rect id="Rectangle" x="32" y="0" width="2" height="2" />
        </g>
      </g>
    </svg>
  )
}

export default ArtboardIcon
