import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import CheckBox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { useStyles } from './ChoiceStyles'

function Choice({
  questionType,
  selectedChoice,
  choice,
  buttonHandler,
  multiSelectedChoice,
}) {
  const props = {
    isSelected: selectedChoice === choice,
    isMultiSelected: multiSelectedChoice?.includes(choice),
  }
  const classes = useStyles(props)
  return (
    <Button className={classes.choice} onClick={buttonHandler}>
      {questionType === 'multi-choice' ? (
        <CheckBox
          icon={<CheckBoxOutlineBlankIcon data-testid="checkboxIcon-testId" />}
          checkedIcon={<CheckBoxIcon className={classes.multiChoice} />}
          checked={props?.isMultiSelected}
          data-testid="choiceCheckbox-testId"
        />
      ) : (
        <Radio
          checked={props?.isSelected}
          value={choice}
          label={choice}
          data-testid="choiceRadio-testId"
        />
      )}
      <Typography
        className={classes.choiceText}
        data-testid="choiceText-testId"
      >
        {choice}
      </Typography>
    </Button>
  )
}

export default Choice
