/**
 * @description End point for Quiz
 */
export const QUIZ_ENDPOINT = '/quiz'

/**
 * @description Prefix End point for Login
 */
export const CANDIDATE_ENDPOINT = '/candidate'
/**
 * @description End point for Login
 */
export const LOGIN_ENDPOINT = '/login'
/**
 * @description End point for Username
 */
export const USER_ENDPOINT = '/user'
/**
 * @description End point for Compile
 */
export const COMPILE_ENDPOINT = '/code-run'
/**
 * @description End point for proctoring
 */
export const PROCTORING_ENDPOINT = '/credential-exchange'
/**
 * @description Client ID for GAuth
 */
export const MOCK_REACT_APP_CLIENT_ID =
  window.env?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID
/**
 * @description base URL for mock server
 */
export const MOCK_BASE_URL =
  window.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL
/**
 * @description Admin URL for Redirecting from landing page
 */
export const ADMIN_URL =
  window.env?.REACT_APP_ADMIN_URL || process.env.REACT_APP_ADMIN_URL

/**
 * @description Faro App Name for monitoring and logs
 */
export const FARO_APP_NAME =
  window.env?.REACT_APP_FARO_APP_NAME || process.env.REACT_APP_FARO_APP_NAME

/**
 * @description Faro url for monitoring and logs
 */
export const FARO_APP_URL =
  window.env?.REACT_APP_FARO_APP_URL || process.env.REACT_APP_FARO_APP_URL

/**
 *  @description Faro api key for authentication
 */
export const FARO_API_KEY =
  window.env?.REACT_APP_FARO_API_KEY || process.env.REACT_APP_FARO_API_KEY

/**
 * @description Bugsnag key for monitoring run time errors
 */
export const BUGSNAG_KEY =
  window.env?.REACT_APP_BUGSNAG_KEY || process.env.REACT_APP_BUGSNAG_KEY

/**
 * @description End point for proctoring
 */
export const REFRESH_TOKEN_URL = '/refresh'
