import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Select from 'react-select'

function CustomSelect({ value, onChange, options }) {
  const theme = useTheme()

  const getValuefromOptions = () => {
    return options?.filter((option) => {
      return option?.value === value
    })?.[0]
  }

  const reactSelectStyles = {
    container: (provided) => ({
      ...provided,
      padding: 0,
      height: '100%',
      zIndex: 4,
    }),
    control: (provided) => ({
      ...provided,
      borderWidth: 0,
      minHeight: '100%',
      height: '100%',
      border: 0,
      cursor: 'pointer',
      // This line disable the blue border
      boxShadow: 'none',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      whiteSpace: 'nowrap',
      width: 'auto',
      minWidth: '100%',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '29px',
    }),
    input: (provided) => ({
      ...provided,
      height: '1rem',
      paddingBottom: '5px',
    }),
    option: (styles, state) => ({
      ...styles,

      backgroundColor: state.isSelected
        ? theme?.palette?.primarySelection?.main
        : theme?.palette?.lightBackground?.main,
      color: state.isSelected
        ? theme?.palette?.lightTextColor?.main
        : theme?.palette?.darkTextColor?.main,
      '&:hover': {
        backgroundColor: state.isSelected
          ? theme?.palette?.primarySelection?.main
          : theme?.palette?.highlight?.main,
      },
    }),
  }
  return (
    <Select
      value={getValuefromOptions(value)}
      onChange={onChange}
      options={options}
      isSearchable={false}
      styles={reactSelectStyles}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      inputProps={{
        'data-testid': 'customSelect',
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  )
}

export default CustomSelect
