import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CssBaseline from '@material-ui/core/CssBaseline'
import { FaroErrorBoundary, withFaroProfiler } from '@grafana/faro-react'
import { initializeFaro } from './initialize'
import theme from './theme'
import Routes from './routes/routes'
import QuizSubmissionContext from './Contexts/QuizSubmissionContext'
import MediaQueryContext from './Contexts/MediaQueryContext'
import IsProctoredContext from './Contexts/IsProctoredContext'

const queryClient = new QueryClient()
function App() {
  const [quizSubmission, setQuizSubmission] = useState('')
  const [isProctored, setIsProctored] = useState(false)
  const quizSubmissionValue = { quizSubmission, setQuizSubmission }
  const isProctoredValue = { isProctored, setIsProctored }

  useEffect(() => {
    initializeFaro()
  }, [])

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <FaroErrorBoundary>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <QuizSubmissionContext.Provider value={quizSubmissionValue}>
              <MediaQueryContext.Provider
                value={useMediaQuery('(max-width:1000px)')}
              >
                <IsProctoredContext.Provider value={isProctoredValue}>
                  <Routes />
                </IsProctoredContext.Provider>
              </MediaQueryContext.Provider>
            </QuizSubmissionContext.Provider>
          </ThemeProvider>
        </FaroErrorBoundary>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default withFaroProfiler(App)
