import React from 'react'

function GreenLockIcon() {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="lock" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-11">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <g
            id="285646_lock_icon"
            transform="translate(5.000000, 3.000000)"
            fillRule="nonzero"
          >
            <path
              d="M7,1.71428571 C4.583775,1.71428571 2.625,3.94285714 2.625,6.85714286 L2.625,7.71428571 C2.625,8.14285714 2.9514625,8.57142857 3.3541375,8.57142857 C3.7569,8.57142857 4.0833625,8.14285714 4.0833625,7.71428571 L4.0833625,6.85714286 C4.0833625,4.8 5.389125,3.17142857 7,3.17142857 C8.610875,3.17142857 9.916375,4.8 9.916375,6.85714286 L9.916375,7.71428571 C9.916375,8.14285714 10.24275,8.57142857 10.646125,8.57142857 C11.048625,8.57142857 11.375,8.14285714 11.375,7.71428571 L11.375,6.85714286 C11.375,3.94285714 9.415875,1.71428571 7,1.71428571 Z"
              id="Path"
              fill="#7F8C8D"
            />
            <path
              d="M1.75,6.85714286 C0.783475,6.85714286 0,7.62857143 0,8.57142857 L0,14.5714286 C0,15.5142857 0.783475,16.2857143 1.75,16.2857143 L12.25,16.2857143 C13.216875,16.2857143 14,15.5142857 14,14.5714286 L14,8.57142857 C14,7.62857143 13.216875,6.85714286 12.25,6.85714286 L1.75,6.85714286 Z"
              id="Path"
              fill="#009D89"
            />
            <path
              d="M7,0.428571429 C4.1005125,0.428571429 1.75,2.74285714 1.75,5.57142857 L1.75,6.42857143 C1.75,6.94285714 2.1417375,7.28571429 2.625,7.28571429 C3.1082625,7.28571429 3.5,6.94285714 3.5,6.42857143 L3.5,5.57142857 C3.5,3.6 5.0670375,1.97142857 7,1.97142857 C8.932875,1.97142857 10.5,3.6 10.5,5.57142857 L10.5,6.42857143 C10.5,6.94285714 10.892,7.28571429 11.375,7.28571429 C11.858,7.28571429 12.25,6.94285714 12.25,6.42857143 L12.25,5.57142857 C12.25,2.74285714 9.89975,0.428571429 7,0.428571429 Z"
              id="Path"
              fill="#BDC3C7"
            />
            <path
              d="M1.75,8.57142857 C0.783475,8.57142857 0,9.34285714 0,10.2857143 L0,16.2857143 C0,17.2285714 0.783475,18 1.75,18 L12.25,18 C13.216875,18 14,17.2285714 14,16.2857143 L14,10.2857143 C14,9.34285714 13.216875,8.57142857 12.25,8.57142857 L1.75,8.57142857 Z"
              id="Path"
              fill="#00B9A4"
            />
            <path
              d="M2.036125,6.85714286 C1.7383625,6.85714286 1.4613375,6.94285714 1.2158125,7.02857143 C1.4100625,7.8 2.0957125,8.31428571 2.911125,8.31428571 C3.8030125,8.31428571 4.5255,7.62857143 4.633825,6.85714286 L2.036125,6.85714286 Z"
              id="Path"
              fill="#7F8C8D"
            />
            <path
              d="M12.068,6.77142857 C12.3655,6.77142857 12.642875,6.85714286 12.887875,7.02857143 C12.693625,7.71428571 12.0085,8.31428571 11.193,8.31428571 C10.3005,8.31428571 9.578625,7.62857143 9.470125,6.77142857 L12.068,6.77142857 Z"
              id="Path"
              fill="#7F8C8D"
            />
            <path
              d="M7,0.857142857 C4.1005125,0.857142857 1.75,3.08571429 1.75,6 L1.75,6.85714286 C1.75,7.28571429 2.1417375,7.71428571 2.625,7.71428571 C3.1082625,7.71428571 3.5,7.28571429 3.5,6.85714286 L3.5,6 C3.5,3.94285714 5.0670375,2.31428571 7,2.31428571 C8.932875,2.31428571 10.5,3.94285714 10.5,6 L10.5,6.85714286 C10.5,7.28571429 10.892,7.71428571 11.375,7.71428571 C11.858,7.71428571 12.25,7.28571429 12.25,6.85714286 L12.25,6 C12.25,3.08571429 9.89975,0.857142857 7,0.857142857 Z"
              id="Path"
              fill="#95A5A6"
            />
            <g
              id="Group"
              transform="translate(1.750000, 9.942857)"
              fill="#009C89"
            >
              <rect
                id="Rectangle"
                x="0"
                y="0.342857143"
                width="10.5"
                height="1"
              />
              <rect
                id="Rectangle"
                x="0"
                y="2.05714286"
                width="10.5"
                height="1"
              />
              <rect
                id="Rectangle"
                x="0"
                y="3.77142857"
                width="10.5"
                height="1"
              />
              <rect
                id="Rectangle"
                x="0"
                y="5.48571429"
                width="10.5"
                height="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GreenLockIcon
