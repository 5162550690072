import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme?.palette?.lightBackground?.main,
    borderRadius: '5px',
    padding: '12px 0',
    marginBottom: '5px',
    width: '100%',
  },
  indexWrapper: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: '30px',
    height: '30px',
    margin: '0 15px 0 12px',
    borderRadius: '4px',
    background: (props) =>
      theme?.palette?.[
        `${props.isSolved ? 'primaryButton' : 'secondaryButton'}`
      ]?.main,
    color: (props) =>
      props.isSolved
        ? theme?.palette?.primaryButton?.contrastText
        : theme?.palette?.darkTextColor?.main,
    border: (props) =>
      !props.isSolved ? `solid 1px ${theme?.palette?.borders?.main}` : null,
  },
  solveButton: {
    boxSizing: 'border-box',
    background: theme?.palette?.primaryButton?.main,
    color: theme?.palette?.primaryButton?.contrastText,
    width: '69px',
    height: '100%',
    minWidth: 0,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,

    '&:hover': {
      background: theme?.palette?.primaryButton?.hover,
    },
  },
  modifyButton: {
    boxSizing: 'border-box',
    background: theme?.palette?.secondaryButton?.main,
    color: theme?.palette?.secondaryButton?.contrastText,
    width: '69px',
    height: '100%',
    minWidth: 0,
    border: `solid 1px ${theme?.palette?.primarySelection?.main}`,
  },
  question: {
    overflowWrap: 'break-word',
    msWordBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    MozHyphens: 'auto',
    WebkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
    lineHeight: '22px',
    fontSize: '14px',
    '& > *': {
      overflowWrap: 'break-word',
      msWordBreak: 'break-all',
      wordBreak: 'break-word',
      msHyphens: 'auto',
      MozHyphens: 'auto',
      WebkitHyphens: 'auto',
      hyphens: 'auto',
      whiteSpace: 'pre-wrap',
      margin: 0,
      padding: 0,
      listStylePosition: 'inside',
    },
    '& > * > img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    '& > pre': {
      background: 'black',
      color: 'white',
      fontWeight: 300,
      padding: '5px',
    },
  },
  indexNumber: {
    fontSize: '12px',
  },
  questionWrapper: {
    width: 'calc(100% - 234px)',
    paddingRight: '25px',
  },
  questionTypeWrapper: {
    boxSizing: 'border-box',
    width: '40px',
    marginRight: '56px',
  },
  modifyAndSolveButtonWrapper: {
    marginRight: '12px',
  },
}))
export { useStyles }
