import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    background: `${theme?.palette?.footerGradient?.main}, url(/assets/Footer.png)`,
    backgroundPositionX: 'center',
    color: theme?.palette?.lightTextColor?.main,
    padding: '40px 40px 20px 40px',
    '@media only screen and (min-width:426px)': {
      padding: '60px 60px 40px 60px',
    },
    '@media only screen and (min-width:769px)': {
      padding: '100px 0 40px 0',
    },
    '@media only screen and (min-width:1025px)': {
      padding: '125px 0 40px 0',
    },
    '@media only screen and (min-width:1400px)': {
      paddingTop: '150px 0 40px 0',
    },
  },
  footerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '@media only screen and (min-width:769px)': {
      width: '90%',
    },
    '@media only screen and (min-width:1025px)': {
      width: '85%',
    },
    '@media only screen and (min-width:1400px)': {
      width: '80%',
    },
  },
  footerContentWrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '40px',
    '@media only screen and (min-width:390px)': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  },
  footerColumnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '40px',
    '@media only screen and (min-width:390px)': {
      paddingRight: '40px',
      paddingBottom: 0,
    },
  },
  footerColumnHeading: {
    fontSize: '16px',
    fontWeight: 600,
    textAlign: 'center',
    paddingBottom: '10px',
    '@media only screen and (min-width:390px)': {
      textAlign: 'left',
    },
  },

  footerColumnCellText: {
    textAlign: 'center',
    paddingBottom: '10px',
    '@media only screen and (min-width:390px)': {
      textAlign: 'left',
    },
  },
  link: { textDecoration: 'none', color: theme?.palette?.lightTextColor?.main },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '@media only screen and (min-width:390px)': {
      justifyContent: 'flex-start',
    },
  },
  footerDivider: {
    border: `solid 1px ${theme?.palette?.footerDivider?.main}`,
    width: '100%',
  },
  footerEndWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    '@media only screen and (min-width:650px)': {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  logoWrapper: {
    paddingBottom: '5px',
    '@media only screen and (min-width:650px)': {
      paddingBottom: 0,
    },
  },
  faceBookIcon: {
    fontSize: '20px',
    marginRight: '10px',
  },
  linkedInIcon: {
    fontSize: '20px',
  },
  footerContainer: {
    width: '100%',
    paddingBottom: '10px',
  },
  institueName: {
    fontSize: '12px',
    textAlign: 'center',
  },
  instituteMail: {
    fontSize: '1rem',
    position: 'relative',
    top: '4px',
  },
  twitterIcon: {
    fontSize: '20px',
    marginRight: '10px',
  },
}))
export { useStyles }
