import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import parse from 'html-react-parser'
import GreenTickCircleOutlinedIcon from '../../Icons/GreenTickCircleOutlinedIcon'
import RedWrongCircleOutlinedIcon from '../../Icons/RedWrongCircleOutlinedIcon'
import GrayThreeDotsCircleOutlinedIcon from '../../Icons/GrayThreeDotsCircleOutlinedIcon'
import { useStyles } from './FillInTheBlanksResultRowItemMobileStyles'

function FillInTheBlanksResultRowItemMobile({ question }) {
  const classes = useStyles()
  const isNotAttempted = !question?.result?.userResponse.length > 0
  const isCorrect = question?.result?.isCorrect
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container className={classes.mcqResultWrapper}>
        <Grid container className={classes.iconWrapper}>
          <Grid item className={classes.icon}>
            {isNotAttempted ? <GrayThreeDotsCircleOutlinedIcon /> : null}
            {isCorrect ? <GreenTickCircleOutlinedIcon /> : null}
            {!isCorrect && !isNotAttempted ? (
              <RedWrongCircleOutlinedIcon />
            ) : null}
          </Grid>
        </Grid>
        <Grid container direction="column" className={classes.contentWrapper}>
          <Grid item className={classes.questionWrapper}>
            <Grid item className={classes.question}>
              {parse(question?.question ?? '')}
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            className={classes.selectedChoiceWrapper}
          >
            <Typography className={classes.selectedChoiceHeading}>
              Answer
            </Typography>
            <Typography className={classes.selectedChoice}>
              {isNotAttempted ? <>NA</> : <>{question?.result?.userResponse}</>}
            </Typography>
          </Grid>

          <Grid
            container
            direction="column"
            className={classes.correctChoiceWrapper}
          >
            <Typography className={classes.correctChoiceHeading}>
              Correct Answer
            </Typography>
            <Typography className={classes.correctChoice}>
              {question?.result?.acceptedAnswers?.join(', ')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.marksContainer}>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.marksWrapper}
        >
          <Typography>
            <span className={classes.marks}>
              {question?.result?.marks}/{question?.maximumMarks}
            </span>{' '}
            Marks
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FillInTheBlanksResultRowItemMobile
