import React from 'react'

function DarkThemeIcon() {
  return (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Quiz-screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-27">
          <rect
            id="Rectangle"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <g
            id="Group-26"
            opacity="0.699999988"
            transform="translate(3.142857, 4.000000)"
            fill="#262B4B"
          >
            <path
              d="M8.74325258,0 C7.04086131,2.32697047 7.27964683,5.56215557 9.30481121,7.60827583 C11.3299756,9.6543961 14.5320267,9.89565251 16.8351648,8.17564533 C16.4734264,12.1305268 13.1285239,15.1183758 9.19932273,14.9963952 C8.17667518,14.9646475 7.20633423,14.7250934 6.32821766,14.3180637 C6.44648951,14.3086895 6.53200804,14.2964771 6.57852052,14.2800797 C7.87898998,14.2800797 8.93322877,13.2149319 8.93322877,11.9010055 C8.93322877,10.5870791 7.87898998,9.52193131 6.57852052,9.52193131 L6.57852052,9.52193131 L6.41730274,9.5274199 L6.38070574,9.53102159 C5.89837791,8.14023568 4.58770024,7.14285714 3.04645816,7.14285714 C2.67885387,7.14285714 2.32436589,7.19959585 1.99110522,7.30487834 C2.07713331,3.51505104 4.96412892,0.352853166 8.74325258,0 Z"
              id="Combined-Shape"
            />
            <path
              d="M3.15758477,9 C4.27641632,9 5.20869709,9.86111562 5.41533738,11.0021429 C6.21648011,11.0313593 6.85714286,11.6908622 6.85714286,12.5 C6.85714286,13.3284271 6.18556998,14 5.35714286,14 C5.30461014,14 5.25270816,13.9972995 5.20156816,13.9920297 C4.63599872,14.0209734 3.37652098,13.9603099 3.15758477,13.9603099 C1.88708578,13.9603099 0.857142857,12.8499067 0.857142857,11.480155 C0.857142857,10.1104032 1.88708578,9 3.15758477,9 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default DarkThemeIcon
