import React from 'react'

function RestoreIcon({ disabled }) {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Quiz-screens"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-33">
          <rect
            id="Rectangle-Copy-15"
            fill="#D8D8D8"
            opacity="0"
            x="0"
            y="0"
            width="24"
            height="24"
          />
          <g
            id="Group-32"
            opacity="0.699999988"
            transform="translate(4.000000, 4.000000)"
            fill={disabled ? '#7e7f82' : '#262B4B'}
          >
            <path
              d="M13.6591417,2.34062235 C10.5344258,-0.780207449 5.4723809,-0.780207449 2.34766499,2.34062235 L1.77853408,2.93643127 L-3.55271368e-15,1.15789719 L-3.55271368e-15,6.21782664 L5.03325145,6.21782664 L3.00572259,4.19029779 L3.60153151,3.59448887 C5.64906588,1.56766365 8.81989786,1.21071747 11.2668035,2.73159562 C13.7137091,4.25247377 14.7972453,7.25372938 13.8861791,9.98692819 C12.9751128,12.720127 10.3075304,14.4710098 7.43747906,14.2195689 C4.56742767,13.968128 2.24492979,11.7800719 1.82299743,8.93009111 L0.044463352,8.93009111 C0.518458394,12.9651897 3.94056378,16.004937 8.00340336,15.9997707 C11.2382899,15.9969188 14.1533866,14.0469865 15.3909392,11.0581802 C16.6284918,8.06937394 15.9451304,4.62945506 13.6591417,2.34062235 Z"
              id="Path"
              fillRule="nonzero"
            />
            <circle id="Oval" cx="8" cy="8" r="2" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default RestoreIcon
