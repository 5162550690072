import { useMutation, useQuery } from 'react-query'
import { codeRun } from '../../Repositories/Compile/Compile'

const compileReq = codeRun()

/**
 * this function is to Query get All application
 * @function useCompilePostQuery
 ** @param {options} default parameter
 */
export function useCompilePostQuery(options) {
  return useMutation((args) => compileReq.postCompile(args), {
    ...options,
  })
}

/**
 * this function is to Query get All application
 * @function useCompileGetQuery
 ** @param {options} default parameter
 */
export function useCompileGetQuery(reqId, options) {
  return useQuery(
    ['getCompileQuery', reqId],
    () => compileReq.getCompile(reqId),
    {
      ...options,
    }
  )
}
